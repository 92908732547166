import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../common/state/hooks";
import {
  getAllSessionsByProjectGroup,
  sessionsByProjectGroupSelector,
} from "../../state/statsSlice";
import ButtonText from "../../../../common/components/Button/ButtonText";
import InfoCard from "../../../../common/components/Cards/InfoCard";
import BarChartSessionsPerMonth from "../Charts/BarChartSessionsPerMonth";
import PieChartSessionsPerProject from "../Charts/PieChartSessionsPerProject";
import { IsLoading, useLoading } from "../../../../common/components/AppState/IsLoading";
import { useNavigate } from "react-router-dom";
import InfoButton from "../../../../common/components/InfoButton/InfoButton";
import { getBuilds } from "src/features/builds/state/buildsSlice";
import { getCurrentProjectGroupForSearch } from "src/features/projectGroups/state/projectGroupsSlice";
import Spinner from "src/common/components/Loader/Spinner";
import { LoadingState } from "src/common/state/slice/appStateSlice";
import {
  ANONYMOUS_SESSION_COUNTER_COMPONENT,
  AnonymousSessionCounter,
} from "src/features/subscription/components/AnonymousSessionsCounter";
import { fetchAnonymousSessionsCount } from "src/features/subscription/state/subscriptionSlice";

export const STATS_OVERVIEW_PROJECT_GROUPS_COMPONENT_ID = "statsOverviewProjectGroupsComponent";
export const STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID =
  "statsOverviewProjectGroupsSessionsComponent";
export const BUILDS_COMPONENT_ID = "buildsComponent";

interface Props {
  simplified?: boolean;
}

const StatsOverview = ({ simplified = false }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [numberComputing, setNumberComputing] = useState(true);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [publishedProjects, setPublishedProjects] = useState(0);
  const [waitingForPublishProjects, setWaitingForPublishProjects] = useState(0);

  const builds = useSelector(getBuilds);
  const projectsGroup = useSelector(getCurrentProjectGroupForSearch);

  const loadingBuilds = useLoading(BUILDS_COMPONENT_ID);
  const loadingProjectsGroup = useLoading(STATS_OVERVIEW_PROJECT_GROUPS_COMPONENT_ID);
  const loadingSessions = useLoading(STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID);

  // If there's zero eligible session (session that belongs to a published project), the API (GET custom/projectsSessions) will return an empty array
  // We can use this fact to easily display "no data" messages where it's due
  const projectGroupWithSessions = useSelector(sessionsByProjectGroupSelector);
  const noEligibleSession = projectGroupWithSessions.length === 0;

  const calculateProjects = async () => {
    if (
      Array.isArray(builds) &&
      Array.isArray(projectsGroup) &&
      Array.isArray(projectGroupWithSessions)
    ) {
      const latestBuildsMap = new Map();

      const relevantBuilds = builds.filter((build) => {
        return (
          projectGroupWithSessions.some((pg) => pg.project_group_id === build.project_group?.id) &&
          !build.project_group.is_deleted
        );
      });

      relevantBuilds.forEach((build) => {
        const projectId = build.project_group?.id;
        if (projectId) {
          const existingBuild = latestBuildsMap.get(projectId);
          if (!existingBuild || new Date(build.createdAt) > new Date(existingBuild.createdAt)) {
            latestBuildsMap.set(projectId, build);
          }
        }
      });

      const uniqueBuilds = Array.from(latestBuildsMap.values());

      let published = 0;
      let waitingForPublish = 0;
      uniqueBuilds.forEach((build) => {
        if (build.status === "Published") {
          published++;
        } else if (build.status === "PublishWaiting") {
          waitingForPublish++;
        }
      });

      setPublishedProjects(published);
      setWaitingForPublishProjects(waitingForPublish);
    } else {
      console.error("Expected builds, projectsGroup and projectGroupWithSessions to be arrays.");
    }
  };

  useEffect(() => {
    const calculate = async () => {
      setNumberComputing(true);
      if (
        loadingBuilds === LoadingState.Success &&
        loadingProjectsGroup === LoadingState.Success &&
        loadingSessions === LoadingState.Success
      ) {
        await calculateProjects();
        setNumberComputing(false);
      }
    };

    calculate();
  }, [loadingBuilds, loadingProjectsGroup, loadingSessions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setRerenderKey(rerenderKey + 1);
    });
    dispatch(
      getAllSessionsByProjectGroup({
        componentId: STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID,
      }),
    );
    dispatch(fetchAnonymousSessionsCount({ componentId: ANONYMOUS_SESSION_COUNTER_COMPONENT }));
  }, []);

  return (
    <>
      {!simplified ? (
        <div>
          <div className="flex space-x-6 items-end">
            <h2 className="text-2xl text-black font-medium">{t("sidebar.statistics")}</h2>
            <ButtonText text={t("general.viewAll")} onClick={() => navigate("statistics")} />
          </div>
        </div>
      ) : (
        <InfoButton relevantArticle="aboutStatistiques" />
      )}
      <IsLoading
        componentId={STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID}
        showSuccess={false}
      >
        {true && (
          <div className="w-full">
            <AnonymousSessionCounter
              numberOfPaywalledAnonymousSessions={1}
              className="bg-yellow-100 w-full pt-2 pb-4"
              withSpinner={false}
            />
          </div>
        )}
        <div className="h-[7.375rem] mb-12">
          {numberComputing ? (
            <div className="flex items-center justify-center relative h-full">
              <Spinner />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-6 xl:gap-9 py-6 xl:pb-12">
              <InfoCard
                title={t("pages.statistics.numberOfPublishedProjectGroups")}
                data={String(publishedProjects)}
              />
              <InfoCard
                title={t("pages.statistics.numberOfWaitingPublishProjectGroups")}
                data={String(waitingForPublishProjects)}
              />
            </div>
          )}
        </div>

        <div className="relative">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
            <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
              <p className="text-gray-800 font-medium text-base text-center p-2">
                {t("pages.statistics.sessionsPerMonth")}
              </p>
              <BarChartSessionsPerMonth noSession={noEligibleSession} />
            </div>
            <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
              <p className="text-gray-800 font-medium text-base text-center p-2">
                {t("pages.statistics.sessionsPerProjectGroup")}
              </p>
              <PieChartSessionsPerProject
                noSession={noEligibleSession}
                noDataMessage={t("pages.statistics.noData")}
              />
            </div>
          </div>
        </div>
      </IsLoading>
    </>
  );
};

export default StatsOverview;
