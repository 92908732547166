import { t } from "i18next";
import { setMediaFilter } from "../../../features/gallery/state/gallerySlice";
import { useAppDispatch } from "../../state/hooks";
import { openStandardModal } from "../../state/slice/modal/modalSlice";
import { AppDispatch } from "../../state/store";
import { ButtonText } from "../Button";
import { Tooltip } from "../Tooltip/Tooltip";

type ListItemProps = {
  title: string;
  disabled?: boolean;
  setDisabled?: (arg: boolean) => void;
  avatarStatus?: string;
  setFocus?: any;
  children?: React.ReactNode;
  setValueFct?: any;
};

const ProfileInputStyle = ({
  title,
  disabled,
  setDisabled,
  avatarStatus,
  setFocus = null,
  children,
}: ListItemProps) => {
  const dispatch: AppDispatch = useAppDispatch();

  const openPhotoGallery = () => {
    dispatch(openStandardModal("modal-gallery"));
    dispatch(
      setMediaFilter({
        mediaType: "image",
        labels: [],
        order: "date_desc",
        media360tag: "",
      }),
    );
  };

  const isAvatarField = title === t("pages.profile.main.avatar");
  const isRoleField = title === t("general.roles.role");
  const isPasswordField = title === t("general.password");
  const isPhotoField = title === t("pages.profile.main.photo");
  const isEmailField = title === t("pages.profile.main.email");

  return (
    <div className="w-full flex flex-col sm:flex-row items-center py-2 px-2 xl:py-3 xl:px-4 border-t border-gray-300">
      <p className="w-full sm:w-1/3 text-sm text-center sm:text-left">{title}</p>
      {children}
      <div className="flex grow justify-end">
        {isAvatarField && avatarStatus !== "loading" && (
          <div className="border-r-[1px] mr-2 pr-2 border-gray-300">
            <ButtonText
              type="button"
              text={t("general.preview")}
              onClick={() => {
                dispatch(openStandardModal("modalPreviewAvatar3d"));
              }}
            />
          </div>
        )}

        {!isRoleField && !isAvatarField && (
          <ButtonText
            type="button"
            text={t("general.edit")}
            onClick={() => {
              if (isPasswordField) {
                dispatch(openStandardModal("modalConfirmPassword"));
              } else if (isPhotoField) {
                openPhotoGallery();
              } else if (isEmailField) {
                dispatch(openStandardModal("modalConfirmPasswordForEmail"));
              } else {
                if (setDisabled) {
                  setDisabled(!disabled);
                }
              }
              if (setFocus) {
                setFocus();
              }
            }}
          />
        )}
        {isAvatarField && avatarStatus !== "loading" && (
          <Tooltip classNameChildren="right-1" message={t("tooltip.avatar3dCreation")}>
            <ButtonText
              type="button"
              text={t("general.edit")}
              onClick={() => {
                dispatch(openStandardModal("modalEditAvatar3d"));
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ProfileInputStyle;
