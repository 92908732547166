type Props = {
  s3Url: string;
  projectName: string;
  disabled?: boolean;
};

export default function ProjectCard({ s3Url, projectName, disabled = false }: Props) {
  return (
    <button
      className="relative inline-flex rounded-lg font-medium w-full h-32 bg-center bg-cover bg-black bg-opacity-40 bg-blend-soft-light hover:shadow-2xl"
      style={{ backgroundImage: `url(${s3Url})` }}
    >
      <div
        className={`px-3 pt-2 pb-1 flex grow flex-col h-full justify-between w-full ${
          disabled ? "cursor-not-allowed" : ""
        }`}
      >
        <p className="text-left text-white leading-snug text-ellipsis overflow-clip card-title-outline">
          {projectName}
        </p>
      </div>
    </button>
  );
}
