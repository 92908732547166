import { useState, useEffect, ReactElement, createRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Unity, useUnityContext } from "react-unity-webgl";
import { RiFullscreenLine } from "react-icons/ri";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import { isBrowserSupportingWebGL } from "../../../common/util/isBrowserSupportingWeb";
import UnsupportedWebGL from "../../../common/components/UnsupportedWebGL/UnsupportedWebGL";
import { AppDispatch } from "../../../common/state/store";
import { useTranslation } from "react-i18next";
import InfoButton from "../../../common/components/InfoButton/InfoButton";
import { setAlert } from "../../../features/alert/state/alertsSlice";
import { useParams } from "react-router-dom";
import ProgressBar from "../ProgressBar/ProgressBar";

const viewerFolder = "/ProjectPreviewWebGL/";
let currentUnload: (() => Promise<any>) | null = null;

export default function ProjectPreviewWebGL({
  projectGroupId = -1,
  projectId = -1,
}: {
  projectGroupId?: number;
  projectId?: number;
}): ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const jwt = String(useSelector(jwtSelector));
  const isSupportingWebGL = useMemo(() => isBrowserSupportingWebGL(), []);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const canvasRef = createRef<HTMLCanvasElement>();

  const params = useParams();

  const { unityProvider, sendMessage, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: viewerFolder + "Build/ProjectPreviewWebGL.loader.js",
    dataUrl: viewerFolder + "Build/ProjectPreviewWebGL.data",
    frameworkUrl: viewerFolder + "Build/ProjectPreviewWebGL.framework.js",
    codeUrl: viewerFolder + "Build/ProjectPreviewWebGL.wasm",
  });

  currentUnload = unload;

  const configureUnityApp = () => {
    if (projectId < 0 && projectGroupId < 0) {
      projectId = Number(params.projectId ?? -1);
      projectGroupId = Number(params.id ?? -1);
    }

    sendMessage("DataManager", "SetProjectGroupID", projectGroupId);
    sendMessage("DataManager", "SetProjectID", projectId);
    sendMessage("DataManager", "SetBuildID", 0);
    sendMessage("DataManager", "SetToken", jwt);
    sendMessage("ServerConfig", "SetCustomStrapi", process.env.REACT_APP_API_ENDPOINT + "/");
    sendMessage("DataManager", "SignalIdSetup");

    canvasRef.current?.setAttribute("tabindex", "1");
    canvasRef.current?.focus();

    setIsLoading(false);
  };

  const unloadUnity = () => {
    if (currentUnload)
      currentUnload()
        .then(() => (currentUnload = null))
        .catch((e) => {
          console.error("Unload WebGL error : ", e);
          currentUnload = null;
        });
  };

  useEffect(() => {
    return () => {
      unloadUnity();
    };
  }, []);

  useEffect(() => {
    dispatch(
      setAlert({
        msg: t("pages.projectGroup.preview.experimentalWarning"),
        type: "warning",
        isOpen: true,
      }),
    );
  }, [jwt, projectGroupId]);

  useEffect(() => {
    if (isLoaded) {
      configureUnityApp();
    }
  }, [isLoaded]);

  const toggle = () => {
    const elem = document.querySelector("#div-unity");
    const requestFS = () =>
      elem
        ? elem?.requestFullscreen().catch((err) => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          })
        : null;

    if (!document.fullscreenElement) {
      requestFS();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="h-3/4 p-2">
      <InfoButton relevantArticle="aboutWebgl" />
      <div
        className={`w-full h-full flex flex-col justify-center items-center ${
          isLoading ? "" : "hidden"
        }`}
      >
        <p>{t("general.loading")}</p>
        <ProgressBar
          wrapperClassName="w-1/4 flex bg-gray-500 h-[3%]"
          barClassName="bg-gray-900"
          value={loadingProgression * 100}
        />
      </div>
      <div id="div-unity" className="h-full flex flex-col items-center">
        {isSupportingWebGL ? (
          <>
            <Unity
              unityProvider={unityProvider}
              ref={canvasRef}
              className={isLoading ? "hidden" : "visible"}
              style={{ height: "100%", width: "100%" }}
            />
          </>
        ) : (
          <UnsupportedWebGL />
        )}
        {isSupportingWebGL && (
          <button
            className={`relative mr-2 mt-[-3rem] self-end btn-fullscreen ${
              isLoading ? "hidden" : "visible"
            }`}
            onClick={() => {
              toggle();
            }}
          >
            <RiFullscreenLine fill="white" size={40} />
          </button>
        )}
      </div>
    </div>
  );
}
