// Translator.tsx
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import i18n, { getBrowserLanguage } from "./i18n";
import { ILanguage } from "../../model/model";
import { languageConfig } from "../util/langConfig";

interface ITranslatorProps {
  children: any;
}

const Translator: React.FunctionComponent<ITranslatorProps> = ({ children }) => {
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser) {
      const userLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
      i18n.changeLanguage(userLocale);
    } else {
      const defaultLanguage = getBrowserLanguage();
      i18n.changeLanguage(defaultLanguage);
    }
  }, [currentUser]);

  return children;
};

export default Translator;
