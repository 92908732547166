import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAnonymousSessionsCount,
  fetchPaEmbedLinks,
  getPaSubscribedIframeUrl,
} from "../../../features/subscription/state/subscriptionSlice";
import { jwtSelector } from "../../state/selectors/authSelector";
import { AppDispatch } from "../../state/store";
import { IsLoading } from "../AppState/IsLoading";
import { ANONYMOUS_SESSION_COUNTER_COMPONENT } from "src/features/subscription/components/AnonymousSessionsCounter";

interface ISubscriptionProps {
  msgCb(event: any): void;
}

const SUBSCRIPTION_IFRAME_COMPONENT = "subscriptionIframeComponent";

const SubscriptionIFrame: React.FunctionComponent<ISubscriptionProps> = ({
  msgCb,
}: ISubscriptionProps) => {
  const dispatch: AppDispatch = useDispatch();
  const jwt = useSelector(jwtSelector);
  const proabonoHomeLink = useSelector(getPaSubscribedIframeUrl);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener("message", msgCb, false);
  }, [msgCb]);

  useEffect(() => {
    dispatch(fetchPaEmbedLinks({ componentId: SUBSCRIPTION_IFRAME_COMPONENT }));
    dispatch(fetchAnonymousSessionsCount({ componentId: ANONYMOUS_SESSION_COUNTER_COMPONENT }));
  }, [jwt]);

  return (
    <div className="flex flex-column justify-center h-full w-full">
      <div className="container center-item h-full">
        {proabonoHomeLink ? (
          <>
            <iframe
              title="proabono-iframe-title"
              id="proabono-iframe"
              src={`${proabonoHomeLink}`}
              style={{
                width: "100%",
                height: "100%",
                minHeight: "inherit",
                visibility: isLoaded ? "visible" : "hidden",
              }}
              frameBorder="0"
              onLoad={() => {
                setIsLoaded(true);
              }}
            ></iframe>
          </>
        ) : (
          <IsLoading componentId={SUBSCRIPTION_IFRAME_COMPONENT} showSuccess={false} />
        )}
      </div>
    </div>
  );
};
export default SubscriptionIFrame;
