import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";

export default function NewPublicationButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tooltip message={""} classNameChildren="right-0">
      <button
        className="btn-primary-fill"
        onClick={() => {
          navigate("./new");
        }}
      >
        <div className="flex items-center justify-center">
          <PlusIcon className="mr-2" height={15} width={15} />
          {t("pages.projectGroup.publish.newPublication")}
        </div>
      </button>
    </Tooltip>
  );
}
