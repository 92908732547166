import * as React from "react";
import ModalStandard from "src/common/components/Modal/ModalStandard";
import { IMedia } from "src/model/unityObject";
import Player360 from "./Player360";
import Model3dViewerWeb from "./Model3dViewerWeb";
import { prettifyFileSizeInBytes } from "src/common/util/prettifyFileSizeInBytes";
import { ILanguage } from "src/model/model";
import { languageConfig } from "src/common/util/langConfig";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { prettifyMediaType } from "src/common/util/prettifyMediaType";

interface IModalPreviewProps {
  media: IMedia;
  onCloseCb(arg?: any): any;
}

export const ModalPreview: React.FC<IModalPreviewProps> = ({ media, onCloseCb }) => {
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const MediaPreviewer = () => {
    // 360° and 180° medias (previewed with WebGL)
    if (
      (media.media_360_tag === "media360" || media.media_360_tag === "video180stereo") &&
      media.type !== "model3d"
    ) {
      return (
        <Player360
          mediaUrl={media.s3_url}
          mediaType={media.type as "image" | "video"}
          mediaTag360={media.media_360_tag as "media2d" | "media360" | "video180stereo"}
          className="w-full h-full object-contain rounded-2xl"
          canvasClassName="rounded-2xl"
          disableFullScreen // 25/09/24 : until we fix all the webgl fullscreen bugs
        />
      );
    }

    // 2D images
    if (media.type === "image") {
      return <img className="object-contain w-full h-full" src={media.s3_url} alt="image-media" />;
    }
    // Videos
    if (media.type === "video") {
      return (
        <video controls autoPlay>
          <source src={media.s3_url} type="video/mp4" />
        </video>
      );
    }
    // Audios
    if (media.type === "audio") {
      return (
        <audio id="myAudio" className="w-full" controls autoPlay>
          <source src={media.s3_url} />
        </audio>
      );
    }
    // PDF
    if (media.type === "pdf") {
      return <p>coming soon(tm)</p>;
    }
    // Model 3D
    if (media.type === "model3d") {
      return (
        <Model3dViewerWeb
          modelUrl={media.s3_url}
          className="w-full h-[95%]"
          canvasClassName="rounded-2xl"
        />
      );
    }

    return (
      <img
        className="object-contain h-full w-full"
        src="https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/link_broken.png"
        alt="Broken link"
      />
    );
  };

  return (
    <ModalStandard
      id={`preview_${media.id}`}
      className="w-1/2 h-2/3 relative"
      onClose={onCloseCb}
      modalType="mediaPreview"
      overFlowOff
      showCloseButton
    >
      <div className="flex flex-col h-full">
        <div className="media-preview-container flex items-center justify-center flex-grow overflow-auto">
          <MediaPreviewer />
        </div>
        <div className="flex justify-between mb-4 mt-2 flex-shrink-0">
          <div className="flex">
            <p className="mr-12 text-sm">{media.name}</p>
            <p className="text-gray-500 text-sm">
              {prettifyFileSizeInBytes(Number(media.size_in_bytes), currentLocale)}
            </p>
          </div>
          <p className="text-gray-500 ">
            {prettifyMediaType({ type: media.type, media_360_tag: media.media_360_tag })}
          </p>
        </div>
      </div>
    </ModalStandard>
  );
};
