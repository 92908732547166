import { useTranslation } from "react-i18next";
import { Input } from "../Input";

interface ICreateOrUpdateUserAsAdminFormProps {
  readOnly?: boolean;
  isFormSimplified?: boolean;
  register: any;
  errors: any;
  firstname?: string;
  lastname?: string;
  email?: string;
}

export const CreateOrUpdateUserAsAdminForm = ({
  readOnly = false,
  isFormSimplified = false,
  register,
  errors,
  firstname,
  lastname,
  email,
}: ICreateOrUpdateUserAsAdminFormProps) => {
  const { t } = useTranslation();

  const readOnlyStyle = {
    opacity: 0.65,
    cursor: "not-allowed",
  };

  return (
    <div className="flex flex-col w-full items-center" style={readOnly ? readOnlyStyle : {}}>
      <div className="w-full px-5 pt-4">
        <Input
          disabled={readOnly}
          style={readOnly ? readOnlyStyle : {}}
          type="newUserFirstName"
          label={t("general.firstname")}
          placeholder={readOnly ? firstname : t("general.firstname")}
          registerFct={() => register("newUserFirstName", { required: true })}
          className="rounded border-2 w-72 border-solid border-gray-300 p-2 placeholder:italic"
        />
        <span className={`text-red-500 ${errors.newUserFirstName ? "" : "invisible"}`}>
          {t("pages.project.invalidFirstName") as any}
        </span>
      </div>
      <div className="w-full px-5 pt-4">
        <Input
          disabled={readOnly}
          style={readOnly ? readOnlyStyle : {}}
          type="newUserLastName"
          label={t("general.lastname")}
          placeholder={readOnly ? lastname : t("general.lastname")}
          registerFct={() => register("newUserLastName", { required: true })}
          className="rounded border-2 w-72 border-solid border-gray-300 p-2 placeholder:italic"
        />
        <span className={`text-red-500 ${errors.newUserLastName ? "" : "invisible"}`}>
          {t("pages.project.invalidLastName") as any}
        </span>
      </div>
      <>
        {!isFormSimplified && (
          <div className="w-full px-5 pt-4">
            <Input
              disabled={readOnly}
              style={readOnly ? readOnlyStyle : {}}
              type="newUserEmail"
              label={t("general.email")}
              placeholder={readOnly ? email : t("general.email")}
              registerFct={() =>
                register("newUserEmail", { required: true, pattern: /^\S+@\S+\.\S+$/i })
              }
              className="rounded border-2 w-72 border-solid border-gray-300 p-2 placeholder:italic"
            />
            <span className={`text-red-500 ${errors.newUserEmail ? "" : "invisible"}`}>
              {t("pages.project.invalidEmail") as any}
            </span>
          </div>
        )}
      </>
    </div>
  );
};
