interface MailtoProps {
  mailto?: string;
  subject?: string;
  linkText: string;
  prefilledContent?: string;
  style?: string;
  textStyle?: string;
}

const Mailto = ({
  mailto = "",
  subject = "",
  linkText,
  prefilledContent = "",
  style = "",
  textStyle = "",
}: MailtoProps) => {
  return (
    <div className={`${style}`}>
      <a
        className={`${textStyle}`}
        href={`mailto:${mailto}?subject=${subject}&body=${prefilledContent}`}
      >
        {linkText}
      </a>
    </div>
  );
};

export default Mailto;
