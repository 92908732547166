import { SVGProps } from "react";
import { useTranslation } from "react-i18next";

export const AvailableFeatureCounter = ({
  currentNumberOfAvailable,
  totalNumberOfAvailable,
  text,
  className = "h-full",
}: {
  currentNumberOfAvailable: number;
  totalNumberOfAvailable: number;
  text: string;
  className?: string;
}) => {
  const customRed = "#F25C56";
  const customYellow = "#FFC107";
  const customGrey = "#ACB5BD";

  const { t } = useTranslation();
  const isOverLimit = Number(currentNumberOfAvailable) < 0;

  const generatePath = (ratio: number) => {
    const radius = 45;
    const cx = 50;
    const cy = 50;

    // start x and y at the top of the circle
    const xStart = cx;
    const yStart = cy - radius;

    const props = {
      strokeWidth: "10",
      fillOpacity: "0.0",
      strokeLinecap: "butt",
      stroke: isOverLimit ? customRed : customYellow,
    };

    if (ratio > 1) {
      return (
        <circle cx={50} cy={50} r={radius} {...(props as SVGProps<SVGCircleElement>)}></circle>
      );
    } else {
      const xEnd = cx + Math.sin(2 * Math.PI * ratio) * radius;
      const yEnd = cy - Math.cos(2 * Math.PI * ratio) * radius;

      return (
        <path
          d={`M ${xStart} ${yStart} A ${radius} ${radius} 0 ${
            ratio > 0.5 ? 0 : 1
          } 0 ${xEnd} ${yEnd}`}
          {...(props as SVGProps<SVGPathElement>)}
        />
      );
    }
  };

  const lineHeight = 80 / 6;

  return (
    <div
      className={className}
      style={{ backdropFilter: "blur(2px)", opacity: 1 }}
      id="available-participants-counter"
    >
      <svg height={"100%"} width={"100%"} viewBox={"0 0 120 100"}>
        <rect x="40" y="10" width="160" height="80" fill="white" rx="5" ry="5" />
        <text x={42 + 190 / 3} y={28 + lineHeight} fill="black" fontSize="75%">
          {text}
        </text>
        <text x={42 + 190 / 3} y={28 + 2 * lineHeight} fill="black" fontSize="75%">
          {Number(currentNumberOfAvailable) <= 1
            ? t("pages.participants.availableInSingular")
            : t("pages.participants.availableInPlural")}
        </text>
        <text x={42 + 190 / 3} y={28 + 3 * lineHeight} fill="black" fontSize="75%">
          {t("pages.participants.myOffer")}
        </text>
        <circle // "unfilled" outer circle
          className={`${customRed}`}
          cx="50"
          cy="50"
          r="45"
          fill={isOverLimit ? customRed : customGrey}
          stroke={isOverLimit ? customRed : customGrey}
          strokeWidth="10"
        ></circle>
        <circle // whole disk
          cx="50"
          cy="50"
          r="35"
          fill="#F1F5F9"
          stroke="#F1F5F9"
          strokeWidth="10"
        ></circle>

        {!isOverLimit && generatePath(1 - currentNumberOfAvailable / totalNumberOfAvailable)}
        <text
          fontSize={"200%"}
          fontWeight={"bold"}
          stroke="none"
          fill={isOverLimit ? customRed : "grey"}
          textAnchor="middle"
          alignmentBaseline="middle"
          x="50"
          y="45"
        >{`${currentNumberOfAvailable.toFixed(0)}`}</text>
        <text
          fontSize={"100%"}
          stroke="none"
          fill={isOverLimit ? customRed : "grey"}
          textAnchor="middle"
          alignmentBaseline="middle"
          x="50"
          y="67"
        >{`/${totalNumberOfAvailable.toFixed(0)}`}</text>
      </svg>
    </div>
  );
};

export default AvailableFeatureCounter;
