import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";

type Props = {
  wasStartScene: boolean;
  isStartScene: boolean;
  setIsStartScene: any;
  disabled?: boolean;
};

const StartSceneSVG = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.14453 8.31248C2.67285 8.51756 2.39258 8.94822 2.39258 9.46775C2.39258 9.83006 2.50195 10.0693 2.7959 10.3496L3.00781 10.5547V15.2099V19.8584L2.65234 19.9472C2.07812 20.084 1.59277 20.3574 1.09375 20.8222C0.389648 21.4853 0.00683594 22.3603 0 23.29C0 24.7392 0.957031 26.0928 2.3584 26.6055C2.68652 26.7217 2.91211 26.7558 3.48633 26.749C4.28613 26.749 4.75098 26.6191 5.33887 26.2431C5.76953 25.9697 6.36426 25.3203 6.5625 24.9238L6.71289 24.6094H10.5137H14.3213L14.5264 25.0058C14.9844 25.8808 15.9961 26.5849 17.0352 26.7422C18.4502 26.9541 19.8652 26.2568 20.5762 24.999L20.7949 24.6094H24.541H28.2871L28.4375 24.9238C28.6357 25.3203 29.2305 25.9697 29.6611 26.2431C30.249 26.626 30.7139 26.749 31.5137 26.749C32.0811 26.749 32.3203 26.7148 32.6416 26.5986C34.3437 25.9834 35.3076 24.2334 34.8975 22.4971C34.4668 20.6924 32.6279 19.5234 30.8164 19.8994C29.9141 20.084 28.9707 20.7812 28.5059 21.5947L28.2666 22.0049L24.5273 22.0117H20.7812L20.6035 21.6562C20.3643 21.1777 19.6123 20.4394 19.1406 20.2138C18.3408 19.831 17.5684 19.7353 16.7549 19.9267C15.832 20.1455 14.9434 20.8222 14.5264 21.6152L14.3213 22.0117H10.5273H6.7334L6.51465 21.6152C6.28223 21.1914 5.79004 20.6513 5.38672 20.3847C5.24316 20.2959 4.93555 20.1455 4.70312 20.0635L4.27246 19.9062L4.25195 15.251L4.23828 10.5888L4.47754 10.3633C4.76465 10.0898 4.86719 9.84373 4.86035 9.4199C4.86035 8.90721 4.59375 8.51756 4.08789 8.30564C3.77344 8.17576 3.45215 8.17576 3.14453 8.31248Z"
      fill="#6C757D"
    />
    <path
      d="M5.00391 10.9511C4.94239 11.0126 4.92188 11.7714 4.92188 13.952C4.92188 17.1034 4.91505 17.035 5.28419 17.035C5.48243 17.035 12.6328 14.1161 12.7969 13.9657C12.9404 13.8358 12.9541 13.6102 12.8379 13.4462C12.7354 13.3163 5.56446 10.869 5.27052 10.869C5.16798 10.869 5.05177 10.9032 5.00391 10.9511Z"
      fill="#6C757D"
    />
  </svg>
);

export default function StartSceneTickbox({
  wasStartScene,
  isStartScene = false,
  setIsStartScene,
  disabled = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center mt-8">
      <StartSceneSVG />

      <Tooltip
        message={
          wasStartScene
            ? t("pages.project.projectNeedsAStartScene")
            : t("pages.project.firstSceneTooltip")
        }
      >
        <label
          className={`ml-4 text-gray-600 select-none ${
            wasStartScene ? "cursor-not-allowed" : "cursor-pointer"
          } ${disabled ? "opacity-50" : ""}`}
        >
          <input
            type="checkbox"
            className={`appearance-none rounded-sm mr-2 ${
              wasStartScene ? "cursor-not-allowed" : "cursor-pointer"
            } checked:bg-green-500 ring-0`}
            defaultChecked={isStartScene}
            onClick={() => {
              /* Consigne du PO :On ne peut pas le décocher si l'état initial était true
            (mais on peut dans le cas où il était à false) + tooltip de pourquoi */
              if (!wasStartScene) {
                setIsStartScene(!isStartScene);
              }
            }}
            disabled={disabled}
          />
          {t("pages.project.firstScene")}
        </label>
      </Tooltip>
    </div>
  );
}
