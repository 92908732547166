import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import { useForm } from "react-hook-form";
import AccessTypeTable from "../../features/projects/components/AccessTypeTable";
import {
  getNewProjectGroup,
  INewProjectGroup,
  postProjectGroup,
  setCurrentElements,
  setNewProjectGroup,
  setSelectedProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { getCurrentOrganization, getCurrentUser } from "../../features/profile/state/profileSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { IsLoading } from "../../common/components/AppState/IsLoading";

interface ITemplateProjectAccess {
  decrementStep(): void;
}

export const PROJECT_GROUP_CREATE = "projectGroupCreate";

const TemplateProjectAccess = ({ decrementStep }: ITemplateProjectAccess) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);

  const currentOrg = useSelector(getCurrentOrganization);

  const { newProjectGroup } = useSelector((state: RootState) => {
    return {
      newProjectGroup: getNewProjectGroup(state),
    };
  });

  const { handleSubmit, setValue, watch } = useForm<INewProjectGroup>();

  const onSubmit = async (data: INewProjectGroup) => {
    if (currentOrg) {
      dispatch(
        postProjectGroup({
          name: newProjectGroup.newName,
          source_thumbnail: newProjectGroup.thumbnailId,
          organization: Number(currentOrg.id),
          auth_type: data?.accessType ? data.accessType : "classic",
          componentId: PROJECT_GROUP_CREATE,
          theme_info: newProjectGroup.themeId,
          created_by: currentUser?.email ? currentUser.email : "",
          source_app_main_menu_media: Number(newProjectGroup.menuMediaId),
        }),
      ).then((res: any) => {
        dispatch(setSelectedProjectGroup(res.payload.data));
        dispatch(
          setNewProjectGroup({
            accessType: "classic",
            newName: "",
            thumbnailId: 47,
            themeId: 2,
            menuMediaId: 0,
          }),
        );
        dispatch(setCurrentElements([]));
        navigate(`/courses/${res.payload.data.id}/list`);
      });
    }
  };

  return (
    <>
      <IsLoading componentId={PROJECT_GROUP_CREATE} showSuccess={false} spinnerPlaceholder>
        <h1 className="text-2xl font-medium w-full max-w-7xl mx-auto mt-6 mb-4 px-6 xl:px-3">
          {t("chooseTemplateType.accessType")} :
        </h1>
        <form
          className="flex flex-col grow w-full h-full justify-between items-center overflow-y-auto personalize-scroll-visible"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InfoButton relevantArticle="howToChooseAuthMode" />
          <div className="px-6 xl:px-3">
            <AccessTypeTable setValue={setValue} watch={watch} />
          </div>

          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate("/courses/")}
            backText={t("general.back")}
            backCb={() => decrementStep()}
            nextText={t("general.create")}
          />
        </form>
      </IsLoading>
    </>
  );
};

export default TemplateProjectAccess;
