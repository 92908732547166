import { UseFormRegisterReturn } from "react-hook-form";
import CommonInput from "./CommonInput";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    ReactHookFormInput {
  label?: string;
  highlight?: boolean;
  className?: string;
  setFocus?: any;
  highlightEnabled?: string;
}

export interface ReactHookFormInput {
  registerFct?: () => UseFormRegisterReturn;
}

const Input = ({
  highlight = true,
  className,
  label,
  registerFct,
  setFocus,
  ...props
}: InputProps) => {
  const highlightEnabled = highlight ? "" : "shadow-md shadow-blue-500/50";
  const prettyLabel = `${String(label)[0].toUpperCase()}${String(label).slice(1)}`; // https://stackoverflow.com/q/1026069

  return label ? (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700">{prettyLabel}</label>
      <div className="mt-1">
        <CommonInput
          className={className}
          registerFct={registerFct}
          highlightEnabled={highlightEnabled}
          {...props}
        />
      </div>
    </div>
  ) : (
    <CommonInput
      className={className}
      registerFct={registerFct}
      highlightEnabled={highlightEnabled}
      {...props}
    />
  );
};

export default Input;
