/* eslint-disable indent */
import { Content } from "./dataTypes/Content";
import { ContentType, isAssetContentType, isSceneContentType } from "./dataTypes/ContentType";
import { Action } from "./dataTypes/Action";
import { Node, NodeAsset, NodeLink, NodeScene, RootLayoutRect } from "./nodeTypes";
import SVGNodeScene from "../components/svg/SVGNodeScene";
import SVGNodeAsset from "../components/svg/SVGNodeAsset";

const SPACING = 30;
const VSPACING = 2 * SPACING;
const ASSET_TOP = Math.max(0, (SVGNodeScene.HEIGHT - SVGNodeAsset.HEIGHT) / 2);
//const SCENE_TOP = Math.max(0, (SVGNodeAsset.HEIGHT - SVGNodeScene.HEIGHT) / 2);

type NodesById = { [id: number]: Node };

type NodeWithLayout = NodeAssetWithLayout | NodeSceneWithLayout;
type NodeAssetWithLayout = NodeAsset & {
  layout?: RecursiveLayoutRect;
};
type NodeSceneWithLayout = NodeScene & {
  layout?: RecursiveLayoutRect;
};
type RecursiveLayoutRect = RootLayoutRect & {
  parent?: RecursiveLayoutRect;
};

const nullLayoutRect: Readonly<RootLayoutRect> = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

//////////////////////////////////////////////////////////////////////////////
// ENTRYPOINT: Build the "graph" of components
//////////////////////////////////////////////////////////////////////////////

export const buildGraph = (
  contents: Content[],
  contentTypes: ContentType[],
  actions: Action[],
): [Node[], RootLayoutRect, NodeLink[], any[]] => {
  // wrap the input data in Node instances
  // ➡︎ we keep content.id as contentId
  // ➡︎ the rest of the node is holding contentType info
  const [startScene, nodesById] = buildGraphNodes(contents, contentTypes);

  // sanity check
  if (!startScene) {
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG)
      console.warn("Could not find a start scene");
    return [[], { ...nullLayoutRect }, [], []];
  }

  // after the previous step, all the node are initialized with empty, "children" and "assets" sets.
  // ➡︎ the step below will fix that by running all the provided actions and filling there lists
  locateChildrenAndAssets(startScene, nodesById, actions);

  // now all that's left if to compute the x and y coordinates of each node
  // ➡︎ we ensure we always start with the "START SCENE", even if we find other nodes without a parent (orphans)
  // ➡︎ ⚠️ for simplicity (and memory) sake this function (and the one above) MODIFY the data in "nodesById", this
  //   especially implies that:
  //   • the calls are ORDER-DEPENDANT
  //   • special care must be taken not to keep pointers to data between these steps
  const rootLayoutRect = computeGraphLayout(startScene);

  // and finally we collect the links to display
  const [links, visibleNodes] = collectNodeLinks(startScene, actions);

  // isolate & map unlinked/hidden Scene nodes
  const visibleSceneIds = visibleNodes
    .filter((node) => node.modelName === "content-scene")
    .map((node) => Number(node.contentTypeId));

  const hiddenScenes = contentTypes
    .filter(
      (ct) => ct.modelName === "content-scene" && !visibleSceneIds.includes(Number(ct.object.id)),
    )
    .map((ct) => {
      return {
        type: "scene",
        modelName: ct.modelName,
        details: ct.object,
        parents: {},
        contentId: ct.object.content,
        contentTypeId: ct.object.id,
        x: 0,
        y: 0,
      };
    });

  // and we return the list of all nodes
  return [visibleNodes, rootLayoutRect, links, hiddenScenes];
};

const collectNodeLinks = (rootNode: NodeScene, actions: Action[]) => {
  const visibleNodes = new Set<Node>();
  visibleNodes.add(rootNode);
  const linkByUniqueID = new Map<string, NodeLink>();

  // we generate a unique ID for each link, to avoid generating them twice
  const getUniqueKey = (from: Node, to: Node) =>
    from.contentId < to.contentId
      ? `${from.contentId}-${to.contentId}`
      : `${to.contentId}-${from.contentId}`;

  // and use it before adding a new node
  const addLink = (from: Node, to: Node) => {
    const uniqueKey = getUniqueKey(from, to);
    linkByUniqueID.set(uniqueKey, { from, to });
    visibleNodes.add(from);
    visibleNodes.add(to);
  };

  function _rec(node: NodeScene) {
    let from: Node = node;
    if (node.children.size <= 1) {
      // display all the nodes
      for (const to of Array.from(node.assets)) {
        addLink(from, to);
        from = to;
      }
    }
    for (const child of Array.from(node.children)) {
      addLink(from, child);
      _rec(child);
    }
  }
  _rec(rootNode);

  const mappedLinks = Array.from(linkByUniqueID.values()).map((link, index) => {
    const linksToScene = link.to.modelName === "content-scene" ? true : false;
    const isNotThatSimple =
      link.to.modelName === "content-scene" &&
      actions.filter((a) => {
        return (
          Number(a.affected_content) === Number(link.to.contentId)
          //&& (a.is_activate)
        );
      }).length <= 1;
    return { ...link, linksToScene, isNotThatSimple, index };
  });
  return [mappedLinks, Array.from(visibleNodes)] as const;
};

const buildGraphNodes = (contents: Content[], contentTypes: ContentType[]) => {
  let startScene: NodeScene | null = null;
  const nodesById = {} as { [id: number]: Node };

  // first we collect all the components and build the nodes
  // (it's basically "zipping" content and contentType + a few additional info)
  for (const content of contents) {
    const contentType = contentTypes.find((contentType) => {
      return Number(contentType?.object?.content) === Number(content.id);
    });
    //("OHAIOOOO ~~~~~ in 149 buildGraphNodes, nani contentType ? ", contentType);
    if (!contentType) {
      if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG)
        console.warn(
          `Missing content type #${content.content_type} for content: ${JSON.stringify(
            content,
            null,
            2,
          )}`,
        );
      continue;
    }

    // then we build the node instances
    const nodeInfo = {
      parents: new Set<NodeScene>(), // ⬅︎ all nodes have parents !
      contentId: content.id,
      contentTypeId: contentType.object.id,
      x: 0,
      y: 0,
    };
    // we detect SCENE node since they have more fields
    if (isSceneContentType(contentType)) {
      // node is a SCENE
      const sceneNode: NodeScene = {
        type: "scene",
        modelName: contentType.modelName,
        details: contentType.object,
        children: new Set(), // ⬅︎ it will have a set of children
        assets: new Set(), // ⬅︎ and a set of assets
        ...nodeInfo,
      };
      nodesById[content.id] = sceneNode;
      if (contentType.object.is_start_scene) {
        startScene = sceneNode;
      }
    } else if (isAssetContentType(contentType)) {
      // node is an ASSET (of any kind...)
      nodesById[content.id] = {
        type: "asset",
        modelName: contentType.modelName,
        details: contentType.object,
        ...nodeInfo,
      };
    } else {
      if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG) {
        console.warn("Ignoring non-scene non-asset content:", content, contentType);
      }
    }
  }
  return [startScene, nodesById] as const;
};

const locateChildrenAndAssets = (
  startScene: NodeScene,
  nodesById: NodesById,
  actions: Action[],
) => {
  //
  // STEP 1: prepare the functions that detect the assets and children
  //
  // 1.a:︎ after this step we know, for each node, it's direct children (either asset or scene)
  const directChildrenByContentId = collectDirectChildrenByContentId(nodesById, actions);
  // 1.b: the function below will find, for a given node, the related SCENES
  const findChildrenScenes = getChildrenLookupFunction(directChildrenByContentId);
  // 1.c: the function below will find, for a given node, the related ASSETS
  const findLinkedAssets = getAssetLookupFunction(directChildrenByContentId);

  //
  // STEP 2: we collect the children, stating with the root node
  //
  // ➡︎ we use a simple BFS traversal
  let nextScenes = new Set([startScene]);
  const visitedScenes = new Set([startScene]);
  while (nextScenes.size > 0) {
    // we copy the scenes to process
    const processedScenes = Array.from(nextScenes);
    // and reset the "next scenes": we will fill the set while exploring the children
    nextScenes = new Set();

    for (const scene of processedScenes) {
      // load the children
      const children = findChildrenScenes(scene, new Set(visitedScenes));
      if (children) {
        // FINALLY we update the current node
        scene.children = children;
        scene.assets = findLinkedAssets(scene);
        // and push the children to the list
        children.forEach((child) => {
          nextScenes.add(child);
        });
      }
    }
    // delaying this below allows us to collect nodes with a shared parent
    nextScenes.forEach((scene) => visitedScenes.add(scene));
  }
};

const computeGraphLayout = (startScene: NodeScene): RootLayoutRect => {
  // we do the layout
  const { width, height } = layoutNodesUsingRelativeLayouts(startScene);
  flattenNodeCoordinatesRecursively(startScene);

  return {
    ...nullLayoutRect,
    width,
    height,
  };
};

const flattenNodeCoordinatesRecursively = (node: NodeWithLayout) => {
  if (node.type === "scene") {
    node.assets.forEach(flattenNodeCoordinates);
    node.children.forEach(flattenNodeCoordinatesRecursively);
  }
  flattenNodeCoordinates(node);
};

const flattenNodeCoordinates = (node: NodeWithLayout) => {
  if (!node.layout) return;
  const { x, y } = getAbsoluteLayout(node.layout);
  node.x += x;
  node.y += y;
  // turn back our NodeWithLayout into simple Node instances
  delete node.layout;
};

// Compute nodes position into a hierarchy of layout rectangles.
// Each node position is relative to it's parent layout, which in turn has a position in it's parent layout and so up
// to the root layout:
//
// ┌────────────────────────────────────────────────────────────────────────────────────┐
// │                                           ┌──────────────────────────────────────┐ │
// │                                           │┌────────────────────────────────────┐│ │
// │                                           ││  .─.         .         .         . ││ │
// │                                         ┌─┼┼─(   )───────( )───────( )───────( )││ │
// │ ┌──────────────────────────┐   ┌───────┐│ ││  `─'         '         '         ' ││ │
// │ │  .─.         .         . │   │  .─.  ││ │└────────────────────────────────────┘│ │
// │ │ (   )───────( )───────( )├───┼──   ──┼┤ │                                      │ │
// │ │  `─'         '         ' │   │  `─'  ││ │┌────────────────────────────────────┐│ │
// │ └──────────────────────────┘   └───────┘│ ││  .─.         .         .         . ││ │
// │                                         └─┼┼─(   )───────( )───────( )───────( )││ │
// │                                           ││  `─'         '         '         ' ││ │
// │                                           │└────────────────────────────────────┘│ │
// │                                           └──────────────────────────────────────┘ │
// └────────────────────────────────────────────────────────────────────────────────────┘
//
// BEWARE: this hierarchy must be "flattened" before rendering using the flatten* methods.
//
const layoutNodesUsingRelativeLayouts = (rootNode: NodeScene): RecursiveLayoutRect => {
  // the global layout
  let lastProcessedLayout: RecursiveLayoutRect | null = null;

  // the layout we are building
  let nextScenes = new Set([rootNode]);
  //const visitedScenes = new Set([rootNode]);
  while (nextScenes.size > 0) {
    const processedScenes = Array.from(nextScenes);
    nextScenes = new Set();

    // layout depends on scene types
    const layout =
      processedScenes.length === 1
        ? layoutCurrentSceneWithAssets(processedScenes[0])
        : layoutChildrenStacked(processedScenes);

    if (!lastProcessedLayout) {
      lastProcessedLayout = layout;
    } else {
      // we build the enclosing layout for horizontally-stacked nodes
      const enclosingLayout: RecursiveLayoutRect = {
        ...nullLayoutRect,
        width: lastProcessedLayout.width + SPACING + layout.width,
        height: Math.max(lastProcessedLayout.height, layout.height),
      };
      lastProcessedLayout.y = (enclosingLayout.height - lastProcessedLayout.height) / 2;
      layout.x = lastProcessedLayout.width + SPACING;
      layout.y = (enclosingLayout.height - layout.height) / 2;
      layout.parent = enclosingLayout;
      lastProcessedLayout.parent = enclosingLayout;
      lastProcessedLayout = enclosingLayout;
    }

    // prepare a new batch
    for (const scene of processedScenes) {
      if (scene.type === "scene") {
        scene.children.forEach((childScene) => nextScenes.add(childScene));
      }
    }
  }

  return lastProcessedLayout ?? { ...nullLayoutRect };
};

// Layout a set of nodes vertically
//
// ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─
//  ┌────────────────────────────────────┐│
// ││  .─.         .         .         . │
//  │ (   )───────( )───────( )───────( )◀┼───┐
// ││  `─'         '         '         ' │    │
//  └───────▲────────────────────────────┘│   │
// │        │ VSPACING                        │
//  ┌───────▼────────────────────────────┐│   │
// ││                                    │    │       Individual layout
//  │                                    ◀┼───┼──────    (recursive)
// ││                                    │    │
//  └────────────────────────────────────┘│   │
// │                                          │
//  ┌────────────────────────────────────┐│   │
// ││                                    │    │
//  │                                    ◀┼───┘
// ││                                    │
//  └────────────────────────────────────┘│
// └ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─
//            Returned Layout
//
const layoutChildrenStacked = (nodes: NodeScene[]): RecursiveLayoutRect => {
  // many nodes to layout: we position them on top of another
  const stackedLayouts = nodes.map(layoutCurrentSceneWithAssets);
  // we build the enclosing layout for the vertically-stacked nodes
  const layout = {
    ...nullLayoutRect,
    // take the width of the largest sub-layout
    width: Math.max(...stackedLayouts.map((layout) => layout.width)),
    // and the total height + vspacing
    height:
      stackedLayouts.reduce((sum, layout) => sum + layout.height, 0) +
      (stackedLayouts.length - 1) * VSPACING,
  };
  // then we position the children
  let currentY = 0;
  for (const stackedLayout of stackedLayouts) {
    stackedLayout.parent = layout;
    stackedLayout.y = currentY;
    currentY += stackedLayout.height;
    currentY += VSPACING;
  }
  return layout;
};

// Layout the scene node with its assets
//
//             LayoutRect
//                  │
//         ┌────────┴────────┐
//   ┌─────▼─┐            ┌──▼─────────────────────────────────┐
//   │  .─.  │            │  .─. SPACING .         .         . │
//   │ (   ) │            │ (   )───────( )───────( )───────( )│
//   │  `─'  │            │  `─'         '         '         ' │
//   └───────┘            └────────────────────────────────────┘
//  SCENE w/o asset                   SCENE with assets
//
const layoutCurrentSceneWithAssets = (node: NodeSceneWithLayout): RecursiveLayoutRect => {
  node.layout = {
    ...nullLayoutRect,
    width: SVGNodeScene.WIDTH,
    height: SVGNodeScene.HEIGHT,
  };
  // node.y = SCENE_TOP; // ⬅︎ if one day we decide scene nodes will be smaller than asset nodes...

  if (
    node.children.size <= 1 && // ⬅︎ we don't display assets for menus (as shown in Figma)
    node.assets.size > 0
  ) {
    // make room for the assets
    node.layout.width += node.assets.size * (SVGNodeAsset.WIDTH + SPACING);

    let i = 0;
    for (const assetNode of Array.from(node.assets) as NodeAssetWithLayout[]) {
      assetNode.layout = node.layout;
      assetNode.y = ASSET_TOP;
      assetNode.x = SVGNodeScene.WIDTH + i * SVGNodeAsset.WIDTH + (i + 1) * SPACING;
      i += 1;
    }
  }
  return node.layout;
};

// The list of actions is not very useful in itself, this function turns it info a map of "content Id to children nodes".
// (this is an intermediate step before attaching the linked nodes)
const collectDirectChildrenByContentId = (nodesById: NodesById, actions: Action[]) => {
  const directChildrenByContentId: { [id: string]: Set<Node> } = {};
  for (const action of actions) {
    // on each action, only 2 fields are of interest for us
    // {
    //   "affact_content": 123,    ⬅︎ the id of the SOURCE CONTENT
    //   "affacted_content": 123,  ⬅︎ the id of the DESTINATION (affected) CONTENT
    //   ...
    // }
    // since we have a map of node by content id, we can retrieve the relevant nodes:
    const sourceNode = nodesById[action.affect_content];
    const destinationNode = nodesById[action.affected_content];
    if (!sourceNode || !destinationNode) {
      if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG) {
        console.warn(
          `ACTION #${action.id} references non-existing content`,
          action,
          "...sourceNode = ",
          sourceNode,
          "\n... destinationNode = ",
          destinationNode,
        );
        continue;
      }
    }
    // we're not interested in self-referencing actions...
    // (we will prevent cycles later on, but these ones are easy to catch)
    if (sourceNode === destinationNode) continue;

    // DEBUG
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG) {
      console.debug(
        `ACTION #${action.id}: from ${getDebugNodeName(sourceNode)} to ${getDebugNodeName(
          destinationNode,
        )}`,
      );
    }

    // we add an entry to the list of children
    const children = directChildrenByContentId[sourceNode.contentId] ?? new Set();
    children.add(destinationNode);
    directChildrenByContentId[sourceNode.contentId] = children;
  }
  return directChildrenByContentId;
};

const getChildrenLookupFunction = (directChildrenByContentId: { [id: string]: Set<Node> }) =>
  function findChildrenScenes(node: Node, visitedNodes: Set<Node>): Set<NodeScene> {
    visitedNodes.add(node); // to avoid infinite looping...

    // load the direct children
    const children = directChildrenByContentId[node.contentId];
    if (!children) {
      return new Set(); // noop
    }

    const result: Set<NodeScene> = new Set();
    children.forEach((child) => {
      if (visitedNodes.has(child)) return; // avoid infinite loop...
      switch (child.type) {
        case "scene":
          result.add(child);
          break;
        case "asset":
          findChildrenScenes(child, visitedNodes).forEach((child) => {
            result.add(child);
          });
      }
    });
    return result;
  };

const getAssetLookupFunction = (directChildrenByContentId: { [id: string]: Set<Node> }) =>
  function findLinkedAssets(node: Node, visitedNodes: Set<Node> = new Set()): Set<NodeAsset> {
    visitedNodes.add(node);

    // load the direct children
    const children = directChildrenByContentId[node.contentId];
    if (!children) {
      return new Set(); // noop
    }

    // we build the result while exploring the children
    const result: Set<NodeAsset> = new Set();
    children.forEach((child) => {
      if (visitedNodes.has(child)) return;

      if (child.type === "asset") {
        result.add(child);
        findLinkedAssets(child, visitedNodes).forEach((child) => {
          result.add(child);
        });
      }
    });
    return result;
  };

const getDebugNodeName = (node: Node) => {
  const prefix = node.type === "scene" ? "SCENE" : "ASSET";
  const ids = `${node.contentId}/CT-${node.contentTypeId}`;
  return `${prefix} ${node.details.name} (${ids})`;
};

// turns a relative layout into an absolute layout
const getAbsoluteLayout = (layout: RecursiveLayoutRect): RootLayoutRect => {
  if (layout.parent) {
    // here we won't face a cycle so we can recurse without worry...
    const { x: parentX, y: parentY } = getAbsoluteLayout(layout.parent);
    // we rewrite the layout in-place: this way we encache the results.
    // once again the ORDER of calls is important !
    layout.x += parentX;
    layout.y += parentY;
    delete layout.parent;
  }
  return layout;
};
