import { TrashIcon } from "@heroicons/react/outline";
import { Checkbox } from "../../common/components/Input";
import { Key, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import TableComp from "../../common/components/Table/Table";
import { useAppDispatch } from "../../common/state/hooks";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "../../features/projectGroups/components/ProjectGroupList";
import {
  deleteMultipleProjectGroup,
  fetchAllCurrentElements,
  removeElementsOfProject,
  setSelectedProjectGroupFromId,
} from "../../features/projectGroups/state/projectGroupsSlice";
import PGBadge from "../../common/components/PGBadge/PGBadge";
import {
  deleteMultipleProject,
  setCurrentProjectFromId,
} from "../../features/projects/state/projectsSlice";
import Badge from "../../common/components/Badge/Badge";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import DisplayActions from "../../common/util/DisplayActions";
import NoItemsList from "../../common/util/NoItemsList";
import { prettifyFileSizeInBytes } from "../../common/util/prettifyFileSizeInBytes";
import { dateConfig } from "src/common/util/DateConfig";
import { useSelector } from "react-redux";
import {
  getCurrentOrganization,
  getIsAdminOfOrganization,
} from "src/features/profile/state/profileSlice";
// import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";

import { IMedia } from "src/model/unityObject";
import CheckAll from "src/common/components/UsersTable/CheckAll";

export interface ItemsList {
  arrayItemsForList: any;
  setClickedProjectGroupId: (id: number) => void;
  handleClick: (e: any) => void;
  clickedProjectGroupId: number;
  isProjectList?: boolean;
  translateTypeAuth?: (type: string) => void;
  isCheck: Array<any>;
  isMediaList?: boolean;
  currentLocale?: "en" | "fr";
  isModalEditMedia?: boolean;
  openMediaDetail?: (id: number, item: any, index: number) => void;
  setIsCheck: (item: any) => void;
  isCheckMedias?: Array<any>;
  openModalDeleteMultipleMedias?: () => void;
  setIsCheckMedias?: (item: any) => void;
  onDeleteMedia?: (media: any) => void;
  isModalEdit?: boolean;
  isProjectGroup?: boolean;
  setEditedProjectGroupId?: (id: any, projectGroup: any) => void;
}

const ModeListProjectGroup = ({
  arrayItemsForList,
  setClickedProjectGroupId,
  handleClick,
  clickedProjectGroupId,
  isProjectList = false,
  isMediaList = false,
  isProjectGroup = false,
  currentLocale,
  isCheck,
  setIsCheck,
  setIsCheckMedias = () => [],
  openModalDeleteMultipleMedias = () => [],
  openMediaDetail = () => [],
  onDeleteMedia = () => [],
  setEditedProjectGroupId,
  isModalEdit,
}: ItemsList) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [stuffToDisplay, setStuffToDisplay] = useState<IMedia[]>([]);
  useEffect(() => {
    setStuffToDisplay(arrayItemsForList);
  }, [arrayItemsForList]);

  const filterPrivateProjectGroup = [...stuffToDisplay].filter((media: any) => {
    if (media?.organization) {
      return Number(media?.organization.id) !== Number(3);
    } else {
      return true;
    }
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAdminOfOrg = useSelector(getIsAdminOfOrganization);
  const currentOrg = useSelector(getCurrentOrganization);

  useEffect(() => {
    setIsCheckAll(false);
    setIsCheck([]);
    setIsCheckMedias([]);
  }, [arrayItemsForList.length]);

  const canBeDeleted = (itemsForList: any) => {
    if (isMediaList) {
      return Number(itemsForList.organization.id) === Number(currentOrg.id);
    }

    if (!isAdminOfOrg) {
      return false;
    } else {
      if (("status" in itemsForList && itemsForList.status !== "Edit") || isProjectList) {
        return itemsForList.status !== "Published";
      } else {
        return "has_existing_build" in itemsForList && !itemsForList?.has_existing_build;
      }
    }
  };

  const deletableStuff = filterPrivateProjectGroup.filter((stuff) => {
    return canBeDeleted(stuff);
  });

  const checkAllProjectGroupComponent = () => {
    return (
      <CheckAll
        className={""}
        checked={isCheck.length === deletableStuff.length && isCheck.length > 0}
        onChange={handleSelectAll}
      />
    );
  };

  const handleClickGroupCheck = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, String(id)]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => Number(item) !== Number(id)));
    }
  };

  const handleSelectAll = () => {
    if (isCheck.length > 0) {
      setIsCheck([]);
      setIsCheckMedias([]);
    } else {
      if (isMediaList) {
        setIsCheckMedias(filterPrivateProjectGroup);
        setIsCheck(filterPrivateProjectGroup.map((li: any) => String(li.id)));
      } else {
        setIsCheck(
          arrayItemsForList
            .filter((li: any) => canBeDeleted(li))
            .map((li: any) => {
              return String(li.id);
            }),
        );
      }
      setIsCheckAll(!isCheckAll);
    }
  };

  const openModalDeleteMultiple = () => {
    if (isMediaList) {
      openModalDeleteMultipleMedias();
    } else {
      dispatch(openConfirmationModal("deleteMultipleDeletesConfirmation"));
    }
  };

  const deleteSelectionButtonText = () => {
    if (isCheck.length === 1) {
      return t("general.deleteElement");
    } else {
      return t("general.deleteElements");
    }
  };

  const deleteSelection =
    isCheck.length === 1 ? t("general.deleteElementSingular") : t("general.deleteElementPlural");

  //project list
  const RelevantBadge = (projectStatus: string) => {
    switch (projectStatus) {
      case "Edit":
      default:
        return (
          <Badge text={t("general.notPublished")} color="bg-red-500" textColor="text-red-100" />
        );
      case "Published":
        return (
          <Badge
            text={t("general.inPublication")}
            color="bg-green-500"
            textColor="text-green-100"
          />
        );
    }
  };

  // delete Project
  const deleteProjectFromList = (projectId: number) => {
    setClickedProjectGroupId(projectId);
    dispatch(setCurrentProjectFromId(Number(projectId)));
    dispatch(openConfirmationModal("deleteProjectConfirmation"));
  };
  //delete Project Group
  const deleteProjectGroupFromList = (projectGroupItemId: number) => {
    setClickedProjectGroupId(projectGroupItemId);
    dispatch(setSelectedProjectGroupFromId(projectGroupItemId));
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: projectGroupItemId,
        componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
      }),
    );
    dispatch(openConfirmationModal("deleteProjectGroupConfirmation"));
  };

  const translateTypeAuth = (type: string) => {
    switch (type) {
      case "classic":
        return t("pages.accessTypeTable.passwordLogin");
      case "simplified":
        return t("pages.accessTypeTable.simplifiedLogin");
      case "none":
      default:
        return t("pages.accessTypeTable.noLogin");
    }
  };

  const getColumnValues = stuffToDisplay.map((itemsForList: any, i: Key | null | undefined) => {
    const displayCheckbox = () => {
      if (isMediaList) {
        if (Number(itemsForList.organization.id) !== Number(3) && isModalEdit) {
          return (
            <Checkbox
              key={`key-checkbox-list-${i}`}
              checked={isCheck.includes(String(itemsForList.id))}
              id={itemsForList.id}
              value={JSON.stringify(itemsForList)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                handleClick(e);
              }}
            />
          );
        }
      } else {
        if (canBeDeleted(itemsForList))
          return (
            <Checkbox
              key={`key-checkbox-list-${i}`}
              checked={isCheck?.includes(String(itemsForList.id))}
              id={itemsForList?.id}
              value={itemsForList?.id}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                handleClickGroupCheck(e);
              }}
            />
          );
      }
      return <></>;
    };
    const name = itemsForList?.name;

    const isDeleteDisabled = () => {
      if (isProjectList) {
        if (itemsForList.status !== "Edit") {
          return {
            disabled: true,
            disabledTooltip: t("tooltip.cannotDeletePublishedProject"),
          };
        }
      }
      if (itemsForList?.has_existing_build) {
        return {
          disabled: true,
          disabledTooltip: t("tooltip.thisCourseHasBeenPublished"),
        };
      }
      if (!isAdminOfOrg) {
        return {
          disabled: true,
          disabledTooltip: t("tooltip.mustBeAdminToDeleteModule"),
        };
      }
      return {
        disabled: false,
        disabledTooltip: "",
      };
    };

    const prettifyFormat = (format: string) => {
      switch (format) {
        case "image":
        case "audio":
          return format.replace(/^\w/, (c) => c.toUpperCase());
        case "model3d":
        case "video":
          return t(`pages.gallery.individualMediaType.${format}`);
        default:
          return format;
      }
    };

    const displayActionsComponent = () => {
      const deleteStatus = isDeleteDisabled();
      return (
        <DisplayActions
          itemsForList={itemsForList}
          handleClick={handleClick}
          isProjectList={isProjectList}
          deleteOneFromList={isProjectList ? deleteProjectFromList : deleteProjectGroupFromList}
          isDeleteDisabled={deleteStatus.disabled}
          deleteDisabledTooltip={deleteStatus.disabledTooltip}
          setEditedProjectGroupId={setEditedProjectGroupId}
        />
      );
    };

    if (isProjectList) {
      return {
        projectId: itemsForList.id,
        checkbox: displayCheckbox(),
        name: name,
        releaseState: RelevantBadge(itemsForList?.status),
        actions: displayActionsComponent(),
      };
    } else if (isMediaList) {
      const dateMedia = new Date(itemsForList.createdAt).toLocaleString(dateConfig(currentLocale));
      const sliceIndexDate =
        currentLocale === "fr" ? dateMedia.indexOf(" ") : dateMedia.indexOf(",");

      return {
        checkbox: displayCheckbox(),
        name: name,
        size: prettifyFileSizeInBytes(
          Number(itemsForList.size_in_bytes),
          currentLocale ? currentLocale : "fr",
        ),
        format: prettifyFormat(itemsForList.type),
        date: dateMedia.substring(0, sliceIndexDate),
        type: itemsForList.media_360_tag
          ? t(`pages.gallery.mediaType.${itemsForList.media_360_tag}`)
          : "",
        extension: itemsForList.filename.split(".").pop().toUpperCase(),
        actions: (
          <DisplayActions
            itemsForList={itemsForList}
            openMediaDetail={openMediaDetail}
            onDeleteMedia={onDeleteMedia}
            isMediaList
            isModalEdit={isModalEdit}
            setEditedProjectGroupId={setEditedProjectGroupId}
          />
        ),
      };
    } else {
      return {
        projectGroupId: itemsForList.id,
        checkbox: displayCheckbox(),
        name: name,
        typeAuth: translateTypeAuth(String(itemsForList.auth_type)),
        releaseState: <PGBadge projectGroupId={itemsForList.id} />,
        actions: displayActionsComponent(),
      };
    }
  });

  //add filter here changing stuffToDisplay and then setStuffToDisplay with the new array

  /////////////////////////////////

  const colHeaders = () => {
    if (isProjectList) {
      return [t("general.nameTable"), "Publication", ""];
    } else if (isMediaList) {
      return [
        t("general.fileName"),
        t("general.weight"),
        "Format",
        t("general.addDate"),
        "Type",
        "Extension",
        "",
      ];
    } else {
      return [
        t("general.nameTable"),
        t("general.authenticationType"),
        t("general.publicationStatus"),
        "",
      ];
    }
  };

  const titlesModal = () => {
    return isCheck.length > 1
      ? t(`pages.${isProjectList ? "projects" : "projectGroup"}.deletionModal.titlePlural`)
      : t(`pages.${isProjectList ? "projects" : "projectGroup"}.deletionModal.title`);
  };
  const textModal = () => {
    if (isProjectList) {
      return isCheck.length > 1
        ? t("pages.projects.deletionModal.bodyPlural")
        : t("pages.projects.deletionModal.body");
    } else {
      return t("pages.projectGroup.deletionModal.body");
    }
  };

  const onClickSubmitForDeleteProjectOrProjectsGroup = () => {
    if (isProjectList) {
      setIsCheck([]);
      dispatch(closeConfirmationModal("deleteMultipleDeletesConfirmation"));
      dispatch(
        deleteMultipleProject({
          projectsListId: isCheck,
          componentId: `project-${clickedProjectGroupId}`,
        }),
      ).then((res: any) => {
        const idOfDeletedProjects = res.payload.data;
        idOfDeletedProjects.map((id: number) => dispatch(removeElementsOfProject(id)));
      });
    } else {
      dispatch(closeConfirmationModal("deleteMultipleDeletesConfirmation"));
      setIsCheck([]);
      dispatch(
        deleteMultipleProjectGroup({
          projectGroupListId: isCheck,
          componentId: `project-group-${clickedProjectGroupId}`,
        }),
      );
    }
  };
  const displayCheckAll = () => {
    if (isMediaList) {
      if (isModalEdit) {
        if (filterPrivateProjectGroup.length > 0) {
          return checkAllProjectGroupComponent();
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    } else {
      return checkAllProjectGroupComponent();
    }
  };

  return (
    <>
      <IsLoading componentId={`project-${clickedProjectGroupId}`} showSuccess={false}>
        {arrayItemsForList.length > 0 ? (
          <div className="ml-5 mb-3 pr-5 h-full">
            {isCheck.length > 0 && (
              <div className="h-[2rem]">
                <h3>
                  {isCheck.length} {deleteSelection}
                  {" - "}
                  <button onClick={() => openModalDeleteMultiple()}>
                    <div className="flex">
                      <p className="text-red-500">{deleteSelectionButtonText()}</p>

                      <TrashIcon className="w-5 h-5 stroke-red-500 mr-3" />
                    </div>
                  </button>
                </h3>
              </div>
            )}

            <div
              className={`overflow-y-auto personalize-scroll-visible ${
                isCheck.length > 0 ? "h-[calc(100%-2rem)]" : "h-full"
              }`}
            >
              <TableComp
                handleClick={handleClick}
                isProjectList={isProjectList}
                isMediaList={isMediaList}
                rows={getColumnValues}
                colHeaders={colHeaders()}
                checkAll={displayCheckAll()}
                listTofilter={stuffToDisplay}
                setListOfItemsFilter={setStuffToDisplay}
                isProjectGroup={isProjectGroup}
                className="w-full"
              />
            </div>
          </div>
        ) : (
          <div>
            <NoItemsList isModeProjectList={isProjectList} isModeMediaList={isMediaList} />
          </div>
        )}
      </IsLoading>
      <ModalConfirmation
        id="deleteMultipleDeletesConfirmation"
        onClickSubmit={() => {
          onClickSubmitForDeleteProjectOrProjectsGroup();
        }}
        title={titlesModal()}
        text={textModal()}
        componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
      />
    </>
  );
};

export default ModeListProjectGroup;
