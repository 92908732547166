import { useState } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "../../common/components/Navigation/Navigation";
import StepperMUI from "../../common/components/Stepper/Stepper";
import ProjectNewName from "./ProjectNewName";
import ProjectThumbnail from "./ProjectThumbnail";
import ProjectOverview from "./ProjectOverview";
import {
  getNewProjectSelector,
  projectsSelector,
} from "../../features/projects/state/projectsSlice";
import { useSelector } from "react-redux";
import { IProject } from "../../model/model";
import { getSelectedProjectGroup } from "src/features/projectGroups/state/projectGroupsSlice";
import ProjectLanguage from "./ProjectLanguage";

export default function CreateProjectWrapper() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  // If the template is a user-made custom template, only have 2 steps instead of 3
  const projects = useSelector(projectsSelector);
  const newProject = useSelector(getNewProjectSelector);
  const selectedProjectGroup = useSelector(getSelectedProjectGroup);

  const clonedProject = projects.find(
    (p: IProject) => Number(p.id) === Number(newProject.projectId),
  );
  const isCustomTemplate = clonedProject?.is_user_template;

  const renderStep = (step: number) => {
    if (isCustomTemplate) {
      switch (step) {
        case 0:
        default:
          return <ProjectNewName incrementStep={incrementStep} decrementStep={decrementStep} />;
        case 1:
          return (
            <ProjectLanguage
              incrementStep={incrementStep}
              decrementStep={decrementStep}
              mode="newProjectFromCustomTemplate"
            />
          );
        case 2:
          return <ProjectThumbnail decrementStep={decrementStep} />;
      }
    } else {
      switch (step) {
        case 0:
        default:
          return <ProjectOverview incrementStep={incrementStep} />;
        case 1:
          return <ProjectNewName incrementStep={incrementStep} decrementStep={decrementStep} />;
        case 2:
          return (
            <ProjectLanguage
              incrementStep={incrementStep}
              decrementStep={decrementStep}
              mode="newProject"
            />
          );
        case 3:
          return <ProjectThumbnail decrementStep={decrementStep} />;
      }
    }
  };

  return (
    <>
      <Navigation
        title={t("pages.createProject.title")}
        showGoBackButton
        backCustomRedirectTo={
          selectedProjectGroup?.id ? `/courses/${selectedProjectGroup.id}/list` : "/courses"
        }
      />
      <StepperMUI
        step={step}
        mode={isCustomTemplate ? "newProjectFromCustomTemplate" : "newProject"}
      />
      {renderStep(step)}
    </>
  );
}
