import { ReactElement } from "react";

interface Props {
  style: string;
  width: number;
  height: number;
  fill: string;
  stroke: string;
}

export default function IPhone({ width, height, fill, style, stroke }: Props): ReactElement {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.33 40"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            id="Iphone"
            className="cls-1"
            d="M4,0A4,4,0,0,0,0,4V36a4,4,0,0,0,4,4H17.33a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4H4ZM2.22,4.44H19.11V33.78H2.22Zm8.45,31.12a1.33,1.33,0,1,1-1.34,1.33,1.33,1.33,0,0,1,1.34-1.33Z"
          />
        </g>
      </g>
    </svg>
  );
}
