import { useState } from "react";
import { useTranslation } from "react-i18next";
import ThreeDotsDropdownMenu from "src/common/components/Icon/ThreeDotsDropdownMenu";
import { menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import { useAppDispatch } from "src/common/state/hooks";
import { deleteGptConversation } from "../state/chatbotSlice";
import { syncUserWithServer } from "src/features/profile/state/profileSlice";

const GptConversationThreeDotButton = ({
  id,
  className = "",
  onRename,
  onMenuOpen,
  menuPosition = "below",
}: {
  id: number;
  className?: string;
  onRename: () => void;
  onMenuOpen?: () => void;
  menuPosition?: "above" | "below";
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const menuItems: menuItems = [
    {
      menuItemText: t("general.rename"),
      itemId: t("general.rename"),
      icon: "PencilAltIcon",
    },
    {
      menuItemText: t("general.delete"),
      itemId: t("general.delete"),
      icon: "TrashIcon",
      disabled: isDeleting,
    },
  ];

  const stopThePropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleDeleteClick = async () => {
    if (isDeleting) return;
    setIsDeleting(true);

    try {
      await dispatch(
        deleteGptConversation({
          id,
          cb: () => dispatch(syncUserWithServer({})),
        }),
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div onClick={stopThePropagation} className={className}>
      <ThreeDotsDropdownMenu
        className="h-4 w-6"
        menuItems={menuItems}
        handleClick={(e) => {
          if (String(e.menuItemText) === String(t("general.rename"))) {
            onRename();
          }
          if (e.menuItemText === t("general.delete")) {
            handleDeleteClick();
          }
        }}
        onMenuOpen={onMenuOpen}
        menuPosition={menuPosition}
      />
    </div>
  );
};

export default GptConversationThreeDotButton;
