import { useSelector } from "react-redux";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { getNumberOfPaywalledAnonymousSessions } from "../state/subscriptionSlice";
import { useTranslation } from "react-i18next";

interface IAnonymousSessionCounterProps {
  numberOfPaywalledAnonymousSessions?: number;
  className?: string;
  withSpinner?: boolean;
}

export const ANONYMOUS_SESSION_COUNTER_COMPONENT = "anonymousSessionCounterComponent";

export const AnonymousSessionCounter: React.FC<IAnonymousSessionCounterProps> = ({
  className = "",
  withSpinner = true,
}: IAnonymousSessionCounterProps) => {
  const { t } = useTranslation();
  const numberOfPaywalledAnonymousSessions = useSelector(getNumberOfPaywalledAnonymousSessions);

  const content =
    numberOfPaywalledAnonymousSessions > 0 ? (
      <div className={`flex flex-col items-center justify-center round-lg ${className}`}>
        <p className="text-lg">
          {t("pages.profile.subscription.anonymousSessionWarningPart1")}&nbsp;
          <strong>{numberOfPaywalledAnonymousSessions}</strong>&nbsp;
          {t("pages.profile.subscription.anonymousSessionWarningPart2")}
        </p>
        <p className="text-sm italic text-center">
          {t("pages.profile.subscription.anonymousSessionWarningSubtext")}
        </p>
      </div>
    ) : null;

  return withSpinner ? (
    <IsLoading componentId={ANONYMOUS_SESSION_COUNTER_COMPONENT} showSuccess={false}>
      {content}
    </IsLoading>
  ) : (
    <div className="anonymous-session-counter">{content}</div>
  );
};
