import { Component, ErrorInfo, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { postErrorReport } from "../../util/postErrorReport";

interface Props {
  children: ReactNode;
  currentUserId: number;
}

interface State {
  hasError: boolean;
  errorPath: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorPath: window.location.pathname,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorPath: window.location.pathname };
  }

  componentDidUpdate() {
    if (this.state.hasError && this.state.errorPath !== window.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    const prettyError = {
      name: error.name,
      message: error.message,
      stack: errorInfo,
    };

    window.sessionStorage.setItem("error", JSON.stringify(errorInfo));

    postErrorReport({
      environment: "React",
      trace: prettyError,
      context_infos: {
        NODE_ENV: process.env.REACT_APP_DEBUG ? "test" : process.env.NODE_ENV,
        errorPath: this.state.errorPath,
        userId: this.props.currentUserId,
      },
    });
  }

  public render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      return <Navigate to="/error" />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
