const fromTimestampToPrettyFrenchDate = (timestamp: string) => {
  const date = new Date(timestamp);

  // Use Intl.DateTimeFormat with custom options
  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    weekday: "long", // Full day name (e.g., "Jeudi")
    day: "2-digit", // Two-digit day (e.g., "30")
    month: "long", // Full month name (e.g., "Septembre")
    year: "numeric", // Full year (e.g., "2024")
    hour: "2-digit", // Two-digit hour (e.g., "11")
    minute: "2-digit", // Two-digit minute (e.g., "30")
  }).format(date);

  const capitalizedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace(",", "");

  return capitalizedDate;
};

export default fromTimestampToPrettyFrenchDate;
