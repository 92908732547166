import { UsersIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { deleteProjectPermissons } from "../../features/projects/state/projectsSlice";
import { useDispatch, useSelector } from "react-redux";
import parseSimplifiedUserIdentifier from "../../common/util/parseSimplifiedUserIdentifier";
import InviteExternalCollaboratorFormContainer from "../../features/team/components/InviteExternalCollaboratorForm";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../common/state/store";
import { ILanguage, IProjectsPermissions } from "../../model/model";
import {
  closeConfirmationModal,
  openConfirmationModal,
  openStandardModal,
} from "../../common/state/slice/modal/modalSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { CONFIRM_TEAM_COMPONENT } from "../../features/team/components/AddTeamModal";
import { TiDelete } from "react-icons/ti";
import {
  getCurrentProjectGroupPermissions,
  setCurrentProjectGroupPermissions,
} from "../../features/projectGroups/state/projectGroupsSlice";
import {
  fetchSubscription,
  isLimitReachedForFeature,
} from "../../features/subscription/state/subscriptionSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import { setAlert } from "../../features/alert/state/alertsSlice";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { allTeamSelector } from "../../features/team/state/teamSlice";
import { dateConfig } from "src/common/util/DateConfig";
import { languageConfig } from "src/common/util/langConfig";
import RoundImagePreview from "src/features/projects/components/RoundImagePreview";
import TableComp from "src/common/components/Table/Table";

const projectGroupCollaborators = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const collaborators = useSelector(allTeamSelector);
  const currentProjectPermissions = useSelector(getCurrentProjectGroupPermissions);
  const currentUser = useSelector(getCurrentUser);

  const preferredLanguage = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const currentProjectCollaborators = currentProjectPermissions.filter(
    (perm) => Number(perm.user?.role.id) === 5 && Number(perm.user?.id) !== Number(currentUser?.id),
  );
  const [currentCollaboratorProjectList, setCurrentCollaboratorProjectList] = useState<
    IProjectsPermissions[]
  >([]);
  useEffect(() => {
    setCurrentCollaboratorProjectList(currentProjectCollaborators);
  }, [currentProjectPermissions]);

  const [isDarkMode, setIsDarkMode] = useState(false);
  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);

  const canAddNewCollab = useSelector(
    (state: RootState) => !isLimitReachedForFeature(state, "number-of-available-creator-accounts"),
  );

  const [selectedPerm, setSelectedPerm] = useState<IProjectsPermissions | null>(null);

  const onSelectMode = (mode: "dark" | "light") => {
    if (mode === "dark") {
      if (!isDarkMode) setIsDarkMode(true);
    } else {
      if (isDarkMode) setIsDarkMode(false);
    }
  };

  useEffect(() => {
    dispatch(fetchSubscription({ componentId: CONFIRM_TEAM_COMPONENT }));
  }, [collaborators]);

  useEffect(() => {
    // Check to see if Media-Queries are supported
    if (window.matchMedia) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => onSelectMode(e.matches ? "dark" : "light"));

      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (!isDarkMode) setIsDarkMode(true);
      }
    }
  });

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (1 / 2));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const openDeletePermModal = (perm: IProjectsPermissions) => {
    setSelectedPerm(perm);
    dispatch(openConfirmationModal("deletePermCollabConfirmation"));
  };

  const deletePerm = (perm: IProjectsPermissions) => {
    dispatch(
      deleteProjectPermissons({
        permission: perm,
        componentId: CONFIRM_TEAM_COMPONENT,
      }),
    )
      .then(() => {
        const newPerms = currentProjectPermissions.filter((p) => Number(p.id) !== Number(perm.id));
        dispatch(setCurrentProjectGroupPermissions(newPerms));
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.permissionRemovedSuccessfully"),
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            type: "danger",
            msg: t("alerts.permissionRemovedFail"),
          }),
        );
      });

    dispatch(closeConfirmationModal("deletePermCollabConfirmation"));
  };

  const onAddUserClick = () => {
    dispatch(openStandardModal("add-team"));
  };

  const colHeaders = [
    t("general.firstname"),
    t("general.lastname"),
    t("general.email"),
    t("general.dateAdded"),
    "",
  ];

  const getColumnValues = currentCollaboratorProjectList.map((perm: any) => ({
    avatar: <RoundImagePreview media={perm.user.source_avatar} />,
    firstname: perm.user.firstname,
    lastname: perm.user.lastname,
    email: parseSimplifiedUserIdentifier(perm.user.email),
    date: new Date(perm.createdAt).toLocaleString(dateConfig(preferredLanguage)),
    delete: (
      <button onClick={() => openDeletePermModal(perm)}>
        <TiDelete size={30} />
      </button>
    ),
  }));

  return (
    <>
      <InfoButton relevantArticle="aboutCollaborators" />
      <div className="container h-full w-full max-w-7xl mx-auto px-4">
        <div>
          <div className="add-collab xl:w-[60rem] lg:w-[40rem] sm:w-[20rem]">
            <div className="inline-block mt-8">
              <h2 className="text-2xl font-medium mb-2">
                {t("pages.project.projectGroupCollaborators.addCollaborator")}
              </h2>
            </div>
            <IsLoading componentId={CONFIRM_TEAM_COMPONENT} showSuccess={false} spinnerPlaceholder>
              <div className="flex">
                <div className="w-full flex justify-end ">
                  <div className="mt-14">
                    <button
                      className="inline-flex text-sm text-green-500 font-medium border-0 mb-5"
                      onClick={onAddUserClick}
                    >
                      <UsersIcon className="stroke-green-500 w-9 h-9 mr-1" />
                      <span className="w-36">
                        {t("pages.project.projectGroupCollaborators.existingCollaborator")}
                      </span>
                    </button>
                  </div>
                </div>

                <InviteExternalCollaboratorFormContainer
                  isFormSimplified
                  enableEmail
                  maxCollaboratorsExceeded={!canAddNewCollab}
                />
              </div>
            </IsLoading>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-medium mb-2">
            {t("pages.project.projectGroupCollaborators.collaborators")}
          </h2>
          {currentProjectCollaborators.length > 0 ? (
            <div
              className="relative my-5 mx-auto overflow-x-auto mb-5 border border-gray-300 shadow-md sm:rounded-lg personalize-scroll-visible"
              key={`${tableHeight}`}
              style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
            >
              <TableComp
                isCollaboratorAndParticipant={true}
                rows={getColumnValues}
                colHeaders={colHeaders}
                listTofilter={currentCollaboratorProjectList}
                setListOfItemsFilter={setCurrentCollaboratorProjectList}
                className="h-full w-full overflow-y-auto personalize-scroll-visible the-table-comp"
              />
            </div>
          ) : (
            <p className="text-center mt-8">
              {t("pages.project.projectGroupCollaborators.noCollaboratorsForThisCourse")}
            </p>
          )}
        </div>

        <ModalConfirmation
          id="deletePermCollabConfirmation"
          onClickSubmit={() => deletePerm(selectedPerm as IProjectsPermissions)}
          title={t("pages.project.projectGroupCollaborators.deletePermConfirmModal.title")}
          text={t("pages.project.projectGroupCollaborators.deletePermConfirmModal.body")}
          componentId={CONFIRM_TEAM_COMPONENT}
        />
      </div>
    </>
  );
};

export default projectGroupCollaborators;
