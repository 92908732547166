import { useEffect, useRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement>,
    ReactHookFormInput {
  label?: string;
  isResizable?: boolean;
}

export interface ReactHookFormInput {
  registerFct?: () => UseFormRegisterReturn;
}

const TextArea = ({ isResizable = true, label, registerFct, onChange, ...props }: InputProps) => {
  const prettyLabel = `${String(label)[0].toUpperCase()}${String(label).slice(1)}`; // https://stackoverflow.com/q/1026069
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  if (!isResizable) {
    props.style = { resize: "none", ...props.style };
  }
  const handleInputChange = (e) => {
    onChange && onChange(e); // Call custom onChange if provided
    registerFct && registerFct().onChange(e);
  };

  return (
    <>
      {label ? (
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">{prettyLabel}</label>
          <div className="mt-1 mb-3">
            <textarea ref={inputRef} {...props} {...registerFct?.()} onChange={handleInputChange} />
          </div>
        </div>
      ) : (
        <textarea ref={inputRef} {...props} {...registerFct?.()} onChange={handleInputChange} />
      )}
    </>
  );
};

export default TextArea;
