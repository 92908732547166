import ThreeDotsDropdownMenu, { menuItems } from "../Icon/ThreeDotsDropdownMenu";

interface DisplayThreeDotsDropdownMenuProps {
  isEnduser: boolean;
  menuItems: menuItems;
  pg: {
    s3Url: string;
    name: string;
    id: number;
  };
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  setEditedProjectGroupId?: (id: number, projectGroup: any) => void;
  currentProjectGroupElement?: any;
}

const DisplayThreeDotsDropdownMenu = ({
  menuItems,
  pg,
  handleThreeDotButtonClick,
  isEnduser,
  setEditedProjectGroupId,
  currentProjectGroupElement,
}: DisplayThreeDotsDropdownMenuProps) => {
  if (!isEnduser) {
    return (
      <div
        className="absolute top-3 right-3 h-6 w-6 rounded-md z-10 mr-4"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
        }}
      >
        <ThreeDotsDropdownMenu
          className="h-6 w-6"
          menuItems={menuItems}
          currentProjectGroupElement={currentProjectGroupElement}
          setEditedProjectGroupId={setEditedProjectGroupId}
          handleClick={(e) =>
            handleThreeDotButtonClick({
              item: "projectGroup",
              itemId: pg.id,
              action: e.itemId,
            })
          }
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default DisplayThreeDotsDropdownMenu;
