import { Link } from "react-router-dom";
import Icon, { IconName } from "../Icon/Icon";
import { SideBarItemProps } from "../Sidebar/SidebarItem";

export const MobileNavbarItem = ({
  icon,
  routeTo,
  title,
  disabled,
  fillIcon = "#0C1E59",
  stroke,
  isSelected = false,
  color = "bg-blue-500",
  textColor = "text-white",
  ...props
}: SideBarItemProps & { color?: string; textColor?: string; stroke?: string }) => {
  let button = (
    <button
      className={`flex flex-col justify-center h-full  cursor-pointer items-center ${
        isSelected && !disabled ? "bg-[#414587]" : color
      }  rounded-md xxl:my-0`}
      {...props}
    >
      <Icon
        icon={icon as IconName}
        fill={fillIcon}
        stroke={stroke ? stroke : fillIcon}
        className={`w-6 h-6 ${stroke ? "" : !disabled ? "stroke-white" : "stroke-gray-400"}`}
      />
      <p className={`${textColor} text-sm`}>{title}</p>
    </button>
  );

  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};
