import { IMedia } from "../../../model/unityObject";
import ImageCard from "../../gallery/components/ImageCard";
import { ControllerRenderProps } from "react-hook-form";
import { useEffect } from "react";
import { menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import { ICustomRemoveButton } from "src/common/components/CustomXIcon/CustomXIcon";

export const MediaSelector = ({
  handleButtonClick,
  media,
  currentMedia,
  field,
  customMenuItems,
  disabled = false,
  CustomRemoveButton,
  className = undefined,
  showThreeDotsMenu = true,
}: {
  field: ControllerRenderProps;
  handleButtonClick: (mediaId: number, item: any, index: number) => void;
  media: IMedia;
  currentMedia: number | undefined;
  customMenuItems?: menuItems;
  disabled?: boolean;
  CustomRemoveButton?: ICustomRemoveButton;
  className?: string;
  showThreeDotsMenu?: boolean;
}) => {
  useEffect(() => {
    if (currentMedia !== field.value) {
      field.onChange(currentMedia);
    }
  }, [currentMedia]);

  const is3DEnvironment = field.name === "source_model3d";

  return (
    <ImageCard
      handleButtonClick={(mediaId: number, item: any, index: number) =>
        handleButtonClick(mediaId, item, index)
      }
      handleCardClick={() => {
        handleButtonClick(0, null, 0);
      }}
      isMediaPickerMode={false}
      currentMedia={currentMedia}
      media={media}
      customMenuItems={customMenuItems}
      disabled={disabled}
      showThreeDotsMenu={showThreeDotsMenu}
      CustomRemoveButton={is3DEnvironment ? CustomRemoveButton : undefined}
      className={className}
    />
  );
};
