import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getBuilds } from "../../../features/builds/state/buildsSlice";
import { IBuild, ILanguage } from "../../../model/model";
import Badge from "../Badge/Badge";
import { dateConfig } from "src/common/util/DateConfig";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { languageConfig } from "src/common/util/langConfig";

type Props = { projectGroupId: string | number };

export default function PGBadge({ projectGroupId }: Props) {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const preferredLanguage = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const builds = useSelector(getBuilds);
  const appropriateBuilds = builds
    ? builds.filter((build: IBuild) => {
        return Number(build.project_group?.id) === Number(projectGroupId);
      })
    : [];

  const badgeProps = {
    text: t("general.notPublished"),
    color: "bg-red-500",
    textColor: "text-red-100",
  };

  if (appropriateBuilds.length > 0) {
    const latestBuild = [...appropriateBuilds].sort(
      (a: IBuild, b: IBuild) => b.version - a.version,
    )[0];

    if (latestBuild.status === "Published") {
      badgeProps.text = t("general.published");
      badgeProps.color = "bg-green-500";
      badgeProps.textColor = "text-green-100";
      badgeProps.text += `: ${
        new Date(latestBuild.updatedAt)
          .toLocaleString(dateConfig(preferredLanguage), {
            day: "numeric",
            month: "numeric",
            year: "2-digit",
          })
          .split(",")[0]
      }`;
    }
    if (latestBuild.status === "PublishWaiting") {
      badgeProps.text = t("general.publishWaiting");
      badgeProps.color = "bg-blue-500";
      badgeProps.textColor = "text-blue-100";
    }
    if (latestBuild.status === "ChangesNeeded") {
      badgeProps.text = t("general.changesNeeded");
      badgeProps.color = "bg-yellow-500";
      badgeProps.textColor = "text-yellow-100";
    }
  }

  return <Badge {...badgeProps} />;
}
