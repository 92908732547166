import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/state/store";

interface IGraphuState {
  isValid: boolean;
  isLoading: boolean;
}
const initialState: IGraphuState = {
  isValid: false,
  isLoading: false,
  // isZoomed: false
};

export const graphuSlice = createSlice({
  name: "graphu",
  initialState,
  reducers: {
    setIsValid: (state: IGraphuState, action: any) => {
      state.isValid = action.payload;
    },
    setIsLoading: (state: IGraphuState, action: any) => {
      state.isLoading = action.payload;
    },

    // setIsZoomed: (state, action) => {
    //   state.isZoomed = action.payload;
    // },
  },
});

export const {
  setIsValid,
  setIsLoading,
  // setIsZoomed
} = graphuSlice.actions;

export const getIsValid = (state: RootState) => state.graphu.isValid;
export const getIsLoading = (state: RootState) => state.graphu.isLoading;
//export const getIsZoomed = (state: IStore) => state.graphu.isZoomed;

export default graphuSlice.reducer;
