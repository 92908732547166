import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Navigation from "../../common/components/Navigation/Navigation";
import { getIsAdminOfOrganization } from "../../features/profile/state/profileSlice";

const ProfileWrapper = ({ enduser = false }: { enduser?: boolean }) => {
  const isAdmin = useSelector(getIsAdminOfOrganization);

  const { t } = useTranslation();
  const tabs = [
    {
      title: t("pages.profile.tabs.myDetails"),
      urlEndPoint: "me",
      enduserCanAccess: true,
      nonAdminCanAccess: true,
    },
    {
      title: t("pages.profile.tabs.myOrganization"),
      urlEndPoint: "organization",
      enduserCanAccess: true,
      nonAdminCanAccess: true,
    },

    {
      title: t("pages.profile.tabs.subscription"),
      urlEndPoint: "subscription",
      enduserCanAccess: false,
      nonAdminCanAccess: false,
    },
  ];

  const filteredTabs = tabs.filter(
    (t: {
      title: string;
      urlEndPoint: string;
      enduserCanAccess: boolean;
      nonAdminCanAccess: boolean;
    }) => {
      if (enduser) {
        return t.enduserCanAccess;
      } else if (!isAdmin) {
        return t.nonAdminCanAccess;
      } else {
        return t;
      }
    },
  );
  return (
    <>
      <Navigation title={t("pages.profile.myProfile")} tabs={filteredTabs} />
      <Outlet />
    </>
  );
};

export default ProfileWrapper;
