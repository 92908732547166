import StatsOverview from "../../features/stats/components/StatsOverview/StatsOverview";

const StatisticsOverview = () => {
  return (
    <div className="w-full mx-auto p-6">
      <StatsOverview simplified={true} />
    </div>
  );
};

export default StatisticsOverview;
