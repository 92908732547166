import { ViewGridAddIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlatformIcon from "../../../common/components/Icon/PlatformIcon/PlatformIcon";
import { IBuild } from "../../../model/model";
import {
  getBuilds,
  getLatestBuildsOfProjectGroup,
  setIsPublicationNewVersion,
} from "../../builds/state/buildsSlice";
import { getSelectedProjectGroup } from "../../projectGroups/state/projectGroupsSlice";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import { useAppDispatch } from "src/common/state/hooks";

export default function PublishWhileThereIsAnExistingBuild() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const builds = useSelector(getBuilds);
  const latestBuildsOfProjectGroup = useSelector(
    getLatestBuildsOfProjectGroup(currentProjectGroup.id),
  );
  const pendingBuildsForThisPg = builds.filter((b: IBuild) => {
    return (
      Number(b.project_group?.id) === Number(currentProjectGroup.id) &&
      (b.status === "PublishWaiting" || b.status === "ChangesNeeded")
    );
  });

  const thereIsntAnyPendingBuild = pendingBuildsForThisPg.length === 0;

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <h3 className="mb-1">
        {t("pages.projectGroup.publish.yourCourseHasBeenPublishedOnOnePlatformAlready")}
      </h3>
      <div className="flex justify-center space-x-4 h-[70%]">
        <div className="p-4 m-1 h-80 w-1/3 text-lg border-4 rounded-lg bg-gray-200 border-rose-500 select-none flex flex-col items-center justify-between">
          <div className="flex w-1/2 justify-between scale-75">
            <PlatformIcon platform="OculusQuest" />
            <PlatformIcon platform="windows10" />
            <PlatformIcon platform="MacOS" />
            <PlatformIcon platform="WebGLApp" />
          </div>
          <h3 className="text-center">{`${t(
            "pages.projectGroup.publish.iPublishWithoutAddingNewModulesPart1",
          )} ${latestBuildsOfProjectGroup[0].version} ${t(
            "pages.projectGroup.publish.iPublishWithoutAddingNewModulesPart2",
          )}`}</h3>
          <p className="text-sm text-center">
            {t("pages.projectGroup.publish.iPublishWithoutAddingNewModulesSubtext")}
          </p>
          <div className="flex w-full justify-center">
            <button
              className="btn-primary-fill items-center"
              onClick={() => {
                dispatch(setIsPublicationNewVersion(false));
                navigate(`/courses/${currentProjectGroup.id}/publish/new/platforms`);
              }}
            >
              {t("general.next")}
            </button>
          </div>
        </div>
        <div
          className={
            "p-4 m-1 h-80 w-1/3 text-lg border-4 rounded-lg bg-gray-200 border-rose-500 select-none flex flex-col align-items justify-between"
          }
        >
          <div className="flex w-full justify-center scale-75">
            <ViewGridAddIcon width={60} height={60} />
          </div>
          <h3 className="text-center">
            {t("pages.projectGroup.publish.iPublishAddingNewModules")}
          </h3>
          <p className=" text-center text-sm">
            {t("pages.projectGroup.publish.iPublishAddingNewModulesSubtext")}
          </p>
          <div className="flex w-full justify-center">
            <Tooltip
              message={`${
                thereIsntAnyPendingBuild
                  ? ""
                  : t("pages.projectGroup.publish.cannotCreateNewReleaseDueToReleaseInProgress")
              }`}
            >
              <button
                disabled={!thereIsntAnyPendingBuild}
                className={`btn-primary-fill ${
                  thereIsntAnyPendingBuild ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                onClick={() => {
                  if (thereIsntAnyPendingBuild) {
                    dispatch(setIsPublicationNewVersion(true));
                    navigate(`/courses/${currentProjectGroup.id}/publish/new/version`);
                  }
                }}
              >
                {t("general.next")}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
