import axios from "axios";
import apiUrls from "../../api";

interface IPostErrorReport {
  environment: "React";
  trace: any;
  context_infos: any;
}

export const postErrorReport = ({
  environment = "React",
  trace,
  context_infos,
}: IPostErrorReport) => {
  axios
    .post(apiUrls.userErrorTraces, {
      environment,
      trace,
      context_infos,
    })
    .catch((error) => {
      console.error(error);
    });
};
