import { InformationCircleIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";

interface IComponentInformation {
  title: string;
  moduleName: string;
  infoTooltipText?: string;
}

const InformationComponent = ({
  title,
  moduleName,
  infoTooltipText = "",
}: IComponentInformation) => {
  return (
    <div className="mt-2 border-gray-300">
      <div className="w-full flex flex-col sm:flex-row items-center py-2 px-2 xl:py-3 xl:px-4 border-t border-gray-300">
        <p className="w-full sm:w-1/3 text-sm text-center sm:text-left flex">
          {title}
          {infoTooltipText && (
            <Tooltip title={infoTooltipText}>
              <InformationCircleIcon className="h-4 w-4 ml-2" />
            </Tooltip>
          )}
        </p>

        <div className="w-full">
          <div className="mt-1 mb-3">
            <p className="block text-sm font-medium text-gray-700 ">{moduleName}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationComponent;
