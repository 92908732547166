import { useTranslation } from "react-i18next";
import { ReactHookFormInput } from "./Input";

export interface DropdownProps
  extends React.SelectHTMLAttributes<HTMLSelectElement>,
    ReactHookFormInput {
  asDefaultValue?: boolean;
  label?: string;
  width?: string;
  options: {
    disabled?: boolean;
    value: string;
    optionText: string;
    disabledTooltipText?: string;
  }[];
  onClickCb?: any;
  onChangeCb?: any;
  noSelectionText?: string;
  noStatsAvailable?: boolean;
  noStatsAvailableText?: string;
  defaultValueIndex?: number;
  languague?: boolean;
  mobile?: boolean;
  borderOff?: boolean;
  labelFontClassName?: string;
}

const Dropdown = ({
  label,
  width = "",
  asDefaultValue,
  options,
  registerFct,
  onClickCb,
  onChangeCb,
  noSelectionText,
  noStatsAvailable = false,
  noStatsAvailableText,
  defaultValueIndex = -1,
  mobile = false,
  languague = false,
  borderOff = false,
  labelFontClassName,
  ...props
}: DropdownProps) => {
  const { t } = useTranslation();

  const indexZeroText = () => {
    if (noStatsAvailable) {
      if (noStatsAvailableText) {
        return noStatsAvailableText;
      } else {
        return t("pages.statistics.noStatsAvailable");
      }
    } else if (noSelectionText) {
      return noSelectionText;
    } else {
      return t("general.pleaseSelect");
    }
  };

  const controlDefaultValueIndex = () => {
    if (languague) {
      if (defaultValueIndex === 2) {
        return "2";
      } else {
        return "1";
      }
    } else {
      const defaultValue = asDefaultValue
        ? options[defaultValueIndex >= 0 ? defaultValueIndex : -1]?.value
        : 0;
      return defaultValue;
    }
  };
  const defaultValue = options[defaultValueIndex >= 0 ? defaultValueIndex : -1]?.value ?? 0;

  // onChangeCb is needed for iMac compatibility
  // setting it another way will break the react-hook-form linked Dropdown,
  // since the onChange set will override the react-hook-form "register(x, onchange:onchange)" one
  const selectProps = {
    ...props,
  } as any;

  if (onChangeCb) {
    selectProps["onChange"] = onChangeCb;
  }
  if (onClickCb) {
    selectProps["onClick"] = onClickCb;
  }

  return (
    <div className={` ${mobile ? "-mt-2" : ""}`}>
      <label
        className={`block font-medium text-gray-700 ${
          mobile ? "text-sm" : labelFontClassName ? labelFontClassName : "text-lg"
        }`}
      >
        {label}
      </label>
      <div className={mobile ? "mt-0" : "mt-1"}>
        <select
          key={`${controlDefaultValueIndex()}-${mobile}`}
          {...selectProps}
          name="########"
          id={`########-${label}`}
          className={`${width} ${borderOff ? "border-0" : ""} ${
            noStatsAvailable
              ? "cursor-not-allowed height:44px cursor-pointer w-auto"
              : "cursor-pointer"
          } ${mobile ? "h-2/5" : ""}`}
          {...(registerFct && registerFct())}
          defaultValue={defaultValue}
          disabled={noStatsAvailable}
        >
          {!asDefaultValue && <option value={0}>{indexZeroText()}</option>}
          {options.map((option, i: any) => {
            return (
              <option
                key={`${i}-${option.value}`}
                className="disabled:bg-gray-200"
                disabled={option.disabled}
                value={option.value}
                title={
                  option.disabled && option.disabledTooltipText ? option.disabledTooltipText : ""
                }
              >
                {option.optionText}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
