import SlideOver, {
  classSlideCloseAnimation,
  sliderAnimationDurationInMs,
} from "../../../common/components/SlideOver/SlideOver";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { RootState } from "../../../common/state/store";
import {
  currentMediaSelector,
  mediaConfigSelector,
  updateMediaDetail,
  setUploadedOldFile,
} from "../state/gallerySlice";
import { MdDeleteOutline } from "react-icons/md";
import { IMedia } from "../../../model/unityObject";
import { MediaViewer } from "./MediaViewer";
import { useState } from "react";
import { useAppDispatch } from "../../../common/state/hooks";
import { useForm } from "react-hook-form";
import {
  closeConfirmationModal,
  closeSlider,
  getCurrentModalStateSlider,
  openConfirmationModal,
  setAnimationClassState,
} from "../../../common/state/slice/modal/modalSlice";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { Input } from "../../../common/components/Input";
import { useLocation } from "react-router-dom";
import { prettifyFileSizeInBytes } from "../../../common/util/prettifyFileSizeInBytes";
import { languageConfig } from "../../../common/util/langConfig";
import { ILanguage } from "../../../model/model";
import { getCurrentUser } from "../../profile/state/profileSlice";
import Media360TagDropdown from "./Media360TagDropdown";
import ModalConfirmationToDeleteMedia from "src/common/components/Modal/ModalConfirmationToDeleteMedia";

interface IMediaDetail {
  media: IMedia;
  large: boolean;
  id: string;
}

export const MEDIA_TAGS_COMPONENT_ID = "tagSelector";
export const MEDIA_TAGS_CHECK_BEFORE_DELETE_ID = "tagSelectorCheckBeforeDelete";
export const MEDIA_NEW_DATA_FOR_UPDATE = "mediaEditForUpdateData";

export const MediaDetailContainer = ({ id, large }: { large: boolean; id: string }) => {
  const { media } = useSelector((state: RootState) => {
    return {
      media: currentMediaSelector(state),
      config: mediaConfigSelector(state),
    };
  });
  return <>{media && <MediaDetail id={id} large={large} media={media} />}</>;
};

export const MediaDetail = ({ id, large, media }: IMediaDetail) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  // To avoid painful issues keeping the store in sync, we disable the media deletion when not in the dedicated gallery
  const path = location.pathname.split("/");
  const shouldHideSuppressButton = !(
    path.length > 1 && location.pathname.split("/")[1] === "medias"
  );

  const closeSliderAnimation = () => {
    setTimeout(() => {
      dispatch(closeSlider({ id }));
    }, sliderAnimationDurationInMs);

    setTimeout(() => {
      dispatch(setAnimationClassState(classSlideCloseAnimation));
    }, 1);
  };

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const getModalSliderState = useSelector(getCurrentModalStateSlider);

  useEffect(() => {
    dispatch(closeConfirmationModal("deleteMediaConfirmationFromList"));
  }, []);

  const onDeleteMedia = () => {
    setAreButtonsDisabled(true);
    dispatch(openConfirmationModal("deleteMedia"));
    dispatch(setUploadedOldFile(undefined));
    setAreButtonsDisabled(false);
  };

  type mediaValues = {
    name: string;
    media_360_tag: string;
  };
  //submit media name
  const {
    register: registerNewMediaDataEdited,
    reset: resetMediaData,

    formState: { isDirty },
    getValues,
  } = useForm<mediaValues>({
    defaultValues: {
      name: media.name,
    },
  });
  useEffect(() => {
    resetMediaData({
      name: media.name,
    });
  }, [getModalSliderState]);

  const onSubmitMediaEdit = (name: string, media360Tag: string) => {
    const mediaDataEdited = {
      ...media,
      name: name,
      media_360_tag: media360Tag,
    };
    if (mediaDataEdited.name !== "") {
      dispatch(
        updateMediaDetail({ media: mediaDataEdited, componentId: MEDIA_NEW_DATA_FOR_UPDATE }),
      ).then(() => {
        closeSliderAnimation();
      });
      resetMediaData({
        name: name,
        media_360_tag: media360Tag,
      });
    }
  };

  const cancel = () => {
    closeSliderAnimation();

    resetMediaData({
      name: media.name,
      media_360_tag: media.media_360_tag,
    });
  };

  return (
    <SlideOver id={id} large={large}>
      <IsLoading componentId={MEDIA_TAGS_COMPONENT_ID} showSuccess={false} />
      <IsLoading componentId={MEDIA_TAGS_CHECK_BEFORE_DELETE_ID} showSuccess={false} />
      <IsLoading componentId={MEDIA_NEW_DATA_FOR_UPDATE} showSuccess={false} />
      <div className="flex w-full flex-col justify-between p-6 h-full">
        <div>
          <MediaViewer media={media} />
          <div className="my-3">
            <h1 className="text-sm font-medium">{media.name}</h1>

            <h1 className="text-sm font-medium text-gray-500">
              {prettifyFileSizeInBytes(media.size_in_bytes as number, currentLocale)}
            </h1>
          </div>

          <div className="flex py-6">
            <div className="w-full">
              <>
                <div>
                  <form>
                    <div className="mb-10">
                      <div className="block text-lg font-medium text-gray-700 mb-5 ">
                        {t("general.renameFile")}
                      </div>
                      <Input
                        type="text"
                        registerFct={() => registerNewMediaDataEdited("name", { required: true })}
                        placeholder={"name"}
                        required
                      />
                    </div>
                    <Media360TagDropdown
                      mode="edit"
                      media={media}
                      registerFct={() =>
                        registerNewMediaDataEdited("media_360_tag", { required: true })
                      }
                    />

                    <div
                      className=" mr-2 mt-3 bottom-4 right-28
                    absolute "
                    >
                      <button
                        type="button"
                        onClick={() => {
                          if (isDirty) {
                            onSubmitMediaEdit(getValues("name"), getValues("media_360_tag"));
                          }
                        }}
                        className="btn-primary-fill mt-2 -bottom-px -right-24
                            absolute"
                        disabled={!isDirty}
                      >
                        {t("general.save")}
                      </button>
                      <div className="mt-3">
                        <button
                          type="button"
                          onClick={cancel}
                          className="btn-alternative-outline mt-2 absolute -bottom-px -left-80 ml-3.5"
                        >
                          {t("general.cancel")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="mt-3">
                  <div
                    className="flex bottom-4 right-32
                    absolute "
                  >
                    <button
                      type="button"
                      className={`flex mr-5 items-center btn-alternative-outline ${
                        shouldHideSuppressButton ? "hidden" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();

                        onDeleteMedia();
                      }}
                      disabled={areButtonsDisabled || shouldHideSuppressButton}
                    >
                      <MdDeleteOutline size={20} />

                      {t("general.delete")}
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmationToDeleteMedia
        id={"deleteMedia"}
        idMedia={media.id}
        mediasToDelete={[media]}
      />
    </SlideOver>
  );
};
