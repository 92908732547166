import * as AllIcons from "@heroicons/react/outline";
import { SVGProps } from "react";

export type IconName = keyof typeof AllIcons;

interface IconProps extends SVGProps<SVGSVGElement> {
  icon: IconName;
  className: string;
}

const Icon = ({ icon, className, ...props }: IconProps) => {
  const SingleIcon = AllIcons[icon];
  return <SingleIcon className={className} {...props} />;
};

export default Icon;
