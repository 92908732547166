import { Outlet } from "react-router-dom";
import MobileFooter from "../components/MobileFooter/MobileFooter";
import MobileHeader from "../components/MobileHeader/MobileHeader";
import { PrivatePage } from "./PrivatePage";
import Alert from "../components/Alert/Alert";

const MobileLayout = ({
  screenSize,
}: {
  screenSize: {
    height: number;
    width: number;
  };
}) => {
  return (
    <PrivatePage>
      <div
        style={{ height: screenSize.height }}
        className="bg-slate-100 m-auto h-full
          grid mobile-portrait-layout
          grid-areas-mobile-portrait-layout 
          grid-cols-mobile-portrait-layout 
          grid-rows-mobile-portrait-layout"
      >
        <MobileHeader className="grid-in-header" />
        <Alert />

        <main className="grid-in-main">
          <Outlet />
        </main>
        <MobileFooter className="grid-in-footer flex justify-self-end w-full justify-evenly bg-blue-500" />
      </div>
    </PrivatePage>
  );
};

export default MobileLayout;
