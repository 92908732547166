import axios from "axios";

interface MonitorConfig {
  pingInterval?: number; // Interval in ms (default: 5000)
  pingThreshold?: number; // Max acceptable ping time in ms (default: 300)
  maxFailures?: number; // Failures before showing alert (default: 3)
  showAlert: () => void; // Function to show the alert
  hideAlert: () => void; // Function to hide the alert
}

export const internetConnexionMonitor = ({
  pingInterval = 5000,
  pingThreshold = 300,
  maxFailures = 3,
  showAlert,
  hideAlert,
}: MonitorConfig) => {
  let failedPingCount = 0;
  let hasShownAlert = false;
  let intervalId: NodeJS.Timeout;

  const pingTest = async () => {
    try {
      const response = await axios.get("https://checkip.amazonaws.com", {
        timeout: pingThreshold,
      });
      if (response.status === 200) {
        failedPingCount = 0;
        if (hasShownAlert) {
          hideAlert();
          hasShownAlert = false;
        }
        return;
      }
    } catch (error) {
      failedPingCount += 1;
    }

    if (failedPingCount >= maxFailures && !hasShownAlert) {
      showAlert();
      hasShownAlert = true;
    }
  };

  const start = () => {
    intervalId = setInterval(pingTest, pingInterval);
  };

  const stop = () => {
    clearInterval(intervalId);
  };

  start();

  return { stop };
};
