import PlatformIcon from "../../../common/components/Icon/PlatformIcon/PlatformIcon";

interface ButtonDownloadClient extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  platform: string;
  href: string;
  styled: string;
  textInfo: string;
  textIcon: string;
}

const DownloadClientButton = ({
  platform,
  styled,
  href,
  textInfo = "",
  textIcon = "",
  ...props
}: ButtonDownloadClient) => {
  return (
    <div className="flex flex-col items-center justify-evenly space-y-5 ">
      <button className={styled} {...props}>
        <a href={href} download target="_blank" rel="noopener noreferrer">
          <div className="flex flex-row h-full w-full items-center justify-evenly">
            <p className="text-white max-w-xs text-center ">{textIcon}</p>
            <PlatformIcon platform={platform} iconProps={{ width: 15, fill: "white", style: "" }} />
          </div>
        </a>
      </button>
    </div>
  );
};

export default DownloadClientButton;
