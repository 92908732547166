import { useNavigate, useParams } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { getTemplate } from "../../features/projects/state/projectsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import TemplatePreviewMedia from "../../features/projects/components/TemplateMedia";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import { FooterButtons } from "src/common/components/FooterButtons";

interface IProjectOverview {
  incrementStep(): void;
}

const ProjectOverview = ({ incrementStep }: IProjectOverview) => {
  const { t } = useTranslation();
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { templates } = useSelector((state: RootState) => {
    return {
      templates: getTemplate(state),
    };
  });

  const selectedTemplate = templates.find(
    (template) => template.language === "fr" && Number(template.project.id) === Number(templateId),
  );

  const { selectedProjectGroup } = useSelector((state: RootState) => {
    return {
      selectedProjectGroup: getSelectedProjectGroup(state),
    };
  });

  return (
    <>
      <h1 className="text-2xl my-5 mx-6 font-medium">
        {selectedTemplate?.project?.name ?? "Features"}
      </h1>
      <p className="px-10 lg:pl-10  w-full xl:w-2/5 font-medium">
        {selectedTemplate?.top_left_infos}
      </p>
      <TemplatePreviewMedia item={selectedTemplate} />
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 my-auto w-full px-6 py-6 lg:px-6  gap-10 xl:w-2/5 ">
        {selectedTemplate?.features_texts.map((featuretext: string, index: number) => (
          <div key={index} className="flex w-full h-max">
            <div className="mr-2">
              <CheckIcon className="stroke-green-500 w-8 h-8" />
            </div>
            <div className="block">
              <h3 className="font-medium text-lg">{featuretext}</h3>
            </div>
          </div>
        ))}
      </div>
      <FooterButtons
        cancelText={t("general.cancel")}
        cancelCb={() => {
          navigate(`/courses/${selectedProjectGroup.id}/list`);
        }}
        backText={t("general.back")}
        backCb={() => {
          navigate(-1);
        }}
        nextText={t("general.next")}
        nextCb={() => incrementStep()}
      />
    </>
  );
};

export default ProjectOverview;
