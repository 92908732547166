const CheckAll = ({
  checked,
  onChange,
  className = "absolute left-4 top-26 mt-3",
  labelClassName = "",
}: {
  checked: boolean;
  onChange(): any;
  className?: string;
  labelClassName?: string;
}) => {
  return (
    <div className={className}>
      <div className="flex items-center py-2 ">
        <label className={`${labelClassName}`}>
          <input
            className="cursor-pointer"
            checked={checked}
            type="checkbox"
            onChange={onChange}
            id="check-all"
          ></input>
        </label>
      </div>
    </div>
  );
};

export default CheckAll;
