import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WixarLogo3 from "../../common/components/Icon/WixarLogo3";
import SubscriptionIFrame from "../../common/components/SubscriptionIFrame/SubscriptionIFrame";
import { jwtSelector } from "../../common/state/selectors/authSelector";
import {
  fetchSubscription,
  getSubscriptionStatus,
} from "../../features/subscription/state/subscriptionSlice";
import { AppDispatch } from "../../common/state/store";
import { logoutAuth } from "../../features/auth/state/authSlice";
import { Button } from "@mui/material";

export default function NoSubscriptionHome(): ReactElement {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const jwt = useSelector(jwtSelector);
  const subscriptionState = useSelector(getSubscriptionStatus);

  useEffect(() => {
    if (jwt) {
      dispatch(fetchSubscription({}));
    }
  }, [jwt]);

  const msgCb = (event: any) => {
    if (event.data.ReferenceOffer && event.data.IdSubscription) {
      window.location.reload();
    }

    // close the iframe
    const frameProAbono = document.getElementById("frame-proabono");
    frameProAbono?.parentNode?.removeChild(frameProAbono);
  };

  const logout_click = () => {
    dispatch(logoutAuth());
  };

  return (
    <div className="flex flex-col grid h-full w-full bg-white">
      <div style={{ alignSelf: "end" }} className="flex justify-end mt-2 mr-2">
        <Button onClick={logout_click} className="btn-primary-fill">
          {t("general.logout")}
        </Button>
      </div>

      <div>
        <div
          className="grid items-center justify-center justify-items-center"
          style={{ paddingTop: "1%", paddingBottom: "1%" }}
        >
          <WixarLogo3 style={{ width: "75%" }} />
        </div>
      </div>

      <div>
        <div>
          <div className="text-center">
            <p>
              {subscriptionState === "NeverHasSubscription"
                ? t("pages.noSubscriptionHome.neverHasSubscriptionMessage")
                : t("pages.noSubscriptionHome.noSubscriptionMessage")}
            </p>
          </div>
        </div>
      </div>

      <div className="h-[70vh]">
        <SubscriptionIFrame msgCb={msgCb} />
      </div>
    </div>
  );
}
