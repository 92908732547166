import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentProject } from "../../features/projects/state/projectsSlice";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import Navigation from "../../common/components/Navigation/Navigation";
import { useTranslation } from "react-i18next";
import AddTeamModal from "../../features/team/components/AddTeamModal";
import ParticipantCsv from "../../features/team/components/ParticipantFromCsvModal";
import { useEffect } from "react";
import { clearAlerts, setAlert } from "../../features/alert/state/alertsSlice";
import OnboardingComponent from "../../common/util/OnBoardingComponent";
import stepsOnboarding from "../../common/util/OnboardingTour";
import { getIsLoading } from "../../features/graph/state/projectsSliceLegacy";

const Main = () => {
  const currentProject = useSelector(getCurrentProject);
  const isGraphLoading = useSelector(getIsLoading);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const modulePublished = currentProject.status !== "Edit";

  const pathArray = pathname.split("/").filter(Boolean);

  const isInNodeDetailsPage =
    pathArray[0] === "courses" && pathArray[4] === "graph" && pathArray[5] === "node";

  /* WIXAR-2588 : display an alert when project is published */
  useEffect(() => {
    if (modulePublished) {
      dispatch(
        setAlert({
          type: "warning",
          msg: t("alerts.publishedModule"),
          subMsg: t("alerts.publishedModuleAreNotEditable"),
          customTimeoutInMs: 2147483647,
          /* https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value */
        }),
      );
    }
    return () => {
      dispatch(clearAlerts());
    };
  }, []);

  const tabs = [
    { title: t("pages.project.tabs.scenario"), urlEndPoint: "graph" },
    { title: t("general.preview"), urlEndPoint: "preview" },
    { title: t("general.participants"), urlEndPoint: "learners" },
    { title: t("general.information"), urlEndPoint: "information" },
  ];
  const revelantArticle = modulePublished ? "modulePublished" : "howToAddScene";
  const revelantArticleIsPublished = modulePublished ? "" : "howToAddScene";
  return (
    <>
      <Navigation
        title={currentProject.name}
        isProjectView
        showGoBackButton
        tabs={tabs}
        backCustomRedirectTo={
          isInNodeDetailsPage ? `/${pathArray.slice(0, 5).join("/")}` : undefined
        }
      />
      {!isGraphLoading && (
        <OnboardingComponent
          id={revelantArticleIsPublished}
          relevantArticle={revelantArticleIsPublished}
          steps={stepsOnboarding().onboardingHowToAddScene}
        />
      )}
      <InfoButton
        relevantArticle={`${revelantArticle}`}
        haveButtonToOnboarding={revelantArticleIsPublished === "howToAddScene"}
      />
      <Outlet />
      <AddTeamModal />
      <ParticipantCsv />
    </>
  );
};

export default Main;
