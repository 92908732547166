import ReactMarkdown from "react-markdown";

const ChatBubble = ({
  sender,
  text,
  customKey,
  ...props
}: {
  sender: "user" | "bot";
  text: string;
  customKey: number;
}) => {
  return (
    <div
      key={customKey}
      className={`flex chat-bubble ${sender === "user" ? "justify-end" : "justify-start"}`}
      {...props}
    >
      {text && (
        <div
          className={`px-4 py-2 rounded-3xl ${
            sender === "user" ? "max-w-xs bg-blue-500 text-white" : "bg-gray-300 text-black"
          } bot-bubble`}
        >
          {sender === "bot" ? <ReactMarkdown>{text}</ReactMarkdown> : text}
        </div>
      )}
    </div>
  );
};

export default ChatBubble;
