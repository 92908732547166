import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UploadItem from "../../common/components/UploadBar/UploadItem";
import { getCurrentUploadsStateList } from "../../features/gallery/state/gallerySlice";

export default function MobileUploadsList() {
  const { t } = useTranslation();

  const currentUploadsStateList = useSelector(getCurrentUploadsStateList);

  return (
    <div className={"flex flex-row items-center h-full w-full justify-between"}>
      <div className="h-full w-full overflow-x-auto">
        <div className="w-full h-full flex flex-col h-[3rem] mt-8">
          {currentUploadsStateList.length <= 0 ? (
            <p className="text-center italic mt-20">
              {t("pages.gallery.uploadPanel.noMediaUploadedYet")}
            </p>
          ) : (
            currentUploadsStateList.map((v) => (
              <div key={`upload_bar_${v.uploadId}`} className="pl-2 pr-2 mt-2 w-full h-[75%]">
                <UploadItem item={v} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
