import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmationModal } from "../../state/slice/modal/modalSlice";
import { AppDispatch } from "src/common/state/store";
import ModalConfirmation from "src/common/components/Modal/ModalConfirmation";
import {
  deleteCreator,
  deleteCreators,
  deleteEnduser,
  deleteEndusers,
  getDeleteUsersDraft,
  setDeleteUsersDraft,
} from "src/features/team/state/teamSlice";
import { useEffect } from "react";
import {
  ANOTHER_COLLABORATORS_TABLE_COMPONENT,
  COLLABORATORS_LIST_COMPONENT,
} from "src/pages/Collaborators/CollaboratorsList";
import {
  ANOTHER_PARTICIPANTS_TABLE_COMPONENT,
  ENDUSERS_LIST_COMPONENT,
} from "src/pages/Participants/ParticipantsList";
import { displayProperAlert } from "src/common/util/displayProperAlert";

const ModalConfirmUserDeletion = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const deleteUsersDraft = useSelector(getDeleteUsersDraft);

  const deduceText = (
    type: "delete_creator" | "delete_creators" | "delete_enduser" | "delete_endusers",
  ) => {
    const output = {
      title: `${t("pages.collaborators.youreGonnaEraseThisUser")}: ${
        deleteUsersDraft.usersToBeDeleted[0].email
      }`,
      text: "",
      text2: "",
    };
    switch (type) {
      case "delete_creator":
      default:
        output.text = t("pages.collaborators.thisCollaboratorWillLoseEverything1");
        output.text2 = t("pages.collaborators.thisCollaboratorWillLoseEverything2");
        break;
      case "delete_creators":
        output.title = t("pages.collaborators.youreGonnaEraseTheseUsers");
        output.text = t("pages.collaborators.theseCollaboratorsWillLoseEverything1");
        output.text2 = t("pages.collaborators.theseCollaboratorsWillLoseEverything2");
        break;
      case "delete_enduser":
        output.text = t("pages.participants.thisParticipantWillLoseEverything1");
        output.text2 = t("pages.participants.thisParticipantWillLoseEverything2");
        break;
      case "delete_endusers":
        output.title = t("pages.collaborators.youreGonnaEraseTheseUsers");
        output.text = t("pages.participants.theseParticipantsWillLoseEverything1");
        output.text2 = t("pages.participants.theseParticipantsWillLoseEverything2");
        break;
    }
    return output;
  };

  useEffect(() => {
    return () => {
      dispatch(
        setDeleteUsersDraft({ type: "delete_creator", usersToBeDeleted: [{ id: 0, email: "" }] }),
      );
    };
  }, []);

  const relevantText = deduceText(deleteUsersDraft.type);

  return (
    <ModalConfirmation
      title={relevantText.title}
      text={`${relevantText.text} ${relevantText.text2}`}
      onClickSubmit={() => {
        if (deleteUsersDraft.usersToBeDeleted.length > 1) {
          if (deleteUsersDraft.type.includes("creator")) {
            dispatch(
              deleteCreators({
                creators: deleteUsersDraft.usersToBeDeleted.map((u) => u.id),
                componentId: ANOTHER_COLLABORATORS_TABLE_COMPONENT,
              }),
            ).then((res: any) => {
              displayProperAlert({
                httpStatus: res.payload.status,
                successMsg: "alerts.collaboratorsDeletedSuccessfully",
                errorMsg: "alerts.collaboratorsDeletedFail",
                dispatch,
                t,
              });
            });
          } else {
            dispatch(
              deleteEndusers({
                endusers: deleteUsersDraft.usersToBeDeleted.map((u) => u.id),
                componentId: ANOTHER_PARTICIPANTS_TABLE_COMPONENT,
              }),
            ).then((res: any) => {
              displayProperAlert({
                httpStatus: res.payload.status,
                successMsg: "alerts.participantsDeletedSuccessfully",
                errorMsg: "alerts.participantsDeletedFail",
                dispatch,
                t,
              });
            });
          }
        }
        if (deleteUsersDraft.usersToBeDeleted.length === 1) {
          const userToBeDeleted = deleteUsersDraft.usersToBeDeleted[0];
          if (deleteUsersDraft.type.includes("creator")) {
            console.log(
              `Promise to delete creator ${userToBeDeleted.id} - ${userToBeDeleted.email}`,
            );
            dispatch(
              deleteCreator({
                id: userToBeDeleted.id,
                componentId: COLLABORATORS_LIST_COMPONENT,
              }),
            ).then((res: any) => {
              displayProperAlert({
                httpStatus: res.payload.status,
                successMsg: "alerts.collaboratorDeletedSuccessfully",
                errorMsg: "alerts.creatorDeletedFail",
                dispatch,
                t,
              });
            });
          } else {
            console.log(
              `Promise to delete enduser ${userToBeDeleted.id} - ${userToBeDeleted.email}`,
            );
            dispatch(
              deleteEnduser({ id: userToBeDeleted.id, componentId: ENDUSERS_LIST_COMPONENT }),
            ).then((res: any) => {
              displayProperAlert({
                httpStatus: res.payload.status,
                successMsg: "alerts.participantDeletedSuccessfully",
                errorMsg: "alerts.participantDeletedFail",
                dispatch,
                t,
              });
            });
          }
        }

        return dispatch(closeConfirmationModal("confirm-delete-users"));
      }}
      id={"confirm-delete-users"}
    >
      {deleteUsersDraft.usersToBeDeleted.length > 1 && (
        <div>
          <p className="text-gray-600 mb-4">
            {t("pages.collaborators.theFollowingUsersWillBeDeleted")} :
          </p>
          {deleteUsersDraft.usersToBeDeleted.map((user, i) => (
            <p key={`${i}_${user.id}`} className="m-2 text-center italic">
              {user.email}
            </p>
          ))}
        </div>
      )}
      <p className="text-gray-600 mb-4 mt-2">{t("general.areYouSure")}</p>
    </ModalConfirmation>
  );
};

export default ModalConfirmUserDeletion;
