import { ChevronRightIcon, ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNameFormatter } from "src/features/graph/util/NameFormatter";
import { mediaSelector } from "../state/gallerySlice";
import Spinner from "src/common/components/Loader/Spinner";

interface IEntitiesToDisplayWarning {
  users: any[];
  projects: any[];
  organizations: any[];
}
interface IModuleWarning {
  entitiesToDisplayWarning: IEntitiesToDisplayWarning;
}

const Dropdown = ({ title, isOpen, toggleDropdown, children }: any) => (
  <div className="dropdown border-2 border-gray-300">
    <div className="dropdown-header" onClick={toggleDropdown}>
      <ExclamationCircleIcon className="stroke-red-500 h-5 w-5 mr-2" />
      {title}
      {isOpen ? (
        <ChevronDownIcon className="stroke-green-500 ml-2 h-4 w-4" />
      ) : (
        <ChevronRightIcon className="stroke-green-500 ml-2 h-4 w-4" />
      )}
    </div>
    {isOpen && (
      <div className="dropdown-body open py-2 grid grid-flow-col h-auto border-t-2">{children}</div>
    )}
  </div>
);

export const MediaModules = ({ entitiesToDisplayWarning }: IModuleWarning) => {
  const { t } = useTranslation();
  const { getSameFormattedModelNameThanUnity } = useNameFormatter();
  const allMedias = useSelector(mediaSelector);
  const [isOpen, setOpen] = useState<number | null>(null);

  const toggleDropdown = (index: number) => setOpen(isOpen === index ? null : index);

  const renderMedias = ({ items, allMedias, usedAsWhat }) => {
    return items.map((item) => {
      const media = allMedias.find((media) => media.id === item);
      return (
        <div className="ml-3" key={item} id={String(item)}>
          <h6>
            {`${
              media
                ? `${t("pages.gallery.deleteMedia.mediaNamed")} "${media.name}"`
                : `${t("pages.gallery.deleteMedia.mediaWithId")} "${item}"`
            } ${t("pages.gallery.deleteMedia.usedAs")} ${usedAsWhat}`}
          </h6>
        </div>
      );
    });
  };

  const renderSection = ({ entities, contextText, withDropdown = false }: any) =>
    entities.length >= 1 && (
      <>
        <div className="mx-5 mt-4">
          <h4 className="font-semibold text-xl">{contextText.header}</h4>
        </div>
        <div className={`overflow-y-auto ${entities.length < 3 ? "" : "h-72"}`}>
          {entities.map((entity, index) => {
            return withDropdown ? (
              <Dropdown
                key={`${entity.name}_${index}`}
                title={entity.projectName}
                isOpen={isOpen === index}
                toggleDropdown={() => toggleDropdown(index)}
              >
                {renderMedias({
                  items: entity.projectMedias,
                  allMedias: allMedias,
                  usedAsWhat: !contextText.usedAsWhat
                    ? entity.apiId.includes("content")
                      ? `${getSameFormattedModelNameThanUnity(entity.apiId)}`
                      : `${getSameFormattedModelNameThanUnity(
                          `${entity.apiId}.${entity.fieldName}`,
                        )}`
                    : contextText.usedAsWhat,
                })}
              </Dropdown>
            ) : (
              <div
                key={`${entity.username}_${index}`}
                className="dropdown border-2 border-gray-300"
              >
                <div className="dropdown-header">
                  <ExclamationCircleIcon className="stroke-red-500 h-5 w-5 mr-2" />
                  {renderMedias({
                    items: entity.projectMedias,
                    allMedias: allMedias,
                    usedAsWhat: !contextText.usedAsWhat
                      ? entity.apiId.includes("content")
                        ? `${getSameFormattedModelNameThanUnity(entity.apiId)}`
                        : `${getSameFormattedModelNameThanUnity(
                            `${entity.apiId}.${entity.fieldName}`,
                          )}`
                      : contextText.usedAsWhat,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );

  const projectContextText = {
    header:
      entitiesToDisplayWarning.projects.length > 1
        ? t("pages.gallery.deleteMedia.yourModules")
        : t("pages.gallery.deleteMedia.yourModule"),
  };

  const userContextText = {
    header: t("pages.gallery.deleteMedia.yourUser"),
    usedAsWhat: t("pages.gallery.deleteMedia.profilePicture"),
  };

  const organizationContextText = {
    header: t("pages.gallery.deleteMedia.yourOrganization"),
    usedAsWhat: t("pages.gallery.deleteMedia.organizationLogo"),
  };

  if (!entitiesToDisplayWarning || Object.keys(entitiesToDisplayWarning).length === 0) {
    return <Spinner />;
  }

  return (
    <div className="justify-center">
      {renderSection({
        entities: entitiesToDisplayWarning.projects,
        contextText: projectContextText,
        withDropdown: true,
      })}
      {renderSection({
        entities: entitiesToDisplayWarning.users,
        contextText: userContextText,
      })}
      {renderSection({
        entities: entitiesToDisplayWarning.organizations,
        contextText: organizationContextText,
      })}
    </div>
  );
};
