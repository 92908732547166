type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  x?: number;
  y?: number;
  color?: string;
};

export default function Trophy({
  width = 28,
  height = 28,
  viewBox = "0 0 11 10",
  x = 37,
  y = 56,
  fill = "#FFC107",
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2284_25922)">
        <path
          d="M2.21969 3.43521C2.0256 3.46866 1.86719 3.58045 1.86719 3.75953C1.86719 3.93866 2.02502 4.08381 2.21969 4.08381C2.31599 4.08381 2.40877 4.07002 2.49623 4.04493C2.41426 3.84654 2.35155 3.63674 2.30791 3.41895C2.30791 3.41895 2.24182 3.4314 2.21969 3.43521Z"
          fill={fill}
        />
        <path
          d="M6.2632 6.33467C6.16376 6.09546 6.12785 5.89291 6.12785 5.89291C6.10599 5.76972 5.98138 5.69687 5.8509 5.73112C5.8509 5.73112 5.46286 5.83284 5.12787 5.83284C4.79304 5.83284 4.40539 5.73124 4.40539 5.73124C4.27487 5.69706 4.15019 5.76991 4.1284 5.8931C4.1284 5.8931 4.09446 6.08463 3.99816 6.32095C3.93401 6.47836 3.89823 6.53262 3.89823 6.53262C3.82783 6.63936 3.88128 6.72668 4.01698 6.72668H6.23872C6.37445 6.72668 6.42773 6.63948 6.35712 6.53281C6.35709 6.53281 6.32618 6.48617 6.2632 6.33467Z"
          fill={fill}
        />
        <path
          d="M7.21121 0H3.04609C2.87183 0 2.73047 0.130184 2.73047 0.29077V2.68964C2.73047 3.44054 2.97286 4.12402 3.41293 4.61419C3.85589 5.10754 4.46529 5.37928 5.12869 5.37928C5.79212 5.37928 6.40148 5.10754 6.84442 4.61419C7.28448 4.12402 7.52687 3.44054 7.52687 2.68964V0.29077C7.52684 0.130184 7.38548 0 7.21121 0Z"
          fill={fill}
        />
        <path
          d="M2.23668 2.36593H1.63875C1.19171 2.36593 0.828018 2.03139 0.828018 1.62006C0.828018 1.2088 1.19171 0.874187 1.63875 0.874187C1.87524 0.874187 2.08835 0.967838 2.23665 1.11696V0.338869C2.05309 0.266058 1.85096 0.225586 1.63875 0.225586C0.802984 0.225586 0.123047 0.851187 0.123047 1.62006C0.123047 2.389 0.802984 3.01453 1.63875 3.01453H2.25074C2.24148 2.9071 2.23668 2.80032 2.23668 2.69025V2.36593Z"
          fill={fill}
        />
        <path
          d="M8.00586 3.01453H8.61784C9.45358 3.01453 10.1335 2.38903 10.1335 1.62006C10.1335 0.851187 9.45361 0.225586 8.61784 0.225586C8.40563 0.225586 8.2035 0.266058 8.01998 0.338869V1.11692C8.16828 0.967838 8.38139 0.874155 8.61784 0.874155C9.06485 0.874155 9.42854 1.20876 9.42854 1.62003C9.42854 2.03132 9.06489 2.3659 8.61784 2.3659H8.01998V2.69022C8.01995 2.80032 8.01508 2.9071 8.00586 3.01453Z"
          fill={fill}
        />
        <path
          d="M2.21969 7.18164C2.02502 7.18164 1.86719 7.32685 1.86719 7.50592V9.11121C1.86719 9.29032 2.02502 9.43549 2.21969 9.43549H8.03562C8.23029 9.43549 8.38812 9.29032 8.38812 9.11121V7.50592C8.38812 7.32685 8.23029 7.18164 8.03562 7.18164H2.21969Z"
          fill={fill}
        />
        <path
          d="M7.94808 3.41895C7.90444 3.63678 7.84174 3.84654 7.75977 4.04493C7.84723 4.06999 7.94001 4.08381 8.03631 4.08381C8.23098 4.08381 8.38881 3.93866 8.38881 3.75953C8.38881 3.58045 8.24265 3.47076 8.03631 3.43521C8.01203 3.43105 7.94808 3.41895 7.94808 3.41895Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2284_25922">
          <rect width="10.255" height="9.435" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
