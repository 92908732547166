import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AIChatButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/chat/scenario-helper")}
      className="relative inline-block cursor-pointer font-medium text-base whitespace-nowrap transition-all duration-300 w-28 my-3 bg-gradient-to-r from-yellow-600 to-green-400 rounded-lg text-center items-center ml-4"
    >
      <span className="relative z-10 text-white hover:text-yellow-500 animate-glow-pulse">
        {`Wixar ${t("general.ai")}`}
      </span>
    </div>
  );
};

export default AIChatButton;
