import { useSelector } from "react-redux";
import { IProject } from "../../../model/model";
import MoveIcon from "../Icon/MoveIcon";
import ThreeDotsDropdownMenu, { menuItem, menuItems } from "../Icon/ThreeDotsDropdownMenu";
import { mediaSelector } from "src/features/gallery/state/gallerySlice";
import logoWixarPlaceholder from "src/common/util/logoWixarPlaceholder";

interface ButtonSquareIconProjectDraggableProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  project: IProject;
  currentProject: number | undefined;
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  template?: boolean;
  isSelected?: boolean;
  showThreeDotsButton?: boolean;
  isCustomTemplateCard?: boolean;
  index?: number | null;
}

const ButtonSquareIconsProjectDraggable = ({
  template,
  project,
  currentProject,
  handleThreeDotButtonClick,
  isSelected = false,
  showThreeDotsButton = true,
  isCustomTemplateCard = false,
  index = null,
  ...props
}: ButtonSquareIconProjectDraggableProps) => {
  const allMedias = useSelector(mediaSelector);

  const menuItems: menuItems = [
    { menuItemText: "Edit", icon: "PencilAltIcon" },
    { menuItemText: "Fait template", icon: "SaveAsIcon" },
    { menuItemText: "Delete", icon: "TrashIcon" },
  ];

  if (project.name.length > 28) {
    project.name.substring(0, 28) + "...";
  }

  const getBackgroundImage = () => {
    let url = logoWixarPlaceholder;
    if (project?.source_thumbnail?.s3_url) {
      url = project?.source_thumbnail?.s3_url;
    } else if (typeof project?.source_thumbnail === "number") {
      const relevantSourceMedia = allMedias.find(
        (m) => Number(m.id) === Number(project.source_thumbnail),
      );
      if (relevantSourceMedia) {
        url = (relevantSourceMedia?.thumbnail_s3_url || relevantSourceMedia?.s3_url) as string;
      }
    }
    return `url(${url})`;
  };

  return (
    <button
      className={`cursor-grabbing relative inline-flex rounded-lg font-medium w-full h-32 bg-center bg-cover hover:ring-4 hover:ring-purple-500 bg-black bg-opacity-9-100 bg-blend-soft-light ${
        isSelected ? "ring-4 ring-purple-500" : ""
      }`}
      style={{ backgroundImage: getBackgroundImage() }}
      {...props}
    >
      {index != null && <div className="text-white absolute left-3 top-3">{index}</div>}
      <div className="absolute self-center w-[30%] left-[35%]">
        <MoveIcon fill="#00C889" />
      </div>
      <div className="px-3 pt-2 pb-1 flex grow flex-col h-full justify-end w-full">
        <p className="text-left text-white leading-snug text-ellipsis overflow-clip card-title-outline">
          {project.name}
        </p>
      </div>
      {showThreeDotsButton && (
        <div
          className="absolute top-2 right-3 h-6 w-6 rounded-md"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
          }}
        >
          <ThreeDotsDropdownMenu
            menuItems={
              isCustomTemplateCard
                ? menuItems.filter((mi: menuItem) => mi.menuItemText === "Delete")
                : menuItems
            }
            handleClick={(e) => {
              handleThreeDotButtonClick({
                item: "project",
                itemId: project.id,
                action: e.itemId,
              });
            }}
          />
        </div>
      )}
    </button>
  );
};

export default ButtonSquareIconsProjectDraggable;
