import { ReactElement, useEffect } from "react";
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../features/profile/state/profileSlice";
import { languageConfig } from "../../util/langConfig";
import { ILanguage } from "../../../model/model";

const ZENDESK_KEY = "df5e3ccc-1c71-4493-876b-d79036067a33";

const setting = {
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
      "fr-FR": "Besoin d'aide",
    },
  },
  helpCenter: {
    suppress: true,
  },
  talk: {
    suppress: true,
  },
  answerBot: {
    suppress: true,
  },
  webWidget: {
    offset: {
      horizontal: "7.0rem",
    },
    position: {
      horizontal: "left",
    },
  },
};

export default function ZendeskChat(): ReactElement {
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  useEffect(() => {
    ZendeskAPI("webWidget", "setLocale", currentLocale);
    ZendeskAPI("webWidget", "hide");
  }, [currentLocale]);

  return (
    <Zendesk
      defer
      zendeskKey={ZENDESK_KEY}
      {...setting}
      onLoaded={() => {
        ZendeskAPI("webWidget", "setLocale", currentLocale);
        ZendeskAPI("webWidget", "hide");
      }}
      className="support-item"
    />
  );
}
