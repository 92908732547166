import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { DropdownMenuIcon } from "../../common/components/Button";
import { Dropdown } from "../../common/components/Input";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import Navigation from "../../common/components/Navigation/Navigation";
import { useAppDispatch } from "../../common/state/hooks";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import { setAlert } from "../../features/alert/state/alertsSlice";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import { STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID } from "../../features/stats/components/StatsOverview/StatsOverview";
import {
  ISessionsByProjectWithColor,
  fetchCurrentProjectGroupElement,
  fetchGlobalResultsDownloadLink,
  getSelectedProjectGroupId,
  getSelectedProjectId,
  sendProjectResultsGlobal,
  sessionsByProjectGroupSelector,
  setSelectedProjectGroupId,
  setSelectedProjectId,
} from "../../features/stats/state/statsSlice";
import TitleWithDeletedIcon from "src/common/components/TitleWithDeletedIcon/TitleWithDeletedIcon"; // <-- Update import

export const STATS_DOWNLOAD_PROJECT_CSV = "statsDownloadProjectCsv";

const StatisticsWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const projectGroupsWithSessions = useSelector(sessionsByProjectGroupSelector);
  const selectedProjectId = useSelector(getSelectedProjectId);
  const selectedProjectGroupId = useSelector(getSelectedProjectGroupId);
  const currentUser = useSelector(getCurrentUser);

  const selectedCourse = projectGroupsWithSessions.find(
    (course) => course.project_group_id === selectedProjectGroupId,
  );

  const projectsForSelectedCourse = selectedCourse ? selectedCourse.projects : [];

  const selectedProjectWithSessions = projectsForSelectedCourse.find(
    (ppws: ISessionsByProjectWithColor) => ppws.project_id === selectedProjectId,
  );

  const isSelectedCourseDeleted = selectedCourse?.is_deleted || false;
  const isSelectedProjectDeleted = selectedProjectWithSessions?.is_deleted || false;

  const [isDeletedProject, setIsDeletedProject] = useState(isSelectedProjectDeleted);

  useEffect(() => {
    if (isSelectedCourseDeleted) {
      setIsDeletedProject(true);
    } else {
      setIsDeletedProject(selectedProjectWithSessions?.is_deleted || false);
    }
  }, [selectedCourse, selectedProjectWithSessions]);

  const tabs = [
    { title: "Groupe", urlEndPoint: "group" },
    { title: t("general.participants"), urlEndPoint: "learners" },
  ];

  const sendProjectRes = () => {
    dispatch(closeConfirmationModal("sendResultsCreatorAskConfirmation"));
    if (currentUser) {
      dispatch(
        sendProjectResultsGlobal({
          projectId: Number(selectedProjectId),
          trainerId: Number(currentUser.id),
          componentId: STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID,
        }),
      ).then(() => {
        dispatch(
          setAlert({
            type: "success",
            msg: t("pages.statistics.sendResultsCreatorConfirmation.body"),
          }),
        );
      });
    }
  };

  const openConfirmationModalWrapper = () => {
    dispatch(openConfirmationModal("sendResultsCreatorAskConfirmation"));
  };

  const downloadCSV = () => {
    dispatch(
      fetchGlobalResultsDownloadLink({
        projectId: Number(selectedProjectId),
        componentId: STATS_DOWNLOAD_PROJECT_CSV,
      }),
    ).then((res) => {
      window.location.assign(res.payload.s3_url);
    });
  };

  return (
    <>
      <IsLoading
        componentId={STATS_OVERVIEW_PROJECT_GROUPS_SESSIONS_COMPONENT_ID}
        showSuccess={false}
      />
      <IsLoading componentId={STATS_DOWNLOAD_PROJECT_CSV} showSuccess={false} />
      <Navigation
        title={
          <TitleWithDeletedIcon
            selectedProjectWithSessions={selectedProjectWithSessions}
            selectedCourse={selectedCourse}
            isDeletedProject={isDeletedProject}
            isSelectedCourseDeleted={isSelectedCourseDeleted}
          />
        }
        tabs={tabs}
        buttons={
          <div className="flex">
            <div className="mr-5">
              <Dropdown
                label={t("pages.statistics.selectProjectGroup")}
                options={projectGroupsWithSessions.map((ppgws) => {
                  return {
                    optionText: ppgws.project_group_name,
                    value: String(ppgws.project_group_id),
                  };
                })}
                onChangeCb={(e: any) => {
                  dispatch(setSelectedProjectId(0));
                  dispatch(setSelectedProjectGroupId(Number(e.target.value)));
                }}
                value={selectedProjectGroupId}
                noSelectionText={`--- ${t("pages.statistics.overview")} ---`}
                noStatsAvailable={projectGroupsWithSessions.length === 0}
              />
            </div>
            <div>
              <Dropdown
                label={t("pages.statistics.selectProject")}
                options={projectsForSelectedCourse.map((ppws) => {
                  return { optionText: ppws.project_name, value: String(ppws.project_id) };
                })}
                onChangeCb={(e: any) => {
                  dispatch(setSelectedProjectId(Number(e.target.value)));

                  if (e.target.value === "0") {
                    navigate("/statistics");
                  } else {
                    dispatch(
                      fetchCurrentProjectGroupElement({ projectId: Number(e.target.value) }),
                    );
                    navigate("group");
                  }
                }}
                value={selectedProjectId}
                noSelectionText={`--- ${t("pages.statistics.overview")} ---`}
                noStatsAvailable={projectsForSelectedCourse.length === 0}
                noStatsAvailableText={t("pages.statistics.selectProjectGroup")}
              />
              <DropdownMenuIcon
                disabled
                disabledTooltip={t("general.comingSoon")}
                className={`${selectedProjectId !== 0 ? "" : "invisible"}`}
                menuText={t("pages.statistics.exportResults")}
                menuIcon={"TrendingUpIcon"}
                items={[
                  {
                    text: t("pages.statistics.downloadCSV"),
                    id: "csv-download",
                    icon: "DownloadIcon",
                    onClick: downloadCSV,
                  },
                  {
                    text: t("pages.statistics.sendResultsCreator"),
                    id: "send-results-creator-item",
                    icon: "MailIcon",
                    onClick: () => openConfirmationModalWrapper(),
                  },
                ]}
              />
            </div>
          </div>
        }
      />
      <Outlet />
      <ModalConfirmation
        title={t("pages.statistics.sendResultsCreatorAskConfirmation.title")}
        text={t("pages.statistics.sendResultsCreatorAskConfirmation.body")}
        onClickSubmit={sendProjectRes}
        id={"sendResultsCreatorAskConfirmation"}
      />
    </>
  );
};

export default StatisticsWrapper;
