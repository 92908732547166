import { PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "../../common/components/AppState/Error";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { Input } from "../../common/components/Input";
import OrganizationInputStyle from "../../common/components/Input/OrganizationInputStyle";
import ModalConfirmBeforeNavigatingOut from "../../common/components/Modal/ModalConfirmBeforeNavigatingOut";
import { closeStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import { useCallbackPrompt } from "../../common/util/useCallbackPrompt";
import { setAlert } from "../../features/alert/state/alertsSlice";
import ModalGallery from "../../features/graph/components/ModalGallery";
import {
  fetchMoodleEnv,
  getIsAdminOfOrganization,
  getMoodleEnv,
  setCurrentUserAndOrg,
  updateOrganization,
} from "../../features/profile/state/profileSlice";
import { getCurrentOrganization, getCurrentUser } from "../../features/profile/state/profileSlice";
import RoundImagePreview from "../../features/projects/components/RoundImagePreview";
import { allTeamSelector } from "../../features/team/state/teamSlice";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import { IOrganization, ISource, IUser } from "../../model/model";
import MoodleManagement from "src/common/components/MoodleManagement/MoodleManagement";

export const ORGANIZATION_COMPONENT_ID = "organizationComponent";
export const ORGPHOTO_COMPONENT_ID = "OrganizationPhotoComponent";

type FormValues = {
  name: string;
  source_logo: number;
};

const Organization = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { currentUser, isAdmin, organization, team } = useSelector((state: RootState) => {
    return {
      currentUser: getCurrentUser(state),
      organization: getCurrentOrganization(state),
      team: allTeamSelector(state),
      isAdmin: getIsAdminOfOrganization(state),
    };
  });

  const moodleEnv = useSelector(getMoodleEnv);

  const moodleFeature = useSelector((state: RootState) =>
    getSubscriptionFeature(state, "moodle-integration"),
  );

  useEffect(() => {
    if (!currentUser || !organization) {
      dispatch(setCurrentUserAndOrg({ componentId: ORGANIZATION_COMPONENT_ID })).then((action) => {
        const temp = action as PayloadAction<{ currentUser: IUser; organization: IOrganization }>;
        reset({
          name: temp.payload.organization?.name,
          source_logo: Number(temp.payload.organization?.source_logo?.id),
        });
        dispatch(fetchMoodleEnv({ organizationId: temp.payload.organization.id }));
      });
    } else {
      dispatch(fetchMoodleEnv({ organizationId: organization.id }));
    }
  }, []);

  useEffect(() => {
    reset({
      name: organization?.name,
      source_logo: Number(organization?.source_logo?.id),
    });
  }, []);

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  const [disabledName, setDisabledName] = useState(true);
  const [disabledSourceLogo, setDisabledSourceLogo] = useState(true);
  const [disabledEmail, setDisabledEmail] = useState(true);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      name: organization?.name,
      source_logo: Number(organization?.source_logo?.id),
    },
  });

  const onSubmit = (data: FormValues) => {
    const updatedOrganization = {
      ...(organization as IOrganization),
      name: data.name,
      source_logo: {
        ...(organization?.source_logo as ISource),
        id: Number(data.source_logo),
      },
    };
    dispatch(updateOrganization({ componentId: ORGANIZATION_COMPONENT_ID, ...updatedOrganization }))
      .then((res: any) => {
        setDisabledName(true);
        setDisabledSourceLogo(true);
        reset({
          name: res.payload?.name,
          source_logo: Number(res.payload?.source_logo?.id),
        });
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.organizationEditSuccess"),
          }),
        );
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  const cancel = () => {
    reset({
      name: organization?.name,
      source_logo: Number(organization?.source_logo?.id),
    });
    setDisabledName(true);
    setDisabledSourceLogo(true);
  };

  const orgAdminPlaceholder = team.find(
    (user: IUser) => String(user.id) === String(organization?.admin.id),
  )?.email;

  const hasAnythingChanged =
    Number(organization?.source_logo?.id) !== watch("source_logo") || isDirty;

  const hasSomethingChanged = () => {
    if (organization?.name !== getValues("name")) {
      return true;
    }

    if (Number(organization?.source_logo?.id) !== getValues("source_logo")) {
      return true;
    }

    return false;
  };

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    hasSomethingChanged(),
  );

  return (
    <>
      <ModalGallery
        nodeId="organization"
        onMediaChange={(e: any) => {
          const selectedMediaId = Number(e.id);
          setValue("source_logo", selectedMediaId);
          dispatch(closeStandardModal("modal-gallery"));
        }}
      />
      <div className="relative grow w-full pl-4 pr-4 px-4 mx-auto personalize-scroll-visible overflow-y-auto">
        <h2 className="text-2xl font-medium mb-2 mt-2 xl:mt-4">
          {t("pages.profile.tabs.myOrganization")}
        </h2>
        <h3 className="py-3">{t("pages.profile.organization.subheading")}</h3>
        <div className="mt-2 border-gray-300">
          <Error componentId={ORGANIZATION_COMPONENT_ID} />
          <IsLoading componentId={ORGANIZATION_COMPONENT_ID} showSuccess={false} spinnerPlaceholder>
            <form className="h-full mb-2" onSubmit={handleSubmit(onSubmit)}>
              <OrganizationInputStyle
                title={t("pages.profile.organization.orgName")}
                currUserId={currentUser?.id}
                disabled={disabledName}
                setDisabled={setDisabledName}
              >
                <Input
                  autoFocus
                  type="text"
                  highlight={disabledName}
                  readOnly={disabledName}
                  registerFct={() => register("name")}
                  className={`font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0
                 `}
                  placeholder={t("pages.profile.organization.orgNamePlaceholder")}
                />
              </OrganizationInputStyle>

              <OrganizationInputStyle
                title={t("pages.profile.organization.companyLogo")}
                currUserId={currentUser?.id}
                disabled={disabledSourceLogo}
                setDisabled={setDisabledSourceLogo}
                setValueFct={setValue}
              >
                <RoundImagePreview
                  componentId={ORGPHOTO_COMPONENT_ID}
                  media={watch("source_logo")}
                  alt="Logo"
                  bigger
                />
              </OrganizationInputStyle>

              <OrganizationInputStyle
                title={t("pages.profile.organization.adminEmail")}
                currUserId={currentUser?.id}
                disabled={disabledEmail}
                setDisabled={setDisabledEmail}
                hidden
              >
                <Input
                  type="text"
                  readOnly={disabledEmail}
                  className="font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0 ${ ? }"
                  placeholder={orgAdminPlaceholder ? orgAdminPlaceholder : "Anarchy"}
                />
              </OrganizationInputStyle>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="btn-alternative-outline mr-4"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("general.cancel")}
                </button>
                <button type="submit" className="btn-primary-fill" disabled={!hasAnythingChanged}>
                  {t("general.save")}
                </button>
              </div>
            </form>

            {moodleFeature?.IsEnabled && isAdmin && (
              <MoodleManagement
                organization={organization}
                currentUser={currentUser}
                moodleEnv={moodleEnv}
                key={String(moodleEnv)}
              />
            )}
          </IsLoading>
        </div>
      </div>
      <ModalConfirmBeforeNavigatingOut
        showDialog={showPrompt}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </>
  );
};

export default Organization;
