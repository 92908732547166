import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import AvailableFeatureCounter from "src/common/components/AvailableCounter/AvailableFeatureCounter";
import { Checkbox } from "src/common/components/Input";
import TableComp from "src/common/components/Table/Table";
import { useAppDispatch } from "src/common/state/hooks";
import { RootState } from "src/common/state/store";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { getCurrentUsersFromCsv } from "src/features/projectGroups/state/projectGroupsSlice";
import { CONFIRM_CSV_COMPONENT } from "src/features/team/components/ParticipantFromCsvModal";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import {
  bulkInviteEndusersWithoutProject,
  getEndusers,
  inviteExternalEnduserWithoutProject,
} from "src/features/team/state/teamSlice";
import { FooterButtons } from "src/common/components/FooterButtons";

const PARTICIPANTS_CSV_IMPORT_COMPONENT = "participantsCsvImportComponent";

const ParticipantsCsvImport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const usersFromCsv = useSelector(getCurrentUsersFromCsv);
  const participants = useSelector(getEndusers);
  const participantsFeature = useSelector((state: RootState) =>
    getSubscriptionFeature(state, "number-of-available-enduser-accounts"),
  );

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<Array<any>>([]);
  const [teamList, setTeamList] = useState<Array<any>>(usersFromCsv);
  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);

  const currentNumberOfAvailableParticipants = participantsFeature
    ? participantsFeature.QuantityCurrent - isCheck.length
    : 0;
  const totalNumberOfAvailableParticipants = participantsFeature
    ? participantsFeature.QuantityCurrent + participants.length
    : -1;

  const isOverLimit = currentNumberOfAvailableParticipants < 0;

  const deduceNextDisabledTooltip = () => {
    if (
      isOverLimit ||
      participants.length + isCheck.length >
        (participantsFeature ? participantsFeature.QuantityCurrent + participants.length : 0)
    ) {
      return t("tooltip.participantsLimitExceeded");
    }
    if (isCheck.length === 0) {
      return t("tooltip.pleaseSelectAtLeastOneParticipant");
    }
    return "";
  };

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (3 / 5));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(teamList.map((incomingUser: any) => incomingUser.email));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, String(id)]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => String(item) !== String(id)));
    }
  };

  useEffect(() => {
    if (isCheck.length === teamList.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const getColumnValues = teamList.map((member: any, i: number) => {
    const filterSneakySneaky = member.email.includes("@sneakysneaky.ua") ? "" : member.email;

    return {
      checkbox: (
        <Checkbox
          key={i}
          checked={isCheck.includes(member.email)}
          id={String(member.email)}
          value={Object.values(member)}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        />
      ),
      firstName: member.firstname,
      lastName: member.lastname,
      email: filterSneakySneaky,
    };
  });

  const onSubmit = async () => {
    const filterResultOfNewUser = teamList.filter((users: any) =>
      isCheck.map((mails) => mails).includes(users.email),
    );

    if (filterResultOfNewUser.length > 0) {
      if (filterResultOfNewUser.length === 1) {
        dispatch(
          inviteExternalEnduserWithoutProject({
            componentId: PARTICIPANTS_CSV_IMPORT_COMPONENT,
            ...filterResultOfNewUser[0],
          }),
        ).then(() => {
          navigate("/learners");

          dispatch(
            setAlert({
              type: "success",
              msg: t("alerts.participantInvitedSuccessfully"),
            }),
          );
        });
      }
      if (filterResultOfNewUser.length > 1) {
        dispatch(
          bulkInviteEndusersWithoutProject({
            componentId: PARTICIPANTS_CSV_IMPORT_COMPONENT,
            endusers: filterResultOfNewUser,
          }),
        ).then(() => {
          navigate("/learners");
          dispatch(
            setAlert({
              type: "success",
              msg: t("alerts.participantsInvitedSuccessfully"),
            }),
          );
        });
      }
    }
  };

  const cancelForm = () => {
    setIsCheck([]);
    navigate("/learners/new");
  };

  const colHeaders = [t("general.firstname"), t("general.lastname"), t("general.email")];

  return (
    <IsLoading componentId={PARTICIPANTS_CSV_IMPORT_COMPONENT} showSuccess={false}>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full h-full">
          {/* overflow-y-auto personalize-scroll-visible */}
          <div className="w-1/4 mt-8">
            <AvailableFeatureCounter
              className="w-80 h-28"
              currentNumberOfAvailable={currentNumberOfAvailableParticipants}
              totalNumberOfAvailable={totalNumberOfAvailableParticipants}
              text={t("pages.participants.participants")}
            />
          </div>
          <form
            className="h-full w-1/2 flex flex-col items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex mb-3 mt-8">
              <h1 className="w-full font-bold text-xl text-center">
                {t("pages.participants.importCsv")}
              </h1>
            </div>

            <h2 className="mb-8 w-4/5 text-center">
              {t("pages.participants.importCsvAdditionalInfos")}
            </h2>

            {getColumnValues?.length > 0 && (
              <IsLoading componentId={CONFIRM_CSV_COMPONENT} showSuccess={false}>
                <TableComp
                  rows={getColumnValues}
                  colHeaders={colHeaders}
                  selectFromListInTable={true}
                  isAllCollaborators={true}
                  isCollaboratorAndParticipant={true}
                  style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
                  className={"personalize-scroll-visible w-full"}
                  key={tableHeight}
                  listTofilter={teamList}
                  setListOfItemsFilter={setTeamList}
                  checkAll={
                    <>
                      <div className="flex items-center py-2" key={"Rising Hope"}>
                        <input
                          checked={isCheck.length === teamList.length}
                          type="checkbox"
                          onChange={handleSelectAll}
                          id="myCheck"
                        ></input>
                      </div>
                    </>
                  }
                />
              </IsLoading>
            )}
          </form>
          <div className="w-1/4"></div>
        </div>
        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => cancelForm()}
          nextText={t("general.invite")}
          nextCb={() => {
            onSubmit();
          }}
          nextDisabled={
            isOverLimit ||
            isCheck.length === 0 ||
            participants.length + isCheck.length >
              (participantsFeature ? participantsFeature.QuantityCurrent + participants.length : 0)
          }
          nextDisabledTooltipText={deduceNextDisabledTooltip()}
        />
      </div>
    </IsLoading>
  );
};

export default ParticipantsCsvImport;
