import { useState } from "react";
import StepperMUI from "../../../common/components/Stepper/Stepper";
import SelectModulesStep from "./SelectModulesStep";
import SelectPlatformsStep from "./SelectPlatformsStep";

export default function PublishNewVersion() {
  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
      default:
        return <SelectModulesStep incrementStep={incrementStep} />;
      case 1:
        return <SelectPlatformsStep decrementStep={decrementStep} />;
    }
  };

  return (
    <div className="flex flex-col align-center items-center h-full w-full">
      <StepperMUI mode="publish" step={step} />
      {renderStep(step)}
    </div>
  );
}
