import apiUrls from "../../api";

export interface ITargetObject {
  table: string;
  id: number;
}

export interface IEventMessage {
  issuedFromEnv: number;
  issuedFromUser: number;
  action: string;
  actionTsInMs: number;
  targetObjects: ITargetObject[];
}

let ES = { OPEN: 0 } as EventSource;

const InitEventSource = (jwt: string) => {
  ES = new EventSource(`${apiUrls.sseStream}?auth=${jwt}`);
  return null;
};

export { InitEventSource, ES };
