import { useLocation } from "react-router-dom";
import { CreateOrUpdateUserAsAdmin } from "src/common/components/CreateOrUpdateUserAsAdmin/CreateOrUpdateUserAsAdmin";

const UpdateCollaborator = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[location.pathname.split("/").length - 1];
  return <CreateOrUpdateUserAsAdmin mode="update" userRole="creator" userId={Number(userId)} />;
};

export default UpdateCollaborator;
