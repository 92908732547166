import { ReactElement } from "react";

interface Props {
  style?: string;
  width: number | string;
  height: number | string;
  fill?: string;
  stroke?: string;
  inTable?: boolean;
}

export default function WebGLIframe({
  width,
  height,
  fill = "",
  style = "",
  stroke = "",
  inTable = false,
}: Props): ReactElement {
  return (
    <svg
      version="1.1"
      viewBox="0 0 1300 610"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      style={inTable ? { marginTop: "-1.5vh" } : {}}
    >
      <title>WebGL logo</title>
      <desc>An open standard for hardware-accelerated web graphics by Khronos Group</desc>
      <g transform="translate(-64.6 -23.3)">
        <polygon points="1114 318.1 1114 323.6 1106 323.6 1106 342.7 1100 342.7 1100 323.6 1092 323.6 1092 318.1" />
        <polygon points="1138 342.7 1138 324 1138 324 1133 342.7 1128 342.7 1122 324 1122 324 1122 342.7 1116 342.7 1116 318.1 1126 318.1 1130 334.5 1134 318.1 1144 318.1 1144 342.7" />
      </g>
      <g transform="matrix(.9958 0 0 1 -57.13 -23.3)">
        <path d="m859.4 341.3c-9 3.8-18 5.7-27.1 5.7-14.4 0-27.4-2.5-38.9-7.5s-21.2-11.9-29.1-20.7-14-19.1-18.2-31-6.3-24.7-6.3-38.4c0-14.1 2.1-27.1 6.3-39.2s10.2-22.6 18.1-31.5c7.9-9 17.6-16 29.1-21.1s24.4-7.7 38.9-7.7c9.7 0 19 1.5 28 4.4s17.2 7.2 24.5 12.8 13.3 12.6 18 20.9 7.6 17.8 8.7 28.5h-39.4c-2.5-10.5-7.2-18.4-14.2-23.7s-15.5-7.9-25.6-7.9c-9.3 0-17.2 1.8-23.7 5.4s-11.8 8.4-15.8 14.5-7 13-8.8 20.7-2.8 15.7-2.8 24c0 7.9 0.9 15.6 2.8 23.1 1.8 7.5 4.8 14.2 8.8 20.2s9.3 10.8 15.8 14.4 14.4 5.4 23.7 5.4c13.7 0 24.3-3.5 31.8-10.4s11.8-16.9 13.1-30.1h-41.6v-31.1h78.9v101.7h-26.3l-4.2-21.3c-7.3 9.5-15.5 16.1-24.5 19.9z" />
        <path d="m981.5 154.6v153.1h91.7v34.9h-133v-188z" />

        <path d="m786.3 366c-63.1 38.4-157.7 60.2-263.2 60.2-190.1 0-344.1-79.1-344.1-176.8 0-97.6 154.1-176.8 344.1-176.8 105.9 0 200.8 22.3 263.9 60.9-68.8-64.7-192.4-110.1-333.6-110.2-216.4 0-391.8 101.2-391.8 226.1s175.4 226.2 391.7 226.2c140.7 0 263.9-45.3 333-109.6z" />
      </g>
      <g transform="translate(-61.6,-23.3)">
        <polygon points="282.5 296.9 246.2 139.9 211.9 139.9 265.7 344.6 297.9 344.6 343.2 183.8 387.9 344.6 420.2 344.6 475.9 139.9 439.6 139.9 403.3 296.9 359.5 139.9 326.3 139.9" />
        <path d="m566.5 226.1c-5-7.1-11.3-12.5-18.7-16.2-7.6-3.8-16.4-5.7-26.2-5.7-11 0-20.9 2-29.5 5.9-8.1 3.7-15 9.2-20.6 16.3-10.3 13.1-15.7 31.6-15.7 53.5 0 9.7 1.4 18.9 4.1 27.1 2.7 8.3 6.8 15.6 12 21.7 11 12.8 26.7 19.6 45.4 19.6 9.8 0 17.8-1 24.5-3.1 5.8-1.8 9.7-4.2 12.9-6.3 4.1-2.7 7.8-6 11.2-9.8 2.8-3.3 5.3-6.9 7.5-10.8 3.8-7 5.7-13.6 6.1-17l0.3-2.3h-26.7l-0.1 1.9c-0.7 8.7-12.1 24.2-32.4 24.2-29 0-35.9-21-36.4-39.5h97.2v-2.1c0-11.9-1.2-22.8-3.6-32.3-2.7-9.9-6.4-18.3-11.3-25.1zm-48 2.1c21.5 0 33 11.6 35.1 35.4h-69.6c1.1-19.3 16.4-35.4 34.5-35.4z" />
        <path d="m715.7 245.7c-2.6-8.3-6.4-15.6-11.3-21.7-5.1-6.3-11.3-11.2-18.4-14.6-7.4-3.5-15.7-5.3-24.7-5.3-8.3 0.1-15.8 1.9-22.4 5.4-5.5 2.8-10.4 6.8-14.6 11.8v-62.8h-26.6v186h25.3v-12.7c2.2 2.9 5.1 6 8.9 8.7 7.2 5.2 16.1 7.8 26.4 7.8 11.1 0 21.1-2.5 29.5-7.4 7.5-4.4 13.9-10.7 18.9-18.7 4.4-7 7.7-15.3 9.9-24.6 1.9-8 2.9-16.8 2.9-25.4 0.1-9.3-1.2-18.3-3.8-26.5zm-23.9 30.2c0 14.6-2.9 26.8-8.5 35.4-5.9 9.1-14.5 13.7-25.5 13.7-15.9 0-34.5-11.1-34.5-42.5 0-15 1.9-26.6 5.7-35.4 5.5-12.7 14.8-18.8 28.3-18.8 14.4 0 24.1 5.6 29.5 17.3 4.4 9.6 5 21.3 5 30.3z" />
      </g>
      <text
        x="60%"
        y="552"
        dy="50"
        textAnchor="middle"
        fill={fill}
        fontSize="200px"
        fontFamily="Verdana"
      >
        &lt;iframe/&gt;
      </text>
    </svg>
  );
}
