import ThreeDotsDropdownMenu, { menuItem } from "src/common/components/Icon/ThreeDotsDropdownMenu";
import ThemePreview from "src/common/components/ThemePreview/ThemePreview";
import { IThemeInfo } from "src/model/model";

export interface IThemeItemParams {
  theme: IThemeInfo;
  onClick?: (arg0: IThemeInfo) => void;
  threeDotsMenuItems?: menuItem[];
  threeDotsMenuHandleClick?: (item: any, index: number) => void;
  isWixarTheme?: boolean;
}

const ThemeItem = ({
  theme,
  onClick,
  threeDotsMenuItems,
  threeDotsMenuHandleClick,
}: IThemeItemParams) => {
  return (
    <div className="w-52 h-52 relative" key={`chooseThemeListPrivate_${theme.id}`}>
      <label className={`w-full h-full relative ${onClick ? "cursor-pointer" : "cursor-default"}`}>
        <ThemePreview themeInfo={theme} className="w-52 h-52" />
        <button
          className="flex w-full justify-center items-center cursor-default"
          onClick={() => {
            if (onClick) {
              onClick(theme);
            }
          }}
        >
          <span>{theme.name}</span>
        </button>
        {threeDotsMenuItems && threeDotsMenuHandleClick && threeDotsMenuItems.length > 0 && (
          <div className="relative w-52 bottom-52 right-0 flex justify-end mr-[1.5rem] overflow-visible">
            <div className="absolute right-0 flex justify-end mr-[1.5rem] overflow-visible">
              <ThreeDotsDropdownMenu
                handleClick={(item, index) => {
                  threeDotsMenuHandleClick(item, index);
                }}
                menuItems={threeDotsMenuItems}
              />
            </div>
          </div>
        )}
      </label>
    </div>
  );
};

export default ThemeItem;
