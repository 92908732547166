import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getNewProjectSelector,
  getEditedProject,
  getCurrentProject,
  setNewProjectEnabledLanguagesIds,
  setEditedProjectEnabledLanguagesIds,
  updateProject,
} from "../../features/projects/state/projectsSlice";
import {
  getCurrentElements,
  getSelectedProjectGroup,
  setCurrentElements,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import { useEffect, useState } from "react";
import { FooterButtons } from "../../common/components/FooterButtons";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import LanguageSelector from "./LanguageSelector";
import { loadGraph } from "src/features/graph/state/graphLegacy";
import { PROJECT_LIST_COMPONENT_ID } from "src/features/projects/components/ProjectList";

interface IProjectLanguage {
  incrementStep?: () => void;
  decrementStep?: () => void;
  mode?: string;
}

const ProjectLanguage = ({ incrementStep, decrementStep, mode = "" }: IProjectLanguage) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { newProject, selectedProjectGroup, editedProject, currentUser, currentProject } =
    useSelector((state: RootState) => {
      return {
        newProject: getNewProjectSelector(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProject: getEditedProject(state),
        currentProject: getCurrentProject(state),
        currentUser: getCurrentUser(state),
      };
    });

  const selectedProjectElements = useSelector(getCurrentElements);

  const userPreferredLanguage = String(currentUser?.preferred_language?.id) || "1";

  const deduceDefaultLanguageIds = () => {
    if (mode === "edit-language") {
      return editedProject.enabledLanguagesIds?.length
        ? editedProject.enabledLanguagesIds
        : [userPreferredLanguage];
    }
    return newProject.enabledLanguagesIds?.length
      ? newProject.enabledLanguagesIds
      : [userPreferredLanguage];
  };

  const [languageIdsValue, setLanguageIdsValue] = useState(deduceDefaultLanguageIds());
  const [nineHellsKey, setNineHellsKey] = useState(666);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === "edit-language") {
      dispatch(setEditedProjectEnabledLanguagesIds(languageIdsValue));
    } else if (mode === "newProject" || mode === "newProjectFromCustomTemplate") {
      dispatch(setNewProjectEnabledLanguagesIds(languageIdsValue));
      if (incrementStep) {
        incrementStep();
      }
    }
  };

  const handleSave = () => {
    if (mode === "edit-language") {
      dispatch(
        updateProject({
          id: currentProject.id,
          payload: {
            enabled_languages: languageIdsValue,
          },
          componentId: PROJECT_LIST_COMPONENT_ID,
        }),
      ).then((res) => {
        const updatedProjectElements = [...selectedProjectElements].map((element) => {
          if (element.project.id === res.payload.id) {
            return {
              ...element,
              project: res.payload,
            };
          }
          return element;
        });
        dispatch(setCurrentElements(updatedProjectElements));
        dispatch(loadGraph(currentProject.id));
      });
      navigate(`/courses/${selectedProjectGroup.id}/list/${currentProject.id}/graph`);
    }
  };

  useEffect(() => {
    const baseLanguages =
      mode === "edit-language" ? editedProject.enabledLanguagesIds : newProject.enabledLanguagesIds;
    setLanguageIdsValue(baseLanguages?.length ? baseLanguages : [userPreferredLanguage]);
    setNineHellsKey(nineHellsKey + 1);
  }, [mode, editedProject.enabledLanguagesIds, newProject.enabledLanguagesIds]);

  const dropdownOptions = [
    { value: "1", optionText: t("general.languages.french") },
    { value: "2", optionText: t("general.languages.english") },
    { value: "3", optionText: t("general.languages.italian") },
  ];

  const handleCancel = () => {
    navigate(`/courses/${selectedProjectGroup.id}/list/${currentProject.id}/graph`);
  };

  return (
    <>
      <h1 className="text-2xl my-5 mx-6 font-medium">
        {t("pages.editProjectLanguage.selectLanguages")}
      </h1>
      <h1 className="text-md my-5 mx-6 font-medium">
        {t("pages.editProjectLanguage.selectLanguagesSubtitle")}
      </h1>
      <form className="flex flex-col grow w-full justify-between" onSubmit={onSubmit}>
        <div className="mx-6 max-w-md">
          <LanguageSelector
            key={nineHellsKey}
            options={dropdownOptions}
            selectedValues={languageIdsValue}
            setSelectedValues={setLanguageIdsValue}
            mode={mode}
            dispatch={dispatch}
          />
        </div>
        {mode === "edit-language" ? (
          <FooterButtons
            cancelCb={handleCancel}
            cancelText={t("general.cancel")}
            nextText={t("general.save")}
            nextCb={handleSave}
          />
        ) : (
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate(`/courses/${selectedProjectGroup.id}/list`)}
            backText={t("general.back")}
            backCb={() => decrementStep()}
            nextText={t("general.next")}
            nextDisabled={languageIdsValue.length < 1}
            nextDisabledTooltipText={t("pages.editProjectLanguage.selectAtLeastALanguage")}
          />
        )}
      </form>
    </>
  );
};

export default ProjectLanguage;
