export default [
  "#87BFFF",
  "#2667FF",
  "#3B28CC",

  "#adaaaa",
  "#6BAA75",
  "#84DD63",

  "#8E6C88",
  "#F4D35E",

  "#EE964B",
  "#F95738",
  "#16F4D0",
  "#429EA6 ",
  "#4B1D3F",

  "#561D25",
  "#315C2B",
  "#E8DCB9",
  "#AFCBFF",
];

// mui colors just in case: https://gist.github.com/kiley0/756bf555c5a7ae17b8d03596ae364712
