import { useTranslation } from "react-i18next";
import MobileUploadButton from "../../common/components/Icon/MobileUploadButton";

export default function MobileDashboard() {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-between items-center">
      <h3 className="text-center text-gray-500 text-xl m-4 font-bold">
        {t("pages.dashboard.youCanAddMediaAnytime")}
      </h3>
      <MobileUploadButton size="150" />
      <h4 className="text-center text-gray-500">{`* ${t(
        "pages.dashboard.enjoyAllFeaturesByConnectingOnDesktop",
      )}`}</h4>
    </div>
  );
}
