import React, { useRef, ReactElement, useEffect, useMemo, useCallback } from "react";
import { RiFullscreenLine } from "react-icons/ri";
import { Unity, useUnityContext } from "react-unity-webgl";
import { isBrowserSupportingWebGL } from "../../../common/util/isBrowserSupportingWeb";
import UnsupportedWebGL from "../../../common/components/UnsupportedWebGL/UnsupportedWebGL";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../common/components/ProgressBar/ProgressBar";

const viewerFolder = "/Player360/";
let currentUnload: (() => Promise<any>) | null = null;

interface Props {
  mediaUrl: string;
  mediaTag360: "media2d" | "media360" | "video180stereo";
  mediaType: "video" | "image";
  className?: string;
  canvasClassName?: string;
  disableFullScreen?: boolean;
}

const createValidCssSelectorFromS3URl = (url: string): string => {
  const urlParts = url.split("/");
  const key = urlParts[urlParts.length - 1].split("?")[0]; // Get the last part before query params
  return key.replace(/[^a-zA-Z0-9-_]/g, ""); // Remove invalid characters for CSS selectors
};

export default function Player360({
  mediaUrl,
  mediaTag360,
  mediaType,
  className,
  canvasClassName,
  disableFullScreen = false,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = React.useState(true);
  const isSupportingWebGL = useMemo(() => isBrowserSupportingWebGL(), []);
  const canvasRef = useRef<HTMLCanvasElement>(null); // Change to useRef
  const { t } = useTranslation();

  const playerDivId = createValidCssSelectorFromS3URl(mediaUrl);

  const { unityProvider, sendMessage, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: viewerFolder + "Build/Player360.loader.js",
    dataUrl: viewerFolder + "Build/Player360.data",
    frameworkUrl: viewerFolder + "Build/Player360.framework.js",
    codeUrl: viewerFolder + "Build/Player360.wasm",
  });

  useEffect(() => {
    if (unload) {
      currentUnload = unload;
    }
  }, [unload]);

  const configureUnityApp = useCallback(() => {
    if (mediaTag360 === "video180stereo") {
      sendMessage("VCR", "ConfigureFor180Stereo");
    }

    if (mediaType === "video") {
      sendMessage("VCR", "PlayVideo", mediaUrl);
    } else {
      sendMessage("VCR", "ShowImage", mediaUrl);
    }

    if (canvasRef.current) {
      canvasRef.current.setAttribute("tabindex", "1");
      canvasRef.current.focus();
    }

    setIsLoading(false);
  }, [mediaTag360, mediaType, mediaUrl, sendMessage]);

  useEffect(() => {
    if (isLoaded) {
      configureUnityApp();
    }
  }, [isLoaded, configureUnityApp]);

  useEffect(() => {
    return () => {
      if (currentUnload) {
        currentUnload().catch((err) => {
          console.error("🙀 Failed to unload Unity instance:", err);
        });
      }
    };
  }, []);

  const toggleFullscreen = useCallback(() => {
    const elem = document.querySelector(`#${playerDivId}`);

    if (!document.fullscreenElement) {
      elem?.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        console.error("Failed to enter fullscreen:", err);
      });
    } else {
      document.exitFullscreen();
      sendMessage("VCR", "ResetTextureSizes");
    }
  }, [playerDivId, sendMessage]);

  return (
    <div className={`${className} unity-player-div`}>
      <div
        className={`w-full h-full flex flex-col justify-center items-center ${
          isLoading ? "" : "hidden"
        }`}
      >
        <p>{t("general.loading")}</p>
        <ProgressBar
          wrapperClassName="w-1/4 flex bg-gray-500 h-[3%]"
          barClassName="bg-gray-900"
          value={loadingProgression * 100}
        />
      </div>
      <div
        id={playerDivId}
        className={`h-full ${isLoading ? "hidden" : "flex"} flex-col items-center`}
      >
        {isSupportingWebGL ? (
          <Unity
            unityProvider={unityProvider}
            ref={canvasRef} // Use useRef instead of createRef
            className={`${isLoading ? "hidden" : "visible"} ${canvasClassName ?? ""}`}
            style={{ height: "100%", width: "100%" }}
          />
        ) : (
          <UnsupportedWebGL />
        )}
        {isSupportingWebGL && !disableFullScreen && (
          <button
            className={`relative mr-2 mt-[-3rem] self-end btn-fullscreen ${
              isLoading ? "hidden" : "visible"
            }`}
            onClick={() => {
              toggleFullscreen();
            }}
          >
            <RiFullscreenLine fill="white" size={40} />
          </button>
        )}
      </div>
    </div>
  );
}
