import { CreateOrUpdateUserAsAdmin } from "src/common/components/CreateOrUpdateUserAsAdmin/CreateOrUpdateUserAsAdmin";

const CreateParticipant = ({ authType = "standard" }: { authType?: "standard" | "simplified" }) => {
  return (
    <CreateOrUpdateUserAsAdmin
      mode="create"
      userRole={authType === "standard" ? "enduser" : "simplifiedEnduser"}
    />
  );
};

export default CreateParticipant;
