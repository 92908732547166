import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
export default function UnsupportedWebGL(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="unsupported-webgl">
      <h3>
        {t("pages.project.unsupportedWebGL")} {"😒"}
      </h3>
      <a href="https://caniuse.com/webgl" target="_blank" rel="noopener noreferrer">
        <h3>{t("pages.project.unsupportedWebGLLink")}</h3>
      </a>
    </div>
  );
}
