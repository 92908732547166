import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalStandard from "../Modal/ModalStandard";
import ModalCloseButton from "../Modal/ModalCloseButton";
import Spinner from "../Loader/Spinner";

// import { updateUser } from "../../features/profile/state/profileSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { closeStandardModal } from "../../state/slice/modal/modalSlice";

export const OLDPASSWORD_COMPONENT_ID = "oldPasswordComponent";
export const NEWPASSWORD_COMPONENT_ID = "newPasswordComponent";

const Avatar3dPopup = (props: any) => {
  window.addEventListener("message", receiveMessage, false);

  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [currUrl, setCurrUrl] = useState("1");
  const [rightUrl, setRightUrl] = useState("2");

  const handleLoading = () => {
    setLoading(false);
  };

  function receiveMessage(event: any) {
    if (
      typeof event.data === "string" &&
      event.data.startsWith("https://") &&
      event.data.endsWith(".glb")
    ) {
      if (event.data !== currUrl) {
        setRightUrl(event.data);
      }
    }
    setCurrUrl(event.data);
  }

  useEffect(() => {
    if (rightUrl === currUrl) {
      props.handleAvatar3dChange(rightUrl);
      setRightUrl(currUrl);
      dispatch(closeStandardModal("modalEditAvatar3d"));
      setCurrUrl("");
    }
  }, [currUrl, rightUrl]);

  return (
    <>
      <ModalStandard id="modalPreviewAvatar3d" fullScreen={false}>
        <div className="flex w-full">
          <div className="relative grow w-full max-w-7xl xl:px-0 mx-auto">
            <div>
              <h2 className="pt-1 pb-6 font-medium text-lg">
                {t("pages.profile.main.previewAvatar.title")}
              </h2>
              <ModalCloseButton isAvatarField />
            </div>

            {loading && (
              <div className="w-full h-12">
                <div className="completely-centered">
                  <Spinner />
                </div>
              </div>
            )}
            <img
              style={{ display: loading ? "none" : "block" }}
              className="inline object-cover w-full rounded-2xl"
              src={
                props.preview ??
                "https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/link_broken.png"
              }
              onLoad={handleLoading}
              // onLoadStart={handleLoading}
              alt="Profile image"
            />
          </div>
        </div>
      </ModalStandard>
      <ModalStandard showCloseButton id="modalEditAvatar3d" fullScreen={false}>
        <div className="flex w-full">
          <div className="relative grow w-full max-w-7xl xl:px-0 mx-auto">
            <h2 className="pt-1 pb-6 font-medium text-lg">
              {t("pages.profile.main.avatar3dCreation.title")}
            </h2>
            <div className="inline object-cover w-full">
              <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                height="500px"
                src="https://wixar.readyplayer.me/"
                allow="clipboard-write"
              ></iframe>
            </div>
          </div>
        </div>
      </ModalStandard>
    </>
  );
};

export default Avatar3dPopup;
