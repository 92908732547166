import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { useAppDispatch } from "../../../../common/state/hooks";
import { getSelectedProjectGroupScorePerProject } from "../../state/statsSlice";
import BarChartSessionsPerMonth from "../Charts/BarChartSessionsPerMonth";
import { IsLoading } from "../../../../common/components/AppState/IsLoading";
import SuccessRatePerScene from "../Charts/SuccessRatePerScene";
import { SUCCESS_RATE_PER_SCENE_COMPONENT_ID } from "../../../../pages/Statistics/StatisticsForProject";
import { ENDUSER_STATS_PER_PROJECT_COMPONENT_ID } from "../../../../pages/Statistics/StatisticsDetail";

interface IProps {
  projectGroupId: number;
  userId?: number;
}

export const STATS_PROJECT_GROUP_CHARTS_COMPONENT_ID = "projectGroupChartsComponent";

const ProjectGroupCharts = ({ projectGroupId, userId = 0 }: IProps) => {
  const { t } = useTranslation();
  const selectedGroupProjectScorePerProject = useSelector(getSelectedProjectGroupScorePerProject);

  const noEligibleSession = false;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
      {/* Top left graph */}
      <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
        <p className="text-gray-800 font-medium text-base text-center p-2">
          {t("pages.statistics.sessionsPerMonth")}
        </p>
        <BarChartSessionsPerMonth
          noSession={noEligibleSession}
          isProject={false}
          id={projectGroupId}
          userId={userId}
        />
      </div>

      {/* Top right graph */}
      <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
        <p className="text-gray-800 font-medium text-base text-center p-2">
          {t("pages.statistics.successRatePerProject")}
        </p>
        <IsLoading
          componentId={
            userId ? ENDUSER_STATS_PER_PROJECT_COMPONENT_ID : SUCCESS_RATE_PER_SCENE_COMPONENT_ID
          }
          showSuccess={false}
          spinnerPlaceholder
          spinnerStyle={{ marginTop: "-1.8vh" }}
        >
          <SuccessRatePerScene data={selectedGroupProjectScorePerProject.projects} />
        </IsLoading>
      </div>
    </div>
  );
};

export default ProjectGroupCharts;
