import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import ModalStandard from "../../common/components/Modal/ModalStandard";
import Navigation from "../../common/components/Navigation/Navigation";
import PlaceholderWithIcon from "../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { useAppDispatch } from "../../common/state/hooks";
import { closeStandardModal } from "../../common/state/slice/modal/modalSlice";
import EnduserProjectCard from "../../features/enduserStats/components/EnduserProjectCard";
import EnduserSessionDetails from "../../features/enduserStats/components/EnduserSessionDetails";
import IProjectGroupCompletionItem from "../../features/enduserStats/dataTypes/IProjectGroupCompletionItem";
import IProjectGroupsCompletionItem from "../../features/enduserStats/dataTypes/IProjectGroupsCompletionItem";
import {
  getEnduserCurrentProjectGroupCompletion,
  getEnduserProjectGroupsCompletion,
} from "../../features/enduserStats/state/enduserStatsSlice";
import StatisticsEndUser from "../../pages/Statistics/EndUserStats";

export const ENDUSER_PROJECT_LIST_COMPONENT_ID = "enduserProjectListComponentId";

export const EnduserProjectsList = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const enduserProjectGroupCompletion = useSelector(getEnduserCurrentProjectGroupCompletion);
  const enduserProjectGroupsCompletion = useSelector(getEnduserProjectGroupsCompletion);
  const currentProjectGroup = enduserProjectGroupsCompletion.find(
    (pgc: IProjectGroupsCompletionItem) => Number(pgc.projectGroupId) === Number(id),
  ) as IProjectGroupsCompletionItem;
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [currentIdProject, setCurrentIdProject] = useState(Number);

  const tabs = [
    { title: t("pages.enduserProjectGroup.tabs.list.title"), urlEndPoint: "list" },
    { title: t("pages.enduserProjectGroup.tabs.todo.title"), urlEndPoint: "todo" },
    { title: t("pages.enduserProjectGroup.tabs.pending.title"), urlEndPoint: "pending" },
    { title: t("pages.enduserProjectGroup.tabs.completed.title"), urlEndPoint: "completed" },
  ];

  const endOfUrl = pathname.split("/")[pathname.split("/").length - 1];

  const projects = enduserProjectGroupCompletion
    .filter((epgc: IProjectGroupCompletionItem) => {
      const progress = epgc.progressPercentage;
      // Only diplay unscorable projects in 'list' tab
      if (progress === null) {
        return endOfUrl === "list" ? true : false;
      }
      switch (endOfUrl) {
        case "pending":
          return progress > 0 && progress < 100;
        case "todo":
          return progress === 0;
        case "completed":
          return progress === 100;
        case "list":
        default:
          return true;
      }
    })
    .map((epgc: IProjectGroupCompletionItem, i: number) => {
      return (
        <EnduserProjectCard
          key={`${epgc.projectId}_${i}`}
          showThreeDotsDropdownMenu
          onClick={() => {
            setCurrentProjectName(epgc.projectName);
            setCurrentIdProject(epgc.projectId);
          }}
          {...epgc}
        />
      );
    });

  return (
    <>
      <IsLoading
        componentId={ENDUSER_PROJECT_LIST_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <>
          <Navigation
            title={currentProjectGroup?.projectGroupName}
            tabs={tabs}
            showSearchBar={false}
            showGoBackButton
          />
          <div className="block px-6 w-full py-6">
            <div className="inline-flex justify-between w-full">
              <div
                className={`w-full ${
                  projects.length > 0
                    ? "grid grid-cols-2 xl:grid-cols-7 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-10 mx-auto"
                    : "flex justify-center"
                }`}
              >
                {projects.length > 0 ? (
                  projects
                ) : (
                  <PlaceholderWithIcon
                    title={t(`pages.enduserProjectGroup.tabs.${endOfUrl}.placeholderTitle`)}
                    text={""}
                    heroIcon="InformationCircleIcon"
                    className="flex flex-col items-center h-[6pc]"
                  />
                )}
              </div>
            </div>
          </div>
        </>
      </IsLoading>
      <ModalStandard
        id="enduserStats"
        className="w-3/5 stats-modal-end-user"
        onClose={() => dispatch(closeStandardModal("enduserStats"))}
        showCloseButton
      >
        <StatisticsEndUser projectId={currentIdProject} projectName={currentProjectName} />
      </ModalStandard>
      <ModalStandard
        id="enduserSessionDetails"
        className="w-1/2 h-2/3"
        onClose={() => dispatch(closeStandardModal("enduserSessionDetails"))}
        showCloseButton
      >
        <EnduserSessionDetails
          projectGroupName={currentProjectGroup?.projectGroupName}
          projectName={currentProjectName}
        />
      </ModalStandard>
    </>
  );
};

export default EnduserProjectsList;
