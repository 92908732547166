export const fromFileReturnMD5AndSizeWorker = (file: File,callback:(res:any, error:any) => void) => {
    const w = new Worker(new URL("./fromFileReturnMD5AndSize.worker.ts", import.meta.url));
  
    if (w) {
      w.onmessage = (e: any) => {
        if (e.data[0] === "setRes") {
          callback(e.data[1],null);
          w.terminate();
        } else if(e.data[0] === "setError") {
          callback(null,e.data[1]);
          w.terminate();
        }
      };
      w.postMessage(["setFile", file]);
    }
  };