import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  LabelList,
  Cell,
} from "recharts";
import { IBareSession } from "../../../enduserStats/dataTypes/IEnduserStatsOverview";
import "./Charts.scss";

interface Props {
  sessions: IBareSession[];
}

const EnduserBarChartSessionsPerMonth = ({ sessions = [] }: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([{}]);
  const [highestValue, setHighestValue] = useState(0);
  const [lowestNonNullValue, setLowestValue] = useState(0);

  // Create array of 12 latest months
  const monthList = [
    t("pages.statistics.months.january"),
    t("pages.statistics.months.february"),
    t("pages.statistics.months.march"),
    t("pages.statistics.months.april"),
    t("pages.statistics.months.may"),
    t("pages.statistics.months.june"),
    t("pages.statistics.months.july"),
    t("pages.statistics.months.august"),
    t("pages.statistics.months.september"),
    t("pages.statistics.months.october"),
    t("pages.statistics.months.november"),
    t("pages.statistics.months.december"),
  ];

  const now = new Date();
  const lastTwelveMonths: any[] = [];

  for (let i = 0; i < 12; i++) {
    const formattedDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
    const year = formattedDate.getFullYear();
    const month = formattedDate.getMonth();
    const toDisplay = `${month + 1}/${String(year).slice(-2)}`;
    lastTwelveMonths.unshift({
      monthName: monthList[month],
      toDisplay,
      month: month,
      year: year,
      sessions: {},
    });
  }

  // Populate the array of 'month objects' with relevant values
  useEffect(() => {
    // from latest 12 months, assign, for each project, the number of sessions created each month
    const dataIR = lastTwelveMonths.map((monthSexyObject: any) => {
      let sessionsOfThatMonth = 0;
      sessions.forEach((session: IBareSession) => {
        // when there is a userId greater than zero, filter sessions by userId

        const monthOfSession = new Date(session.createdAt).getMonth();
        const yearOfSession = new Date(session.createdAt).getFullYear();

        if (monthSexyObject.year === yearOfSession && monthSexyObject.month === monthOfSession) {
          // if year & month are matching, increment session number for that project
          sessionsOfThatMonth = sessionsOfThatMonth + 1;
        }
      });

      //Manage the graph interval manually
      if (sessionsOfThatMonth > highestValue) {
        setHighestValue(sessionsOfThatMonth);
      }
      if (
        lowestNonNullValue === 0 ||
        (sessionsOfThatMonth !== 0 && sessionsOfThatMonth < lowestNonNullValue)
      ) {
        setLowestValue(sessionsOfThatMonth);
      }

      return {
        name: monthSexyObject.toDisplay,
        Sessions: sessionsOfThatMonth,
      };
    });

    setData(dataIR as any);
  }, []);

  const prettifyColorBarDependingOnValueRange = (currentValue: number) => {
    // Todo: better algo that handles a whole range & not only 3 cases
    let opacity = 0.7;
    if (currentValue === highestValue) {
      opacity = 1;
    }
    if (currentValue === lowestNonNullValue) {
      opacity = 0.5;
    }
    return `rgba(86,60,255,${opacity})`;
  };

  return (
    <ResponsiveContainer id="toto" className="bar-chart">
      {sessions.length === 0 ? (
        <p className="no-data-message">{t("pages.statistics.noData")}</p>
      ) : (
        <BarChart margin={{ top: 20, right: 30, left: 0, bottom: 0 }} data={data}>
          <XAxis dataKey="name" interval={0} />
          <YAxis />

          <Bar dataKey="Sessions">
            {data.map((entry: any, index: number) => {
              return (
                <Cell
                  key={`cell-${String(entry)}-${index}`}
                  fill={prettifyColorBarDependingOnValueRange(entry.Sessions)}
                />
              );
            })}
            <LabelList
              dataKey="Sessions"
              position="insideTop"
              formatter={(value: number) => (value ? value : "")}
              fill="#FFFFFF"
            />
          </Bar>
          <Tooltip
            cursor={{ fill: "rgba(0,0,0,0.15)" }}
            labelFormatter={(value) => {
              const parsed = value.split("/");
              const prettyMonth = monthList[parsed[0] - 1];
              const prettyYear = `20${parsed[1]}`;
              return `${prettyMonth} ${prettyYear}`;
            }}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default EnduserBarChartSessionsPerMonth;
