import { useAppDispatch } from "../../state/hooks";
import { closeConfirmationModal } from "../../state/slice/modal/modalSlice";
import ModalStandard from "./ModalStandard";
import { IsLoading } from "../AppState/IsLoading";
import { MediaModules } from "src/features/gallery/components/MediaModuleWarning";
import Spinner from "../Loader/Spinner";
import {
  MEDIA_CHECK_BEFORE_DELETE_ID,
  MEDIA_COMPONENT_ID,
  MEDIA_MULTIPLE_MEDIA_DELETE_COMPONENT_ID,
} from "src/features/gallery/components/MediaList";
import { useEffect, useRef, useState } from "react";
import {
  deleteMedia,
  deleteMultipleMediaFromIds,
  checkIfMediasAreUsed,
  mediaSelector,
} from "src/features/gallery/state/gallerySlice";
import { handleMediaPlaceholderRefreshAfterDeletion } from "../../../common/util/handleMediaPlaceholderRefreshAfterDeletion";
import { useSelector } from "react-redux";
import { RootState } from "src/common/state/store";
import { useTranslation } from "react-i18next";

type ModalStandardProps = {
  id: string;
  text?: string;
  text2?: string;
  componentId?: string;
  isMediaList?: boolean;
  idMedia?: number;
  mediasIdToDeleteThatIsChecked?: Array<any>;
  mediasToDelete?: Array<any>;
};

const ModalConfirmationToDeleteMedia = ({
  id,
  text = "",
  text2 = "",
  componentId,
  isMediaList = false,
  idMedia = -1,
  mediasIdToDeleteThatIsChecked = [],
  mediasToDelete = [],
}: ModalStandardProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { allMedias } = useSelector((state: RootState) => {
    return {
      allMedias: mediaSelector(state),
    };
  });

  const [entitiesToDisplayWarning, setEntitiesToDisplayWarning] = useState({
    projects: [],
    users: [],
    organizations: [],
  });
  const [isComputingFinished, setIsComputingFinished] = useState(true);

  const prevIdMediaRef = useRef<number>();
  const prevMediasIdToDeleteThatIsCheckedRef = useRef<Array<any>>();
  const prevMediasToDeleteRef = useRef<Array<any>>();

  const zeroEntitiesToDisplayWarning =
    entitiesToDisplayWarning.users.length === 0 &&
    entitiesToDisplayWarning.projects.length === 0 &&
    entitiesToDisplayWarning.organizations.length === 0;

  const moreThanZeroEntitiesToDisplayWarning =
    entitiesToDisplayWarning.users.length > 0 ||
    entitiesToDisplayWarning.projects.length > 0 ||
    entitiesToDisplayWarning.organizations.length > 0;

  const oneEntityToDisplayWarning =
    entitiesToDisplayWarning.users.length === 1 ||
    entitiesToDisplayWarning.projects.length === 1 ||
    entitiesToDisplayWarning.organizations.length === 1;

  const moreThanOneEntityToDisplayWarning =
    entitiesToDisplayWarning.users.length > 1 ||
    entitiesToDisplayWarning.projects.length > 1 ||
    entitiesToDisplayWarning.organizations.length > 1;

  const resetStates = () => {
    setEntitiesToDisplayWarning({ projects: [], users: [], organizations: [] });
    setIsComputingFinished(true);
  };

  const deleteMultipleMedias = () => {
    if (zeroEntitiesToDisplayWarning && mediasIdToDeleteThatIsChecked.length > 0) {
      dispatch(
        deleteMultipleMediaFromIds({
          componentId: MEDIA_CHECK_BEFORE_DELETE_ID,
          mediaIds: mediasIdToDeleteThatIsChecked,
        }),
      ).then((res: any) => {
        res.payload.forEach((elt) => {
          handleMediaPlaceholderRefreshAfterDeletion({ payload: elt, allMedias, dispatch });
        });
      });
    }
    dispatch(closeConfirmationModal(id));
  };

  const deleteSingleMedia = () => {
    if (zeroEntitiesToDisplayWarning && idMedia !== -1) {
      dispatch(deleteMedia({ componentId: MEDIA_COMPONENT_ID, mediaId: idMedia })).then(
        (res: any) => {
          handleMediaPlaceholderRefreshAfterDeletion({ payload: res.payload, allMedias, dispatch });
        },
      );
    }
    dispatch(closeConfirmationModal(id));
  };

  const toDisplayOrNotToDisplayWarning = async (medias: any) => {
    setIsComputingFinished(false);

    const entities = await dispatch(
      checkIfMediasAreUsed({
        mediaIDs: [medias],
        componentId: MEDIA_MULTIPLE_MEDIA_DELETE_COMPONENT_ID,
        isToDeleteOne: false,
      }),
    ).then((res) => {
      const st: Array<any> = [];
      (res.payload as any).data.forEach((e: any) => {
        return e[0].map((item: any) => {
          if (!st.some((proj: any) => proj.projectName === item.projectName)) {
            st.push({ ...item, mediaId: e[1].mediaId });
          }
        });
      });
      return st;
    });

    const allProjectItems = entities.map((item: any) => {
      return {
        ...item,
        projectMedias: [item.mediaId],
      };
    });

    const mergedProjectItems = allProjectItems.reduce((acc: any, project: any) => {
      const existingProject = acc.find((p: any) => p.projectName === project.projectName);
      if (existingProject) {
        existingProject.projectMedias.push(...project.projectMedias);
      } else {
        acc.push(project);
      }
      return acc;
    }, []);

    const projectEntities = mergedProjectItems.filter(
      (entity: any) => entity.apiId !== "user" && entity.apiId !== "organization",
    );
    const userEntities = mergedProjectItems.filter((entity: any) => entity.apiId === "user");
    const organizationEntities = mergedProjectItems.filter(
      (entity: any) => entity.apiId === "organization",
    );

    if (entities.length > 0) {
      setEntitiesToDisplayWarning({
        projects: projectEntities,
        users: userEntities,
        organizations: organizationEntities,
      });
    }
    setIsComputingFinished(true);
  };

  useEffect(() => {
    const prevMediasIdToDeleteThatIsCheckedJSON = JSON.stringify(
      prevMediasIdToDeleteThatIsCheckedRef.current,
    );
    const mediasIdToDeleteThatIsCheckedJSON = JSON.stringify(mediasIdToDeleteThatIsChecked);
    const prevMediasToDeleteJSON = JSON.stringify(prevMediasToDeleteRef.current);
    const mediasToDeleteJSON = JSON.stringify(mediasToDelete);

    if (
      prevIdMediaRef.current !== idMedia ||
      prevMediasIdToDeleteThatIsCheckedJSON !== mediasIdToDeleteThatIsCheckedJSON ||
      prevMediasToDeleteJSON !== mediasToDeleteJSON
    ) {
      toDisplayOrNotToDisplayWarning(mediasToDelete);
    }

    prevIdMediaRef.current = idMedia;
    prevMediasIdToDeleteThatIsCheckedRef.current = mediasIdToDeleteThatIsChecked;
    prevMediasToDeleteRef.current = mediasToDelete;
  }, [mediasToDelete, mediasIdToDeleteThatIsChecked, idMedia]);

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, [id]);

  const modalMessage = () => {
    if (isComputingFinished && mediasToDelete.length === 1 && zeroEntitiesToDisplayWarning) {
      return t("pages.gallery.deleteMedia.confirmMessage");
    } else if (isComputingFinished && mediasToDelete.length > 1 && zeroEntitiesToDisplayWarning) {
      return t("pages.gallery.deleteMedia.confirmMessagePlural");
    } else if (
      isComputingFinished &&
      mediasToDelete.length <= 1 &&
      (oneEntityToDisplayWarning || moreThanOneEntityToDisplayWarning)
    ) {
      return t("pages.gallery.deleteMedia.warningMessage");
    } else if (
      isComputingFinished &&
      mediasToDelete.length > 1 &&
      moreThanOneEntityToDisplayWarning
    ) {
      return t("pages.gallery.deleteMedia.warningMessagePlural");
    } else {
      return t("general.loading");
    }
  };

  return (
    <ModalStandard id={id} fullScreen={false} modalType={"confirmation"}>
      <div className="modalContent personalize-scroll-visible">
        {componentId && (
          <div className="z-20">
            <IsLoading componentId={componentId} showSuccess={false} />
          </div>
        )}
        <div>
          <p className={`${isMediaList ? "font-bold text-2xl mx-5" : "font-bold text-2xl"} my-4`}>
            {modalMessage()}
          </p>
          <p className="text-gray-600 mb-4">{text}</p>
          {text2 && <p className="text-gray-600 mb-4">{text2}</p>}
          <div>
            <IsLoading componentId={MEDIA_CHECK_BEFORE_DELETE_ID} showSuccess={false}>
              {isComputingFinished ? (
                <MediaModules entitiesToDisplayWarning={entitiesToDisplayWarning} />
              ) : (
                <Spinner />
              )}
            </IsLoading>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-1">
        <button
          type="button"
          className="btn-alternative-outline mr-4"
          onClick={() => {
            dispatch(closeConfirmationModal(id));
            // resetStates();
          }}
        >
          {String(t("general.cancel"))}
        </button>
        <button
          className="btn-primary-fill"
          type="button"
          disabled={!isComputingFinished}
          onClick={() => {
            if (mediasIdToDeleteThatIsChecked.length === 0) {
              deleteSingleMedia();
            } else {
              deleteMultipleMedias();
            }
          }}
        >
          {!isComputingFinished
            ? t("general.loading")
            : moreThanZeroEntitiesToDisplayWarning
            ? t("general.ok")
            : t("general.yes")}
        </button>
      </div>
    </ModalStandard>
  );
};

export default ModalConfirmationToDeleteMedia;
