import { useAppDispatch } from "../../state/hooks";
import { closeConfirmationModal } from "../../state/slice/modal/modalSlice";
import ModalStandard from "./ModalStandard";
import { IsLoading } from "../AppState/IsLoading";
import { t } from "i18next";

type ModalStandardProps = {
  title: string;
  text?: string;
  text2?: string;
  submitButtonText?: string;
  onClickSubmit: any;
  onClickCancel?: any;
  id: string;
  componentId?: string;
  children?: any;
  isMediaList?: boolean;
};

const ModalConfirmation = ({
  id,
  title,
  text = "",
  text2 = "",
  componentId,
  onClickSubmit,
  onClickCancel = () => {
    [];
  },
  children,
  submitButtonText,
  isMediaList = false,
}: ModalStandardProps) => {
  const dispatch = useAppDispatch();

  return (
    <ModalStandard id={id} fullScreen={false} modalType={"confirmation"}>
      <div className="modalContent personalize-scroll-visible">
        {componentId && (
          <div className="z-20">
            <IsLoading componentId={componentId} showSuccess={false} />
          </div>
        )}
        <div>
          <p className={`${isMediaList ? "font-bold text-2xl mx-5" : "font-medium text-lg"} my-4`}>
            {title}
          </p>
          <p className="text-gray-600 mb-4">{text}</p>
          {text2 && <p className="text-gray-600 mb-4">{text2}</p>}
          <div>{children}</div>
        </div>
      </div>

      <div className="flex justify-end mt-1">
        <button
          type="button"
          className="btn-alternative-outline mr-4"
          onClick={() => {
            if (onClickCancel) {
              onClickCancel();
            }
            dispatch(closeConfirmationModal(id));
            onClickCancel();
          }}
        >
          {String(t("general.cancel"))}
        </button>
        <button className="btn-primary-fill" type="button" onClick={onClickSubmit}>
          {submitButtonText ? submitButtonText : String(t("general.yes"))}
        </button>
      </div>
    </ModalStandard>
  );
};

export default ModalConfirmation;
