interface ISidebarSectionProps {
  title: string;
  children: any;
}

export const SidebarSection = ({ title, children }: ISidebarSectionProps) => {
  return (
    <div className="divide-y my-3 1536px:my-4 1920pxx:my-5 xxl:my-6">
      <span className="text-gray-400 text-sm uppercase">{title}</span>
      <div className="py-1 xxl:mt-1"></div>
      {children}
    </div>
  );
};
