import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const DoubleNavigationItem = ({
  to,
  label,
  action,
}: {
  to: string;
  label: string;
  action: string;
}) => {
  return (
    <>
      <Link to={to} className="ml-1 text-sm font-medium text-gray-400 md:ml-2">
        {label}
      </Link>
      <ChevronRightIcon className="w-5 h-5 ml-2 text-gray-400" />
      <span className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
        {action}
      </span>
    </>
  );
};

export default DoubleNavigationItem;
