export const languageConfig = (preferredLanguage?: string): "en" | "fr" => {
  if (preferredLanguage) {
    switch (preferredLanguage) {
      default:
      case "English":
        return "en";
      case "French":
        return "fr";
    }
  } else {
    const osLang = navigator.language.split("-")[0]?.toLowerCase();
    if (osLang !== "en" && osLang !== "fr") {
      return "en";
    } else {
      return osLang;
    }
  }
};
