import { HTMLAttributes } from "react";
import { UseFormSetValue } from "react-hook-form";
import { INewProjectGroup } from "../../projectGroups/state/projectGroupsSlice";
import "./AccessTypeTable.scss";

const AccessTypeCustomInput = ({
  id,
  buttonText,
  setValue,
  selected = false,
}: {
  id: string;
  buttonText: string;
  setValue: UseFormSetValue<INewProjectGroup>;
  selected: boolean;
} & HTMLAttributes<HTMLDivElement>) => {
  const handleClick = () => {
    if (id === "simplified-login") {
      setValue("accessType", "simplified");
    }
    if (id === "password-login") {
      setValue("accessType", "classic");
    }
    if (id === "no-login") {
      setValue("accessType", "none");
    }
  };

  return (
    <button
      className={`btn-secondary-fill focus:ring focus:ring-blue-300 w-3/4 no-hover-opacity ${
        selected ? "opacity-100" : "opacity-50"
      }`}
      onClick={handleClick}
      type="button"
    >
      {buttonText}
    </button>
  );
};

export default AccessTypeCustomInput;
