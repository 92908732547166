import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../state/store";
import { jwtSelector } from "../state/selectors/authSelector";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../util/auth";

export const PrivatePage = ({ children }: { children: React.ReactNode }) => {
  const [isExpired, setIsTokenExpired] = useState(false);
  const { jwt } = useSelector((state: RootState) => {
    return {
      jwt: jwtSelector(state),
    };
  });

  useEffect(() => {
    if (jwt && isTokenExpired(jwt)) {
      setIsTokenExpired(true);
    }
  }, [jwt]);

  return jwt === "" || isExpired === true ? <Navigate to="/login" /> : <>{children}</>;
};
