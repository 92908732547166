import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import { IUser } from "../../../model/model";
import {
  ICreatorDetails,
  IDeleteUsersDraft,
  IEnduserDetails,
  IEnhancedUser,
  IGetOrgMembersWithPermissionsResponse,
  ITeamState,
  IUserDraftStrings,
  InviteExternalFormData,
  InviteExternalFormDataWithoutEmail,
} from "./teamInterfaces";
import parseSimplifiedUserIdentifier from "src/common/util/parseSimplifiedUserIdentifier";

const initialState: ITeamState = {
  members: [],
  creators: [],
  endusers: [],
  deleteUsersDraft: {
    type: "delete_creator",
    usersToBeDeleted: [{ id: 0, email: "" }],
  },
  createOrEditUserDraft: {
    id: 0,
    username: "",
    email: "",
    provider: "",
    confirmed: true,
    blocked: false,
    role: {
      id: 5,
      name: "Creator",
      description: "Creators using the Wixar app",
      type: "creator",
    },
    created_at: "",
    updated_at: "",
    organization: {
      id: 0,
      name: "",
      createdAt: "",
      updatedAt: "",
      legal_name: "",
      address_locality: "",
      address_region: "",
      address_country: "",
      phone: "",
      postal_code: "",
      email: "",
      siret: "tata",
      street_address: "",
      source_logo: {
        id: 0,
        s3_url: "",
        createdAt: "",
        updatedAt: "",
        organization: 0,
        type: "",
        name: "",
        size_in_bytes: 0,
        filename: "",
      },
      iban: "",
      admin: {
        avatar3d_url: "",
        blocked: false,
        confirmationToken: null,
        confirmed: true,
        createdAt: "",
        email: "",
        firstname: "",
        has_accepted_cgv: true,
        id: 0,
        lastname: "",
        password: "",
        provider: null,
        resetPasswordToken: null,
        updatedAt: "",
        username: "",
      },
      representative_name: "",
      proabono_uuid: "",
    },
    source_avatar: {
      id: 0,
      s3_url: "",
      createdAt: "",
      updatedAt: "",
      organization: 0,
      type: "",
      name: "",
      size_in_bytes: 0,
      filename: "",
    },
    firstname: "",
    lastname: "",
    avatar3d_url: "",
    preferred_language: {
      id: 1,
      name: "French",
    },
    has_accepted_cgv: true,
    user_opened_tutorial: {
      id: 1,
      web_getStarted: true,
      web_howToAddScene: true,
      web_howToLinkScene: true,
      web_howToPublish: true,
      web_howToChooseAuthMode: true,
      web_aboutCourses: true,
      web_aboutModules: true,
      web_aboutWebgl: true,
      web_aboutParticipants: true,
      web_aboutCollaborators: true,
      web_aboutPublication: false,
      web_aboutStatistiques: true,
      web_aboutSoundtrack: true,
      web_aboutWixarClient: false,
      web_manageSubscription: true,
      web_modulePublished: true,
    },
    role_string: "creator",
    isUserAllowedByProjectOrProjectGroupArray: [],
  },
};

const updateUser = ({ userArray, payloadData }: any) => {
  return userArray.map((enhancedUser: any) => {
    if (Number(payloadData.id) === Number(enhancedUser.id)) {
      return { ...payloadData, id: Number(payloadData.id) };
    } else {
      return enhancedUser;
    }
  });
};

export const getTeam = createAsyncThunk(
  "team/all",

  async ({ orgId }: { orgId: number } & IAppLoaderAction, { rejectWithValue }) => {
    try {
      const response = (await axios.get)<Array<IUser>>(
        `${apiUrls.register.users}?populate[organization][populate]=admin&populate[organization][populate]=source_logo&filters[organization][id][$eq]=${orgId}&populate=role`,
      );
      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const getTeamAsAdmin = createAsyncThunk(
  "team/allForAdmin",
  // eslint-disable-next-line no-empty-pattern
  async ({}: unknown & IAppLoaderAction, { rejectWithValue }) => {
    try {
      const response = (await axios.get)<IGetOrgMembersWithPermissionsResponse>(
        `${apiUrls.teamForAdmin}`,
      );
      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteExternalCreatorToOrg = createAsyncThunk(
  "team/inviteExternalCreatorToOrg",
  async (
    {
      inviterId,
      organizationId,
      newUserFirstName,
      newUserLastName,
      newUserEmail,
    }: {
      inviterId: number;
      organizationId: number;
    } & InviteExternalFormData &
      IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.projects.inviteExternalCreatorToOrg}`, {
        inviterId,
        organizationId,
        newUserFirstName,
        newUserLastName,
        newUserEmail,
      });
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteExternalEnduserWithoutEmail = createAsyncThunk(
  "team/inviteExternalEnduserWithoutEmail",
  async (
    {
      inviterId,
      projectId = -1,
      projectGroupId = -1,
      newUserFirstName,
      newUserLastName,
    }: {
      inviterId: number;
      projectId?: number;
      projectGroupId?: number;
    } & InviteExternalFormDataWithoutEmail &
      IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.inviteWithoutEmail}`, {
        requesterId: inviterId,
        projectId,
        firstname: newUserFirstName,
        lastname: newUserLastName,
        projectGroupId,
      });
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteExternalEnduser = createAsyncThunk(
  "team/inviteExternalEnduser",
  async (
    {
      inviterId,
      projectId = -1,
      projectGroupId = -1,
      newUserFirstName,
      newUserLastName,
      newUserEmail,
    }: {
      inviterId: number;
      projectId?: number;
      projectGroupId?: number;
    } & InviteExternalFormData &
      IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.projects.inviteEnduser}`, {
        userId: inviterId,
        projectId,
        projectGroupId,
        firstname: newUserFirstName,
        lastname: newUserLastName,
        email: newUserEmail,
      }).catch((e) => {
        throw e;
      });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteExternalEnduserWithoutProject = createAsyncThunk(
  "team/inviteExternalEnduserWithoutProject",
  async (
    {
      firstname,
      lastname,
      email,
    }: {
      firstname: string;
      lastname: string;
      email: string;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.projects.inviteEnduserWithoutProject}`, {
        firstname,
        lastname,
        email,
      });
      return response; // NEW USERS SHOULD BE RETURNED THEN ONFULFILLED INSERTED INTO THE STORE §§
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export interface IEnduserToBeInserted {
  firstname: string;
  lastname: string;
  email: string;
}

export const bulkInviteEndusersWithoutProject = createAsyncThunk(
  "team/bulkInviteEndusersWithoutProject",
  async (
    {
      endusers,
    }: {
      endusers: IEnduserToBeInserted[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios
        .post(`${apiUrls.projects.bulkInviteEndusersWithoutProject}`, {
          endusers,
        })
        .catch((e) => {
          throw e;
        });
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteCreator = createAsyncThunk(
  "team/deleteCreator",
  async (
    {
      id,
    }: {
      id: number;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.delete)(`${apiUrls.register.deleteCreator}?id=${id}`);
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteCreators = createAsyncThunk(
  "team/deleteCreators",
  async (
    {
      creators,
    }: {
      creators: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.register.deleteCreators}`, { creators });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteEnduser = createAsyncThunk(
  "team/deleteEnduser",
  async (
    {
      id,
    }: {
      id: number;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.delete)(`${apiUrls.register.deleteEnduser}?id=${id}`);
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteEndusers = createAsyncThunk(
  "team/deleteEndusers",
  async (
    {
      endusers,
    }: {
      endusers: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.register.deleteEndusers}`, { endusers });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  "team/fetchUserDetails",
  async (
    {
      id,
      firstname,
      lastname,
      email,
    }: {
      id: number;
      firstname?: string;
      lastname?: string;
      email?: string;
    } & IAppLoaderAction,
    { rejectWithValue, dispatch },
  ) => {
    if (firstname && lastname && email) {
      dispatch(setCreateOrEditUserDraftStrings({ firstname, lastname, email }));
    }
    try {
      const response = (await axios.get)(`${apiUrls.admin.userDetails}?id=${id}`);
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteCreatorWithPerms = createAsyncThunk(
  "team/inviteCreatorWithPerms",
  async (
    {
      firstname,
      lastname,
      email,
      pgIds,
    }: {
      firstname?: string;
      lastname?: string;
      email: string;
      pgIds: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.admin.inviteCreatorWithPerms}`, {
        firstname,
        lastname,
        email,
        pgIds,
      });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const inviteEnduserWithPerms = createAsyncThunk(
  "team/inviteEnduserWithPerms",
  async (
    {
      firstname,
      lastname,
      email,
      projectIds,
    }: {
      firstname?: string;
      lastname?: string;
      email: string;
      projectIds: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.admin.inviteEnduserWithPerms}`, {
        firstname,
        lastname,
        email,
        projectIds,
      });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const editCreatorPerms = createAsyncThunk(
  "team/editCreatorPerms",
  async (
    {
      userId,
      allowedPgIds,
    }: {
      userId: number;
      allowedPgIds: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.put)(`${apiUrls.admin.editCreatorPerms}`, {
        userId,
        allowedPgIds,
      });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const editEnduserPerms = createAsyncThunk(
  "team/editEnduserPerms",
  async (
    {
      userId,
      allowedProjectIds,
    }: {
      userId: number;
      allowedProjectIds: number[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.put)(`${apiUrls.admin.editEnduserPerms}`, {
        userId,
        allowedProjectIds,
      });
      return await response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    clearTeam: (state: ITeamState) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    setDeleteUsersDraft: (state: ITeamState, action: PayloadAction<IDeleteUsersDraft>) => {
      state.deleteUsersDraft = action.payload;
    },
    setCreateOrEditUserDraft: (
      state: ITeamState,
      action: PayloadAction<ICreatorDetails | IEnduserDetails>,
    ) => {
      state.createOrEditUserDraft = action.payload;
    },
    setCreateOrEditUserDraftStrings: (
      state: ITeamState,
      action: PayloadAction<IUserDraftStrings>,
    ) => {
      state.createOrEditUserDraft.firstname = action.payload.firstname;
      state.createOrEditUserDraft.lastname = action.payload.lastname;
      state.createOrEditUserDraft.email = action.payload.email;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeam.fulfilled, (state, { payload }) => {
      const members = payload.data as Array<IUser>;
      state.members = members;
    });
    builder.addCase(getTeamAsAdmin.fulfilled, (state, { payload }) => {
      state.creators = payload.data.creators;
      state.endusers = payload.data.endusers.map((enduser) => {
        return { ...enduser, email: parseSimplifiedUserIdentifier(enduser.email) };
      });
    });
    builder.addCase(inviteExternalCreatorToOrg.fulfilled, (state, { payload }) => {
      const newUser = payload.data as IUser;
      if (newUser.id !== undefined) {
        state.members = [...state.members, newUser];
      } else {
        const newUserFromCsv = payload.data.user as IUser;
        state.members = [...state.members, newUserFromCsv];
      }
    });
    builder.addCase(deleteCreator.fulfilled, (state, { payload }) => {
      state.members = [...state.members].filter((u: IUser) => {
        return Number(u.id) !== Number(payload.data.id);
      });
      state.creators = [...state.creators].filter((u: IEnhancedUser) => {
        return Number(u.id) !== Number(payload.data.id);
      });
    });
    builder.addCase(deleteEnduser.fulfilled, (state, { payload }) => {
      state.members = [...state.members].filter((u: IUser) => {
        return Number(u.id) !== Number(payload.data.id);
      });
      state.endusers = [...state.endusers].filter((u: IEnhancedUser) => {
        return Number(u.id) !== Number(payload.data.id);
      });
    });
    builder.addCase(deleteEndusers.fulfilled, (state, { payload }) => {
      state.members = [...state.members].filter((u: IUser) => {
        return !payload.data.deleted.includes(Number(u.id));
      });
      state.endusers = [...state.endusers].filter((u: IEnhancedUser) => {
        return !payload.data.deleted.includes(Number(u.id));
      });
    });
    builder.addCase(deleteCreators.fulfilled, (state, { payload }) => {
      state.members = [...state.members].filter((u: IUser) => {
        return !payload.data.deleted.includes(Number(u.id));
      });
      state.creators = [...state.creators].filter((u: IEnhancedUser) => {
        return !payload.data.deleted.includes(Number(u.id));
      });
    });
    builder.addCase(inviteExternalEnduserWithoutProject.fulfilled, (state, { payload }) => {
      state.members = [...state.members, { ...payload.data.user }];
      state.endusers = [...state.endusers, { ...payload.data.user }];
    });
    builder.addCase(bulkInviteEndusersWithoutProject.fulfilled, (state, { payload }) => {
      state.members = [...state.members, ...payload.data];
      state.endusers = [...state.endusers, ...payload.data];
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      state.createOrEditUserDraft = payload.data;
    });
    builder.addCase(inviteCreatorWithPerms.fulfilled, (state, { payload }) => {
      if (!Object.prototype.hasOwnProperty.call(payload.data, "error")) {
        state.members = [...state.members, payload.data];
        state.creators = [...state.creators, payload.data];
      }
    });
    builder.addCase(inviteEnduserWithPerms.fulfilled, (state, { payload }) => {
      if (!Object.prototype.hasOwnProperty.call(payload.data, "error")) {
        // Check if user already exists in members, replace if exists, append if not
        const isMemberExist = state.members.some((member) => member.id === payload.data.id);

        state.members = isMemberExist
          ? state.members.map((member) => (member.id === payload.data.id ? payload.data : member))
          : [...state.members, payload.data];

        // Do the same for endusers
        const isEnduserExist = state.endusers.some((enduser) => enduser.id === payload.data.id);
        state.endusers = isEnduserExist
          ? state.endusers.map((enduser) =>
              enduser.id === payload.data.id ? payload.data : enduser,
            )
          : [...state.endusers, payload.data];
      }
    });
    builder.addCase(inviteExternalEnduserWithoutEmail.fulfilled, (state, { payload }) => {
      if (!Object.prototype.hasOwnProperty.call(payload.data, "error")) {
        const modernUserStructRebuiltFromLegacyApiResponse = {
          ...payload.data.user,
          email: parseSimplifiedUserIdentifier(payload.data.user.email),
          permissions: [payload.data.createdPermission],
        };

        // Check if user already exists in members, replace if exists, append if not
        const isMemberExist = state.members.some(
          (member) => member.id === modernUserStructRebuiltFromLegacyApiResponse.id,
        );
        state.members = isMemberExist
          ? state.members.map((member) =>
              member.id === modernUserStructRebuiltFromLegacyApiResponse.id
                ? modernUserStructRebuiltFromLegacyApiResponse
                : member,
            )
          : [...state.members, modernUserStructRebuiltFromLegacyApiResponse];

        // Check if user already exists in enduser, replace if exists, append if not
        const isEnduserExist = state.endusers.some(
          (enduser) => enduser.id === modernUserStructRebuiltFromLegacyApiResponse.id,
        );
        state.endusers = isEnduserExist
          ? state.endusers.map((enduser) =>
              enduser.id === modernUserStructRebuiltFromLegacyApiResponse.id
                ? modernUserStructRebuiltFromLegacyApiResponse
                : enduser,
            )
          : [...state.endusers, modernUserStructRebuiltFromLegacyApiResponse];
      }
    });
    builder.addCase(editCreatorPerms.fulfilled, (state, { payload }) => {
      state.members = updateUser({
        userArray: state.members,
        payloadData: payload.data,
      });
      state.creators = updateUser({
        userArray: state.creators,
        payloadData: payload.data,
      });
    });
    builder.addCase(editEnduserPerms.fulfilled, (state, { payload }) => {
      state.members = updateUser({
        userArray: state.members,
        payloadData: payload.data,
      });
      state.endusers = updateUser({
        userArray: state.endusers,
        payloadData: payload.data,
      });
    });
  },
});

export const allTeamSelector = (state: RootState) => state.team.members;

export const getCreators = (state: RootState) => state.team.creators;
export const getEndusers = (state: RootState) => state.team.endusers;

export const getDeleteUsersDraft = (state: RootState) => state.team.deleteUsersDraft;
export const getCreateOrEditUserDraft = (state: RootState) => state.team.createOrEditUserDraft;

export const teamForProjectSelector = (state: RootState) => {
  const permissions = state.graphLegacy.project && state.graphLegacy.project.permissions;
  const allTeam = allTeamSelector(state);
  return allTeam.filter((user) =>
    permissions?.map((perm) => Number(perm.user)).includes(Number(user.id)),
  );
};

export const {
  clearTeam,
  setDeleteUsersDraft,
  setCreateOrEditUserDraft,
  setCreateOrEditUserDraftStrings,
} = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
