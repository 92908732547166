import Tour from "reactour";
import { useState } from "react";
import { IUserOpenedTutorial } from "../../model/model";
import { useSelector } from "react-redux";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/outline";

import {
  getUserOpenedTutorial,
  setHasOpenedTutorial,
} from "../../features/profile/state/profileSlice";
import {
  closeOnboardingModal,
  getCurrentOnboardingModalState,
} from "../state/slice/modal/modalSlice";
import { useAppDispatch } from "../state/hooks";
import { useTranslation } from "react-i18next";

interface OnboardingProps {
  steps: any;
  relevantArticle: string;
  id: string;
}

const OnboardingComponent = ({ steps, relevantArticle, id }: OnboardingProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const userOpenedTutorials = useSelector(getUserOpenedTutorial) as IUserOpenedTutorial;
  const onboardingState = useSelector(getCurrentOnboardingModalState);
  const dbKeyname = `web_${relevantArticle}`;

  const shouldPopHelpIntoUserFace =
    userOpenedTutorials !== undefined
      ? !userOpenedTutorials[dbKeyname as keyof IUserOpenedTutorial]
      : false;

  const handleTourClose = () => {
    setCurrentStep(0);
    dispatch(closeOnboardingModal(id));
    if (shouldPopHelpIntoUserFace) {
      dispatch(
        setHasOpenedTutorial({
          userOpenedTutorialId: Number(userOpenedTutorials.id),
          newValues: {
            ...userOpenedTutorials,
            [dbKeyname]: true,
          },
        }),
      );
    }
  };

  return (
    <Tour
      getCurrentStep={(step) => {
        setCurrentStep(step);
      }}
      rounded={5}
      goToStep={currentStep}
      steps={steps}
      isOpen={onboardingState.id === id ? onboardingState.show : false}
      onRequestClose={handleTourClose}
      maskSpace={5}
      accentColor="#00c889"
      prevButton={
        <button className="btn btn-primary">
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
      }
      nextButton={
        <button className="btn btn-primary">
          <ArrowRightIcon className="w-4 h-4" />
        </button>
      }
      lastStepNextButton={
        <button className="btn-primary-fill w-full text-base" onClick={() => handleTourClose()}>
          {t("general.finish")}
        </button>
      }
    />
  );
};

export default OnboardingComponent;
