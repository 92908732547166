import { CheckIcon, MinusIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { INewProjectGroup } from "../../projectGroups/state/projectGroupsSlice";
import AccessTypeCustomInput from "./AccessTypeCustomInput";
import "./AccessTypeTable.scss";

const AccessTypeTable = ({
  setValue,
  watch,
}: {
  setValue: UseFormSetValue<INewProjectGroup>;
  watch: UseFormWatch<any>;
}) => {
  const { t } = useTranslation();
  const selected = watch("accessType");

  const isAnonymousSelected = selected === "none";

  const isSimpSelected = selected === "simplified";

  const isStandardSelected = selected === "classic";

  useEffect(() => {
    setValue("accessType", "none");
  }, []);

  return (
    <table className="relative table-fixed w-full max-w-7xl px-6 xl:px-0">
      <thead>
        <tr>
          <th className="text-left"></th>
          <th className={`text-center py-2 ${isAnonymousSelected ? "active-top" : ""}`}>
            {t("pages.accessTypeTable.noLogin")}
          </th>
          <th className={`text-center py-2 ${isSimpSelected ? "active-top" : ""}`}>
            {t("pages.accessTypeTable.simplifiedLogin")}{" "}
          </th>
          <th className={`text-center py-2 ${isStandardSelected ? "active-top" : ""}`}>
            {t("pages.accessTypeTable.passwordLogin")}{" "}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th className="text-left bg-white"> {t("pages.accessTypeTable.features")}</th>
          <th
            className={`text-left bg-white py-2 ${isAnonymousSelected ? "active-middle" : ""}`}
          ></th>
          <th className="text-left bg-white"></th>
          <th className="text-left bg-white"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-1 border-b"> {t("pages.accessTypeTable.canUseCollabMode")}</td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1 border-b">
            {t("pages.accessTypeTable.collabAvatarPersonalization")}
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1">{t("pages.accessTypeTable.sendMailNotifsToLearners")}</td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className="text-left bg-white">{t("pages.accessTypeTable.reporting")}</th>
          <th className="text-left bg-white"></th>
          <th className="text-left bg-white"></th>
          <th className="text-left bg-white"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-1 border-b">
            {t("pages.accessTypeTable.wixarLmsInterfaceForLearners")}
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1 border-b"> {t("pages.accessTypeTable.sendResultsToLearners")}</td>
          <td className="py-1 border-b">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1 border-b"> {t("pages.accessTypeTable.identifiedSessions")}</td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1"> {t("pages.accessTypeTable.globalStatsForScorableInteractions")}</td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className="text-left bg-white">{t("pages.accessTypeTable.access")}</th>
          <th className="text-left bg-white"></th>
          <th className="text-left bg-white"></th>
          <th className="text-left bg-white"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-1 border-b">{t("pages.accessTypeTable.playableOffline")}</td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1">{t("pages.accessTypeTable.securizedAccessToTheExperience")}</td>

          <td className="py-1 border-b ">
            <div className="flex justify-center">
              <MinusIcon className=" h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 text-center border-b w-28">
            <div className="flex justify-center">
              <MinusIcon className="h-5 w-5 stroke-gray-300" />
            </div>
          </td>
          <td className="py-1 text-center  border-b w-28">
            <div className="flex justify-center">
              <CheckIcon className="h-5 w-5 stroke-green-500" />
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-1"></td>

          <td className="py-3 ">
            <div className="flex justify-center">
              <AccessTypeCustomInput
                id="no-login"
                buttonText={t("general.select")}
                setValue={setValue}
                selected={isAnonymousSelected}
              />
            </div>
          </td>
          <td className="py-3">
            <div className="flex justify-center">
              <AccessTypeCustomInput
                id="simplified-login"
                buttonText={t("general.select")}
                setValue={setValue}
                selected={isSimpSelected}
              />
            </div>
          </td>
          <td className="py-3">
            <div className="flex justify-center">
              <AccessTypeCustomInput
                id="password-login"
                buttonText={t("general.select")}
                setValue={setValue}
                selected={isStandardSelected}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AccessTypeTable;
