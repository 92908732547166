import { useSelector } from "react-redux";
import Icon from "../../../common/components/Icon/Icon";
import ThreeDotsDropdownMenu, {
  menuItems,
} from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import { languageConfig } from "../../../common/util/langConfig";
import { prettifyFileSizeInBytes } from "../../../common/util/prettifyFileSizeInBytes";
import { ILanguage } from "../../../model/model";
import { IMedia } from "../../../model/unityObject.d";
import { getCurrentUser } from "../../profile/state/profileSlice";
import { getCurrentEditedMediaField } from "../state/gallerySlice";
import { MediaViewer } from "./MediaViewer";
import { useTranslation } from "react-i18next";
import { prettifyMediaName } from "src/common/util/prettifyMediaName";
import { prettifyMediaType } from "src/common/util/prettifyMediaType";
import React from "react";
import { ICustomRemoveButton } from "src/common/components/CustomXIcon/CustomXIcon";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";

const ImageCard = ({
  media,
  currentMedia,
  handleButtonClick,
  //openMediaDetail,
  handleCardClick,
  customMenuItems,
  showThreeDotsMenu = true,
  showEditButton = false,
  id = "",
  isMediaPickerMode,
  disabled,
  CustomRemoveButton,
  className = undefined,
}: {
  media: IMedia;
  //openMediaDetail: (mediaId: number) => void;
  currentMedia: number | undefined;
  isMediaPickerMode: boolean;
  handleCardClick?: () => void;
  handleButtonClick: (mediaId: number, item: any, index: number) => void;
  customMenuItems?: menuItems;
  showThreeDotsMenu?: boolean;
  showEditButton?: boolean;
  id?: string;
  disabled?: boolean;
  CustomRemoveButton?: ICustomRemoveButton;
  className?: string;
}) => {
  const { t } = useTranslation();
  let menuItem: menuItems = [{ menuItemText: t("general.edit"), icon: "PencilAltIcon" }];
  if (customMenuItems) {
    menuItem = menuItem.concat(customMenuItems);
  }

  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const currentlyEditedMediaField = useSelector(getCurrentEditedMediaField);

  const onClick = () => {
    if (handleCardClick && !disabled) {
      handleCardClick();
    }
  };
  const currentPrettifyFileSizeInBytes = prettifyFileSizeInBytes(
    Number(media.size_in_bytes),
    currentLocale,
  );

  return (
    <div
      className={`${className} rounded-xl overflow-visible flex relative bg-white overflow-hidden shadow-md image-card ${
        disabled
          ? "hover:cursor-not-allowed"
          : "hover:scale-105 hover:cursor-pointer hover:shadow-2xl"
      }   ${
        isMediaPickerMode
          ? "focus:ring-green-500 hover:ring-green-500 hover:ring-4 focus:ring-4"
          : ""
      } 
      ${
        isMediaPickerMode &&
        (Number(currentlyEditedMediaField.currentValue) === Number(media?.id) ||
          Number(media.id) === Number(currentMedia))
          ? "ring-purple-500 ring-4 scale-105"
          : ""
      }`}
      id={id}
    >
      <div className="w-full h-full" onClick={onClick}>
        <div className="relative peer">
          {CustomRemoveButton &&
            React.createElement(CustomRemoveButton.component, CustomRemoveButton.customProps)}
          <MediaViewer media={media} />
        </div>

        <div className={`block mt-1 mb-3 mx-4 ${disabled ? "opacity-50" : ""}`}>
          <div className="flex justify-between w-full">
            <Tooltip classNameChildren="left-0" forceSingleLine={false} message={media.name}>
              <h3 className="text-black text-sm text-left">
                {prettifyMediaName({
                  name: media.name,
                })}
              </h3>
            </Tooltip>
            <div
              className={`rounded-md h-6 w-6 top-2 right-2 ${
                showThreeDotsMenu || showEditButton ? "" : "hidden"
              }`}
            >
              <div className="flex justify-end" onClick={(e) => e.stopPropagation()}>
                {showEditButton && (
                  <button
                    className={`${showEditButton ? "" : "hidden"} w-4/5`}
                    onClick={() => handleButtonClick(media.id, menuItem[0], 0)}
                  >
                    <Icon icon={"PencilAltIcon"} className={"stroke-green-500"} />
                  </button>
                )}
                {showThreeDotsMenu && !disabled && (
                  <ThreeDotsDropdownMenu
                    menuItems={menuItem}
                    handleClick={(item: any, index: number) =>
                      handleButtonClick(media.id, item, index)
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <span className="text-xs text-gray-500">
              {media.size_in_bytes ? currentPrettifyFileSizeInBytes : ""}
            </span>
            <span className="text-xs text-gray-500">
              {prettifyMediaType({ type: media.type, media_360_tag: media.media_360_tag })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
