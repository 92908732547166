interface Props {
  className?: string;
  width?: number;
  height?: number;
  fill: string;
  onClick?(): void;
}

export default function ParticipantIcon2({
  width,
  height,
  fill,
  className,
  onClick = () => null,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      width={width}
      height={height}
      fill={fill}
      className={className}
      onClick={onClick}
    >
      <path
        d="M400.9,193.5c-1-14.4-5.9-39.8-20-63.5c-15.6-26.1-47.3-58.1-109.9-62c-58.7-3.6-94.2,21.4-113.6,43.1
		c-16.5,18.3-24.4,37.1-27.2,44.9l-16.4-1.1c-6.3-0.4-12.6,1.8-17.3,6.2c-4.6,4.3-7.3,10.5-7.3,16.8v75.4c0,6.4,2.7,12.5,7.3,16.8
		c4.3,4,9.9,6.2,15.8,6.2c0.5,0,1,0,1.5-0.1l15.1-1c1.5,29.8,4.7,63.3,18.3,89.7c7.7,14.9,19.4,19.6,35.7,18.9
		c16.1-0.7,32.1-4.1,47.8-8.2c0.3,16.4-1.3,32.9-3.4,49.1c-1.4,10.3,14.9,10.2,16.2,0c2.3-17.6,4-35.7,3.3-53.5
		c14.7-4.1,29.3-8.6,43.7-13.7c9.8-3.4,5.6-19.1-4.3-15.7c-15,5.3-30.2,9.9-45.4,14.1c-12.9,3.6-26,6.9-39.3,9.2c-9,1.6-20,4-29.1,2
		c-16.9-3.7-20.1-38-22.4-51.4c-2.3-13.8-3.9-27.8-4.7-41.7L222,269c11.6-0.8,20.8-10.2,21.4-21.7h139.9c-1.7,6.7-4.4,14.6-7,20.4
		c-8.1,17.8-23.8,30.8-30.3,49.3c-6.1,17.5-3.2,37.2-1.4,55.2c1.3,13.1,2,28.8,8.9,40.4c5.4,9,19.4,0.8,14-8.2
		c-7-11.7-6.6-29.5-7.7-42.7c-1.6-20.7-2.8-38.9,10.9-56c14-17.6,25.3-37.5,29.3-59.8c6.3-2.8,10.6-9,10.6-16.3v-20.1
		C410.8,202.4,406.8,196.4,400.9,193.5z M227.2,191.6V246c0,3.6-2.8,6.5-6.3,6.8L112.7,260c-1.9,0.1-3.7-0.5-5.1-1.8
		c-1.4-1.3-2.2-3.1-2.2-5v-75.4c0-1.9,0.8-3.7,2.2-5c1.3-1.2,2.9-1.8,4.6-1.8c0.2,0,0.3,0,0.5,0l108.2,7.3c3.5,0.2,6.3,3.2,6.3,6.8
		V191.6z M222,162.1l-74.8-5c8.6-20.4,40.3-78,122.8-72.9c95.6,5.9,111.7,82.3,114.4,107.5H243.4v-6.5
		C243.4,173,234,162.9,222,162.1z M243.4,207.9H393c0.8,0,1.6,0.7,1.6,1.5v20.1c0,0.8-0.7,1.5-1.6,1.5H243.4V207.9z"
      />
    </svg>
  );
}
