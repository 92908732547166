//
// The Content-Type objects
//
// They are built around the following structure:
//
// {
//   modelName: "content-xxx"  ⬅︎ reference name of the content-type
//   objects: [ ⬅︎ always a single object inside this array
//     {
//       name: "...",
//       // here we find a content-type-dependant list of fields
//     }
//   ]
// }

import { IContent } from "../../../../model/unityObject";

// The generic type for all the content type objects
export type ContentType =
  // scenes are qualified by a "content-scene" modelName
  | ContentTypeScene
  // many other modelNames refer to different assts
  | ContentTypeAsset
  // The "other" content-type: we neither display nor process them, but they are referenced here for now on
  | ContentTypeOther;

export type ValidContentType = ContentTypeScene | ContentTypeAsset;

export type ValidContentTypeObject = ValidContentType["object"];

// The list of all the "assets" content types
export type ContentTypeAsset =
  | ContentTypeAudio
  | ContentTypeButton1
  | ContentTypeButton2
  | ContentTypeClean
  | ContentTypeHotspotSceneImage
  | ContentTypeHotspotSceneVideo
  | ContentTypeOnboarding
  | ContentTypePhotoCatch
  | ContentTypeQuizz1
  | ContentTypeQuizz2
  | ContentTypeScoringGeneral
  | ContentTypeScoringScene
  | ContentTypeText
  | ContentTypeThumbnail
  | ContentTypeTuto
  | ContentTypeVideo2d
  | ContentTypeVRLocator
  | ContentTypeZoneDetect
  | ContentTypeSpeechToCheck
  | ContentTypePdfViewer
  | ContentTypeQuizz3
  | ContentYesNoQuestion
  | ContentBlur
  | Content3dViewer
  | ContentTypeZoneSort
  | ContentTypeQuizzSort
  | ContentTypeButtonReload
  | ContentTypeHighlight
  | ContentTypeTuto2
  | ContentTypeRandomQuizz
  | ContentTypeElementCatch
  | ContentTypeDangerCatch
  | ContentFireExtinguisher
  | ContentFire
  | ContentQuizzImageMultiple
  | ContentDust;

export type ContentTypeOther = ContentTypeType;

//
// TEST FUNCTIONS
//

export type ModelName = SceneModelName | AssetModelName;

export type SceneModelName = "content-scene";

// The type holding of all the model names for assets
export type AssetModelName = ContentTypeAsset["modelName"];

// The list of all the model names for assets: used for recognizing them
const assetModelNames = new Set<AssetModelName>([
  "content-audio",
  "content-button-1",
  "content-button-2",
  "content-clean",
  "content-hotspot-scene-image",
  "content-hotspot-scene-video",
  "content-onboarding",
  "content-photo-catch",
  "content-quizz-1",
  "content-quizz-2",
  "content-scoring-general",
  "content-scoring-scene",
  "content-text",
  "content-thumbnail",
  "content-tuto",
  "content-video-2-d",
  "content-vr-locator",
  "content-zone-detect",
  "content-speech-to-check",
  "content-pdf-viewer",
  "content-quizz-3",
  "content-yes-no-question",
  "content-blur",
  "content-3d-viewer",
  "content-quizz-sort",
  "content-zone-sort",
  "content-button-reload",
  "content-tuto2",
  "content-highlight",
  "content-random-quizz",
  "content-element-catch",
  "content-danger-catch",
  "content-fire-extinguisher",
  "content-fire",
  "content-quizz-image-multiple",
  "content-dust",
]);

export const isSceneStartSceneContentType = (contentType?: ContentType | null) => {
  if (!contentType || !isSceneContentType(contentType)) return false;
  return Boolean(contentType.object.is_start_scene);
};

export const isSceneModelName = (modelName: string): modelName is SceneModelName =>
  modelName === "content-scene";

export const isAssetModelName = (modelName: string): modelName is AssetModelName =>
  assetModelNames.has(modelName as AssetModelName);

// Returns true if the provided content type is an scene.
// This method behaves as a type check and can be used inside if statements to enforce the ContentTypeScene type.
export const isSceneContentType = (contentType: ContentType): contentType is ContentTypeScene =>
  isSceneModelName(contentType.modelName);

// Returns true if the provided content type is an asset
// This method behaves as a type check and can be used inside if statements to enforce the ContentTypeAsset type.
export const isAssetContentType = (contentType: ContentType): contentType is ContentTypeAsset =>
  isAssetModelName(contentType.modelName);

// Returns true if the provided content type is an ignored type
// This method behaves as a type check and can be used inside if statements to enforce the ContentTypeOther type.
export const isOtherContentType = (contentType: ContentType): contentType is ContentTypeOther =>
  contentType.modelName === "content-type";

//
// Individual content types: one exported type by modelName
//

type GenericContentType<ModelName, AssociatedObject> = {
  modelName: ModelName;
  object: AssociatedObject;
};

// SCENE(S)
export type ContentTypeScene = GenericContentType<"content-scene", ObjectScene>;
// ASSET(S)
export type ContentTypeAudio = GenericContentType<"content-audio", ObjectAudio>;
export type ContentTypeButton1 = GenericContentType<"content-button-1", ObjectCommons>;
export type ContentTypeButton2 = GenericContentType<"content-button-2", ObjectButton1>;
export type ContentTypeClean = GenericContentType<"content-clean", ObjectClean>;
export type ContentTypeHotspotSceneImage = GenericContentType<
  "content-hotspot-scene-image",
  ObjectHotspot
>;
export type ContentTypeHotspotSceneVideo = GenericContentType<
  "content-hotspot-scene-video",
  ObjectHotspot
>;
export type ContentTypeOnboarding = GenericContentType<"content-onboarding", ObjectOnboarding>;
export type ContentTypeThumbnail = GenericContentType<"content-thumbnail", ObjectThumbnail>;
export type ContentTypeQuizz1 = GenericContentType<"content-quizz-1", ObjectQuizz1>;
export type ContentTypeQuizz2 = GenericContentType<"content-quizz-2", ObjectQuizz2>;
export type ContentTypePhotoCatch = GenericContentType<"content-photo-catch", ObjectPhotoCatch>;
export type ContentTypeScoringGeneral = GenericContentType<
  "content-scoring-general",
  ObjectCommons
>;
export type ContentTypeScoringScene = GenericContentType<
  "content-scoring-scene",
  ObjectScoringScene
>;
export type ContentTypeTuto = GenericContentType<"content-tuto", ObjectTuto>;
export type ContentTypeVideo2d = GenericContentType<"content-video-2-d", ObjectVideo2d>;
export type ContentTypeVRLocator = GenericContentType<"content-vr-locator", ObjectVRLocator>;
export type ContentTypeZoneDetect = GenericContentType<"content-zone-detect", ObjectZoneDetect>;
export type ContentTypeText = GenericContentType<"content-text", ObjectText>;
export type ContentTypeSpeechToCheck = GenericContentType<
  "content-speech-to-check",
  ObjectSpeechToCheck
>;
export type ContentTypePdfViewer = GenericContentType<"content-pdf-viewer", ObjectPdfViewer>;
export type ContentTypeQuizz3 = GenericContentType<"content-quizz-3", ObjectQuizz3>;
export type ContentYesNoQuestion = GenericContentType<
  "content-yes-no-question",
  ObjectYesNoQuestion
>;
export type ContentBlur = GenericContentType<"content-blur", ObjectContentBlur>;
export type Content3dViewer = GenericContentType<"content-3d-viewer", ObjectContent3dViewer>;
export type ContentTypeZoneSort = GenericContentType<"content-zone-sort", ObjectContentZoneSort>;
export type ContentTypeQuizzSort = GenericContentType<"content-quizz-sort", ObjectContentQuizzSort>;
export type ContentTypeButtonReload = GenericContentType<
  "content-button-reload",
  ObjectContentButtonReload
>;

export type ContentTypeTuto2 = GenericContentType<"content-tuto2", ObjectContentTuto2>;
export type ContentTypeDangerCatch = GenericContentType<
  "content-danger-catch",
  ObjectContentDangerCatch
>;
export type ContentTypeRandomQuizz = GenericContentType<
  "content-random-quizz",
  ObjectContentRandomQuizz
>;
export type ContentTypeElementCatch = GenericContentType<
  "content-element-catch",
  ObjectContentElementCatch
>;
export type ContentTypeHighlight = GenericContentType<"content-highlight", ObjectContentHighlight>;
export type ContentFireExtinguisher = GenericContentType<
  "content-fire-extinguisher",
  ObjectContentFireExtinguisher
>;
export type ContentFire = GenericContentType<"content-fire", ObjectContentFire>;
export type ContentQuizzImageMultiple = GenericContentType<
  "content-quizz-image-multiple",
  ObjectContentQuizzImageMultiple
>;
export type ContentDust = GenericContentType<"content-dust", ObjectContentDust>;

// OTHER(S)
export type ContentTypeType = GenericContentType<"content-type", ObjectType>;

//
// Associated objects: the following types describe the objects present in the "objects" array.
// They hold the content-type-specific fields.
//

export type ObjectCommons = {
  id: number;
  name: string;
  content: number;
  //created_at: Date;
  //updated_at: Date;
};

export type ObjectScene = ObjectCommons & {
  scene_type: "image360" | "video360" | "model3d";
  is_start_scene: boolean;
  is_looping: boolean;
  timer: number;
  source_media: number;
  source_model3d: number;
  has_video_controller: boolean;
  does_disable_self_from_timer: boolean;
};

export type ObjectOnboarding = ObjectCommons & {
  text: string;
  timer: number;
  font_size: number;
  source_image_2d: number;
  does_disable_self_from_timer: boolean;
};

export type ObjectAudio = ObjectCommons & {
  text: string;
  is_looping: boolean;
  time_code: number;
  source_audio: number;
  is_pause_scene_on_play: boolean;
};

export type ObjectText = ObjectCommons & {
  font_size: number;
  timer: number;
  text: string;
  time_code: number;
  does_disable_self_from_timer: boolean;
  does_pause_scene_on_activate: boolean;
};

export type ObjectThumbnail = ObjectCommons & {
  text: string;
  gazed_text: string;
  source_image: number;
  font_size: number;
  color: string;
};

export type ObjectHotspot = ObjectCommons & {
  text: string;
  font_size: number;
  color: string;
  has_activate_animation: boolean;
};

export type ObjectQuizz = ObjectCommons & {
  time_code: number;
  question_text: string;
  explanation_true_text: string;
  explanation_false_text: string;
  is_answer_1_true: boolean;
  is_answer_2_true: boolean;
  is_answer_3_true: boolean;
  be_scored: boolean;
};

export type ObjectQuizz1 = ObjectQuizz & {
  answer_1_text: string;
  answer_2_text: string;
  answer_3_text: string;
};

export type ObjectQuizz2 = ObjectQuizz & {
  answer_1_image: number;
  answer_2_image: number;
  answer_3_image: number;
};

export type ObjectPhotoCatch = ObjectCommons & {
  text_instruction: string;
  text_success: string;
  text_fail: string;
  be_scored: boolean;
};

export type ObjectButton1 = ObjectCommons & {
  text: string;
  color: string;
};

export type ObjectScoringScene = ObjectCommons & {
  icon_type: "point";
  multiplier: number;
};

export type ObjectTuto = ObjectCommons & {
  source_logo: number;
};

export type ObjectVideo2d = ObjectCommons & {
  source_media: number;
  does_pause_scene_on_play: boolean;
  has_video_controller: boolean;
  color?: string;
  time_code: string;
  is_pause_on_scene_play: boolean;
};

export type ObjectClean = ObjectCommons & {
  time_code: number;
  be_scored: boolean;
};

export type ObjectZoneDetect = ObjectCommons & {
  hotspot_info: string;
  entitled: string;
};

export type ObjectVRLocator = ObjectZoneDetect & {
  activate_start_panel: boolean;
};

export type ObjectType = ObjectCommons & {
  prefab: string;
  has_answer: boolean;
};

export type ObjectSpeechToCheck = ObjectCommons & {
  intro_text: string;
  end_text: string;
  correct_words: any;
  time_code: number;
  timer: number;
  be_scored: boolean;
};

export type ObjectPdfViewer = ObjectCommons & {
  page_to_start: string;
  source_pdf: number;
};

export type ObjectQuizz3 = ObjectCommons & {
  question_text: string;
  explanation_true_text: string;
  explanation_false_text: string;
};

export type ObjectYesNoQuestion = ObjectCommons & {
  is_yes_good_answer: boolean;
  question_text: string;
  explanation_true_text: string;
  explanation_false_text: string;
  yes_button_text: string;
  no_button_text: string;
};

export type ObjectContentBlur = ObjectCommons & any;

export type ObjectContent3dViewer = ObjectCommons & {
  start_rotation: string;
  height: number;
  source_model3d: number;
};

export type ObjectContentQuizzSort = ObjectCommons & {
  question: string;
  explanation_true_text: string;
  explanation_false_text: string;
};

export type ObjectContentZoneSort = ObjectCommons & {
  entitled: string;
};

export type ObjectContentButtonReload = ObjectCommons & {
  text: string;
  color: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentTuto2 = ObjectCommons & {};

export type ObjectContentDangerCatch = ObjectCommons & {
  text_instruction: string;
  text_success: string;
  text_fail: string;
  be_scored: boolean;
};

export type ObjectContentHighlight = ObjectCommons & {
  time_code: number;
  content_size: number;
  rotation_info: any;
  content: IContent;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentRandomQuizz = ObjectCommons & {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentElementCatch = ObjectCommons & {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentFireExtinguisher = ObjectCommons & {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentFire = ObjectCommons & {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentQuizzImageMultiple = ObjectCommons & {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ObjectContentDust = ObjectCommons & {};
