import { Outlet } from "react-router-dom";
import ProjectSidebar from "../../common/components/Sidebar/ProjectSidebar";

const ProjectEdit = () => {
  return (
    <>
      <ProjectSidebar />
      <Outlet />
    </>
  );
};

export default ProjectEdit;
