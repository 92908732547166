/* eslint-disable indent */

import React, { MouseEventHandler, SVGProps, useMemo } from "react";
import NodeInfo from "./NodeInfo";
import "./SceneNode.scss";

export type Props = SVGProps<SVGSVGElement> & {
  title: string;
  valid?: boolean;
  onClick?: MouseEventHandler<SVGSVGElement>;
  sceneType?: string;
  content?: any;
  ctName?: string;
  details?: any;
  unlinked?: boolean;
  startScene?: boolean;
  width?: number | string;
  height?: number | string;
  viewBox?: string;
};

type Sized = {
  HEIGHT: number;
  WIDTH: number;
};

const SVGNodeScene: React.FC<Props> & Sized = ({
  title,
  valid,
  onClick,
  content,
  sceneType,
  ctName,
  startScene,
  width,
  height,
  viewBox,
  ...svgProps
}: any) => {
  const x = svgProps.x;

  const tspans = useMemo(() => buildTSpans(title), [title]);
  const strokeWidth = 2;
  return (
    <svg
      width={width ?? SVGNodeScene.WIDTH}
      height={height ?? SVGNodeScene.HEIGHT}
      viewBox={viewBox ?? "0 0 100 120"}
      onClick={onClick}
      {...svgProps}
      className={"thenode " + svgProps.className ?? ""}
    >
      <g fill="none">
        <NodeInfo
          x="75"
          y="20"
          type="scene"
          infoText={`Scene ${sceneType}`}
          infoTitle={ctName}
          contentId={content.id}
          parentX={Number(x)}
          parentY={0}
          parentHeight={height ?? SVGNodeScene.HEIGHT}
          parentWidth={width ?? SVGNodeScene.WIDTH}
        />
      </g>
      <g viewBox="0 0 79 79">
        {startScene ? (
          <polygon
            points="25,0 25,120 100,60"
            stroke="rgb(118,126,133)"
            strokeWidth={strokeWidth}
            fill={valid ? "#02FCA5" : "rgb(73,80,87)"}
            id="start-scene-node"
          />
        ) : (
          <circle
            stroke="rgb(118,126,133)"
            strokeWidth={strokeWidth}
            // fill={valid ? "#02FCA5" : "#03154D"} #fc0303
            fill={
              // startScene ? "#000000" :      this was to ensure props.startScene being passed correctly
              valid ? "#02FCA5" : "rgb(73,80,87)"
            }
            cx="50%"
            cy="50%"
            x="0"
            y="0"
            r={39.5}
            filter={undefined}
            className="tour-scene-circle"
          />
        )}
        <text
          fontSize={13}
          letterSpacing={-0.336}
          fill={valid ? "#010038" : "#FFFFFF"}
          textAnchor="middle"
        >
          {tspans}
        </text>
      </g>
    </svg>
  );
};

SVGNodeScene.WIDTH = 150;
SVGNodeScene.HEIGHT = 150;

// we want to display text into the scene components, but there are a few issues
// - positioning is painful (think "centering")
// - we CANNOT WRAP TEXT: it will be available in SVG2 and is partially supported,
//   but as of now the only reliable and multi-platform way to auto-wrap text is
//   to do it using JS...
//
// The function below will build 1 or 2 <tspan> elements to display the title:
const buildTSpans = (title: string): JSX.Element | JSX.Element[] | null => {
  const elements = title.split(/\s+/, 2);

  switch (elements.length) {
    case 0:
      return null;
    case 1:
      if (title.length > 8) title = title.substring(0, 5) + "...";
      return (
        <tspan x="50%" y="50%" key="title" alignmentBaseline="middle" className="unselectable-text">
          {title.toLocaleUpperCase()}
        </tspan>
      );
    default:
      // eslint-disable-next-line no-case-declarations
      let [topTitle, bottomTitle] = elements;
      if (topTitle.length > 8) topTitle = topTitle.substring(0, 5) + "...";
      if (bottomTitle.length > 8) bottomTitle = bottomTitle.substring(0, 5) + "...";
      return [
        <tspan key="top-title" x="50%" y={"45%"} className="unselectable-text">
          {topTitle.toLocaleUpperCase()}
        </tspan>,
        <tspan key="bottom-title" x="50%" y={"55%"} className="unselectable-text">
          {bottomTitle.toLocaleUpperCase()}
        </tspan>,
      ];
  }
};

export default SVGNodeScene;
