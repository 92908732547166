interface Props {
  className?: string;
  width?: number;
  height?: number;
  fill: string;
  stroke?: string;
  onClick?(): void;
}

export default function ParticipantIcons({
  width,
  height,
  fill,
  stroke = "white",
  className,
  onClick = () => null,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_3909_27453)">
        <path
          d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 11C11.7091 11 13.5 9.20914 13.5 7C13.5 4.79086 11.7091 3 9.5 3C7.29086 3 5.5 4.79086 5.5 7C5.5 9.20914 7.29086 11 9.5 11Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5 21V19C23.4993 18.1137 23.2044 17.2528 22.6614 16.5523C22.1184 15.8519 21.3581 15.3516 20.5 15.13"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 3.13C17.3604 3.35031 18.123 3.85071 18.6676 4.55232C19.2122 5.25392 19.5078 6.11683 19.5078 7.005C19.5078 7.89318 19.2122 8.75608 18.6676 9.45769C18.123 10.1593 17.3604 10.6597 16.5 10.88"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3909_27453">
          <rect width={width} height={height} fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
