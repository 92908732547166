/* eslint-disable indent */
import { ReactElement } from "react";
import { MdInfo } from "react-icons/md";
import "./NodeInfo.scss";

interface Props {
  x: string;
  y: string;
  type: "asset" | "scene";
  infoTitle?: string;
  infoText?: string;
  className?: string;
  contentId?: number;
  parentX?: number;
  parentY?: number;
  parentWidth?: any;
  parentHeight?: any;
  addSceneButton?: boolean;
}

export default function NodeInfo({
  x,
  y,
  type,
  infoTitle,
  infoText,
  contentId,
  className = "",
  parentX,
  parentY,
  parentWidth,
  parentHeight,
  addSceneButton,
}: Props): ReactElement {
  const devMode =
    process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG ? true : false;

  return (
    <>
      <g className={`tooltip-wrapper ${className} `} viewBox="0 0 100 100">
        {infoTitle && (
          <text
            x={20}
            y={20}
            fill="#000000"
            className={
              type === "asset"
                ? "tooltip-title-asset"
                : addSceneButton
                ? "tooltip-title-add-scene"
                : "tooltip-title-scene"
            }
          >
            {infoTitle}
          </text>
        )}
        {infoText && (
          <text
            x={0}
            y={20}
            fill="#000000"
            className={
              type === "asset"
                ? "tooltip-text-asset"
                : addSceneButton
                ? "tooltip-text-add-scene"
                : "tooltip-text-scene"
            }
          >
            {devMode
              ? `contentId=${contentId} ; x=${parentX} ; y=${parentY} ; height=${parentHeight} ; width=${parentWidth}`
              : infoText}
          </text>
        )}
        <MdInfo
          x={x}
          y={y}
          className={`tooltip-node ${addSceneButton ? "add-scene-button" : ""}`}
        ></MdInfo>
      </g>
    </>
  );
}
