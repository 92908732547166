import ProgressBar from "../../../common/components/ProgressBar/ProgressBar";

type Props = { completion: number };

export default function EnduserScoreCompletionBar({ completion }: Props) {
  const getAppropriateColors = (completion: number) => {
    if (completion === 100) {
      return {
        bar: "bg-green-500",
        text: "text-green-500",
      };
    }
    if (completion > 0 && completion < 100) {
      return {
        bar: "bg-yellow-500",
        text: "text-black",
      };
    } else {
      return {
        bar: "bg-gray-300",
        text: "text-black",
      };
    }
  };

  return (
    <div className="flex flex-row">
      <div className="flex min-w-[10vw] w-[10vw] flex flex-row bg-gray-300 rounded-lg">
        <ProgressBar
          barClassName={`${
            getAppropriateColors(completion).bar
          } rounded-lg text-center drop-shadow-md`}
          value={completion}
        />
      </div>

      <p className={`${getAppropriateColors(completion).text} ml-2 w-4`}>
        {Math.round(completion)}%
      </p>
    </div>
  );
}
