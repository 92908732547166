import { useSelector } from "react-redux";
import { Article } from "react-zendesk-helpcenter";
import { getCurrentUser } from "../../../features/profile/state/profileSlice";
import { ILanguage } from "../../../model/model";
import { closeSlider, openOnboardingModal } from "../../state/slice/modal/modalSlice";
import deduceZendeskArticle from "../../util/deduceZendeskArticle";
import { languageConfig } from "../../util/langConfig";
import { useAppDispatch } from "../../state/hooks";
import { useTranslation } from "react-i18next";
import "./ZendeskArticle.scss";

interface IZendeskArticle {
  reference: string;
  haveButtonToOnboarding?: boolean;
}

export default function ZendeskArticle({
  reference,
  haveButtonToOnboarding = false,
}: IZendeskArticle) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(getCurrentUser);
  const userLanguage = currentUser?.preferred_language as ILanguage;

  const ZendeskArticle = deduceZendeskArticle({
    language: languageConfig(userLanguage ? (userLanguage.name as string) : ""),
    reference,
  });
  const handleTourOpen = () => {
    dispatch(openOnboardingModal(reference));
    dispatch(closeSlider("help-slider"));
  };

  return (
    <div className="zendesk-article">
      <div className="justify-between bg-green-500 py-6 text-lg px-4 sm:px-6 font-medium  text-gray-900 flex ">
        {ZendeskArticle.prettyTitle}
        {haveButtonToOnboarding && (
          <button
            className="border-green-200 rounded bg-white text-green-500 p-2 drop-shadow-2xl inline-flex text-sm items-center  border border-green-500"
            onClick={handleTourOpen}
          >
            {t("general.helpOnboardingSteps")}
          </button>
        )}
      </div>
      <div className="relative mt-6 flex-1 px-4 sm:px-6 zendesk-article">
        <Article articleId={ZendeskArticle.id} domain="wixar" />
      </div>
    </div>
  );
}
