import { UserAddIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../common/components/Input";
import ModalStandard from "../../../common/components/Modal/ModalStandard";
import { useForm } from "react-hook-form";
export const CONFIRM_CSV_COMPONENT = "teamComponent";
import { useSelector } from "react-redux";
import { getCurrentUsersFromCsv } from "../../projectGroups/state/projectGroupsSlice";
import {
  getCurrentProject,
  getCurrentProjectPermissions,
  getProjectPermissionsFromServer,
  setCurrentProjectPermissions,
  setProjectTeam,
} from "../../projects/state/projectsSlice";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import TableComp from "../../../common/components/Table/Table";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useDispatch } from "react-redux";
import { currentUserSelector } from "../../../common/state/selectors/authSelector";
import { inviteExternalEnduser } from "../state/teamSlice";
import { Key } from "react";

const ParticipantCsv = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const getCurrentUserFromCsv = useSelector(getCurrentUsersFromCsv);
  const [listCsvToFilter, setListCsvToFilter] = useState<Array<any>>([]);

  const currentProjectPermissions = useSelector(getCurrentProjectPermissions);
  const { user, project } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      project: getCurrentProject(state),
    };
  });

  const projectPermissions = currentProjectPermissions.map((permission) => permission.user);
  const permissionstoString = projectPermissions.map(String);
  const { handleSubmit } = useForm<any>({ defaultValues: { idForInvite: permissionstoString } });

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<Array<any>>([]);
  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(listCsvToFilter.map((li: any) => li.email));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, String(id)]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => String(item) !== String(id)));
    }
  };

  useEffect(() => {
    setListCsvToFilter(getCurrentUserFromCsv);
  }, [getCurrentUserFromCsv]);

  useEffect(() => {
    if (isCheck.length === listCsvToFilter.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const getColumnValues = listCsvToFilter.map((member: any, i: Key | null | undefined) => {
    const filterSneakySneaky = member.email.includes("@sneakysneaky.ua") ? "" : member.email;

    return {
      checkbox: (
        <Checkbox
          key={i}
          checked={isCheck.includes(member.email)}
          id={String(member.email)}
          value={Object.values(member)}
          onClick={(e) => handleClick(e)}
        />
      ),
      firstName: member.firstname,
      lastName: member.lastname,
      email: filterSneakySneaky,
    };
  });

  const onSubmit = async () => {
    const filterResultOfNewUser = listCsvToFilter.filter((users: any) =>
      isCheck.map((mails) => mails).includes(users.email),
    );

    const createObjectFromArray = filterResultOfNewUser.map((user: any) => {
      const createObject = {
        newUserEmail: user.email,
        newUserFirstName: user.firstname,
        newUserLastName: user.lastname,
      };
      return createObject;
    });

    dispatch(closeStandardModal("add-participant-csv"));

    if (createObjectFromArray.length > 0) {
      createObjectFromArray.map(async (datas: any) => {
        const res = await dispatch(
          inviteExternalEnduser({
            componentId: CONFIRM_CSV_COMPONENT,
            inviterId: user?.id,
            projectId: project.id,
            ...datas,
          }),
        ).then((res: any) => {
          // dispatch(
          //   setAlert({
          //     type: "success",
          //     msg: t("alerts.participantInvitedSuccessfully"),
          //   }),
          // );
          return res;
        });

        const newUser = (res.payload as any).data;
        await dispatch(
          setProjectTeam({
            inviteeId: newUser.user.id,
            projectId: project.id,
            componentId: CONFIRM_CSV_COMPONENT,
            inviterId: Number(user?.id),
          }),
        );

        dispatch(getProjectPermissionsFromServer({ projectId: project.id })).then((res) => {
          dispatch(setCurrentProjectPermissions((res.payload as any).data));
        });
      });
    }
  };
  const cancelForm = () => {
    dispatch(closeStandardModal("add-participant-csv"));
    setIsCheck([]);
  };

  const colHeaders = [t("general.firstname"), t("general.lastname"), t("general.email")];

  return (
    <ModalStandard className="" id="add-participant-csv" fullScreen={false}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="flex mb-3">
          <UserAddIcon className="w-6 h-6 stroke-black mr-3" />
          <h1 className="font-bold text-xl">
            {t("pages.project.inviteParticipants.importedParticipants")}
          </h1>
        </div>

        <h2 className="mb-2">{t("pages.project.inviteParticipants.infoImportedParticipants")}</h2>

        {getColumnValues?.length > 0 && (
          <IsLoading componentId={CONFIRM_CSV_COMPONENT} showSuccess={false}>
            <div className="absolute left-10 top-26 mt-3">
              <div className="flex items-center py-2">
                <input
                  checked={isCheck.length === listCsvToFilter.length}
                  type="checkbox"
                  onChange={handleSelectAll}
                  id="myCheck"
                ></input>
              </div>
            </div>
            <TableComp
              selectFromListInTable={true}
              isAllCollaborators={true}
              isCollaboratorAndParticipant={true}
              rows={getColumnValues}
              colHeaders={colHeaders}
              className="max-h-[45rem] w-full"
              listTofilter={listCsvToFilter}
              setListOfItemsFilter={setListCsvToFilter}
            />
          </IsLoading>
        )}

        <div className="w-full flex justify-end">
          <button
            type="button"
            className="btn-alternative-outline mt-2"
            onClick={() => cancelForm()}
          >
            {t("general.cancel")}
          </button>
          {getColumnValues?.length > 0 && (
            <button
              disabled={isCheck.length === 0}
              type="button"
              onClick={() => handleSubmit(onSubmit)()}
              className="btn-primary-fill mt-2 ml-2"
            >
              {t("general.confirm")}
            </button>
          )}
        </div>
      </form>
    </ModalStandard>
  );
};

export default ParticipantCsv;
