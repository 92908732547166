import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../Icon/Icon";

export const GoBackButton = ({
  backCustomRedirectTo,
  depth = 2,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  backCustomRedirectTo?: string;
  depth: number;
}) => {
  const navigate = useNavigate();
  const loc = useLocation();

  let relativePath = "..";
  if (depth > 1) {
    for (let i = 1; i < depth; i++) {
      relativePath = relativePath + "/..";
    }
  }

  const onButtonClick = () => {
    navigate(
      backCustomRedirectTo ||
        `${loc.pathname}${loc.pathname[loc.pathname.length - 1] === "/" ? "" : "/"}${relativePath}`,
    );
  };
  return (
    <button
      {...props}
      className={`${props?.className ?? ""} btn-primary-fill rounded-[50%] h-7 w-7 p-1`}
      onClick={onButtonClick}
    >
      <span className="flex items-center">
        <Icon stroke="white" icon="ChevronLeftIcon" className="w-full" />
      </span>
    </button>
  );
};
