// MurmurHash3 algorithm 🤓
export const generateHashFromString = (input: string): string => {
  const seed = 0x811c9dc5; // Seed value for the hash
  let hash = seed;

  for (let i = 0; i < input.length; i++) {
    hash ^= input.charCodeAt(i);
    hash = Math.imul(hash, 0x5bd1e995); // Multiply with a prime
    hash ^= hash >>> 13; // XOR with shifted value
  }

  // Convert the hash to a hexadecimal string
  const hexHash = (hash >>> 0).toString(16);

  // Repeat to ensure a longer hash
  return hexHash.padEnd(32, "0"); // Adjust the length as needed
};
