import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Icon from "../Icon/Icon";

type IModalConfirmBeforeNavigatingOut = {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
};

const ModalConfirmBeforeNavigatingOut = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}: IModalConfirmBeforeNavigatingOut) => {
  const { t } = useTranslation();

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 overflow-y-auto"
        static
        onClick={(e: any) => e.stopPropagation()}
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={
              "fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center h-full w-full"
            }
          />
        </Transition.Child>

        <div className={"fixed inset-0"}>
          <div className={"flex items-center justify-center p-4 text-center h-full"}>
            <div className={"mt-auto mb-auto flex w-full justify-center items-center h-full"}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={
                    "h-full transform w-1/4 h-fit rounded-2xl pt-auto pb-auto shadow-xl overflow-y-auto bg-slate-100 p-6 text-left align-middle transition-all"
                  }
                >
                  <div className="flex flex-col w-full">
                    <h2 className="mt-1 mb-6 font-medium text-lg">
                      {t("confirmNavigationModal.title")}
                    </h2>

                    <div className="flex w-full justify-center">
                      <Icon
                        icon="InformationCircleIcon"
                        className="stroke-gray-400"
                        height={40}
                        width={40}
                      />
                    </div>

                    <p className="text-center">{t("confirmNavigationModal.body")}</p>
                  </div>

                  <div className="flex justify-end mt-4">
                    <button
                      type="button"
                      className="btn-alternative-outline mr-4"
                      onClick={() => {
                        cancelNavigation();
                      }}
                    >
                      {String(t("general.cancel"))}
                    </button>
                    <button
                      className="btn-primary-fill"
                      type="button"
                      onClick={() => {
                        confirmNavigation();
                      }}
                    >
                      {t("general.yes")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalConfirmBeforeNavigatingOut;
