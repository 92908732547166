interface ButtonTextProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  text: string;
}

const ButtonText = ({ text, className = "", ...props }: ButtonTextProps) => {
  return (
    <button
      {...props}
      className={`text-purple-500 font-medium text-base border-0 disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
    >
      {text}
    </button>
  );
};

export default ButtonText;
