import { ThumbUpIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import shortenName from "../../../../common/util/shortenName";
import { round } from "../../../../common/util/round";
import { ISession } from "../../../../model/model";
import { getSelectedProjectId } from "../../state/statsSlice";

interface Props {
  sessions: ISession[];
  moreThanThreeParticipants: boolean;
}

interface ProGamer {
  name: string;
  score: number;
}

const Podium = ({ sessions, moreThanThreeParticipants = true }: Props) => {
  const { t } = useTranslation();
  const selectedProjectId = useSelector(getSelectedProjectId);

  const initialScoreValue = { name: "", score: -1, userId: -1 };
  const [first, setFirst] = useState(initialScoreValue);
  const [second, setSecond] = useState(initialScoreValue);
  const [third, setThird] = useState(initialScoreValue);

  useEffect(() => {
    if (moreThanThreeParticipants) {
      /*
        - sort sessions by score (descending)
        - get first session (& session.enduser.id)
        - iterate 'til finding a session with different session.enduser.id
        - repeat a third time
      */
      const sorted = [...sessions].sort((a: ISession, b: ISession) => b.score - a.score);
      let first = initialScoreValue;
      let second = initialScoreValue;
      let third = initialScoreValue;
      sorted.every((s: ISession, i: number) => {
        const prettyObject = {
          name: shortenName({ ...s.enduser }),
          score: s.score,
          userId: Number(s.enduser?.id),
        };
        if (i === 0) {
          first = prettyObject;
          return true; // continue
        } else {
          // if we got the third one, break
          if (third.userId !== -1) {
            return false;
          }
          if (second.userId === -1) {
            if (first.userId !== Number(s.enduser.id)) {
              second = prettyObject;
            }
          }
          if (third.userId === -1) {
            if (first.userId !== Number(s.enduser.id) && second.userId !== Number(s.enduser.id)) {
              third = prettyObject;
            }
          }
          return true;
        }
      });
      setFirst(first);
      setSecond(second);
      setThird(third);
    }
  }, [selectedProjectId, moreThanThreeParticipants]);

  const ProGamer = ({ name, score }: ProGamer) => {
    return (
      <div className="rotate-180">
        <p className="text-center">
          <em>{`${name}`}</em>
        </p>
        <p className="text-center">
          Score: <strong>{round(score)}</strong>
        </p>
      </div>
    );
  };

  return (
    <div className="h-full w-full rounded-lg px-5 py-5 border border-gray-300 bg-white shadow-sm">
      <div className="flex flex-col w-full h-full ">
        <div className="flex items-center">
          <ThumbUpIcon className="w-6 h-6 mr-2" />
          <span className="text-base font-medium">{t("pages.statistics.rank")}</span>
        </div>
        <div className="flex grow flex-row justify-evenly">
          {moreThanThreeParticipants ? (
            <div className="flex grow rotate-180 flex-row justify-evenly">
              <div className="w-1/3 h-12 bg-green-300">
                {<ProGamer score={third.score} name={third.name} />}
              </div>
              <div className="w-1/3 h-36 md:h-full bg-green-700">
                {<ProGamer score={first.score} name={first.name} />}
              </div>
              <div className="w-1/3 h-24 md:h-2/3 bg-green-500">
                {<ProGamer score={second.score} name={second.name} />}
              </div>
            </div>
          ) : (
            <p className="leading-[10vh]">{t("pages.statistics.rankingUnavailable")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Podium;
