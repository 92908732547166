import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Icon, { IconName } from "../Icon/Icon";
import Tooltip from "@mui/material/Tooltip";

interface DropdownMenuIconItems {
  text: string;
  id: string;
  icon: IconName;
  onClick?: () => void;
  className?: string;
}

interface DropdownProps {
  menuText: string;
  handleClick?: () => void;
  items: DropdownMenuIconItems[];
  menuIcon: IconName;
  className?: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

const DropDownMenuIcon = ({
  menuText,
  menuIcon: icon,
  items,
  className,
  disabled = false,
  disabledTooltip = "",
}: DropdownProps) => {
  return (
    <Menu>
      {({ open }) => (
        <>
          <Tooltip title={disabled ? disabledTooltip : ""} disableHoverListener={!disabled}>
            <div>
              <Menu.Button
                className={`btn-dropdown-menu ${className ?? ""} ${
                  disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={disabled}
              >
                <Icon icon={icon} className="mr-2 w-5 h-5" />
                {menuText}
                <ChevronDownIcon className="w-4 h-4" />
              </Menu.Button>
            </div>
          </Tooltip>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 w-52 mt-1 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
            >
              <div className="py-1">
                {items.map((elt, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <div className={elt.className ?? ""}>
                        <button
                          onClick={!disabled ? elt.onClick : undefined}
                          className={`${
                            active ? "bg-gray-100 text-gray-900 font-medium" : "text-gray-700"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <Icon icon={elt.icon} className="mr-2 w-5 h-5" />
                          {elt.text}
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropDownMenuIcon;
