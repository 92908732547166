import { parseHexColor } from "src/common/util/parseHexColor";
import { IThemeInfo } from "src/model/model";

interface IThemePreviewParam {
  themeInfo: IThemeInfo;
  className?: string;
}

const ThemePreview = ({ themeInfo, className }: IThemePreviewParam) => {
  const getContrastColor = (color: string) => {
    const parsedColor = parseHexColor(color);

    const luminance =
      0.2126 * (parsedColor.r / 255) +
      0.7152 * (parsedColor.g / 255) +
      0.0722 * (parsedColor.b / 255);

    return luminance > 0.6 ? "#000000" : "#FFFFFF";
  };

  return (
    <div className={className}>
      <svg height={"100%"} width={"100%"} viewBox={"0 0 100 100"}>
        <g transform="translate(5,5) scale(0.9)">
          <rect
            x="0"
            y="0"
            width="100"
            height="100"
            fill={themeInfo.color_primary}
            ry={Number(themeInfo.corner_radius) / 10}
            rx={Number(themeInfo.corner_radius) / 10}
            {...(themeInfo.enable_outline
              ? {
                  stroke: themeInfo.color_secondary,
                  strokeWidth: Number(themeInfo.outline_width) / 3.0,
                  strokeLinejoin: "round",
                  strokeLinecap: "round",
                }
              : {})}
          />

          <text
            x="50"
            y="15"
            width="50"
            height="15"
            fill={
              themeInfo.is_using_theme_text_color
                ? themeInfo.text_color_primary
                : getContrastColor(String(themeInfo.color_primary))
            }
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="10"
            fontFamily={`${themeInfo.font_title_name}`}
            fontWeight="bold"
          >
            Ma question
          </text>

          <g transform="translate(15,32)">
            <g>
              <g>
                <circle
                  cx="5"
                  cy="5"
                  r="4"
                  fill={themeInfo.color_surface}
                  x="50"
                  y="50"
                  stroke="none"
                  width="20"
                  height="20"
                ></circle>

                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  fill="none"
                  x="50"
                  y="50"
                  stroke="white"
                  width="20"
                  height="20"
                  strokeWidth="0.5"
                ></circle>

                <circle
                  cx="5"
                  cy="5"
                  r="5.5"
                  fill="none"
                  x="50"
                  y="50"
                  stroke="black"
                  width="20"
                  height="20"
                  strokeWidth="0.5"
                ></circle>

                <text
                  x="15"
                  y="0"
                  width="50"
                  height="15"
                  fill={
                    themeInfo.is_using_theme_text_color
                      ? themeInfo.text_color_primary
                      : getContrastColor(String(themeInfo.color_primary))
                  }
                  dominantBaseline="hanging"
                  fontSize="10"
                  fontFamily={`${themeInfo.font_main_name}`}
                  fontWeight="bold"
                >
                  Réponse 1
                </text>
              </g>
            </g>

            <g transform="translate(0,20)">
              <g>
                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  fill="none"
                  x="50"
                  y="50"
                  stroke="white"
                  width="20"
                  height="20"
                  strokeWidth="0.5"
                ></circle>

                <circle
                  cx="5"
                  cy="5"
                  r="5.5"
                  fill="none"
                  x="50"
                  y="50"
                  stroke="black"
                  width="20"
                  height="20"
                  strokeWidth="0.5"
                ></circle>

                <text
                  x="15"
                  y="0"
                  width="50"
                  height="15"
                  fill={
                    themeInfo.is_using_theme_text_color
                      ? themeInfo.text_color_primary
                      : getContrastColor(String(themeInfo.color_primary))
                  }
                  dominantBaseline="hanging"
                  fontSize="10"
                  fontFamily={`${themeInfo.font_main_name}`}
                  fontWeight="bold"
                >
                  Réponse 2
                </text>
              </g>
            </g>
          </g>

          <g>
            <rect x="25" y="75" width="50" height="15" fill={themeInfo.color_secondary} ry="8" />
            <text
              x="50"
              y="83"
              width="50"
              height="15"
              fill={
                themeInfo.is_using_theme_text_color
                  ? themeInfo.text_color_secondary
                  : getContrastColor(String(themeInfo.color_secondary))
              }
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize="8"
              fontFamily={`${themeInfo.font_main_name}`}
            >
              OK
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ThemePreview;
