import * as React from "react";
import { useTranslation } from "react-i18next";
import { BiSearchAlt2 } from "react-icons/bi";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

interface IItems {
  name: string;
}

interface ISearchAutocompleteProps {
  items: IItems[];
  handleOnSearch: any;
  handleOnSelect: any;
}

export const SearchAutocomplete: React.FC<ISearchAutocompleteProps> = ({
  handleOnSearch,
  handleOnSelect,
  items,
}: ISearchAutocompleteProps) => {
  const { t } = useTranslation();
  const [inputBorder, setInputBorder] = React.useState(false);

  return (
    <header className="relative z-50">
      <div onBlur={() => setInputBorder(false)} style={{ width: 250 }}>
        <ReactSearchAutocomplete
          items={items}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onFocus={() => setInputBorder(true)}
          formatResult={(item: any) => {
            return (
              <>
                <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
              </>
            );
          }}
          placeholder={t("general.search")}
          showIcon={false}
          styling={{
            borderRadius: "10px",
            iconColor: "#00c889",
            clearIconMargin: "10px 30px 10px 10px",
            placeholderColor: "#ccd7e0",
            border: inputBorder ? "1px solid #d4d5d8" : "0px",
            height: "46px",
            boxShadow: "0px",
          }}
          showNoResultsText={t("general.noResults")}
        />
      </div>
      <BiSearchAlt2 color={"#9ca3af"} className={"absolute top-3 left-500 w-6 h-6"} />
    </header>
  );
};
