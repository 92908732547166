/* eslint-disable indent */
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../common/state/store";
import { IMedia } from "../../../model/unityObject";
import { IUploadState } from "../../../model/store";
import apiUrls from "../../../api";
import { ISecuredMedia } from "../../../model/model";

export const keyStore = "wwvr-front-v1";

export interface IMediaLegacyState {
  list: IMedia[];
  securedList: ISecuredMedia[];
  tags: string[];
  loading: boolean;
  currentlyPreviewed: IMedia;
  sceneMediaPreviewType?: "image" | "video";
  shouldRerender: boolean;
  currentUploadsStateList: IUploadState[];
}

const initialState: IMediaLegacyState = {
  list: [],
  securedList: [],
  loading: false,
  tags: [],
  currentlyPreviewed: {
    id: 1441,
    s3_url: "https://s3.eu-west-3.amazonaws.com/storage.wixar.io/placeholder_image.jpeg",
    createdAt: "2021-06-03T13:46:43.817Z",
    updatedAt: "2021-06-03T13:46:43.911Z",
    organization: {
      id: "3",
      name: "WideWeb VR Commons",
      created_at: "2020-07-28T14:38:34.000Z",
      updated_at: "2020-10-29T14:52:27.858Z",
      legal_name: "WideWebVR",
      address_locality: "Aix-en-Provence",
      address_region: "Bouches-du-Rhône",
      address_country: "France",
      phone: "+33486760045",
      postal_code: "13290",
      email: "info@wixar.io",
      siret: "null",
      street_address: "Europarc de Pichaury Bat 5, 1330 Avenue J.R.G Gauier De la Lauziere",
      source_logo: "47",
      iban: "not-an-iban",
      admin: 255,
      representative_name: null,
      organization_permission: null,
    },
    type: "image",
    name: "Placeholder_image_360",
    size_in_bytes: 749146,
    filename: "placeholder_image.jpeg",
  } as IMedia,
  shouldRerender: false,
  currentUploadsStateList: [],
};

export const mediaSlice = createSlice({
  name: "medias",
  initialState,
  reducers: {
    setLoading: (state: IMediaLegacyState, action: any) => {
      state.loading = action.payload;
    },
    setMedias: (state: IMediaLegacyState, action: any) => {
      state.list = action.payload.map((smo: IMedia) => {
        const relevantSecuredReference = state.securedList.filter(
          (o: ISecuredMedia) => Number(o.source_media_id) === Number(smo.id),
        )[0];
        return {
          ...smo,
          s3_url: relevantSecuredReference ? relevantSecuredReference.secured_s3url : smo.s3_url,
        };
      });
    },
    setSecuredMedias: (state: IMediaLegacyState, action: any) => {
      state.securedList = [...action.payload];
    },
    addMedia: (state: IMediaLegacyState, action: any) => {
      state.list.push(action.payload);
    },
    setTags: (state: IMediaLegacyState, action: any) => {
      state.tags.push(action.payload);
    },
    mergeTags: (state: IMediaLegacyState, action: any) => {
      const toMerge = action.payload;
      if (!state.tags.includes(toMerge)) {
        state.tags.push(toMerge);
      }
    },
    deleteTag: (state: IMediaLegacyState, action: any) => {
      state.tags = state.tags.filter((tag: string) => tag !== action.payload);
    },
    deleteMedia: (state: IMediaLegacyState, action: any) => {
      state.list = state.list.filter((media) => media.id !== action.payload);
    },
    clearMedias: (state: IMediaLegacyState) => {
      state.list = [];
    },
    setPreviewedMedia: (state: IMediaLegacyState, action: any) => {
      state.currentlyPreviewed = { ...action.payload };
    },
    setShouldRerender: (state: IMediaLegacyState) => {
      state.shouldRerender = !state.shouldRerender;
    },
    addUploadToCurrentList: (state: IMediaLegacyState, action: any) => {
      if (!state.currentUploadsStateList) {
        state.currentUploadsStateList = [];
      }
      const index = state.currentUploadsStateList.findIndex((e) => {
        return e.uploadId === action.payload.uploadId && e.s3name === action.payload.s3name;
      });
      if (index === -1) {
        state.currentUploadsStateList.push(action.payload);
      } else {
        state.currentUploadsStateList[index].percent = action.payload.percent;
        state.currentUploadsStateList[index].isFinished = action.payload.isFinished;
        state.currentUploadsStateList[index].errorMessage = action.payload.errorMessage;
      }
    },
    removeUploadToCurrentList: (state: IMediaLegacyState, action: any) => {
      const index = state.currentUploadsStateList.findIndex((e) => {
        return e.uploadId === action.payload.uploadId && e.s3name === action.payload.s3name;
      });
      state.currentUploadsStateList.splice(index, 1);
    },
    setUploadCurrentList: (state: IMediaLegacyState, action: any) => {
      state.currentUploadsStateList = action.payload;
    },
  },
});

export const {
  setLoading,
  setMedias,
  setSecuredMedias,
  setTags,
  mergeTags,
  deleteTag,
  clearMedias,
  addMedia,
  deleteMedia,
  setPreviewedMedia,
  setShouldRerender,
  addUploadToCurrentList,
  removeUploadToCurrentList,
  setUploadCurrentList,
} = mediaSlice.actions;

function list2DEmpty(matrix: any[]) {
  for (let i = 0; i < matrix.length; i++) {
    if (matrix[i] && Array.isArray(matrix[i]) && matrix[i].length > 0) return false;
  }
  return true;
}

export const destroyAll =
  (medias: IMedia[], refInBDD: any[] = []) =>
  (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    axios
      .post(`${apiUrls.medias.all}/delete-all`, { medias: medias, listRef: refInBDD })
      .then(() => {
        medias.forEach((media) => {
          dispatch(deleteMedia(media.id));
        });
        if (list2DEmpty(refInBDD) === false) {
          axios
            .put(`${apiUrls.medias.all}/default-media-all`, { medias: medias, listRef: refInBDD })
            .catch(() => {
              /*dispatch(setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e })*/
            });
        }

        /*dispatch(setAlert({ isOpen: true, msg: "deleteMediaSuccess", type: "success" }));*/
      })
      .catch(
        () => {
          console.error("error set alert");
        },
        /*dispatch(setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e })),*/
      )
      .finally(() => dispatch(setLoading(false)));
  };

export const destroy =
  (mediaId: number, refInBDD: any[] = [], mediaType: string) =>
  (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    axios
      .delete(`${apiUrls.medias.all}/${mediaId}`)
      .then(() => {
        dispatch(deleteMedia(mediaId));
        if (refInBDD && Array.isArray(refInBDD) && refInBDD.length > 0 && mediaType !== "") {
          axios
            .put(`${apiUrls.medias.all}/default-media/${mediaId}/${mediaType}`, refInBDD)
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {})
            .catch(
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {},
              /*dispatch(
                setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e }),
              ),*/
            );
        }

        // dispatch(setAlert({ isOpen: true, msg: "deleteMediaSuccess", type: "success" }));
      })
      .catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // dispatch(setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e })),
      )
      .finally(() => dispatch(setLoading(false)));
  };
export const checkAllMedia =
  (medias: IMedia[], openModal: any, setList: any) => (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    axios
      .post(`${apiUrls.medias.all}/check-all`, medias)
      .then((response) => {
        setList(response.data);
        openModal();
      })
      .catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e })),
      )
      .finally(() => dispatch(setLoading(false)));
  };

export const checkMedia =
  (mediaId: number, mediaType: string, openModal: any, setList: any) => (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    axios
      .get(`${apiUrls.medias.all}/${mediaId}/${mediaType}`)
      .then((response) => {
        setList(response.data);
        openModal();
      })
      .catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // dispatch(setAlert({ isOpen: true, msg: "deleteMediaFail", type: "danger", subMsg: e })),
      )
      .finally(() => dispatch(setLoading(false)));
  };
export const upload =
  (file: File, orgId: number, cb: any | null, media_360_tag: string, name: string) =>
  (dispatch: Dispatch) => {
    const uploadLimitInBytes = 8e8;

    dispatch(setLoading(true));
    if (file.size <= uploadLimitInBytes) {
      const formdata = new FormData();
      formdata.append("files", file);
      formdata.append("organization", `${orgId}`);
      formdata.append("name", name === "" ? file.name : name);
      formdata.append("media_360_tag", media_360_tag);
      axios
        .post(apiUrls.medias.create, formdata)
        .then((response) => {
          dispatch(addMedia(response.data));
          //dispatch(setAlert({ isOpen: true, msg: "uploadSuccess", type: "success" }));
        })
        .catch(
          (e) =>
            e?.response?.status === 415
              ? {} // display custom alert message on wrong file type error
              : /*dispatch(
                setAlert({
                  isOpen: true,
                  msg: "uploadFailWrongFileType",
                  type: "danger",
                  subMsg: e,
                }),
              )*/
                {} /*dispatch(
                setAlert({
                  isOpen: true,
                  msg: "uploadFail",
                  type: "danger",
                  subMsg: e?.response?.data?.message ?? e,
                }),
              ),*/,
        )
        .finally(() => {
          cb();
          dispatch(setLoading(false));
        });
    } else {
      cb();
      dispatch(setLoading(false));
    }
  };

export const editMedia =
  (media: IMedia, tags: string[], medias: IMedia[]) => (dispatch: Dispatch) => {
    const tagsLower = tags.map((tag) => tag.toLocaleLowerCase());
    axios
      .put(`${apiUrls.medias.all}/${media.id}`, { tags: tagsLower })
      .then((response) => {
        const mediasUpdate = medias.filter((m) => m.id !== media.id);
        mediasUpdate.push(response.data);
        dispatch(setMedias(mediasUpdate));
        //dispatch(setAlert({ isOpen: true, msg: "success", type: "success" }));
      })
      .catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {} /*dispatch(setAlert({ isOpen: true, msg: "fail", type: "danger", subMsg: e }))*/,
      );
  };
export const getMedias = (state: RootState) => state.mediaLegacy.list;
export const getTags = (state: RootState) => state.mediaLegacy.tags;
export const getMediaById = (state: RootState, mediaId: number) =>
  state.mediaLegacy.list.find((media: IMedia) => Number(media.id) === Number(mediaId));
export const getSecuredMedias = (state: RootState) => state.mediaLegacy.securedList;
export const getIsLoading = (state: RootState) => state.mediaLegacy.loading;
export const getCurrentlyPreviewMedia = (state: RootState) => state.mediaLegacy.currentlyPreviewed;
export const getShouldRerenderGraph = (state: RootState) => state.mediaLegacy.shouldRerender;
export const getCurrentUploadsStateList = (state: RootState) =>
  state.mediaLegacy.currentUploadsStateList;

export default mediaSlice.reducer;
