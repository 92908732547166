import { ReactElement } from "react";
import "./index.scss";

interface IProgress {
  key?: string;
  wrapperClassName?: string;
  barClassName?: string;
  value: number;
  animated?: boolean;
  label?: string;
  labelClassName?: string;
}

export default function ProgressBar({
  key,
  wrapperClassName = "bg-gray-500",
  barClassName,
  value,
  animated,
  label,
  labelClassName = "text-xxs font-bold ",
}: IProgress): ReactElement {
  return (
    <div className={`w-full h-full bg-gray-500 rounded-lg ${wrapperClassName}`} key={key}>
      <div className="w-full h-full rounded-lg">
        <div
          style={{ width: `${value}%` }}
          className={`h-full rounded-lg flex items-center justify-center ${barClassName} ${
            animated ? "shim-violet" : ""
          }`}
        >
          {label ? <span className={labelClassName}>{label}</span> : null}
        </div>
      </div>
    </div>
  );
}
