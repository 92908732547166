import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/state/store";
import { IAlert } from "../../../model/model";
import { IAlertsState } from "../../../model/store";

const initialState: IAlertsState = {
  alertsQueue: [],
  publishWarningText: "",
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    /**
     * Use with dispatch: dispatch(setAlert({...params}))
     * @param type "success" | "danger" | "warning"
     * @param msg string
     * @param subMsg (optional) string
     * @param showCloseButton (optional) Boolean
     */
    setAlert: (state: IAlertsState, action) => {
      const alert: IAlert = {
        alertIsOpen: action.payload.isOpen,
        alertMsg: action.payload.msg,
        alertStyle: action.payload.type,
        alertSubMsg: action.payload.subMsg ? action.payload.subMsg.toString() : null,
        alertShowCloseButton: action.payload.showCloseButton
          ? action.payload.showCloseButton
          : true,
        customTimeoutInMs: action.payload.customTimeoutInMs ? action.payload.customTimeoutInMs : 0,
        id: action.payload.id ?? String(Date.now()) + ":" + String(Math.random()),
      };
      state.alertsQueue = [alert];
    },
    setHideAlert: (state: IAlertsState, action) => {
      const index = state.alertsQueue.findIndex((elt) => elt.id === action.payload.id);
      if (index >= 0) {
        state.alertsQueue.splice(index);
      }
    },
    clearAlerts: (state: IAlertsState) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    setPublishWarningText: (state: IAlertsState, action) => {
      state.publishWarningText = action.payload;
    },
  },
});

export const alertsReducer = alertsSlice.reducer;

export const { setAlert, setHideAlert, clearAlerts, setPublishWarningText } = alertsSlice.actions;

export const getAlert = (state: RootState) => state.alerts;
export const getPublishWarningText = (state: RootState) => state.alerts.publishWarningText;

export default alertsSlice.reducer;
