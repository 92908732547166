import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/common/state/hooks";
import {
  getGptConversationsSortedByRecency,
  updateGptConversationName,
  setActiveGptConversation,
} from "../state/chatbotSlice";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import fromTimestampToPrettyFrenchDate from "src/common/util/fromTimestampToPrettyFrenchDate";
import GptConversationThreeDotButton from "./GptConversationThreeDotButton";

interface GptConversationLoaderProps {
  onConversationSelect: (conversationId: number) => void;
  onResetChat: () => void;
}

export const GPT_CONVERSATION_LOADER_COMPONENT_ID = "SELECT_CHAT_HISTORY_COMPONENT";

const GptConversationLoader: React.FC<GptConversationLoaderProps> = ({
  onConversationSelect,
  onResetChat,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userPreviousGptConversations = useSelector(getGptConversationsSortedByRecency);

  const [editingConversationId, setEditingConversationId] = useState<number | null>(null);
  const [newConversationName, setNewConversationName] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null); // Ref to the scrollable container

  const handleConversationSelect = (conversationId: number) => {
    if (editingConversationId === conversationId) return;
    const selectedConversation = userPreviousGptConversations.find(
      (conv) => conv.id === conversationId,
    );
    if (selectedConversation) {
      dispatch(setActiveGptConversation(selectedConversation));
      onConversationSelect(conversationId);
    }
  };

  const handleRename = (id: number) => {
    const conversation = userPreviousGptConversations.find((conv) => conv.id === id);
    if (conversation) {
      const fallbackName = fromTimestampToPrettyFrenchDate(
        conversation.conversation_metadata.timestamp,
      );
      setEditingConversationId(id);
      setNewConversationName(conversation.name || fallbackName);
    }
  };

  const handleRenameSubmit = (id: number) => {
    if (newConversationName.trim() !== "") {
      dispatch(updateGptConversationName({ id, newName: newConversationName }));
      setEditingConversationId(null);
    }
  };

  const handleRenameCancel = () => {
    setEditingConversationId(null);
  };

  const handleThreeDotMenuOpen = (conversationId: number) => {
    const lastItemIndex = userPreviousGptConversations.length - 1;
    const isLastItem = userPreviousGptConversations[lastItemIndex]?.id === conversationId;

    if (isLastItem && scrollableRef.current) {
      const container = scrollableRef.current;

      // Check if the scrollbar exists
      const isScrollable = container.scrollHeight > container.clientHeight;

      if (isScrollable) {
        // Scroll to the bottom of the container
        container.scrollTop = container.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && editingConversationId !== null) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editingConversationId]);

  return (
    <div className="w-full max-h-[80vh] h-[80vh] p-1 bg-gray-100">
      <button
        className="w-full bg-white p-2 mb-1 rounded-lg shadow hover:bg-green-500 hover:text-white border transition-colors"
        onClick={onResetChat}
      >
        <div className="flex items-center justify-center font-bold p-2">
          <FaRegEdit className="mr-2" />
          {t("pages.chat.new")}
        </div>
      </button>
      <IsLoading
        componentId={GPT_CONVERSATION_LOADER_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <div ref={scrollableRef} className="space-y-2 overflow-hidden overflow-y-auto h-[93%]">
          {userPreviousGptConversations.map((conversation, index) => {
            const isEditing = editingConversationId === conversation.id;

            const name =
              conversation?.name ??
              fromTimestampToPrettyFrenchDate(conversation.conversation_metadata.timestamp);

            return (
              <div key={conversation.id} className="relative group">
                {isEditing ? (
                  <input
                    ref={inputRef}
                    className="w-full bg-white text-sm p-2 rounded-lg shadow pr-10 text-left"
                    value={newConversationName}
                    onChange={(e) => setNewConversationName(e.target.value)}
                    onBlur={() => handleRenameSubmit(conversation.id)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleRenameSubmit(conversation.id);
                      if (e.key === "Escape") handleRenameCancel();
                    }}
                    autoFocus
                  />
                ) : (
                  <button
                    className="w-full bg-white text-sm p-2 rounded-lg shadow hover:bg-blue-500 hover:text-white transition-colors pr-10 text-left"
                    onClick={() => handleConversationSelect(conversation.id)}
                  >
                    {name}
                  </button>
                )}
                <div className="absolute top-1 right-1 h-full flex items-center">
                  <GptConversationThreeDotButton
                    id={conversation.id}
                    className="opacity-0 group-hover:opacity-100 transition-opacity"
                    onRename={() => handleRename(conversation.id)}
                    onMenuOpen={() => handleThreeDotMenuOpen(conversation.id)}
                    menuPosition={index <= 3 ? "below" : "above"} // so the dropdown always appear when it should 🐵
                  />
                </div>
              </div>
            );
          })}
        </div>
      </IsLoading>
    </div>
  );
};

export default GptConversationLoader;
