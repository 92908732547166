import Navigation from "../../common/components/Navigation/Navigation";
import DownloadClientButton from "../../features/downloadClient/components/DownloadClientButton";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../common/state/store";
import {
  getLatestWixarClientImacUri,
  getLatestWixarClientVersion,
  getLatestWixarClientWindowsUri,
  getWixarClientUris,
} from "../../features/downloadClient/state/downloadClientSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { useTranslation } from "react-i18next";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { languageConfig } from "src/common/util/langConfig";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { ILanguage } from "src/model/model";
export const DOWNLOAD_WIXAR_CLIENT_COMPONENT_ID = "downloadWixarClientComponentId";

export default function DownloadClient(): ReactElement {
  const dispatch: AppDispatch = useDispatch();

  const { t } = useTranslation();
  const latestWixarClientWindows = useSelector(getLatestWixarClientWindowsUri);
  const latestWixarClientImac = useSelector(getLatestWixarClientImacUri);
  const latestWixarClientVersion = useSelector(getLatestWixarClientVersion);
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  React.useEffect(() => {
    dispatch(getWixarClientUris({ componentId: DOWNLOAD_WIXAR_CLIENT_COMPONENT_ID }));
  }, []);
  interface PlatformComponentProps {
    platform: string;
    version: string;
    size: string;
    lastestVersionHref?: string;
    buttonActivated?: boolean;
    platformIcon?: string;
  }
  interface TableForPlatformComponentProps {
    title: string;
    description: string;
    children: React.ReactNode;
  }
  const TableForPlatformComponent = ({
    title,
    description,
    children,
  }: TableForPlatformComponentProps) => {
    return (
      <div>
        <h1 className="text-3xl font-bold text-black-500 mb-7">{title}</h1>
        <span className=" text-lg text-gray-500">{description}</span>
        <div className="ml-10 mt-12 mb-2">
          <div className="flex max-w-[75%] ">
            <h6 className="text-base font-light text-gray-700 w-[366px]">
              {t("general.platforms")}
            </h6>
            <h6 className="text-base font-light  text-gray-700 w-[370px]">
              {t("general.lastVersion")}{" "}
            </h6>
            <h6 className="text-base  font-light text-gray-700 w-[28.1rem]">
              {t("general.weightStudio")}
            </h6>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  };
  const PlatformComponent = ({
    platform,
    version,
    size,
    lastestVersionHref = "",
    buttonActivated = true,
    platformIcon = "windows10",
  }: PlatformComponentProps) => {
    return (
      <div className="bg-gray-200  mb-4 2xl:h-14 xl:h-18 rounded-md mt-3 max-w-[75%]">
        <div className="flex p-4 ">
          <p className="text-gray-900 text-lg  w-[350px]">{platform}</p>
          <p className="text-gray-700 text-lg w-[370px]">V {version}</p>
          <p className="text-gray-700 text-lg w-[15rem] -pr-2">
            {size} {currentLocale === "fr" ? "Mo" : "Mb"}
          </p>
          {buttonActivated ? (
            <DownloadClientButton
              platform={platformIcon}
              styled="w-52 h-10 rounded-md bg-green-500 hover:shadow-lg hover:scale-105 -mt-2"
              href={lastestVersionHref}
              textInfo={t("pages.downloadClient.windows10Explanation")}
              textIcon={t("general.download")}
            />
          ) : (
            <button className="w-40">
              <span className="underline underline-offset-4 text-purple-600">
                {t("studioDownload.linkLab")}
              </span>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Navigation title={"Wixar Hub"} />
      <InfoButton relevantArticle="aboutWixarClient" />
      <IsLoading componentId={DOWNLOAD_WIXAR_CLIENT_COMPONENT_ID} showSuccess={false}>
        <div className=" mt-10 pl-[9rem]  overflow-auto w-full h-full personalize-scroll-visible ">
          <div className="mt-8">
            <TableForPlatformComponent title="" description={t("pages.downloadClient.header")}>
              <PlatformComponent
                platformIcon="Windows10"
                platform="Wixar Hub Windows 10/11"
                version={latestWixarClientVersion}
                size="83"
                lastestVersionHref={latestWixarClientWindows}
              />

              <PlatformComponent
                platformIcon="MacOS"
                platform="Wixar Hub MacOS"
                version={latestWixarClientVersion}
                size="110"
                lastestVersionHref={latestWixarClientImac}
              />
            </TableForPlatformComponent>
          </div>
        </div>
      </IsLoading>
      {
        /* for development regarding ErrorBoundary:
        uncomment next line to purposely crash this view */
        // Promise.reject("oopsie")
      }
    </>
  );
}
