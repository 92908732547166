import { useTranslation } from "react-i18next";

const stepsOnboarding = () => {
  const { t } = useTranslation();

  return {
    introOnboarding: [
      {
        selector: ".introOnboarding-step-1",
        content: () => (
          <div>
            <p className="pb-3">{t("onboardingtour.introOnboarding.step1")}</p>
            <a
              className="font-bold text-green-500 underline underline-offset-4 "
              target="_blank"
              rel="noopener noreferrer"
              href={t("general.zendeskLink")}
            >
              {t("onboardingtour.introOnboarding.step1Bold")}
            </a>
          </div>
        ),
      },
      {
        selector: ".introOnboarding-step-2",
        content: t("onboardingtour.introOnboarding.step2"),
      },
      {
        selector: ".introOnboarding-step-3",
        content: t("onboardingtour.introOnboarding.step3"),
      },
      {
        selector: ".introOnboarding-step-4",
        content: t("onboardingtour.introOnboarding.step4"),
      },
      {
        selector: ".introOnboarding-step-5",
        content: t("onboardingtour.introOnboarding.step5"),
      },
      {
        selector: ".introOnboarding-step-6",
        content: t("onboardingtour.introOnboarding.step6"),
      },
      {
        selector: ".introOnboarding-step-7",
        content: t("onboardingtour.introOnboarding.step7"),
      },
    ],
    onboardingAboutModules: [
      {
        selector: ".onboardingAboutModules-step-1",
        content: t("onboardingtour.onboardingAboutModules.step1"),
      },
      {
        selector: ".onboardingAboutModules-step-2",
        content: t("onboardingtour.onboardingAboutModules.step2"),
      },
      {
        selector: ".onboardingAboutModules-step-3",
        content: t("onboardingtour.onboardingAboutModules.step3"),
      },
    ],
    onboardingHowToAddScene: [
      {
        selector: ".onboardingHowToAddScene-step-1",
        content: t("onboardingtour.onboardingHowToAddScene.step1"),
      },
      {
        selector: ".onboardingHowToAddScene-step-2",
        content: t("onboardingtour.onboardingHowToAddScene.step2"),
      },
      {
        selector: ".onboardingHowToAddScene-step-3",
        content: t("onboardingtour.onboardingHowToAddScene.step3"),
      },
      {
        selector: ".onboardingHowToAddScene-step-4",
        content: t("onboardingtour.onboardingHowToAddScene.step4"),
      },
      {
        selector: ".onboardingHowToAddScene-step-5",
        content: () => (
          <div>
            <p>{t("onboardingtour.onboardingHowToAddScene.step5")}</p>
            <p className="font-bold">{t("onboardingtour.onboardingHowToAddScene.step5Bold")}</p>
          </div>
        ),
      },
    ],
  };
};

export default stepsOnboarding;
