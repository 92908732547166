import { useTranslation } from "react-i18next";
import CirclePercent from "../../../common/components/Button/CirclePercent";
import ThreeDotsDropdownMenu, {
  menuItems,
} from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import Trophy from "../../../common/components/Trophy/Trophy";
import { useAppDispatch } from "../../../common/state/hooks";
import { openStandardModal } from "../../../common/state/slice/modal/modalSlice";
import IProjectGroupCompletionItem from "../dataTypes/IProjectGroupCompletionItem";
import { fetchEnduserSessionDetailsForGivenProject } from "../state/enduserStatsSlice";
import { useSelector } from "react-redux";
import { getSelectedProjectWithSessions } from "src/features/stats/state/statsSlice";
import { getCurrentUser } from "src/features/profile/state/profileSlice";

interface ButtonSquareIconProps
  extends React.HTMLAttributes<HTMLDivElement>,
    IProjectGroupCompletionItem {}

export const ENDUSER_PROJECT_SESSION_DETAIL_COMPONENT = "enduserProjectSessionDetailComponent";

const EnduserProjectCard = ({
  projectId,
  projectName,
  projectThumbnail,
  order,
  progressPercentage, // 0-100 range | null
  score,
  isPerfectScore,
  dateLastSession,
  showThreeDotsDropdownMenu,
  height = "h-32",
  width = "w-full",
  ...props
}: ButtonSquareIconProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);

  if (projectName.length > 28) {
    projectName.substring(0, 28) + "...";
  }

  const successGreen = "#00C889";
  const failGray = "#ACB5BD";

  const renderProperBlock = () => {
    if (progressPercentage !== null) {
      return (
        <>
          <div className="h-10 flex">
            <CirclePercent
              withAnimation={false}
              percent={progressPercentage ? progressPercentage : 0}
              circleColor={
                progressPercentage && progressPercentage >= 100
                  ? "rgb(0 200 137)"
                  : "rgb(250 204 21)"
              }
            />
            <p className="ml-1 text-white text-sm font-thin card-title-outline text-start flex items-center w-full whitespace-nowrap">
              {t("pages.enduserProjectGroup.projectCard.completion")}
            </p>
          </div>
          <div className="h-10 flex items-center">
            <div>
              <Trophy height={15} width={15} fill={isPerfectScore ? successGreen : failGray} />
            </div>
            <p
              className={`ml-1 ${
                isPerfectScore ? "text-green-500" : "text-white"
              } text-sm font-thin card-title-outline text-start`}
            >
              {score}
            </p>
            <p className="ml-1 text-white text-sm font-thin card-title-outline text-start whitespace-nowrap">
              {t("pages.enduserProjectGroup.projectCard.bestScore")}
            </p>
          </div>
        </>
      );
    } else {
      // TODO: elif/else unauthorized project where the learner can ask access to admin
      return (
        <p className="text-gray-300 text-sm italic	">
          {t("pages.enduserProjectGroup.projectCard.noScoreData")}
        </p>
      );
    }
  };
  const handleButtonClick = (e: any, projectId: number) => {
    const action = e.menuItemText;

    if (action == t("pages.statistics.historical")) {
      dispatch(
        fetchEnduserSessionDetailsForGivenProject({
          projectId,
          componentId: ENDUSER_PROJECT_SESSION_DETAIL_COMPONENT,
        }),
      );
      dispatch(openStandardModal("enduserSessionDetails"));
    }
    if (action === t("pages.statistics.statistics")) {
      dispatch(openStandardModal("enduserStats"));
    }
  };

  const computeMenuItems = () => {
    const userSessionsForThisProject = useSelector(getSelectedProjectWithSessions(projectId));
    const userHasSessionsForThisProject =
      userSessionsForThisProject?.sessions &&
      userSessionsForThisProject.sessions.find((session) => {
        if (session?.enduser) {
          if (session.enduser?.id) {
            return Number(session.enduser.id) === Number(currentUser?.id);
          }
        }
        return false;
      });
    const userHasNoSessionsForThisProject = !userHasSessionsForThisProject;

    const menuItems: menuItems = [
      {
        menuItemText: t("pages.statistics.historical"),
        icon: "PencilAltIcon",
        disabled: userHasNoSessionsForThisProject,
        disabledTooltipText: userHasNoSessionsForThisProject ? t("pages.statistics.noData") : "",
      },
      {
        menuItemText: t("pages.statistics.statistics"),
        icon: "SaveAsIcon",
        disabled: userHasNoSessionsForThisProject,

        disabledTooltipText: userHasNoSessionsForThisProject ? t("pages.statistics.noData") : "",
      },
    ];
    return menuItems;
  };

  return (
    <div
      className={`relative inline-flex rounded-lg font-medium ${width} ${height} bg-center bg-cover bg-black bg-opacity-40`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${projectThumbnail.s3_url})`,
      }}
      {...props}
    >
      <div className="px-3 pt-6 pr-2 pb-1 flex grow flex-col h-full justify-between w-full">
        <p className="text-left text-white leading-snug text-ellipsis overflow-clip card-title-outline h-2/5">
          {projectName}
        </p>
        {renderProperBlock()}
      </div>
      {showThreeDotsDropdownMenu && (
        <ThreeDotsDropdownMenu
          isEndUser
          menuItems={computeMenuItems()}
          handleClick={(e: any) => handleButtonClick(e, projectId)}
        />
      )}
      <div></div>
    </div>
  );
};

export default EnduserProjectCard;
