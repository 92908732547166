import ProjectScenes from "../../features/graph/ProjectScenes";
import { useParams } from "react-router";
import Node from "../../features/graph/components/NodeForm";
import ProjectSidebar from "../../common/components/Sidebar/ProjectSidebar";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../features/graph/state/graphuSlice";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";

export const ProjectGraph = () => {
  const isGraphLoading = useSelector(getIsLoading);

  return (
    <>
      {!isGraphLoading && <ProjectSidebar />}
      <ProjectScenes />
    </>
  );
};

export const NodeScreen = () => {
  const { nodeId, contentType } = useParams();

  const currentProject = useSelector(getCurrentProject);

  if (nodeId) {
    if (
      (currentProject.project_tags[0]?.slug === "custom-templates" &&
        currentProject.is_template === true) ||
      (currentProject.is_template === false && currentProject.status !== "Published")
    ) {
      return <Node nodeId={nodeId} contentType={contentType as string} mode="edit" />;
    } else {
      return <Node nodeId={nodeId} contentType={contentType as string} mode="view" />;
    }
  } else {
    return <>Node not found</>;
  }
};
