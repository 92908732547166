import { normalize, schema } from "normalizr";
import { IMedia } from "../../../model/unityObject";
import { Content } from "./dataTypes/Content";
import { ValidContentTypeObject } from "./dataTypes/ContentType";

// CONTENT
export const contentEntity = new schema.Entity<Content>("contents");

// MEDIA
export const mediaEntity = new schema.Entity<IMedia>("medias");

// CONTENT TYPE
export const contentTypeObjectEntity = new schema.Entity<ValidContentTypeObject>(
  "contentTypeObjects",
  {
    content: contentEntity,
    source_media: mediaEntity,
    source_image_2d: mediaEntity,
    source_image: mediaEntity,
    source_audio: mediaEntity,
    answer_1_image: mediaEntity,
    answer_2_image: mediaEntity,
    answer_3_image: mediaEntity,
    source_pdf: mediaEntity, // CULPRIT FOR CONTENT_PDF PAINFUL INTEGRATION !!
  },
);

type NormalizedContentTypeObjectEntities = {
  contents: { [id: number]: Content };
  contentTypeObjects: { [id: number]: ValidContentTypeObject };
  medias?: { [id: number]: IMedia };
};
export const normalizeContentTypeObject = (data: any) => {
  return normalize<ValidContentTypeObject, NormalizedContentTypeObjectEntities, number>(
    data,
    contentTypeObjectEntity,
  );
};
