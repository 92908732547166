import { Outlet, useLocation } from "react-router-dom";
import ModalConfirmUserDeletion from "src/common/components/Modal/ModalConfirmUserDeletion";
import Navigation from "src/common/components/Navigation/Navigation";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import { useSelector } from "react-redux";

const SpecificTemplateWrapper = () => {
  const location = useLocation();

  const currentProject = useSelector(getCurrentProject);

  const backDepth = () => {
    if (
      location.pathname.includes("background-music") ||
      location.pathname.includes("create/scene")
    ) {
      return 2;
    } else if (location.pathname.includes("node")) {
      return 3;
    } else {
      return 1;
    }
  };

  return (
    <>
      <Navigation
        title={currentProject.name}
        showGoBackButton={location.pathname !== "/templates/" && location.pathname !== "/templates"}
        backDepth={backDepth()}
      />

      <Outlet />
      <ModalConfirmUserDeletion />
    </>
  );
};

export default SpecificTemplateWrapper;
