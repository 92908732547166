import { useSelector } from "react-redux";
import { loadingSelector, LoadingState } from "../../state/slice/appStateSlice";
import { RootState } from "../../state/store";
import Spinner from "../Loader/Spinner";
import { Success } from "./Success";

export const useLoading = (componentId: string) => {
  const { loading } = useSelector((state: RootState) => {
    return {
      loading: loadingSelector(state, componentId),
    };
  });

  return loading;
};

/**
 * Display a Loader if app if loading and a success message if asyncChunk succeded
 * @param componentId
 * @param children (optional): If there if a children, we display it only if appState is Success or Idle
 * @param showSuccess (optional): show "success" message after the spin
 * @param spinnerPlaceholder (optional): should the spinner replace the child component?
 * @param spinnerStyle (optional): override spinner style
 * @param spinForDev (optional): to help the development
 */

export const IsLoading = ({
  componentId,
  children,
  showSuccess = true,
  spinnerPlaceholder = false,
  spinnerStyle = {},
  spinForDev = false,
}: {
  componentId: string;
  children?: JSX.Element | JSX.Element[];
  showSuccess?: boolean;
  spinnerPlaceholder?: boolean;
  spinnerStyle?: Record<string, any>;
  spinForDev?: boolean;
}) => {
  const loading = useLoading(componentId);
  if (!spinForDev && loading && loading === LoadingState.Success && showSuccess === true) {
    return <Success />;
  } else if (spinForDev || (loading && loading === LoadingState.Loading)) {
    return (
      <div className={spinnerPlaceholder ? "flex justify-center items-center w-full h-full" : ""}>
        {loading && loading === loading && (
          <Spinner relative={spinnerPlaceholder} style={spinnerStyle} />
        )}
      </div>
    );
  } else if (children) {
    return <>{children}</>;
  } else {
    return <div></div>;
  }
};
