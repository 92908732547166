import i18next from "i18next";

export const prettifyMediaType = ({
  type,
  media_360_tag,
}: {
  type: string;
  media_360_tag?: string;
}) => {
  let informalMediaType = "other";
  if (media_360_tag && media_360_tag !== "other") {
    switch (media_360_tag) {
      case "video180stereo":
        informalMediaType = "video180stereo";
        break;
      case "media360":
        if (type === "image") {
          informalMediaType = "image3d";
        }
        if (type === "video") {
          informalMediaType = "video3d";
        }
        break;
      case "media2d":
        if (type === "image") {
          informalMediaType = "image2d";
        }
        if (type === "video") {
          informalMediaType = "video2d";
        }
        break;
      default:
        break;
    }
  } else {
    switch (type) {
      case "image":
        informalMediaType = "image2d";
        break;
      case "video":
        informalMediaType = "video2d";
        break;
      case "model3d":
      case "audio":
      case "pdf":
        informalMediaType = type;
        break;
      default:
        informalMediaType = "other";
    }
  }

  return i18next.t(`pages.gallery.individualMediaType.${informalMediaType}`);
};
