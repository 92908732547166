import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useIsModalGallery = () => {
  const location = useLocation();
  const [isModalGallery, setIsModalGallery] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;
    setIsModalGallery(
      pathname.includes("/courses/") && pathname.includes("/list/") && pathname.includes("/graph"),
    );
  }, [location]);

  return isModalGallery;
};
