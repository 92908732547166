import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../common/state/store";
import apiUrls from "../../../api";
import { IContentType } from "../../../model/unityObject";

export interface ICTState {
  list: IContentType[];
}

const initialState: ICTState = {
  list: [],
};

export const contentTypesSlice = createSlice({
  name: "contentTypes",
  initialState,
  reducers: {
    setCts: (state: ICTState, action: any) => {
      state.list = [...action.payload];
    },
  },
});

export const { setCts } = contentTypesSlice.actions;

export const fetchCts = () => (dispatch: Dispatch) => {
  axios
    .get(`${apiUrls.projects.contens.contentType}`)
    .then((res: any) => {
      const response = res.data.map((ct: IContentType) => {
        // prettify "type" values (turning "_" into "-" ) so it can match our model names
        return {
          ...ct,
          type: String(ct.type).replace(/_/g, "-"),
          infoTitle: ct.title,
          infoText: ct.description,
        };
      });
      return dispatch(setCts(response));
    })
    .catch(console.error);
};

export const getCts = (state: RootState) => state.contentTypes.list;

export default contentTypesSlice.reducer;
