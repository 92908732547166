import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from "recharts";
import IProjectGroupsCompletionItem from "../../../enduserStats/dataTypes/IProjectGroupsCompletionItem";
import { roundScorePercentage } from "../../../../common/util/round";

interface Props {
  successRatePerProjectGroupSortedByLatestSession: IProjectGroupsCompletionItem[];
}

export default function EnduserSuccessRatePerCourse({
  successRatePerProjectGroupSortedByLatestSession = [],
}: Props) {
  const { t } = useTranslation();

  const prettyData = successRatePerProjectGroupSortedByLatestSession.map(
    (successRatePerProjectGroupSortedByLatestSession: IProjectGroupsCompletionItem) => {
      const percent = roundScorePercentage({
        score_max: successRatePerProjectGroupSortedByLatestSession.projectGroupMaxScore,
        score: successRatePerProjectGroupSortedByLatestSession.projectGroupScore,
      });
      return {
        name: successRatePerProjectGroupSortedByLatestSession.projectGroupName,
        percent,
      };
    },
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      {successRatePerProjectGroupSortedByLatestSession.length === 0 ? (
        <p className="no-data-message">{t("pages.statistics.noData")}</p>
      ) : (
        <BarChart
          width={600}
          height={300}
          data={prettyData}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 38, bottom: 40 }}
        >
          <XAxis type="number" tickFormatter={(tick) => `${tick}%`} domain={[0, 100]} />
          <YAxis type="category" dataKey="name" />
          <Tooltip />
          <Bar
            isAnimationActive={false}
            dataKey="percent"
            fill="#00C889"
            name={t(
              "pages.dashboard.enduserStatsOverview.successRatePerProjectGroupSortedByLatestSession",
            )}
          >
            <LabelList
              dataKey="percent"
              position={"insideRight"}
              fill="#FFFFFF"
              formatter={(x: any) => {
                return `${x}%`;
              }}
            />
          </Bar>
        </BarChart>
      )}
    </ResponsiveContainer>
  );
}
