import { Tooltip } from "@mui/material";
import * as React from "react";
import Icon from "../Icon/Icon";
import { TrashIcon } from "@heroicons/react/outline";

interface IUserCrudButtonsProps {
  onClickEdit(): any;
  editTooltip?: string;
  editDisabled?: boolean;
  editDisabledTooltip?: string;
  onClickDelete(): any;
  deleteTooltip?: string;
  deleteDisabled?: boolean;
  deleteDisabledTooltip?: string;
}

export const UserCrudButtons: React.FC<IUserCrudButtonsProps> = ({
  onClickEdit,
  editTooltip = "",
  editDisabled = false,
  editDisabledTooltip = "",
  onClickDelete,
  deleteTooltip = "",
  deleteDisabled = false,
  deleteDisabledTooltip = "",
}: IUserCrudButtonsProps) => {
  return (
    <div className="w-20">
      <Tooltip title={editDisabled && editDisabledTooltip ? editDisabledTooltip : editTooltip}>
        <span>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onClickEdit();
            }}
            disabled={editDisabled}
          >
            <Icon
              icon={"PencilAltIcon"}
              className={`w-6 h-6 mr-2 ${
                editDisabled ? "stroke-gray-400 cursor-not-allowed" : "stroke-green-500"
              }`}
            />
          </button>
        </span>
      </Tooltip>
      <Tooltip
        title={deleteDisabled && deleteDisabledTooltip ? deleteDisabledTooltip : deleteTooltip}
      >
        <span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickDelete();
            }}
            disabled={deleteDisabled}
            className={`${deleteDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <TrashIcon
              className={`w-6 h-6 mr-3 ${deleteDisabled ? "stroke-gray-500" : "stroke-red-500"}`}
            />
          </button>
        </span>
      </Tooltip>
    </div>
  );
};
