import PlatformIcon from "../../../common/components/Icon/PlatformIcon/PlatformIcon";
import { prettifyPlatformName } from "../../../common/util/prettifyPlatformName";

export const availablePlatforms = [
  "OculusQuest",
  "PicoNeo",
  "Android",
  "iPhone",
  "iPad",
  "MacOS",
  "Windows10",
  "WebGLiframe",
  "WebGLApp",
];

export interface IPurePlatformCard {
  id: string;
  component: any;
}

const PlatformCards = availablePlatforms.map((platformName: string, i: number) => {
  return {
    id: platformName,
    component: (
      <div key={`iii${i}`} className="flex flex-col items-center justify-center">
        <PlatformIcon platform={platformName} iconProps={{ width: 25, height: 25 }} />
        <p className="mt-2 font-bold text-sm">{prettifyPlatformName(platformName)}</p>
      </div>
    ),
  };
});

export default PlatformCards;
