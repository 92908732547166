const isStringLegalProjectName = (str: string) => {
  if (typeof str !== "string" || str === "") {
    return true; // empty string is allowed here but the "required" on the projectName validation blocks it.
  }
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      return false;
    }
  }
  return true;
};

export default isStringLegalProjectName;
