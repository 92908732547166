import { ISubscriptionFeature } from "src/model/store";

const PROABONO_CONSTANTS: { [key: string]: string } = {
  // Preprod values
  "488ba0a9-6b20-4fda-bfb5-fabf33d568a0": "number-of-available-projects",
  "9e51f23c-2900-4dc5-ab79-9ad1b6715c1f": "number-of-available-creator-accounts",
  "86eff4ee-3b98-4f5d-83b9-8b1e5bfe7d8d": "number-of-available-anonymous-session",
  "e096f0d1-d45b-4e29-b6ac-be8b6e41d433": "number-of-available-enduser-accounts",
  "3c467907-f7e6-4f80-b883-074a913cc9e9": "enduser-can-access-collab",
  "2d50d3df-9967-4c9f-adba-3625aba9acd2": "available-media-storage",
  "c21db757-7b88-4319-935c-760ddf856458": "moodle-integration",
  "eab32d82-7691-4e30-a141-b207d6b7b653": "blockade-labs-integration",
  "1202bc48-bc60-4f52-9303-99843381ae14": "wixar-ai-access",
  // Prod values
  "9930e250-2495-42d6-9c71-a4a2f7589a86": "number-of-available-projects",
  "4245c5b6-3ca7-4495-a9c6-5d6cda25a76e": "number-of-available-creator-accounts",
  "4c8c69f4-00f6-449d-b99e-c51d61efdcfe": "number-of-available-anonymous-session",
  "536d5a8c-6890-4c36-87fc-dbe94d240955": "number-of-available-enduser-accounts",
  "ab6abe45-99cb-4708-9ab9-ee8bdf5b5804": "enduser-can-access-collab",
  "ec25c0d8-b61b-4f35-8a5f-3f9a1f3f08ab": "available-media-storage",
  "3d489de3-e164-4ea7-ab76-8cc1af5ff393": "moodle-integration",
  "91e6a561-2275-492f-a51b-5610d2db9e42": "blockade-labs-integration",
  "56421e6c-5e9b-4858-9e71-97d777f7fc9b": "wixar-ai-access",
};

const featureMapper = (featureArray: ISubscriptionFeature[]) => {
  return featureArray.map((feature) => {
    const featureIdentifier = PROABONO_CONSTANTS[feature.ReferenceFeature];
    if (featureIdentifier) {
      return { ...feature, featureIdentifier };
    } else {
      return feature;
    }
  });
};

export default featureMapper;
