import { ReactElement } from "react";
import { Android, IPad, Iphone, MacOS, OculusQuest, Windows10, WebGL, Pico } from "./index";
import WebGLIframe from "./WebGLIframe";

interface Props {
  platform: string;
  iconProps?: any;
  inTable?: boolean;
}

export default function PlatformIcon({
  platform,
  iconProps = { width: "50px", height: "50px", fill: "black", style: "mr-fix-important" },
  inTable = false,
}: Props): ReactElement {
  switch (platform) {
    case "OculusQuest":
    case "Oculus Quest":
    case "Oculus Quest 2":
      return (
        <OculusQuest
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "PicoNeo":
      return (
        <Pico
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "Android":
      return (
        <Android
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "iPhone":
    case "IPhone":
      return (
        <Iphone
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "iPad":
    case "IPad":
      return (
        <IPad
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "MacOS":
      return (
        <MacOS
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "Windows10":
    case "Windows 64bit":
    case "Windows64bit":
    default:
      return (
        <Windows10
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "WebGLApp":
    case "WebGL App":
      return (
        <WebGL
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width}
          height={iconProps.height}
        />
      );
    case "WebGLiframe":
    case "WebGL iframe":
      return (
        <WebGLIframe
          {...iconProps}
          style={iconProps.style}
          width={iconProps.width ?? 55}
          height={iconProps.height ?? 55}
          inTable={inTable}
        />
      );
  }
}
