import * as AllIcons from "@heroicons/react/outline";
import { useState } from "react";
import IProjectGroupsCompletionItem from "../../../features/enduserStats/dataTypes/IProjectGroupsCompletionItem";
import { IProjectGroup } from "../../../model/model";
import ThreeDotsDropdownMenu, { menuItems } from "../Icon/ThreeDotsDropdownMenu";
import PGBadge from "../PGBadge/PGBadge";
import CirclePercent from "./CirclePercent";

interface ButtonSquareIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  projectGroup: IProjectGroup | IProjectGroupsCompletionItem;
  currentProjectGroup: number | undefined;
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  template?: boolean;
  isEnduser?: boolean;
}

const ButtonSquareIconsProjectGroupEnduser = ({
  template,
  projectGroup,
  currentProjectGroup,
  handleThreeDotButtonClick,
  isEnduser = false,
  ...props
}: ButtonSquareIconProps) => {
  const enduserPg = projectGroup as IProjectGroupsCompletionItem;
  const creatorPg = projectGroup as IProjectGroup;
  const pg = isEnduser
    ? {
        s3Url: enduserPg.projectGroupThumbnail.s3_url,
        name: enduserPg.projectGroupName,
        id: enduserPg.projectGroupId,
      }
    : {
        s3Url: creatorPg.source_thumbnail.s3_url,
        name: creatorPg.name,
        id: creatorPg.id,
      };

  const menuItems: menuItems = [
    { menuItemText: "Edit", icon: "PencilAltIcon" },
    { menuItemText: "Delete", icon: "TrashIcon" },
    // { menuItemText: "Favourite", icon: "StarIcon" },
    // { menuItemText: "Fait template", icon: "SaveAsIcon" },
  ];
  let starColor = "stroke-white";

  const [toggleStar, setToggleStar] = useState(false);

  if (toggleStar) {
    starColor = "fill-yellow-500 stroke-yellow-500";
  }

  if (pg.name.length > 28) {
    pg.name.substring(0, 28) + "...";
  }

  return (
    <button
      className="relative inline-flex rounded-lg font-medium w-full h-32 bg-center bg-cover bg-black bg-opacity-40 bg-blend-soft-light shadow-md hover:shadow-2xl hover:scale-105"
      style={{
        backgroundImage: isEnduser
          ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${pg.s3Url})`
          : `url(${pg.s3Url})`,
      }}
      {...props}
    >
      {isEnduser && enduserPg.isScorable && (
        <div className="w-full absolute flex justify-center top-[-1rem]">
          <div className="h-16">
            <CirclePercent
              isSucceded={enduserPg.hasPerfectScoreOnLatestBuild}
              withAnimation={false}
              percent={enduserPg.progressPercentageOnLatestBuild ?? 0}
              // eslint-disable-next-line no-constant-condition
              circleColor={
                enduserPg.progressPercentageOnLatestBuild >= 100
                  ? "rgb(0 200 137)"
                  : "rgb(250 204 21)"
              }
            />
          </div>
        </div>
      )}

      <div className="px-3 pt-2 pb-1 flex grow flex-col h-full justify-between w-full">
        <div
          className="flex justify-start invisible"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
          }}
        >
          <AllIcons.StarIcon
            onClick={() => setToggleStar(!toggleStar)}
            className={`w-6 h-6 ${starColor}`}
          />
        </div>
        <p className="text-left text-white leading-snug min-w-full text-ellipsis overflow-clip card-title-outline">
          {pg.name}
        </p>

        <div className="align-bottom">{!isEnduser && <PGBadge projectGroupId={pg.id} />}</div>
      </div>
      {!isEnduser && (
        <div
          className="absolute top-2 right-3 h-6 w-6 rounded-md"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
          }}
        >
          <ThreeDotsDropdownMenu
            menuItems={menuItems}
            handleClick={(e) =>
              handleThreeDotButtonClick({
                item: "projectGroup",
                itemId: pg.id,
                action: e.itemId,
              })
            }
          />
        </div>
      )}
    </button>
  );
};

export default ButtonSquareIconsProjectGroupEnduser;
