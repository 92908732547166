export interface ColorType {
  r: number;
  g: number;
  b: number;
  a: number;
}

export const parseHexColor = (color: string): ColorType => {
  const res: ColorType = { r: 0, g: 0, b: 0, a: 255 };

  if (color[0] === "#") {
    res.r = Number("0x" + color.slice(1, 3));
    res.g = Number("0x" + color.slice(3, 5));
    res.b = Number("0x" + color.slice(5, 7));

    if (color.length > 7) {
      res.a = Number("0x" + color.slice(7, 9));
    }
  }

  return res;
};

export const getHexColor = (color: ColorType, includeAlpha = true): string => {
  let res =
    "#" +
    color.r.toString(16).padStart(2, "0") +
    color.g.toString(16).padStart(2, "0") +
    color.b.toString(16).padStart(2, "0");
  if (includeAlpha) {
    res += color.a.toString(16).padStart(2, "0");
  }

  return res;
};
