import { useTranslation } from "react-i18next";
import PlaceholderWithIcon from "src/common/components/PlaceholderWithIcon/PlaceholderWithIcon";

export const NoMediaPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <PlaceholderWithIcon
      title={t("pages.gallery.noMediaFoundCheckFilters")}
      heroIcon="XCircleIcon"
      iconClassName="w-60 h-60"
    />
  );
};
