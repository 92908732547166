import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import InfoCard from "../../common/components/Cards/InfoCard";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import { useAppDispatch } from "../../common/state/hooks";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import { roundPercentage } from "../../common/util/round";
import { setAlert } from "../../features/alert/state/alertsSlice";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import ProjectCharts from "../../features/stats/components/ProjectCharts/ProjectCharts";
import {
  getEnduserStatsPerProject,
  getSelectedProjectId,
  getSelectedProjectWithSessions,
  getSelectedUserProjectScore,
  sendProjectResultsPerUser,
} from "../../features/stats/state/statsSlice";
import { ILanguage, ISession } from "../../model/model";
import { dateConfig } from "src/common/util/DateConfig";
import { languageConfig } from "src/common/util/langConfig";
import { Tooltip } from "@mui/material";

export const ENDUSER_STATS_PER_PROJECT_COMPONENT_ID = "enduserStatsPerProjectComponentId";
export const ENDUSER_STATS_PER_PROJECT_PAGE_ID = "enduserStatsPerProjectPageId";

const StatisticsDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const selectedProjectId = useSelector(getSelectedProjectId);
  const sessions = useSelector(getSelectedProjectWithSessions(selectedProjectId)).sessions;
  const relevantSessions = sessions.filter(
    (s: ISession) => s.enduser && Number(s.enduser.id) === Number(id),
  );
  const user = relevantSessions[0].enduser;
  const selectedUserProjectScore = useSelector(getSelectedUserProjectScore);

  const [averageDurationCompletedSession, setAverageDurationCompletedSession] = useState("");
  const [bestScore, setBestScore] = useState("");
  const [worstScore, setWorstScore] = useState("");

  // Relative ranking
  const numberOfSessions = String(relevantSessions.length);
  const countUniqueParticipants = new Set();

  // Completion rate
  const completionPercentage =
    relevantSessions.length / relevantSessions.filter((s: ISession) => s.is_completed).length;
  const prettyCompletionPercentage = roundPercentage(completionPercentage);

  useEffect(() => {
    // Average duration of completed session
    let duration = 0;
    relevantSessions.forEach((s: ISession) => {
      countUniqueParticipants.add(s.user_name);
      duration += s.total_session_duration;
    });
    setAverageDurationCompletedSession(`${Math.round(duration / relevantSessions.length)} s`);

    // Best & worst scores
    const sorted = [...relevantSessions].sort(
      (a: ISession, b: ISession) => Number(b.score) - Number(a.score),
    );
    setBestScore(String(sorted[0].score));
    setWorstScore(String(sorted[relevantSessions.length - 1].score) && "0");

    dispatch(
      getEnduserStatsPerProject({
        userId: user.id,
        projectId: selectedProjectId,
        componentId: ENDUSER_STATS_PER_PROJECT_COMPONENT_ID,
      }),
    );
  }, []);

  const sendProjectResToUser = () => {
    dispatch(closeConfirmationModal("sendResultsToUserAskConfirmation"));

    if (currentUser) {
      dispatch(
        sendProjectResultsPerUser({
          projectId: Number(selectedProjectId),
          trainerId: Number(currentUser.id),
          traineeId: user.id,
          to: "enduser",
          componentId: ENDUSER_STATS_PER_PROJECT_PAGE_ID,
        }),
      ).then(() => {
        dispatch(
          setAlert({
            type: "success",
            msg: t("pages.statistics.sendResultsEnduserConfirmation.body"),
          }),
        );
      });
    }
  };

  const openConfirmationModalWrapper = () => {
    dispatch(openConfirmationModal("sendResultsToUserAskConfirmation"));
  };

  return (
    <div className="container h-full w-full max-w-screen-2xl mx-auto px-4">
      <IsLoading
        componentId={ENDUSER_STATS_PER_PROJECT_PAGE_ID}
        showSuccess={false}
        spinnerPlaceholder
      />
      <div className="flex justify-between items-center mt-4">
        <h2 className="text-2xl font-medium">
          {user.firstname} {user.lastname}
        </h2>
        <Tooltip title={t("general.comingSoon")}>
          <button
            className="btn-primary-fill cursor-not-allowed"
            onClick={openConfirmationModalWrapper}
            disabled
          >
            {t("pages.statistics.sendResultsEnduser")}
          </button>
        </Tooltip>
      </div>
      <div className="w-full max-w-9xl mx-auto pt-6 sm:px-6 lg:px-8">
        <ProjectCharts projectId={selectedProjectId} userId={Number(id)} />
        <div className="grid grid-cols-3 gap-4 w-full mx-auto xl:px-0 py-6">
          <div className="row-span-2">
            <IsLoading
              componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.rank")}
                data={`${selectedUserProjectScore.rank}/${selectedUserProjectScore.competitors}`}
              />
            </IsLoading>
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.lastSessionDate")}
              data={`${new Date(selectedUserProjectScore.last_session).toLocaleString(
                dateConfig(currentLocale),
              )}`}
            />
          </div>
          <div className="row-span-2">
            <InfoCard title={t("pages.statistics.bestScore")} data={bestScore} />
          </div>

          <div className="row-span-2">
            <IsLoading
              componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.firstSessionScore")}
                data={
                  selectedUserProjectScore.firstSessionScoreAndMaxScoreString !==
                  "incomplete_session"
                    ? String(selectedUserProjectScore.firstSessionScoreAndMaxScoreString)
                    : t("pages.statistics.incompleteSession")
                }
              />
            </IsLoading>
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.completionRate")}
              data={`${prettyCompletionPercentage}% `}
            />
          </div>
          <div className="row-span-2">
            <InfoCard title={t("pages.statistics.worstScore")} data={worstScore} />
          </div>

          <div className="row-span-2">
            <IsLoading
              componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
              showSuccess={false}
              spinnerPlaceholder
            >
              <InfoCard
                title={t("pages.statistics.lastSessionScore")}
                data={
                  selectedUserProjectScore.lastSessionScoreAndMaxScoreString !==
                  "incomplete_session"
                    ? String(selectedUserProjectScore.lastSessionScoreAndMaxScoreString)
                    : t("pages.statistics.incompleteSession")
                }
              />
            </IsLoading>
          </div>
          <div className="row-span-2">
            <InfoCard title={t("pages.statistics.numberOfSessions")} data={numberOfSessions} />
          </div>
          <div className="row-span-2">
            <InfoCard
              title={t("pages.statistics.averageDurationCompletedSession")}
              data={averageDurationCompletedSession}
            />
          </div>
        </div>
      </div>

      <ModalConfirmation
        title={t("pages.statistics.sendResultsEnduserAskConfirmation.title")}
        text={t("pages.statistics.sendResultsEnduserAskConfirmation.body")}
        onClickSubmit={sendProjectResToUser}
        id={"sendResultsToUserAskConfirmation"}
      />
    </div>
  );
};

export default StatisticsDetail;
