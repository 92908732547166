import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "../../../features/profile/state/profileSlice";
import MobileUploadButton from "../Icon/MobileUploadButton";

export default function MobileHeader({ className = "" }: { className?: string }) {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const currentUser = useSelector(getCurrentUser);

  const deduceTitle = () => {
    switch (path) {
      case "":
      default:
        return `${t("pages.dashboard.hello")} ${currentUser?.firstname}`;
      case "upload":
        return t("general.upload");
      case "uploads":
        return t("sidebar.uploads");
    }
  };

  return (
    <div className={className}>
      <div className="flex max-h-full justify-between">
        <div className="max-h-full flex">
          <img
            className="h-[75px] w-[75px] m-1"
            src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/logo_wixar_blue.png"
            alt="wixar-logo"
          />
        </div>
        <h2 className="mt-6 text-center text-2xl font-bold">{deduceTitle()}</h2>
        <div className="h-[75px] w-[75px]">
          {path === "uploads" && <MobileUploadButton size="small" />}
        </div>
      </div>
    </div>
  );
}
