import i18next from "i18next";

/* To add a new localized error message, parse the server response & add a 'if' below */
export const prettifyErrorMessage = (errorString: string) => {
  const prettyError = {
    msg: i18next.t("error.genericServerError"),
    subMsg: "",
  };
  try {
    if (errorString.includes("bt_check_unique")) {
      prettyError.msg = i18next.t("error.userAlreadyExists");
      prettyError.subMsg = i18next.t("error.userAlreadyExists_tryAddingExistingCollaborator");
    }
    if (errorString.includes("no ssd exists")) {
      prettyError.msg = i18next.t("error.noSsdForThisProject");
    }
    if (
      errorString.includes("Upload failed: you're over the media size limit for your organization")
    ) {
      prettyError.msg = i18next.t("alerts.organizationExceedsUploadLimit");
      prettyError.subMsg = i18next.t("alerts.organizationExceedsUploadLimitSubtext");
    }
    if (errorString.includes("no_more_enduser_account_need_to_pay")) {
      prettyError.msg = i18next.t("alerts.organizationExceedsEnduserLimit");
      prettyError.subMsg = i18next.t("alerts.organizationExceedsEnduserLimitSubtext");
    }
    if (errorString.includes("maximum-creator-accounts-reached-for-this-org")) {
      prettyError.msg = i18next.t("alerts.organizationExceedsCreatorLimit");
      prettyError.subMsg = i18next.t("alerts.organizationExceedsCreatorLimitSubtext");
    }
    if (errorString.includes("is not vrReady")) {
      prettyError.msg = i18next.t("alerts.internalProblemWithThisModule");
      prettyError.subMsg = i18next.t("alerts.internalProblemWithThisModulePleaseContactAssistance");
    }
    if (errorString.includes("Existing user with same email address")) {
      prettyError.msg = i18next.t("alerts.unavailableEmail");

      let existingUserRoleName;

      try {
        const errorData = JSON.parse(errorString);
        existingUserRoleName = errorData?.error?.details?.existingUserRoleName;
      } catch (err) {
        console.error("Error parsing errorString:", err);
      }

      if (existingUserRoleName === "Enduser") {
        prettyError.subMsg = i18next.t("alerts.unavailableEmailSubTextParticipant");
      } else if (existingUserRoleName === "Creator") {
        prettyError.subMsg = i18next.t("alerts.unavailableEmailSubTextCollaborator");
      }
    }
    if (errorString.includes("A project with this name already exists in the project-group")) {
      prettyError.msg = i18next.t("alerts.aProjectWithThisNameAlreadyExistsInTheProjectGroup");
    }

    // Project related errors
    if (
      errorString.includes("project_missing_language") ||
      errorString.includes("project_missing_enabledLanguages") ||
      errorString.includes("mismatch_between_language_and_enabledLanguages_in_project") ||
      errorString.includes("project_missing_sourceThumbnail") ||
      errorString.includes("project_missing_sourceLogo") ||
      errorString.includes("missing_contentType_in_content") ||
      errorString.includes("undefined_actions_in_project") ||
      errorString.includes("missing_affectedContent_in_action") ||
      errorString.includes("affectedContent_isDeleted") ||
      errorString.includes("sourceMedia_isDeleted") ||
      errorString.includes("generic_getProject_failure") ||
      errorString.includes("generic_getFullProjectFlat_failure")
    ) {
      prettyError.msg = i18next.t("alerts.internalProblemWithThisModule");
      prettyError.subMsg = i18next.t("alerts.internalProblemWithThisModulePleaseContactAssistance");
    }
    if (errorString.includes("unlinked_scenes")) {
      prettyError.msg = i18next.t("alerts.warningUnlinkedScenes");
      prettyError.subMsg = i18next.t("alerts.warningUnlinkedScenesSubtext");
    }

    // CSV related errors
    if (errorString.includes("Invalid format check the format of the file")) {
      prettyError.msg = i18next.t("alerts.invalidFormatFileCsvOrXlsx");
    }
    if (errorString.includes("Invalid format of the file check if it is a .csv or .xlsx file")) {
      prettyError.msg = i18next.t("alerts.invalidFormatFile");
    }
    if (errorString.includes("Invalid format emails")) {
      prettyError.msg = i18next.t("alerts.invalidEmails");
    }
    if (errorString.includes("No users to add")) {
      prettyError.msg = i18next.t("alerts.noParticipantsToAdd");
    }
    if (errorString.includes("This email already exist")) {
      prettyError.msg = i18next.t("alerts.emailAlreadyExist");
    }
  } catch (error) {
    console.error(error);
  }
  return prettyError;
};
