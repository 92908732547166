import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Navigation from "../../common/components/Navigation/Navigation";

const ChatWrapper = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      title: t("pages.chat.tabs.scenarioHelper"),
      urlEndPoint: "scenario-helper",
      nonAdminCanAccess: true,
    },
    {
      title: t("pages.chat.tabs.wixarpedia"),
      urlEndPoint: "wixarpedia",
      nonAdminCanAccess: true,
    },
  ];

  return (
    <>
      <Navigation title={t("pages.chat.title")} tabs={tabs} />
      <Outlet />
    </>
  );
};

export default ChatWrapper;
