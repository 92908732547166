export const round = (number: number) => {
  return Math.round(number * 100 + Number.EPSILON) / 100;
};

export const roundPercentage = (float: number) => {
  return Math.round(Number(100 / float) * 100 + Number.EPSILON) / 100;
};

interface IRSP {
  score: number;
  score_max: number;
}

export const roundScorePercentage = ({ score, score_max }: IRSP) => {
  if (score === 0 || score_max === 0) {
    return 0;
  }
  return roundPercentage(score_max / score);
};
