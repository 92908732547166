import { useLocation } from "react-router-dom";
import { CreateOrUpdateUserAsAdmin } from "src/common/components/CreateOrUpdateUserAsAdmin/CreateOrUpdateUserAsAdmin";

const UpdateParticipant = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[location.pathname.split("/").length - 1];

  return <CreateOrUpdateUserAsAdmin mode="update" userRole="enduser" userId={Number(userId)} />;
};

export default UpdateParticipant;
