import ModalStandard from "../Modal/ModalStandard";
import AiGeneratedGraph from "./AIGeneratedGraph";

const AIGeneratedGraphModal = ({ markdown }: { markdown: string }) => {
  return (
    <ModalStandard
      id="aiGeneratedGraphModal"
      isStatic
      modalType="standard"
      className="w-[90%] h-auto"
    >
      <AiGeneratedGraph markdown={markdown} />
    </ModalStandard>
  );
};

export default AIGeneratedGraphModal;
