import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setUploadedFile } from "../../../features/gallery/state/gallerySlice";
import {
  computeUploadPanelParams,
  fromMediaTypeInferInputAcceptParam,
} from "../../../features/gallery/state/uploadUtil";
import { useAppDispatch } from "../../state/hooks";
import Icon from "./Icon";
import { setAlert } from "src/features/alert/state/alertsSlice";

export default function MobileUploadButton({ size = "large" }: { size: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const computeStyleValues = () => {
    const styleValues = {
      // defautl to large
      width: "100",
      height: "100",
      textSize: "text-2xl",
    };
    if (size === "small") {
      styleValues.width = "50";
      styleValues.height = "50";
      styleValues.textSize = "text-xs";
    }
    return styleValues;
  };

  const styleValues = computeStyleValues();

  return (
    <>
      <div
        className={`flex flex-col justify-center items-center ${size === "small" && "p-2"}`}
        onClick={() => {
          const hfi = hiddenFileInput?.current as any;
          hfi.click();
        }}
      >
        <svg
          className="svg"
          width={styleValues.width}
          height={styleValues.height}
          viewBox="0 0 100 100"
          fill="#00C889"
        >
          <g>
            <circle cx="50" cy="50" r="50"></circle>
          </g>
          <Icon icon="UploadIcon" className="text-white" viewBox="0 0 40 40" x="20" y="20" />
        </svg>
        <p className={`text-green-500 text-center font-extrabold ${styleValues.textSize}`}>
          {t("pages.project.importMedia")}
        </p>
      </div>

      <input
        id="upload-input"
        key={inputKey}
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        accept={fromMediaTypeInferInputAcceptParam("all")}
        onChange={(e: any) => {
          try {
            const uploadedFile = e.target.files[0] as File;

            const cupp = computeUploadPanelParams({ file: uploadedFile, from: "graph" });
            dispatch(
              setUploadedFile({
                file: uploadedFile,
                metadata: cupp,
              }),
            );

            navigate("/upload");
            setInputKey(Date.now());
          } catch (error) {
            console.error(error);
            dispatch(setAlert({ type: "warning", msg: t("general.extensionNotAccepted") }));
          }
        }}
      ></input>
    </>
  );
}
