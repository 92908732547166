export type BadgeProps = {
  color: string;
  textColor: string;
  text: string;
};
const Badge = ({
  color,
  textColor,
  text,
  className = null,
}: BadgeProps & { className?: string | null }) => {
  return (
    <span
      className={`inline-flex items-center justify-center ${
        className?.includes("p-") || className?.includes("px-") ? "" : "px-2"
      } ${
        className?.includes("p-") || className?.includes("py-") ? "" : "py-1"
      } text-xs font-medium leading-none ${textColor} ${color} rounded-full ${className}`}
    >
      {text}
    </span>
  );
};

export default Badge;
