import { XIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";

export interface ICustomRemoveButton {
  component: React.ElementType;
  customProps?: Record<string, any>;
}

const CustomXIcon: React.FunctionComponent = (props: any) => {
  const { className, tooltipText } = props;
  return (
    <Tooltip title={tooltipText}>
      <XIcon className={`${className}`} aria-hidden="true" {...props} />
    </Tooltip>
  );
};

export default CustomXIcon;
