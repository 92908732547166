import * as AllIcons from "@heroicons/react/outline";

const ChevronRightIconButton = ({
  className,
  visibleScrollBarPG = true,
  scrollRight,
  onClick,
}: {
  className?: string;
  visibleScrollBarPG?: boolean;
  scrollRight?: (direction: string) => void;
  onClick?: () => void;
}) => {
  const defaultClassNames = `absolute top-24 w-6 h-6 ${
    visibleScrollBarPG
      ? "right-2 min-w-0 mr-2 btn-primary-fill rounded-[50%]  p-1 mt-1 ml-3"
      : "right-4"
  }`;

  return (
    <button
      className={`bg-green-500 rounded-full flex justify-center items-center ${
        className ? className : defaultClassNames
      }`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (scrollRight) {
          scrollRight("right");
        }
      }}
    >
      <AllIcons.ChevronRightIcon stroke="white" height={"1.7vh"} width={"1.7vh"} />
    </button>
  );
};

export default ChevronRightIconButton;
