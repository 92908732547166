import { useSelector } from "react-redux";
import {
  getCurrentMediaGalleryId,
  getOpenedSlider,
  getOriginalMediaGalleryId,
  getUploadedFile,
  setOpenedSlider,
  setUploadedFile,
  setUploadedOldFile,
} from "../../../features/gallery/state/gallerySlice";
import { useAppDispatch } from "../../state/hooks";
import { closeStandardModal, openConfirmationModal } from "../../state/slice/modal/modalSlice";
import { XIcon } from "@heroicons/react/outline";
import { RootState } from "../../state/store";
interface CloseButtonProps {
  isAvatarField?: boolean;
}
const CloseButton = ({ isAvatarField = false }: CloseButtonProps) => {
  const dispatch = useAppDispatch();

  const openedSlider = useSelector(getOpenedSlider);
  const uploading = useSelector(getUploadedFile);

  const { originalMediaGalleryId, currentMediaGalleryId } = useSelector((state: RootState) => {
    return {
      originalMediaGalleryId: getOriginalMediaGalleryId(state),
      currentMediaGalleryId: getCurrentMediaGalleryId(state),
    };
  });

  const onGalleryClose = () => {
    if (Number(originalMediaGalleryId) !== Number(currentMediaGalleryId)) {
      dispatch(openConfirmationModal("confirmCloseGallery"));
    } else {
      dispatch(closeStandardModal("modalGallery"));
    }
  };

  return (
    <button
      id="gallery-close-button"
      className="z-50 top-2 right-4 absolute"
      type="button"
      onClick={() => {
        if (uploading || (openedSlider && openedSlider !== "")) {
          dispatch(setUploadedFile(undefined));
          dispatch(setUploadedOldFile(undefined));
          dispatch(setOpenedSlider(""));
        } else if (isAvatarField) {
          dispatch(closeStandardModal("modalPreviewAvatar3d"));
        } else {
          onGalleryClose();
        }
      }}
    >
      <XIcon className="w-6 h-6" />
    </button>
  );
};

export default CloseButton;
