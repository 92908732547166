import { isStringNullOrEmpty } from "../../util/isStringNullOrEmpty";
import Icon, { IconName } from "../Icon/Icon";

interface ButtonIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  icon: IconName;
  styled: string;
  iconeDivStyle?: string;
  marginRight?: string;
  iconeSize?: string;
}

const ButtonIcon = ({
  text = "",
  icon,
  styled,
  iconeDivStyle = "flex flex-row items-center",
  marginRight = "mr-2",
  iconeSize = "w-5 h-5",
  ...props
}: ButtonIconProps) => {
  return (
    <button className={styled} {...props}>
      <div className={iconeDivStyle}>
        <div className={`${marginRight} flex items-center`}>
          <Icon icon={icon} className={iconeSize} />
          {!isStringNullOrEmpty(text) && <div className="ml-1">{text}</div>}
        </div>
      </div>
    </button>
  );
};

export default ButtonIcon;
