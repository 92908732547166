import * as React from "react";
import { Tooltip } from "../Tooltip/Tooltip";

interface IAddNewUserProps {
  className?: string;
  icon: JSX.Element;
  title: string;
  subtitle: JSX.Element;
  buttonText: string;
  buttonDisabled: boolean;
  buttonDisabledTooltip: string;
  buttonOnClick(): any;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDisabledTooltip?: string;
  additionalButtonOnClick?(): any;
}

export const AddNewUserCard: React.FC<IAddNewUserProps> = ({
  className,
  icon,
  title,
  subtitle,
  buttonText,
  buttonDisabled,
  buttonDisabledTooltip = "",
  buttonOnClick,
  additionalButtonText,
  additionalButtonDisabled,
  additionalButtonDisabledTooltip,
  additionalButtonOnClick,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-evenly bg-white rounded-lg h-64 w-[30vw] m-4 ${className}`}
    >
      {icon}
      <h4 className="text-xl font-medium text-center mb-[-2vh]">{title}</h4>
      <div className="mt-0">{subtitle}</div>
      <div className="flex w-full justify-evenly">
        <Tooltip message={buttonDisabled ? buttonDisabledTooltip : ""} forceSingleLine={false}>
          <button
            className={`btn-primary-fill ${buttonDisabled && "cursor-not-allowed"}`}
            onClick={buttonOnClick}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        </Tooltip>
        {additionalButtonText && (
          <Tooltip
            message={
              additionalButtonDisabled && additionalButtonDisabledTooltip
                ? additionalButtonDisabledTooltip
                : ""
            }
            forceSingleLine={false}
          >
            <button
              className={`btn-primary-outline ${additionalButtonDisabled && "cursor-not-allowed"}`}
              onClick={additionalButtonOnClick}
              disabled={additionalButtonDisabled}
            >
              {additionalButtonText}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
