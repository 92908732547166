import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import PlaceholderWithIcon from "../../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import TableComp from "../../../common/components/Table/Table";
import { prettifyDuration } from "../../../common/util/prettifyDuration";
import { ISessionDetail } from "../dataTypes/ISessionDetailForGivenProject";
import { getEnduserSessionDetailForGivenProject } from "../state/enduserStatsSlice";
import { ENDUSER_PROJECT_SESSION_DETAIL_COMPONENT } from "./EnduserProjectCard";
import EnduserScoreCompletionBar from "./EnduserScoreCompletionBar";
import EnduserScoreWithPotentialTrophy from "./EnduserScoreWithPotentialTrophy";

type Props = {
  projectGroupName: string;
  projectName: string;
};

export default function EnduserSessionDetails({ projectGroupName, projectName }: Props) {
  const { t } = useTranslation();
  const sessionDetails = useSelector(getEnduserSessionDetailForGivenProject);
  console.log("sessionDetails huh ? ", sessionDetails);
  const colHeaders = sessionDetails?.isProjectScorable
    ? [
        t("pages.enduserSessionDetail.sessionDate"),
        t("pages.enduserSessionDetail.sessionDuration"),
        t("pages.enduserSessionDetail.completion"),
        t("pages.enduserSessionDetail.score"),
      ]
    : [
        t("pages.enduserSessionDetail.sessionDate"),
        t("pages.enduserSessionDetail.sessionDuration"),
      ];

  const rows = sessionDetails?.sessions.map((s: ISessionDetail, i: number) => {
    const prettyDate = new Date(s.created_at_ts * 1000).toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });

    if (sessionDetails?.isProjectScorable) {
      return [
        prettyDate,
        prettifyDuration(s.duration),
        <EnduserScoreCompletionBar
          key={`${s.created_at_ts * 1000}_${i}`}
          completion={Number(s.progressPercentage)}
        />,
        <EnduserScoreWithPotentialTrophy
          key={`${s.created_at_ts * 1000}__${i}`}
          score={String(s.score)}
          isPerfectScore={Boolean(s.isPerfectScore)}
        />,
      ];
    } else {
      return [prettyDate, prettifyDuration(s.duration)];
    }
  });

  return (
    <IsLoading
      componentId={ENDUSER_PROJECT_SESSION_DETAIL_COMPONENT}
      showSuccess={false}
      spinnerPlaceholder
    >
      <div className="flex w-full h-full flex-col mt-[-4vh] z-0">
        <h2 className="mt-2 mb-2 text-2xl font-medium">
          {t("pages.enduserSessionDetail.details")}: {projectGroupName} - {projectName}
        </h2>
        <h3 className="mt-2 mb-2 text-xl font-medium">Sessions</h3>
        {rows.length > 0 ? (
          <TableComp colHeaders={colHeaders} rows={rows} checkAll={null} className="w-full" />
        ) : (
          <div className="flex w-full h-3/5 justify-center items-center">
            <hr className="bg-gray-300 h-[2px]" />

            <PlaceholderWithIcon
              title={t("pages.enduserSessionDetail.noSession")}
              text={""}
              heroIcon="InformationCircleIcon"
              className="flex flex-col items-center h-[6pc]"
            />
          </div>
        )}
      </div>
    </IsLoading>
  );
}
