import { useState, useEffect, RefObject } from "react";

const useScrollbarVisibility = (
  elementRef: RefObject<HTMLElement>,
  vertical?: boolean,
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const { scrollWidth, clientWidth, scrollHeight, clientHeight } = elementRef.current;
      const isVisible = vertical
        ? clientHeight > 0 && scrollHeight > clientHeight
        : clientWidth > 0 && scrollWidth > clientWidth;

      setIsVisible(isVisible);
    }
  }, [elementRef, vertical]);

  return isVisible;
};

export default useScrollbarVisibility;
