import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/common/state/hooks";
import Chatbot from "src/features/chatbot/components/Chatbot";
import GptConversationLoader, {
  GPT_CONVERSATION_LOADER_COMPONENT_ID,
} from "src/features/chatbot/components/GptConversationLoader";
import {
  setActiveGptConversation,
  getGptConversationsSortedByRecency,
  getActiveGptConversation,
  fetchGptConversations,
  resetChat,
} from "src/features/chatbot/state/chatbotSlice";

const ScenarioHelperChat: React.FC = () => {
  const dispatch = useAppDispatch();
  const userPreviousGptConversations = useSelector(getGptConversationsSortedByRecency);
  const userActiveGptConversation = useSelector(getActiveGptConversation);

  const handleConversationSelect = (conversationId: number) => {
    const selectedConversation = userPreviousGptConversations.find(
      (conv) => conv.id === conversationId,
    );
    if (selectedConversation) {
      dispatch(setActiveGptConversation(selectedConversation));
    }
  };

  const handleResetChat = () => {
    dispatch(resetChat({ cb: resetChatLocally }));
  };

  const resetChatLocally = () => {
    setActiveGptConversation(null);
  };

  useEffect(() => {
    dispatch(fetchGptConversations({ componentId: GPT_CONVERSATION_LOADER_COMPONENT_ID }));
  }, [dispatch]);

  return (
    <div className="flex h-full w-full">
      <div className="w-1/4 bg-gray-100 pr-4 pl-4 pt-1">
        <GptConversationLoader
          onConversationSelect={handleConversationSelect}
          onResetChat={handleResetChat}
        />
      </div>

      <div className="w-3/4">
        <Chatbot mode="scenarioHelper" activeConversation={userActiveGptConversation} />
      </div>
    </div>
  );
};

export default ScenarioHelperChat;
