import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import ProjectLanguage from "./ProjectLanguage";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";

const EditProjectLanguageWrapper = () => {
  const { t } = useTranslation();

  const selectedProject = useSelector(getCurrentProject);

  return (
    <>
      <Navigation
        title={`${t("pages.editProjectName.title")}: ${selectedProject.name}`}
        isProjectView={true}
      />
      <ProjectLanguage mode="edit-language" />
    </>
  );
};

export default EditProjectLanguageWrapper;
