import { IProject } from "../../../model/model";
import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from "react";
import ButtonSquareIconsProjectDraggable from "../../../common/components/Button/ButtonSquareIconsProjectDraggable";
import { IProjectOrder } from "../../projectGroups/state/projectGroupsSlice";

type ProjectListChangeOrderProps = {
  projects: IProject[];
  componentId?: string;
  isEnduser?: boolean;
  setOrder: (tab: Array<IProjectOrder>) => any;
};

const ProjectListChangeOrder = ({
  projects: projectGroups = [],
  setOrder,
}: ProjectListChangeOrderProps) => {
  const projectGroupList = projectGroups;
  const [pgList, setPgList] = useState(projectGroupList);

  useEffect(() => {
    const l: Array<IProjectOrder> = pgList.map((elt, index) => {
      return { order: index + 1, projectId: elt.id };
    });
    setOrder(l);
  }, [pgList]);

  return (
    <ReactSortable
      list={pgList.map((x) => ({ ...x, chosen: true }))}
      setList={setPgList}
      className={
        "w-full max-w-9xl grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-10 mx-auto"
      }
    >
      {pgList.map((project: IProject, i: number) => {
        return (
          <div className="cursor-grabbing" key={`project-reorder-${project.id}`}>
            <ButtonSquareIconsProjectDraggable
              handleThreeDotButtonClick={() => null}
              currentProject={project.id}
              project={project}
              showThreeDotsButton={false}
              index={i + 1}
            />
          </div>
        );
      })}
    </ReactSortable>
  );
};

export default ProjectListChangeOrder;
