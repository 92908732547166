import Icon, { IconName } from "../Icon/Icon";

interface Props {
  title: string;
  text?: string;
  heroIcon: IconName;
  className?: string;
  iconClassName?: string;
  stroke?: string;
  decorationTitle?: string;
  strokeWidth?: number;
}

export default function PlaceholderWithIcon({
  title,
  text = "",
  heroIcon,
  className = "flex flex-col items-center h-2/5",
  iconClassName = "w-full",
  stroke = "rgb(209 213 219)",
  strokeWidth = 2,
  decorationTitle = "font-bold",
}: Props) {
  return (
    <span className={className}>
      <Icon strokeWidth={strokeWidth} stroke={stroke} icon={heroIcon} className={iconClassName} />
      <h1 className={`font-bold ${decorationTitle}`}>{title}</h1>
      {text && <h6>{text}</h6>}
    </span>
  );
}
