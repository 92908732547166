import { ReactElement } from "react";

interface Props {
  style: string;
  width: number;
  height: number;
  fill: string;
  stroke: string;
}

export default function Android({ width, height, fill, style, stroke }: Props): ReactElement {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.58 40"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            id="Android"
            className="cls-1 "
            d="M18.54,37.62V31.94H15.06v5.68A2.38,2.38,0,0,1,12.69,40h0a2.38,2.38,0,0,1-2.37-2.37h0V31.94H7.39a1.63,1.63,0,0,1-1.63-1.63V13.5c4.94,0,16.45,0,22.08,0V30.31a1.63,1.63,0,0,1-1.63,1.63H23.29v5.68A2.39,2.39,0,0,1,20.91,40h0a2.38,2.38,0,0,1-2.37-2.38Zm10.3-11.87V15.38A2.38,2.38,0,0,1,31.21,13h0a2.37,2.37,0,0,1,2.37,2.37h0V25.75a2.37,2.37,0,0,1-2.36,2.37h0a2.36,2.36,0,0,1-2.37-2.36ZM0,25.75V15.38A2.38,2.38,0,0,1,2.37,13h0a2.37,2.37,0,0,1,2.37,2.37h0V25.75a2.37,2.37,0,0,1-4.74,0ZM5.65,12.44a11.34,11.34,0,0,1,5.71-8.61L9.69.58a.37.37,0,0,1,.66-.34L12,3.49a11.16,11.16,0,0,1,9.45,0L23.36.19a.36.36,0,0,1,.5-.14h0A.38.38,0,0,1,24,.56h0L22.14,3.78a11.34,11.34,0,0,1,5.8,8.67ZM20.92,7.57a.93.93,0,0,0,.93.92h0a.93.93,0,0,0,.93-.92h0a.93.93,0,0,0-.93-.93h0a.93.93,0,0,0-.93.93Zm-10.08,0a.92.92,0,0,0,.93.92h0a.93.93,0,0,0,0-1.85.93.93,0,0,0-.93.93Z"
          />
        </g>
      </g>
    </svg>
  );
}
