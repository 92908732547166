export const prettifyFileSizeInBytes = (
  sizeInBytes: number,
  language: "en" | "fr",
  fractionDigits = 2,
) => {
  let suffix = "B";
  if (language === "fr") {
    suffix = "o";
  }

  if (sizeInBytes < 1024) {
    return `${sizeInBytes.toFixed(fractionDigits)} ${suffix}`;
  } else if (sizeInBytes >= 1024 && sizeInBytes < 1048576) {
    // 1048576 = 1024 bytes * 1024 bytes = 1 MB
    return `${(sizeInBytes / 1024).toFixed(fractionDigits)} K${suffix}`;
  } else if (sizeInBytes >= 1048576 && sizeInBytes < 1073741824) {
    // 1073741824 = 1024 * 1024 * 1024 = 1GB
    return `${(sizeInBytes / 1048576).toFixed(fractionDigits)} M${suffix}`;
  } else {
    return `${(sizeInBytes / 1073741824).toFixed(fractionDigits)} G${suffix}`;
  }
};
