import { IProject } from "../../../model/model";

const canSaveProject = (project: IProject) => {
  if (project.status === "Published" || project.status === "PublishWaiting") {
    return { canSave: false, reason: "pages.project.editNode.noEditableProject" };
  }
  return { canSave: true, reason: "" };
};

export default canSaveProject;
