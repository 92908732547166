import { MediaList } from "./components/MediaList";
import { Nav, regexIsNodeContentScene } from "./components/Nav";
import { MediaDetailContainer } from "./components/MediaDetail";
import { Actions } from "./components/Actions";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterMediaResults } from "./state/filterUtil";
import { AppDispatch, RootState } from "../../common/state/store";
import { IMedia } from "../../model/unityObject";
import {
  currentFilterSelector,
  mediaSelector,
  setCurrentMediaSidebar,
  mediaByProjectSelector,
  getCurrentMediaGalleryId,
  getOriginalMediaGalleryId,
  setCurrentMediaGallery,
  getUploadedFile,
  getIsGalleryLoaded,
  setUploadedFile,
  setUploadedOldFile,
  getCurrentModeListGallery,
  getAllMedia,
} from "./state/gallerySlice";
import { currentOrgSelector } from "../../common/state/selectors/authSelector";
import {
  closeConfirmationModal,
  closeSlider,
  openConfirmationModal,
  openSlider,
} from "../../common/state/slice/modal/modalSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { GenerateMediaPanel } from "./components/GenerateMediaPanel";
import { UploadPanel, UPLOAD_SLIDEOVER_COMPONENT_ID } from "./components/UploadPanel";
import Spinner from "../../common/components/Loader/Spinner";
import { getNodeById } from "../graph/state/graphLegacy";
import { useLocation } from "react-router-dom";
import { isStringNullOrEmpty } from "src/common/util/isStringNullOrEmpty";
import {
  computeUploadPanelParams,
  deduceMediaType,
  fromMediaTypeInferInputAcceptParam,
} from "./state/uploadUtil";
import { setAlert } from "../alert/state/alertsSlice";
import { FolderDownloadIcon } from "@heroicons/react/outline";
import InfoButton from "src/common/components/InfoButton/InfoButton";
import { useIsModalGallery } from "src/common/util/isModalGallery";

export const MEDIA_LIST_COMPONENT_ID = "mediaListComponent";

export const Gallery = ({
  isMediaPickerMode = false,
  onConfirmSelectMedia,
  onCancel,
  nodeId,
  isModalEdit,
  contentType,
  title = "",
}: {
  isModalEdit?: boolean;
  projectId?: string;
  // We can use the gallery to select media in graph explorer. Set this to true to do it
  isMediaPickerMode?: boolean;
  onConfirmSelectMedia?: (media: IMedia) => void;
  onCancel?: () => void;
  nodeId?: string;
  contentType?: string;
  title?: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const nodeTypeScene = useSelector((state: RootState) =>
    getNodeById(state, String(nodeId), contentType),
  );
  const { t } = useTranslation();
  const isModalGallery = useIsModalGallery();

  const dispatch: AppDispatch = useDispatch();
  const Modal_ID = "media-detail-slideover";

  const [mediaToDisplay, setMediaToDisplay] = useState<IMedia[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [opacity, setOpacity] = useState("opacity-0");

  const {
    allMedias,
    mediaByProject,
    currentFilter,
    originalMediaGalleryId,
    currentMediaGalleryId,
    isGalleryLoaded,
    org,
  } = useSelector((state: RootState) => {
    return {
      allMedias: mediaSelector(state), // use this to get all medias
      mediaByProject: mediaByProjectSelector(state),
      currentFilter: currentFilterSelector(state),
      originalMediaGalleryId: getOriginalMediaGalleryId(state),
      currentMediaGalleryId: getCurrentMediaGalleryId(state),
      org: currentOrgSelector(state),
      isGalleryLoaded: getIsGalleryLoaded(state),
    };
  });

  useEffect(() => {
    if (!isGalleryLoaded) {
      dispatch(getAllMedia({ orgId: org.id }));
    }
  }, []);

  const currentModeListGallery = useSelector(getCurrentModeListGallery);
  const uploading = useSelector(getUploadedFile);
  const currentMediaFilter = useSelector(currentFilterSelector) as any;

  const { pathname } = useLocation();
  const openMediaDetail = (mediaId: number) => {
    dispatch(openSlider(Modal_ID));
    dispatch(setCurrentMediaSidebar(mediaId));
  };

  useEffect(() => {
    dispatch(setUploadedFile(undefined));
    dispatch(setUploadedOldFile(undefined));
    dispatch(closeSlider(""));
  }, []);

  useEffect(() => {
    const isAll = true;
    // Here we can only display the media for one project or all the medias
    setMediaToDisplay(filterMediaResults(isAll ? allMedias : mediaByProject, currentFilter));
  }, [currentFilter, allMedias]);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollIntoView();
    }
  }, [currentFilter]);

  useEffect(() => {
    dispatch(setCurrentMediaGallery(originalMediaGalleryId));

    const sc = () => {
      const el = document?.getElementById("gallery-selected-media-target");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      } else {
        setTimeout(sc, 100);
      }
    };

    if (nodeId) {
      sc();
    }
  }, [originalMediaGalleryId]);

  const onCancelWrapper = () => {
    if (Number(originalMediaGalleryId) !== Number(currentMediaGalleryId)) {
      dispatch(openConfirmationModal("confirmCloseGallery"));
    } else {
      if (onCancel) {
        dispatch(closeConfirmationModal("confirmCloseGallery"));
        onCancel();
      }
    }
  };

  useEffect(() => {
    if (uploading) {
      dispatch(openSlider(UPLOAD_SLIDEOVER_COMPONENT_ID));
    } else {
      dispatch(closeSlider(UPLOAD_SLIDEOVER_COMPONENT_ID));
    }
  }, [uploading]);
  const currentNode =
    Number(nodeId) !== 0
      ? useSelector((state: RootState) => getNodeById(state, nodeId ? nodeId : "-1"))
      : null;

  //filter gif id content-scene

  const isNodeContentScene = currentNode?.modelName === "content-scene";
  const mediaIsContentScene = () => {
    if (regexIsNodeContentScene(pathname) || isNodeContentScene) {
      return mediaToDisplay.filter(
        (media: IMedia) =>
          media.upload_status !== "transcoding" &&
          media.filename.slice(media.filename.lastIndexOf(".")) !== ".gif",
      );
    } else {
      return mediaToDisplay.filter((media: IMedia) => media.upload_status !== "transcoding");
    }
  };

  // drag mode
  const dynamicWarningDragAndDrop = () => {
    if (!nodeId) {
      return t("general.extensionNotAccepted");
    }

    switch (nodeTypeScene?.modelName) {
      case "content-video-2-d":
        return t("general.extensionNotAcceptedVideo2d");
      case "content-audio":
        return t("general.extensionNotAcceptedAudio");
      case "content-scene":
        return currentFilter?.mediaType === "model3d"
          ? t("general.extensionNotAccepted3d")
          : t("general.extensionNotAcceptedImageAndVideo");
      case "content-thumbnail":
      case "content-onboarding":
        return t("general.extensionNotAcceptedImage");
      case "content-3d-viewer":
        return t("general.extensionNotAccepted3d");
      default:
        return t("general.extensionNotAccepted");
    }
  };

  const alertExtension = (e: any) => {
    dispatch(
      setAlert({
        msg: dynamicWarningDragAndDrop(),
        type: "warning",
        isOpen: true,
        customTimeoutInMs: 20000,
      }),
    );
    e.dataTransfer.files[0] = null;
  };

  let selectedFileType = "all";
  if (currentNode) {
    if (currentNode.modelName === "content-scene" && currentMediaFilter?.mediaType !== "model3d") {
      selectedFileType = "content-scene";
    } else {
      selectedFileType = currentMediaFilter?.mediaType;
    }
  }
  if (regexIsNodeContentScene(pathname)) {
    selectedFileType = "content-scene";
  }

  const isNodeContentSceneOrRegex = isNodeContentScene || regexIsNodeContentScene(pathname);

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      setOpacity("opacity-1");
    }, 300);
    setIsDragging(true);
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpacity("opacity-0");
    setIsDragging(false);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setIsDragging(false);
    setOpacity("opacity-0");

    const extension = event.dataTransfer.files[0]?.name.slice(
      event.dataTransfer.files[0]?.name.lastIndexOf("."),
    );

    const testIfExtensionIsGif = () => {
      if (isNodeContentSceneOrRegex) {
        if (extension === ".gif") {
          return true;
        }
      }
      return false;
    };

    let currentFileType = event.dataTransfer.files[0].type;
    if (isStringNullOrEmpty(currentFileType)) {
      const splitFileName = event.dataTransfer.files[0].name.split(".");
      if (splitFileName.length > 0) {
        try {
          const type = deduceMediaType(splitFileName[splitFileName.length - 1]);
          currentFileType = type + "/" + splitFileName[splitFileName.length - 1];
        } catch {
          currentFileType = "";
        }
      }
    }

    const splitInArray = fromMediaTypeInferInputAcceptParam(selectedFileType).split(",");
    const testExtensions = !splitInArray.includes(currentFileType);

    if (testExtensions || extension === ".m4v" || testIfExtensionIsGif()) {
      alertExtension(event);
    } else {
      const uploadedFile = event.dataTransfer.files[0] as File;
      const cupp = computeUploadPanelParams({ file: uploadedFile, from: "graph" });
      dispatch(setUploadedFile({ file: uploadedFile, metadata: cupp }));
    }
  };

  return (
    <div>
      <InfoButton relevantArticle="helpWithMediaExtensions" />

      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`  absolute  block  flex w-full h-full  ${
          isDragging
            ? `dragging bg-white ${opacity} ease-in-out duration-[900ms] border-2 border-green-500`
            : ""
        }`}
      >
        {/* hierarchy problem with divs and the draging is disabled when colliding with other divs */}
        {isDragging ? (
          <div className="pointer-events-none w-ful h-full mx-auto">
            <div className="pointer-events-none italic text-5xl text-white flex flex-col items-center mt-96 font-bold z-[999] text-gray-500 ">
              {t("general.dragFilesHere")}
              <div className="text-xl mt-2 text-gray-500">{t("general.dragAndDropText")}</div>

              <FolderDownloadIcon className="w-20 h-20 stroke-green-500 mt-6" />
            </div>
          </div>
        ) : (
          <div ref={wrapperRef} className="flex w-full h-full">
            {!isGalleryLoaded ? (
              <Spinner />
            ) : (
              <>
                <div className="w-full gallery h-full flex flex-col">
                  <div ref={navbarRef}>
                    <Nav
                      title={title}
                      nodeId={nodeId ?? "0"}
                      mediaForSearchTitle={mediaToDisplay}
                    />
                  </div>

                  <div className="flex flex-col flex-grow overflow-y-auto h-full  mediaListWrapper">
                    <MediaList
                      medias={mediaIsContentScene()}
                      isModalEdit={isModalEdit}
                      listMode={isModalGallery ? false : currentModeListGallery}
                      openMediaDetail={openMediaDetail}
                      currentMedia={currentMediaGalleryId}
                      isMediaPickerMode={isMediaPickerMode}
                    />
                    {onConfirmSelectMedia && (
                      <Actions
                        onConfirmSelectMedia={onConfirmSelectMedia}
                        onCancel={onCancelWrapper}
                      />
                    )}
                  </div>

                  <ModalConfirmation
                    title={t("pages.gallery.cancelConfirmationModal.title")}
                    text={t("pages.gallery.cancelConfirmationModal.body")}
                    onClickSubmit={() => {
                      dispatch(closeConfirmationModal("confirmCloseGallery"));
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                    id={"confirmCloseGallery"}
                  />
                </div>
              </>
            )}
            <MediaDetailContainer id={Modal_ID} large={false} />
            <UploadPanel uploadFromSelectionCb={onConfirmSelectMedia} />
            <GenerateMediaPanel />
          </div>
        )}
      </div>
    </div>
  );
};
