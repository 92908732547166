import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalStandard from "../Modal/ModalStandard";
import { checkPassword } from "../../../features/profile/state/profileSlice";
import { getCurrentModalCurrentState } from "../../state/slice/modal/modalSlice";
import { AppDispatch } from "../../state/store";
import { IsLoading } from "../AppState/IsLoading";
import { InputIcon } from "../Input";
import { Input } from "../../../common/components/Input";

interface IChangePasswordPoppup {
  register: any;
  onsubmit: any;
  setIsConfirmPasswordOpen: (value: boolean) => void;
  isConfirmPasswordOpen: boolean;
  email: string;
}

export const OLDPASSWORD_COMPONENT_ID = "oldPasswordComponent";

const ChangeEmailPoppup = ({ register, onsubmit, email }: IChangePasswordPoppup) => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const modalState = useSelector(getCurrentModalCurrentState);

  const [oldPassword, setOldPassword] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState(false);
  const [isConfirmPasswordOpen, setIsConfirmPasswordOpen] = useState(true);
  const [isEmailInputOpen, setIsEmailInputOpen] = useState(false);

  const [currPasswordVisible, setCurrPasswordVisible] = useState(false);

  useEffect(() => {
    setErrorOldPassword(false);
    setIsConfirmPasswordOpen(true);
  }, [modalState]);

  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handlePasswordConfirmation = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      checkPassword({
        componentId: OLDPASSWORD_COMPONENT_ID,
        password: oldPassword,
      }),
    ).then((res: any) => {
      if (Number(res.payload) === 0) {
        setIsConfirmPasswordOpen(false);
        setErrorOldPassword(false);
        setOldPassword("");
        setIsEmailInputOpen(true);
      } else {
        setErrorOldPassword(true);
      }
    });
  };

  const handleEmailChange = (e: React.FormEvent) => {
    e.preventDefault();
    onsubmit();
  };

  return (
    <>
      <ModalStandard id="modalConfirmPasswordForEmail" showCloseButton fullScreen={false}>
        {isConfirmPasswordOpen ? (
          <div className="flex w-full">
            <div className="completely-centered z-50">
              <IsLoading componentId={OLDPASSWORD_COMPONENT_ID} showSuccess={false} />
            </div>
            <div className="relative grow w-full max-w-7xl px-4 xl:px-0 mx-auto">
              <form onSubmit={handlePasswordConfirmation}>
                <h2 className="pt-3 pb-1 font-medium text-lg">
                  {t("pages.profile.main.emailChangeTitle")}
                </h2>
                <h3 className="pb-3">{t("pages.profile.main.emailChange")}</h3>
                <InputIcon
                  autoFocus
                  onChange={(event: any) => {
                    setOldPassword(event.target.value);
                  }}
                  type={currPasswordVisible ? "text" : "password"}
                  position="right"
                  placeholder={t("pages.profile.main.changePassword.oldPasswordPlaceholder")}
                  handleClick={() => setCurrPasswordVisible(!currPasswordVisible)}
                  icon={currPasswordVisible === true ? "EyeIcon" : "EyeOffIcon"}
                />
                {errorOldPassword && (
                  <div className="font-small text-red-700">
                    {t("pages.profile.main.changePassword.error")}
                  </div>
                )}
                <div className="flex justify-end mt-3">
                  <button className="btn-primary-fill" type="submit">
                    {t("general.confirm")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : isEmailInputOpen ? (
          <div>
            <h2 className="font-medium text-lg">{t("pages.profile.main.enterNewMail")}</h2>
            <h3 className="py-1">{t("pages.profile.main.emailChangeConfirm")}</h3>
            <form onSubmit={handleEmailChange}>
              <div className="flex pt-3">
                <p className="mr-10 mt-2 font-semibold">{t("pages.profile.main.email")}</p>
                <Input
                  autoFocus
                  type="email"
                  readOnly={false}
                  registerFct={() =>
                    register("email", { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })
                  }
                  className="mr-2 font-medium bg-white  drop-shadow-2xl read-only:focus:ring-0 text-gray-600  max-w-max text-center sm:text-left border-0"
                  placeholder={t("pages.profile.main.emailPlaceholder")}
                />
              </div>
              <div className="flex justify-end mt-3">
                <button
                  className="btn-primary-fill"
                  type="button"
                  disabled={!regexEmail.test(email)}
                  onClick={() => {
                    onsubmit();
                  }}
                >
                  {t("general.confirm")}
                </button>
              </div>
            </form>
          </div>
        ) : null}
      </ModalStandard>
    </>
  );
};

export default ChangeEmailPoppup;
