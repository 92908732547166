import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import { Outlet, useLocation } from "react-router-dom";
import {
  getCurrentElements,
  getSelectedProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { useTranslation } from "react-i18next";
import AddTeamModal from "../../features/team/components/AddTeamModal";
import ParticipantCsv from "../../features/team/components/ParticipantFromCsvModal";

const ProjectGroupEdit = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname.split("/");
  const lastUrlElement = path[path.length - 1];

  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const selectedCurrentElements = useSelector(getCurrentElements);

  const publishTabShouldBeVisible = selectedCurrentElements && selectedCurrentElements.length > 0;

  const tabs = [
    { title: t("pages.projectGroup.tabs.list"), urlEndPoint: "list" },
    { title: t("general.preview"), urlEndPoint: "preview" },
    { title: t("pages.projectGroup.tabs.team"), urlEndPoint: "team" },
    {
      title: t("pages.projectGroup.tabs.publish"),
      urlEndPoint: "publish",
      hidden: !publishTabShouldBeVisible,
    },
    { title: t("general.information"), urlEndPoint: "information" },
  ];

  return (
    <>
      <Navigation
        title={selectedProjectGroup.name}
        tabs={tabs}
        isProjectGroupView
        showGoBackButton
        backDepth={lastUrlElement === "new" ? 1 : 2}
      />
      <Outlet />
      <AddTeamModal />
      <ParticipantCsv />
    </>
  );
};

export default ProjectGroupEdit;
