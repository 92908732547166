import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import CreateProjectGroupTheme from "./CreateProjectGroupTheme";

const EditProjectGroupThemeWrapper = () => {
  const { t } = useTranslation();

  const selectedProjectGroup = useSelector(getSelectedProjectGroup);

  return (
    <>
      <Navigation
        title={
          <>
            <span style={{ color: "black" }}>{t("pages.editProjectGroupName.title")} :</span>
            {` ${selectedProjectGroup.name}`}
          </>
        }
        isProjectGroupView={true}
      />
      <CreateProjectGroupTheme editMode />
    </>
  );
};

export default EditProjectGroupThemeWrapper;
