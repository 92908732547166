import { t } from "i18next";
import { DocumentAddIcon, UserIcon } from "@heroicons/react/outline";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { Input } from "../../../common/components/Input";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import {
  currentOrgSelector,
  currentUserSelector,
} from "../../../common/state/selectors/authSelector";
import { AppDispatch, RootState } from "../../../common/state/store";
import { isStringNullOrEmpty } from "../../../common/util/isStringNullOrEmpty";
import { setAlert } from "../../alert/state/alertsSlice";
import { getIsAdminOfOrganization } from "../../profile/state/profileSlice";
import {
  getSelectedProjectGroup,
  setUserFromCsv,
  uploadNewParticipant,
} from "../../projectGroups/state/projectGroupsSlice";
import {
  getCurrentProject,
  getProjectPermissionsFromServer,
  setCurrentProjectPermissions,
  setProjectTeam,
} from "../../projects/state/projectsSlice";
import {
  allTeamSelector,
  inviteExternalEnduser,
  inviteExternalEnduserWithoutEmail,
} from "../state/teamSlice";
import { openStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { CONFIRM_CSV_COMPONENT } from "./ParticipantFromCsvModal";
import PlaceholderWithIcon from "src/common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import {
  fetchSubscription,
  isLimitReachedForFeature,
} from "src/features/subscription/state/subscriptionSlice";
import { InviteExternalFormData } from "../state/teamInterfaces";

export const INVITE_EXTERNAL_PROJECT_COMPONENT = "inviteExternalProjectComponent";

const isComingSoon = false;

interface IForgottenPasswordForm {
  onSubmit: (data: any) => void;
}

const InviteExternalParticipantFormContainer = () => {
  const { user, org } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      org: currentOrgSelector(state),
    };
  });

  const dispatch: AppDispatch = useDispatch();
  const currentProject = useSelector(getCurrentProject);
  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  const onSubmit = async (data: InviteExternalFormData) => {
    if (org && org.id && user && user.id) {
      if (currentProjectGroup.auth_type === "simplified") {
        const res = await dispatch(
          inviteExternalEnduserWithoutEmail({
            componentId: INVITE_EXTERNAL_PROJECT_COMPONENT,
            projectId: currentProject.id,
            inviterId: user.id,
            ...data,
          }),
        ).then((res: any) => {
          dispatch(
            setAlert({
              type: "success",
              msg: t("alerts.participantInvitedSuccessfully"),
            }),
          );
          return res;
        });

        const newUser = (res.payload as any).data;

        await dispatch(
          setProjectTeam({
            inviteeId: newUser.user.id,
            projectId: currentProject.id,
            componentId: INVITE_EXTERNAL_PROJECT_COMPONENT,
            inviterId: user.id,
          }),
        );
      } else {
        await dispatch(
          inviteExternalEnduser({
            componentId: INVITE_EXTERNAL_PROJECT_COMPONENT,
            inviterId: user.id,
            projectId: currentProject.id,
            ...data,
          }),
        ).then((res: any) => {
          if (!res.error) {
            dispatch(
              setAlert({
                type: "success",
                msg: t("alerts.participantInvitedSuccessfully"),
              }),
            );
            return res;
          }
        });
      }
      dispatch(getProjectPermissionsFromServer({ projectId: currentProject.id })).then((res) => {
        dispatch(setCurrentProjectPermissions((res.payload as any).data));
      });
    }
  };

  return (
    <IsLoading
      componentId={INVITE_EXTERNAL_PROJECT_COMPONENT}
      showSuccess={false}
      spinnerPlaceholder
    >
      <InviteExternalParticipant onSubmit={onSubmit} />
    </IsLoading>
  );
};

const InviteExternalParticipant = ({ onSubmit }: IForgottenPasswordForm) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InviteExternalFormData>();
  let ref: any;
  const clearForm = () => {
    if (ref) {
      ref.reset();
    }
  };
  const dispatch: AppDispatch = useDispatch();
  const watchValues = watch();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const isAdminOfOrg = useSelector(getIsAdminOfOrganization);

  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  useEffect(() => {
    if (isAdminOfOrg) {
      if (currentProjectGroup.auth_type === "simplified") {
        if (
          !isStringNullOrEmpty(watchValues.newUserFirstName) &&
          !isStringNullOrEmpty(watchValues.newUserLastName)
        ) {
          setIsAddButtonDisabled(false);
        } else {
          setIsAddButtonDisabled(true);
        }
      } else {
        if (
          !isStringNullOrEmpty(watchValues.newUserEmail) &&
          !isStringNullOrEmpty(watchValues.newUserFirstName) &&
          !isStringNullOrEmpty(watchValues.newUserLastName)
        ) {
          setIsAddButtonDisabled(false);
        } else {
          setIsAddButtonDisabled(true);
        }
      }
    }
  }, [watchValues]);

  const hiddenFileInput = useRef(null);
  const currentProject = useSelector(getCurrentProject);
  const currentUser = useSelector(currentUserSelector);
  const isSimplified = currentProjectGroup.auth_type === "simplified";

  const canAddNewEnduser = useSelector(
    (state: RootState) => !isLimitReachedForFeature(state, "number-of-available-enduser-accounts"),
  );

  const team = useSelector(allTeamSelector);
  useEffect(() => {
    dispatch(fetchSubscription({}));
  }, [team]);

  return (
    <div
      className={`${isSimplified ? "" : "ml-12"}bg-slate-100 flex invite-external mt-3 mb-4 w-full`}
    >
      <div className="flex border-2 border-gray-300 rounded-lg  py-3 px-4  flex-1 mx-auto items-center justify-center relative">
        {!canAddNewEnduser && (
          <div className="flex absolute top-2 left-2">
            <UserIcon className=" w-5 h-5 mb-3 text-gray-500 mr-1" />
            <span className="text-gray-500 font-medium">
              {t("pages.project.projectGroupParticipants.newParticipant") as any}
            </span>
          </div>
        )}

        {!canAddNewEnduser ? (
          <div className="flex items-center justify-center w-full">
            <PlaceholderWithIcon
              title={t("pages.project.inviteParticipants.nbAccountExceededTitle")}
              // text={t("pages.project.projectGroupCollaborators.nbAccountExceededSubtitle")}
              heroIcon="ExclamationIcon"
              iconClassName="h-16 w-16 stroke-yellow-400"
            />
          </div>
        ) : (
          <>
            <form
              className="w-full"
              onSubmit={handleSubmit((data) => {
                onSubmit(data);
                clearForm();
              })}
              ref={(f) => (ref = f)}
            >
              <div className="flex ">
                <UserIcon className=" w-5 h-5 mb-3 text-gray-500 mr-1" />
                <span className="text-gray-500 font-medium">
                  {t("pages.project.projectGroupParticipants.newParticipant") as any}
                </span>
              </div>
              <div className=" flex w-full items-center">
                <div className="w-full mr-5">
                  <Input
                    type="newUserLastName"
                    label={t("general.lastname")}
                    placeholder={t("general.lastname")}
                    registerFct={() => register("newUserLastName", { required: true })}
                    className="rounded-lg w-72 border-2 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                  />
                  <span className={`text-red-500 ${errors.newUserLastName ? "" : "invisible"}`}>
                    {t("pages.project.invalidLastName") as any}
                  </span>
                </div>
                <div className="w-full mr-5">
                  <Input
                    type="newUserFirstName"
                    label={t("general.firstname")}
                    placeholder={t("general.firstname")}
                    registerFct={() => register("newUserFirstName", { required: true })}
                    className="rounded-lg  w-72 border-2 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                  />
                  <span className={`text-red-500 ${errors.newUserFirstName ? "" : "invisible"}`}>
                    {t("pages.project.invalidFirstName") as any}
                  </span>
                </div>
                {currentProjectGroup.auth_type !== "simplified" && (
                  <div className="w-full mr-5">
                    <Input
                      type="newUserEmail"
                      label={t("general.email")}
                      placeholder={t("general.email")}
                      registerFct={() =>
                        register("newUserEmail", { required: true, pattern: /^\S+@\S+\.\S+$/i })
                      }
                      className="rounded-lg w-72 border-2 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                    />
                    <span className={`text-red-500 ${errors.newUserEmail ? "" : "invisible"}`}>
                      {t("pages.project.invalidEmail") as any}
                    </span>
                  </div>
                )}

                <div className="flex flex-col sm:flex-row sm:justify-between ">
                  <Tooltip
                    message={isAdminOfOrg ? "" : `${t("general.onlyAdminCanDoThis")}`}
                    classNameChildren="right-1"
                  >
                    <button
                      className="btn-primary-fill w-28"
                      type="submit"
                      disabled={isAddButtonDisabled}
                    >
                      {String(t("general.invite"))}
                    </button>
                  </Tooltip>
                </div>
              </div>
            </form>

            {currentProjectGroup.auth_type === "classic" && canAddNewEnduser && (
              <div className="absolute ml-51 mt-32">
                <Tooltip
                  classNameChildren="right-32"
                  message={
                    isComingSoon
                      ? t("pages.participants.comingSoonTooltip")
                      : t("tooltip.csvValidFormat")
                  }
                >
                  <button
                    className={`inline-flex text-sm text-green-500 ${
                      isComingSoon && "cursor-not-allowed"
                    } font-medium border-0 mb-5`}
                    disabled={isComingSoon}
                    onClick={() => {
                      const hfi = hiddenFileInput?.current as any;
                      hfi.click();
                    }}
                  >
                    <DocumentAddIcon className="stroke-green-500 w-7 h-7 mt-2  " />
                    <span className="mt-1 w-28 -ml-4 ">
                      {t("pages.project.projectGroupParticipants.addParticipantCSV") as any}
                    </span>
                  </button>
                </Tooltip>
                <input
                  id="upload-input-csv"
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  accept={".csv, .xlsx"}
                  onChange={(e: any) => {
                    const uploadedFile = e.target.files[0] as File;

                    if (uploadedFile) {
                      dispatch(
                        uploadNewParticipant({
                          userId: currentUser?.id ? currentUser.id : -1,
                          projectId: currentProject.id,
                          withMail: 0,
                          file: uploadedFile,
                          componentId: CONFIRM_CSV_COMPONENT,
                        }),
                      )
                        .then((res: any) => {
                          e.target.value = null;
                          if (res.payload !== undefined) {
                            dispatch(setUserFromCsv(res.payload.data));
                            dispatch(openStandardModal("add-participant-csv"));
                          }
                        })
                        .catch(() => {
                          dispatch(
                            setAlert({
                              type: "danger",
                              msg: t("alerts.participantAddedFail"),
                            }),
                          );
                        });
                    }
                  }}
                ></input>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InviteExternalParticipantFormContainer;
