import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import SubscriptionIFrame from "../../common/components/SubscriptionIFrame/SubscriptionIFrame";
import { getCurrentOrganization, getCurrentUser } from "../../features/profile/state/profileSlice";
import Mailto from "src/common/util/mailto";
import { AnonymousSessionCounter } from "src/features/subscription/components/AnonymousSessionsCounter";

const Subscription = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentOrganizationName = useSelector(getCurrentOrganization);

  const isAdmin = Number(currentUser?.id) === Number(currentUser?.organization?.admin?.id);

  const msgCb = (event: any) => {
    if (event.data.ReferenceOffer && event.data.IdSubscription) {
      window.location.reload();
    }
  };

  return (
    <>
      {isAdmin && <InfoButton relevantArticle="manageSubscription" />}
      <div className="flex flex-col relative grow w-full max-w-8xl px-4 mx-auto">
        <div className="flex justify-between">
          <div className="flex flex-col bg-color-red">
            <h2 className="text-2xl font-medium mb-2 mt-2 xl:mt-4">
              {t("pages.profile.tabs.subscription")}
            </h2>
            <Mailto
              textStyle="text-green-500 underline underline-offset-8"
              mailto="csm@wixar.io"
              linkText={t("pages.profile.subscription.needQuotedService")}
              subject={t("general.mailtoSubject") + currentOrganizationName?.name}
            />
          </div>

          <AnonymousSessionCounter
            numberOfPaywalledAnonymousSessions={1}
            className="bg-yellow-100 w-1/2"
          />
        </div>

        <SubscriptionIFrame msgCb={msgCb} />
      </div>
    </>
  );
};

export default Subscription;
