import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import {
  getUploadedFile,
  getUploadedFileMetadata,
  IMediaUpload,
  mediaSelector,
  setOpenedSlider,
  setUploadedFile,
  uploadMultipart,
  setUploadNewTagsInSuggestions,
  getCurrentTagsOrganizationId,
  setOriginalMediaGallery,
  setUploadedOldFile,
  getUploadedOldFile,
  askForPublicationOf3DEnvironment,
} from "../state/gallerySlice";
import { fromFileReturnMD5AndSize } from "../state/uploadUtil";
import { IMedia } from "../../../model/unityObject";
import Input from "../../../common/components/Input/Input";

import { currentUserSelector } from "../../../common/state/selectors/authSelector";
import Spinner from "../../../common/components/Loader/Spinner";
import { fromFileReturnMD5AndSizeWorker } from "../state/uploadUtilWorkers";
import { useNavigate } from "react-router-dom";
import SlideOver, {
  classSlideCloseAnimation,
  sliderAnimationDurationInMs,
} from "../../../common/components/SlideOver/SlideOver";
import { closeSlider, setAnimationClassState } from "../../../common/state/slice/modal/modalSlice";
import Media360TagDropdown from "./Media360TagDropdown";
import { Dropdown } from "src/common/components/Input";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { InformationCircleIcon } from "@heroicons/react/outline";

export const UPLOAD_SLIDEOVER_COMPONENT_ID = "uploadSlideoverCompId";

export const UploadPanel = ({
  mobile = false,
  uploadFromSelectionCb = () => null,
}: {
  mobile?: boolean;
  uploadFromSelectionCb?: any;
}) => {
  const { handleSubmit } = useForm<IMediaUpload>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tagsOrganization = useSelector(getCurrentTagsOrganizationId);

  const medias = useSelector(mediaSelector);
  const selectedFile = useSelector(getUploadedFile);
  const oldFile = useSelector(getUploadedOldFile);
  const selectedFileMetadata = useSelector(getUploadedFileMetadata);

  const currentUser = useSelector(currentUserSelector);

  const defaultMedia360TagIndex = 1;
  const [preview, setPreview] = useState("");
  const [MD5, setMD5] = useState("");
  const [media360Tag, setMedia360Tag] = useState("other");
  const [canUpload, setCanUpload] = useState(false);
  const [mediaAlreadyExists, setMediaAlreadyExists] = useState(false);
  const [isMd5Loaded, setIsMd5Loaded] = useState(false);
  const [mediaNameToEdit, setMediaNameToEdit] = useState<string>();

  const [media3DTypeOptions] = useState([
    { value: "others", optionText: t("pages.gallery.filters.objects3d") },
    { value: "media360", optionText: t("pages.gallery.filters.environments3d") },
  ]);

  const [media3DType, setMedia3DType] = useState("others");

  const closeSliderAnimation = (isUpload = false) => {
    setTimeout(() => {
      if (isUpload) {
        dispatch(closeSlider(UPLOAD_SLIDEOVER_COMPONENT_ID));
      } else {
        dispatch(setOpenedSlider(""));
        dispatch(setUploadedFile(undefined));
        dispatch(setUploadedOldFile(undefined));
      }
    }, sliderAnimationDurationInMs);

    setTimeout(() => {
      dispatch(setAnimationClassState(classSlideCloseAnimation));
    }, 1);
  };

  useEffect(() => {
    setMediaNameToEdit(selectedFile?.name.slice(0, selectedFile?.name.lastIndexOf(".")));
    if (selectedFileMetadata?.needAskMedia360Tags && media360Tag === "other") {
      setMedia360Tag(selectedFileMetadata.media360Tags[defaultMedia360TagIndex]);
    }
  }, [selectedFileMetadata]);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview("");
      return () => {
        null;
      };
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    const onMd5Return = (res: any) => {
      const existsAlready = medias.find((m: IMedia) => m.MD5 === res.MD5);

      if (existsAlready !== undefined) {
        setMediaAlreadyExists(true);
        setCanUpload(false);
      } else {
        if (
          selectedFile &&
          oldFile &&
          selectedFile.name === oldFile.name &&
          selectedFile.size === oldFile.size &&
          selectedFile.lastModified === oldFile.lastModified
        ) {
          setMediaAlreadyExists(true);
          setCanUpload(false);
        } else {
          setMediaAlreadyExists(false);
          setMD5(res.MD5);
          setCanUpload(true);
        }
      }
      setIsMd5Loaded(true);
    };

    setIsMd5Loaded(false);
    if (window.Worker) {
      fromFileReturnMD5AndSizeWorker(selectedFile, onMd5Return);
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      const onMd5Return = (res: any) => {
        const existsAlready = medias.find((m: IMedia) => m.MD5 === res.MD5);

        if (existsAlready !== undefined) {
          setMediaAlreadyExists(true);
          setCanUpload(false);
        } else {
          if (
            selectedFile &&
            oldFile &&
            selectedFile.name === oldFile.name &&
            selectedFile.size === oldFile.size &&
            selectedFile.lastModified === oldFile.lastModified
          ) {
            setMediaAlreadyExists(true);
            setCanUpload(false);
          } else {
            setMD5(res.MD5);
            setCanUpload(true);
            setMediaAlreadyExists(false);
          }
        }
        setIsMd5Loaded(true);
      };

      setIsMd5Loaded(false);
      if (window.Worker) {
        fromFileReturnMD5AndSizeWorker(selectedFile, onMd5Return);
      } else {
        // Asyncronously check for MD5; enable Upload button if it doesn't exist already
        fromFileReturnMD5AndSize(selectedFile).then((res) => {
          onMd5Return(res);
        });
      }

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }

    return () => {
      null;
    };
  }, [selectedFile, dispatch]);

  const onSubmit = () => {
    if (selectedFile && currentUser) {
      closeSliderAnimation(true);
      setCanUpload(false);

      dispatch(
        setUploadedOldFile({
          file: selectedFile,
          metadata: selectedFileMetadata,
        }),
      );

      if (selectedFileMetadata?.sourceMediaType === "model3d" && media3DType === "media360") {
        dispatch(
          askForPublicationOf3DEnvironment({
            file: selectedFile,
            name: mediaNameToEdit ? mediaNameToEdit : selectedFile?.name,
          }),
        )
          .then((res) => {
            if (res?.status === 200 && res?.data?.isSended) {
              dispatch(
                setAlert({
                  msg: t("general.emailToAskForPublicationWasSentSuccessfully"),
                  type: "success",
                }),
              );
            } else {
              dispatch(setAlert({ msg: t("error.genericServerError"), type: "error" }));
            }
          })
          .catch(() => {
            dispatch(setAlert({ msg: t("error.genericServerError"), type: "error" }));
          });
      } else {
        dispatch(
          uploadMultipart({
            file: selectedFile,
            orgId: currentUser.organization.id,
            name: mediaNameToEdit ? mediaNameToEdit : selectedFile?.name,
            media_360_tag: media360Tag,
            MD5,
            cb: (x: any) => {
              /* Upon upload completion:
              - set uploaded media as selected
              - remove upload panel
              - remove upload bar overlay
            */
              if (uploadFromSelectionCb) {
                uploadFromSelectionCb(x);
              }
              setTimeout(() => {
                dispatch(
                  setUploadNewTagsInSuggestions({ newTags: x.tags, oldTags: tagsOrganization }),
                );
                dispatch(setOriginalMediaGallery(x.id));
              }, 0);
            },
          }),
        );
      }
      if (mobile) {
        navigate("/uploads");
      }
    }
  };

  return mobile ? (
    /* Mobile view */
    <div className="sticky flex flex-col top-0 right-0 pb-3 w-screenshadow-md over overflow-y-auto max-h-screen h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
        <div className={"grow w-full bg-color-red"}>
          {selectedFile && selectedFileMetadata && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <MediaPreview url={preview} type={selectedFileMetadata.sourceMediaType} mobile />
            </div>
          )}
          {!mediaAlreadyExists ? (
            <div className="ml-2">
              <div className="block font-medium text-gray-700 text-sm">
                {t("general.renameFile")}
              </div>
              <Input
                className={`${mobile && "max-w-[65%] h-2/5"}`}
                name="name"
                type="text"
                value={mediaNameToEdit}
                placeholder={t("general.fileName")}
                onChange={(e) => {
                  setMediaNameToEdit(e.target.value);
                }}
                required
              />

              {selectedFileMetadata?.needAskMedia360Tags && (
                <Media360TagDropdown
                  mediaType={String(selectedFileMetadata?.sourceMediaType)}
                  onChangeCb={(v: any) => {
                    setMedia360Tag(v.target.value);
                  }}
                  mode="upload"
                  mobile
                />
              )}
              {selectedFileMetadata?.sourceMediaType === "model3d" && (
                <div className="flex">
                  <Dropdown
                    options={media3DTypeOptions}
                    label={t("pages.gallery.filters.mediatype")}
                    onChangeCb={(e: any) => {
                      setMedia3DType(e.target.value);
                    }}
                    asDefaultValue
                    defaultValueIndex={0}
                  />
                  <div className="flex justify-end items-end ml-3 mb-2">
                    <Tooltip
                      message={t("pages.gallery.uploadPanel.tooltipfor3dTypes")}
                      forceSingleLine={false}
                      lineLength={"w-48"}
                    >
                      <InformationCircleIcon className="stroke-green-500 h-8 w-8 m-auto" />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>{t("pages.gallery.uploadPanel.fileAlreadyInGallery")}</p>
          )}
        </div>
        {isMd5Loaded ? (
          <div className={`${mobile && "h-full ml-2 flex items-center justify-center"}`}>
            <button
              className="btn-alternative-outline mr-3 mt-2"
              onClick={(e: any) => {
                e.preventDefault();
                dispatch(setUploadedFile(undefined));
                dispatch(setUploadedOldFile(undefined));
                navigate("/");
              }}
            >
              {t("general.cancel")}
            </button>
            <button
              className="btn-primary-fill mr-3 mt-2"
              disabled={!canUpload || (selectedFileMetadata?.needAskMedia360Tags && !media360Tag)}
            >
              {t("pages.project.importMedia")}
            </button>
          </div>
        ) : (
          <div className={`flex flex-col mt-4 ${mobile && "ml-2"}`}>
            <Spinner relative />
            <p className="text-center italic">{t("pages.gallery.uploadPanel.preparingMedia")}</p>
          </div>
        )}
      </form>
    </div>
  ) : (
    /* Desktop view */
    <SlideOver large={false} id={UPLOAD_SLIDEOVER_COMPONENT_ID}>
      <div className="flex flex-col px-6 pt-8 pb-3 bg-white overflow-y-auto max-h-screen min-h-full">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full justify-between">
          <div>
            <h3 className="font-medium text-lg pb-6">{t("general.upload")}</h3>

            <div className="space-y-3 w-full">
              {selectedFile && selectedFileMetadata && (
                <div className="flex justify-center items-center flex-col">
                  <p className="text-gray-500 font-medium mb-2">
                    {t("pages.gallery.uploadPanel.selectedFile")}: {selectedFile.name}
                  </p>
                  <MediaPreview
                    url={preview}
                    type={selectedFileMetadata.sourceMediaType}
                    className="max-h-[35vh]"
                  />
                </div>
              )}
              {!mediaAlreadyExists ? (
                <>
                  <div className="block text-lg font-medium text-gray-700 ">
                    {t("general.renameFile")}
                  </div>
                  <Input
                    name="name"
                    type="text"
                    value={mediaNameToEdit}
                    placeholder={"name"}
                    onChange={(e) => {
                      setMediaNameToEdit(e.target.value);
                    }}
                    required
                  />

                  {selectedFileMetadata?.needAskMedia360Tags && (
                    <Media360TagDropdown
                      mode="upload"
                      mediaType={String(selectedFileMetadata?.sourceMediaType)}
                      onChangeCb={(v: any) => {
                        setMedia360Tag(v.target.value);
                      }}
                    />
                  )}
                  {selectedFileMetadata?.sourceMediaType === "model3d" && (
                    <div className="flex">
                      <Dropdown
                        options={media3DTypeOptions}
                        label={t("pages.gallery.filters.mediatype")}
                        onChangeCb={(e: any) => {
                          setMedia3DType(e.target.value);
                        }}
                        asDefaultValue
                        defaultValueIndex={0}
                      />
                      <div className="flex justify-end items-end ml-3 mb-2">
                        <Tooltip
                          message={t("pages.gallery.uploadPanel.tooltipfor3dTypes")}
                          forceSingleLine={false}
                          lineLength={"w-48"}
                        >
                          <InformationCircleIcon className="stroke-green-500 h-8 w-8 m-auto" />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p>{t("pages.gallery.uploadPanel.fileAlreadyInGallery")}</p>
              )}
            </div>
          </div>

          <div className="flex h-full">
            {isMd5Loaded ? (
              <div className="self-end mb-4">
                <button
                  className="btn-alternative-outline mr-3 mt-2"
                  onClick={(e: any) => {
                    e.preventDefault();
                    closeSliderAnimation();
                  }}
                >
                  {t("general.cancel")}
                </button>

                <button
                  className="btn-primary-fill mr-3 mt-2"
                  disabled={
                    !canUpload || (selectedFileMetadata?.needAskMedia360Tags && !media360Tag)
                  }
                >
                  {`${
                    selectedFileMetadata?.sourceMediaType === "model3d"
                      ? media3DType === "media360"
                        ? t("pages.gallery.uploadPanel.askForPublication")
                        : t("pages.project.importMedia")
                      : t("pages.project.importMedia")
                  }`}
                </button>
              </div>
            ) : (
              <div className="flex flex-col mt-4 self-center">
                <Spinner relative />
                <p className="text-center italic">
                  {t("pages.gallery.uploadPanel.preparingMedia")}
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    </SlideOver>
  );
};

interface MediaPreviewParams {
  url: string;
  type: "video" | "image" | "audio" | "pdf" | "model3d";
  className?: string;
  mobile?: boolean;
}

const MediaPreview = ({ url, type, mobile = false, className = "" }: MediaPreviewParams) => {
  const style = mobile ? { maxHeight: "28vh", maxWidth: "90%" } : {};

  if (type === "video") {
    return <video controls src={url} style={style} className={className} />;
  }
  if (type === "image") {
    return <img src={url} style={mobile ? style : { minWidth: "20%" }} className={className} />;
  }
  if (type === "audio") {
    return <audio controls src={url} style={style} className={className} />;
  }
  if (type === "pdf") {
    return (
      <img
        src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/pdf-placeholder.jpg"
        style={style}
        className={className}
      />
    );
  }
  if (type === "model3d") {
    return (
      <img
        src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/3d-placeholder.png"
        style={style}
        className={className}
      />
    );
  } else {
    return (
      <img src="" title="Broken preview" alt="Broken preview" style={style} className={className} />
    );
  }
};
