interface INameShortener {
  firstname?: string;
  lastname?: string;
}

const shortenName = ({ firstname, lastname }: INameShortener) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname.charAt(0).toLocaleUpperCase()}.`;
  }
  if (firstname && !lastname) {
    return firstname;
  }
  if (!firstname && lastname) {
    return lastname;
  }
  return "Anonymous";
};

export default shortenName;
