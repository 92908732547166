import { InformationCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  getHasOpenedTutorial,
  getUserOpenedTutorial,
  setHasOpenedTutorial,
} from "../../../features/profile/state/profileSlice";
import { IUserOpenedTutorial } from "../../../model/model";
import { useAppDispatch } from "../../state/hooks";
import { openSlider } from "../../state/slice/modal/modalSlice";
import SlideOver from "../SlideOver/SlideOver";
import ZendeskArticle from "../ZendeskArticle/ZendeskArticle";

type Props = {
  relevantArticle: string;
  classNameOnboarding?: string;
  haveButtonToOnboarding?: boolean;
};

/* The 'relevantArticle' string must match two things: 
- minus the "web_" prefix: a boolean field onto user-opened-tutorial object ( in store.profile.currentUser.user_opened_tutorial )
- a key inside "articles" const in src/common/util/deduceZendeskArticle.ts
*/

const InfoButton = ({
  relevantArticle,
  classNameOnboarding,
  haveButtonToOnboarding = false,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const userOpenedTutorials = useSelector(getUserOpenedTutorial) as IUserOpenedTutorial;
  const [shouldOpenTour, setShouldOpenTour] = useState(false);
  const dbKeyname = `web_${relevantArticle}`;
  const defaultAxiosJwt = axios.defaults.headers.common["Authorization"];

  useEffect(() => {
    if (defaultAxiosJwt) {
      dispatch(getHasOpenedTutorial({ userOpenedTutorialId: Number(userOpenedTutorials.id) })).then(
        (res) => {
          const userOpenedTutorialsUpdated = res.payload as IUserOpenedTutorial;
          const shouldPopHelpIntoUserFace =
            !userOpenedTutorialsUpdated[dbKeyname as keyof IUserOpenedTutorial];
          !userOpenedTutorialsUpdated[dbKeyname as keyof IUserOpenedTutorial];
          if (shouldPopHelpIntoUserFace) {
            dispatch(
              setHasOpenedTutorial({
                userOpenedTutorialId: Number(userOpenedTutorialsUpdated.id),
                newValues: {
                  ...userOpenedTutorialsUpdated,
                  [dbKeyname]: true,
                },
              }),
            );
            dispatch(openSlider("help-slider"));
            setShouldOpenTour(true);
          } else {
            setShouldOpenTour(false);
          }
        },
      );
    }
  }, [defaultAxiosJwt]);

  return (
    <>
      <button
        onClick={() => dispatch(openSlider("help-slider"))}
        className={`${classNameOnboarding} fixed top-4 right-0 w-10 h-10 bg-green-500 shadow-lg rounded-l-xl hover:bg-green-600 z-50`}
        type="button"
        {...props}
      >
        <InformationCircleIcon className="stroke-white h-8 w-8 m-auto" />
      </button>
      <SlideOver
        setShouldOpenTour={setShouldOpenTour}
        shouldPopHelpIntoUserFace={shouldOpenTour}
        relevantArticle={relevantArticle}
        id="help-slider"
        large
      >
        <ZendeskArticle
          haveButtonToOnboarding={haveButtonToOnboarding}
          reference={relevantArticle}
        />
      </SlideOver>
    </>
  );
};

export default InfoButton;
