/* eslint-disable indent */

import { IContentType } from "../../model/unityObject";

interface IReturn {
  has_been_found: boolean;
  infoTitle?: string;
  infoText?: string;
}

export const fromModelNameGetDescription = (
  contentTypeArray: IContentType[],
  modelName?: string,
): IReturn => {
  const fail = {
    has_been_found: false,
  };
  if (!modelName) return fail;
  const bingo = contentTypeArray.find((ct) => ct.type === modelName);
  return bingo
    ? {
        has_been_found: true,
        infoTitle: bingo?.title,
        infoText: bingo?.description,
      }
    : { ...fail };
};
