import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../common/state/hooks";
import InfoCard from "../../../../common/components/Cards/InfoCard";
import { IsLoading } from "../../../../common/components/AppState/IsLoading";
import InfoButton from "../../../../common/components/InfoButton/InfoButton";
import {
  fetchEnduserStatsOverview,
  getEnduserStatsOverview,
} from "../../../enduserStats/state/enduserStatsSlice";
import EnduserBarChartSessionsPerMonth from "../Charts/EnduserBarChartSessionsPerMonth";
import EnduserSuccessRatePerCourse from "../Charts/EnduserSuccessRatePerCourse";

export const ENDUSER_STATS_OVERVIEW_COMPONENT_ID = "enduserStatsOverviewComponent";

const EnduserStatsOverview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const enduserStatsOverview = useSelector(getEnduserStatsOverview);

  useEffect(() => {
    dispatch(fetchEnduserStatsOverview({ componentId: ENDUSER_STATS_OVERVIEW_COMPONENT_ID }));
  }, []);

  return (
    <>
      <InfoButton relevantArticle="aboutStatistiques" />

      <IsLoading
        componentId={ENDUSER_STATS_OVERVIEW_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-9 py-6 xl:pb-12 xl:pt-9">
          <InfoCard
            title={t("pages.dashboard.enduserStatsOverview.numberOfFinishedProjectGroups")}
            data={String(enduserStatsOverview?.numberOfFinishedProjectGroups)}
          />
          <InfoCard
            title={t("pages.dashboard.enduserStatsOverview.numberOfSucceededProjectGroups")}
            data={String(enduserStatsOverview?.numberOfSucceededProjectGroups)}
          />
          <InfoCard
            title={t("pages.dashboard.enduserStatsOverview.numberOfRemainingProjectGroups")}
            data={String(enduserStatsOverview?.numberOfRemainingProjectGroups)}
          />
          <InfoCard
            title={t("pages.dashboard.enduserStatsOverview.overallSuccessRate")}
            data={`${Number(enduserStatsOverview?.overallSuccessRate).toFixed()}%`}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
          <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
            <p className="text-gray-800 font-medium text-base text-center p-2">
              {t("pages.dashboard.enduserStatsOverview.sessionsForTheTwelveLastMonths")}
            </p>
            <EnduserBarChartSessionsPerMonth
              sessions={enduserStatsOverview?.sessionsForTheTwelveLastMonths}
            />
          </div>
          <div className="xl:h-80 h-72 w-full bg-white rounded-lg shadow-md">
            <p className="text-gray-800 font-medium text-base text-center p-2">
              {t(
                "pages.dashboard.enduserStatsOverview.successRatePerProjectGroupSortedByLatestSession",
              )}
            </p>
            <EnduserSuccessRatePerCourse
              successRatePerProjectGroupSortedByLatestSession={
                enduserStatsOverview?.successRatePerProjectGroupSortedByLatestSession
              }
            />
          </div>
        </div>
      </IsLoading>
    </>
  );
};

export default EnduserStatsOverview;
