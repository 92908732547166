import { PureComponent } from "react";
import { connect } from "react-redux";
import { PieChart, Pie, Cell, Sector, ResponsiveContainer, Legend } from "recharts";
import { ISessionsByProjectGroupWithColor } from "../../state/statsSlice";
import { RootState } from "../../../../common/state/store";
import { ISession } from "../../../../model/model";

interface IEntry {
  name: string;
  value: number;
  color: string;
  completed: number;
}

interface Props {
  allSessionsByProjectGroup: ISessionsByProjectGroupWithColor[];
  noDataMessage: string;
  noSession?: boolean;
}

interface State {
  mappedSessionsByProjectGroup: IEntry[];
  activeIndex: number;
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    completed,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Sessions: ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`Completed: ${completed}`}
      </text>
    </g>
  );
};

/* The boilerplate code below is needed to make the React Class component interact with redux store
Also, don't forget the

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(PieChartComp);

at the end
*/
const mapStateToProps = (state: RootState) => ({
  allSessionsByProjectGroup: state.stats.allSessionsByProjectGroup,
});

const mapDispatchToProps = () => ({
  //
});

/* 
This component is only used in "StatsOverview" (when no particular project is selected)
Meaning, we don't have to do anything special if a project has been selected (store.stats.selectedProjectId > 0)
*/
class PieChartComp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mappedSessionsByProjectGroup: props.allSessionsByProjectGroup
        .filter((sbp: ISessionsByProjectGroupWithColor) => sbp.sessions.length > 0)
        .map((sbp: ISessionsByProjectGroupWithColor) => {
          return {
            name: sbp.project_group_name,
            value: sbp.sessions.length,
            completed: sbp.sessions.filter((s: ISession) => s.is_completed).length,
            color: sbp.color,
          };
        }),
      activeIndex: 0,
    };
  }

  legendStyle = {
    top: "auto",
    right: 0,
  };

  onPieEnter = (_: number, index: number) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        {this.props.noSession ? (
          <p className="no-data-message">{this.props.noDataMessage}</p>
        ) : (
          <PieChart width={600} height={600} style={{ marginTop: "-4.5rem" }}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={this.state.mappedSessionsByProjectGroup}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
            >
              {this.state.mappedSessionsByProjectGroup.map((_entry: IEntry, index) => (
                <Cell key={`cell-${index}`} fill={_entry.color} />
              ))}
            </Pie>
            <Legend
              iconSize={8}
              // layout="vertical"
              // verticalAlign="top"
              // wrapperStyle={this.legendStyle}
              // className="pie-legend"
            />
          </PieChart>
        )}
      </ResponsiveContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(PieChartComp);
