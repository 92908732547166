type InfoCardProps = {
  title: string;
  data: string;
  heightEndUser?: boolean;
};
const InfoCard = ({ title, data, heightEndUser = false }: InfoCardProps) => {
  return (
    <div
      className={`${
        heightEndUser ? "h-24" : "h-full"
      } inline-flex flex-col justify-around w-full rounded-lg px-5 py-5 border border-gray-300 bg-white shadow-sm `}
    >
      <p
        className={`${
          heightEndUser ? "title-infocard-modal" : "text-base"
        } text-gray-500 font-medium`}
      >
        {title}
      </p>
      <p
        className={`${
          heightEndUser ? "title-infocard-modal" : ""
        } text-black text-xl font-semibold`}
      >
        {data}
      </p>
    </div>
  );
};

export default InfoCard;
