import { useState } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "../../../common/components/Navigation/Navigation";
import StepperMUI from "../../../common/components/Stepper/Stepper";
import { ProjectNewName, ProjectThumbnail } from "../../../pages/Projects";

export default function FaitTemplateWrapper() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
      default:
        return (
          <ProjectNewName
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            mode="faitTemplate"
          />
        );
      case 1:
        return <ProjectThumbnail decrementStep={decrementStep} mode="faitTemplate" />;
    }
  };

  return (
    <>
      <Navigation title={t("pages.faitTemplate.title")} />
      <StepperMUI step={step} mode="faitTemplate" />
      {renderStep(step)}
    </>
  );
}
