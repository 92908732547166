export const paths = {
  metier: {
    question: "Quel est votre métier?",
    options: [
      { value: "formateur", label: "Formateur", next: "formateurQuestion" },
      { value: "developpeur", label: "Développeur", next: "developpeurQuestion" },
      { value: "ahou", label: "AHOU! AHOU! AHOU!", next: "ahouQuestion" },
    ],
  },
  formateurQuestion: {
    question: "En entreprise ou dans le public?",
    options: [
      { value: "public", label: "Public" },
      { value: "prive", label: "Privé" },
    ],
  },
  developpeurQuestion: {
    question: "Plutôt Web ou Unity?",
    options: [
      { value: "web", label: "Web" },
      { value: "unity", label: "Unity" },
    ],
  },
  ahouQuestion: {
    question: "AHOU?",
    options: [
      { value: "ahou", label: "AHOU!" },
      { value: "non", label: "Non, je veux vivre." },
    ],
  },
};
