import { SVGProps, useEffect, useState } from "react";
import Trophy from "../Trophy/Trophy";

export const CirclePercent = ({
  percent,
  circleColor = "white",
  withAnimation = true,
  isSucceded = false,
}: {
  percent: number;
  circleColor?: string;
  withAnimation?: boolean;
  isSucceded?: boolean;
}) => {
  const [percentToShow, setPercentToShow] = useState<number>(0);
  const generatePath = (ratio: number) => {
    const props = {
      style: { transform: "rotate(-90deg) translateX(-100%)" },
      strokeWidth: "5",
      fillOpacity: "0.0",
      strokeLinecap: "round",
      stroke: circleColor,
    };

    const radius = 45;
    const xStart = 95;
    const yStart = 50;

    if (ratio >= 1) {
      return (
        <circle cx={50} cy={50} r={radius} {...(props as SVGProps<SVGCircleElement>)}></circle>
      );
    } else {
      const xEnd = Math.cos(ratio * 2 * Math.PI) * radius + 50;
      const yEnd = Math.sin(ratio * 2 * Math.PI) * radius + 50;

      return (
        <path
          d={`M ${xStart} ${yStart} A ${radius} ${radius} 0 ${
            ratio > 0.5 ? 1 : 0
          } 1 ${xEnd} ${yEnd}`}
          {...(props as SVGProps<SVGPathElement>)}
        />
      );
    }
  };

  useEffect(() => {
    if (withAnimation) {
      const update = (per: number, timeout: number) => {
        setTimeout(() => {
          if (per < Math.trunc(percent)) {
            per = per + 1;
            setPercentToShow(per);

            timeout = 5 + (per / Math.trunc(percent)) * 15;
            update(per, timeout);
          }
        }, timeout);
      };

      update(0.0, 5);
    } else {
      setPercentToShow(percent);
    }
  }, []);

  return (
    <div className="h-full rounded-[50%]" style={{ backdropFilter: "blur(2px)" }}>
      <svg height={"100%"} width={"100%"} viewBox={"0 0 100 100"}>
        <circle cx="50" cy="50" r="47.5" fillOpacity={"0.5"} stroke="none" fill="black"></circle>
        {generatePath(percentToShow / 100.0)}

        <text
          fontSize={"200%"}
          stroke="none"
          fill={"white"}
          textAnchor="middle"
          alignmentBaseline="middle"
          x="50"
          y={isSucceded ? "40" : "53"}
        >{`${percentToShow.toFixed(0)}%`}</text>
        {isSucceded && <Trophy />}
      </svg>
    </div>
  );
};

export default CirclePercent;
