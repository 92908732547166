import { TrashIcon } from "@heroicons/react/outline";
import * as React from "react";

interface IDeleteElementsSelectedFromListProps {
  className?: string;
  isCheck: string[];
  selectedTextSingular: string;
  selectedTextPlurial: string;
  deleteButtonTextSingular: string;
  deleteButtonTextPlurial: string;
  deleteCallback: (...args: any) => void;
}

export const DeleteElementsSelectedFromList: React.FC<IDeleteElementsSelectedFromListProps> = ({
  className = "mb-3 ml-1",
  isCheck,
  selectedTextSingular,
  selectedTextPlurial,
  deleteButtonTextSingular,
  deleteButtonTextPlurial,
  deleteCallback,
}: IDeleteElementsSelectedFromListProps) => {
  return (
    <div className={className}>
      {isCheck.length > 0 && (
        <h3>
          {`${isCheck.length} ${
            isCheck.length > 1 ? selectedTextPlurial : selectedTextSingular
          } - `}
          <button onClick={() => deleteCallback()}>
            <div className="flex">
              <p className="text-red-500">{`${
                isCheck.length > 1 ? deleteButtonTextPlurial : deleteButtonTextSingular
              }`}</p>
              <TrashIcon className="w-5 h-5 stroke-red-500 mr-3" />
            </div>
          </button>
        </h3>
      )}
    </div>
  );
};
