import { useDispatch, useSelector } from "react-redux";
import { allTeamSelector } from "../state/teamSlice";
import { AppDispatch, RootState } from "../../../common/state/store";
import TableComp from "../../../common/components/Table/Table";
import { IUser } from "../../../model/model";
import { Checkbox } from "../../../common/components/Input";
import { Key, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { getCurrentUser } from "../../profile/state/profileSlice";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { getCurrentProjectPermissions } from "../../projects/state/projectsSlice";
import { getCurrentProjectGroupPermissions } from "../../projectGroups/state/projectGroupsSlice";

interface IForgottenPasswordForm {
  onSubmit: (data: any) => void;
  className?: string;
  setIsEmpty?: any;
}

const DynamicTable = ({ onSubmit, className = "", setIsEmpty }: IForgottenPasswordForm) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const dispatch: AppDispatch = useDispatch();

  const { team, currentProjectPermissions, currentProjectGroupPermissions } = useSelector(
    (state: RootState) => {
      return {
        team: allTeamSelector(state),
        currentProjectPermissions: getCurrentProjectPermissions(state),
        currentProjectGroupPermissions: getCurrentProjectGroupPermissions(state),
      };
    },
  );

  const [columnValues, setColumnValues] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isCheck, setIsCheck] = useState<Array<any>>([]);

  const { register, handleSubmit, watch } = useForm<any>({
    defaultValues: {
      inviteeId: isCheck,
    },
  });

  const projectPermissions = currentProjectPermissions.map((permission) => permission.user);
  const isInCollabPage = location.pathname.toLowerCase().includes("team");

  const updatedList = team.filter((elt) => {
    //test eamil if its not just 4 random numbers (aka identifier)
    const emailRegex = /^[0-9]{4}$/;
    if (emailRegex.test(elt.email)) {
      return false;
    }

    return (
      !currentProjectPermissions.find((p) => Number(p?.user?.id) === Number(elt?.id)) &&
      !currentProjectGroupPermissions.find((p) => Number(p?.user?.id) === Number(elt?.id)) &&
      elt.role.name === (isInCollabPage ? "Creator" : "Enduser") &&
      Number(currentUser?.id) !== Number(elt.id) &&
      !elt.email.includes("@sneakysneaky.ua")
    );
  });
  const [teamList, setTeamList] = useState<Array<any>>(updatedList);

  useEffect(() => {
    setTeamList(updatedList);
  }, []);

  const watchAllFields = watch("inviteeId");

  const isDirty = watchAllFields?.length > 0;

  const handleClick = (e: any) => {
    const { id, checked } = e.target;

    // Save scroll position before state update
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      setScrollPosition(current.scrollTop);
    }

    // State update logic
    if (checked) {
      const nextValues = [...isCheck, id];
      setIsCheck(nextValues);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // Use effect hook to set scroll position after state update
  useEffect(() => {
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      current.scrollTop = scrollPosition;
    }
  }, [isCheck]);
  const colValues =
    teamList &&
    teamList.map((member: IUser, i: Key | null | undefined) => {
      return {
        checkbox: (
          <Checkbox
            defaultChecked={Boolean(projectPermissions.find((elt) => member?.id === elt?.id))}
            checked={isCheck.includes(member.id)}
            key={`${i}-${member.id}`}
            id={`${member.id}`}
            value={member.id}
            registerFct={() => register("inviteeId")}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          />
        ),
        firstName: member.firstname,
        lastName: member.lastname,
        email: member.email,
      };
    });

  useEffect(() => {
    setIsCheck(
      teamList
        .filter((member) => projectPermissions.find((elt) => member?.id === elt?.id))
        .map((member) => member?.id),
    );

    setColumnValues(colValues as any);

    if (colValues.length === 0 && setIsEmpty) {
      setIsEmpty(true);
    }
  }, [location]);

  const colHeaders = [t("general.firstname"), t("general.lastname"), t("general.email")];
  const tableContainerRef = useRef(null);

  return (
    <form
      className={`flex flex-col ${className} overflow-auto`}
      onSubmit={(e) => e.preventDefault()}
      key={`?${isCheck.join("marlon")}`}
    >
      {columnValues?.length > 0 ? (
        <div className="flex overflow-auto h-[93%]">
          <TableComp
            selectFromListInTable={true}
            isAllCollaborators={true}
            isCollaboratorAndParticipant={true}
            rows={colValues}
            colHeaders={colHeaders}
            className="w-full"
            ref={tableContainerRef}
            listToFilter={teamList}
            setListOfItemsFilter={setTeamList}
          />
        </div>
      ) : (
        <h2 className="mb-2 mt-2 text-center">
          {isInCollabPage
            ? t("pages.project.inviteCollaborators.noMembersToInvite")
            : t("pages.project.inviteParticipants.noMembersToInvite")}
        </h2>
      )}

      <div className="w-full flex justify-end">
        <button
          type="button"
          className="btn-alternative-outline mt-2"
          onClick={() => dispatch(closeStandardModal("add-team"))}
        >
          {t("general.cancel")}
        </button>
        {columnValues?.length > 0 && (
          <button
            disabled={!isDirty}
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            className="btn-primary-fill mt-2 ml-2"
          >
            {t("general.confirm")}
          </button>
        )}
      </div>
    </form>
  );
};
export default DynamicTable;
