import useEventListener from "@use-it/event-listener";
import { Tooltip } from "../Tooltip/Tooltip";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getActiveButtonModalGalleryConfirm,
  setCurrentButtonModalGalleryConfirm,
} from "src/features/gallery/state/gallerySlice";
import { useEffect } from "react";
import React from "react";

interface IProps {
  cancelText?: string;
  backText?: string;
  nextText?: string;
  cancelCb?(param?: any): any;
  backCb?(param?: any): any;
  backDisabled?: boolean;
  nextCb?(param?: any): any;
  nextDisabled?: boolean;
  nextDisabledTooltipText?: string;
  customButton?: any;
  customButtonIndex?: number;
}

export default function FooterButtons({
  cancelText = "",
  cancelCb,
  backText = "",
  backCb,
  backDisabled = false,
  nextText = "",
  nextCb,
  nextDisabled = false,
  nextDisabledTooltipText = "",
  customButton = null,
  customButtonIndex = 0,
}: IProps) {
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const activeButtonModalGalleryConfirm = useSelector(getActiveButtonModalGalleryConfirm);

  useEffect(() => {
    dispatch(setCurrentButtonModalGalleryConfirm(true));
  }, []);

  useEventListener("keydown", (e: any) => {
    if (activeButtonModalGalleryConfirm) {
      if (e.key === "Enter" && nextButtonRef.current && !nextDisabled) {
        e.preventDefault();
        nextButtonRef.current.click();
      }
    }
  });

  const buttons = [];

  if (cancelText && cancelCb) {
    buttons.push(
      <button
        className="font-medium text-base border-0 mr-3"
        type="button"
        onClick={() => cancelCb()}
      >
        {cancelText}
      </button>,
    );
  }
  if (backText) {
    buttons.push(
      <button
        className="btn-alternative-outline mr-3"
        type="button"
        onClick={() => (backCb ? backCb() : null)}
        disabled={backDisabled}
      >
        {backText}
      </button>,
    );
  }
  if (nextText) {
    buttons.push(
      <Tooltip classNameChildren="right-1" message={nextDisabled ? nextDisabledTooltipText : ""}>
        <button
          ref={nextButtonRef}
          className="btn-primary-fill h-full"
          type="submit"
          onClick={() => (nextCb ? nextCb() : null)}
          disabled={nextDisabled}
        >
          {nextText}
        </button>
      </Tooltip>,
    );
  }

  if (customButton) {
    if (customButtonIndex === 0 || customButtonIndex) {
      buttons.splice(customButtonIndex, 0, customButton);
    } else {
      buttons.push(customButton);
    }
  }

  return (
    <div className="sticky bottom-0 bg-gray-100 w-full py-3 flex justify-end px-5 border-t border-gray-300">
      {buttons.map((button: any, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </div>
  );
}
