import { UserAddIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import ModalStandard from "../../../common/components/Modal/ModalStandard";
import { currentUserSelector } from "../../../common/state/selectors/authSelector";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { RootState, AppDispatch } from "../../../common/state/store";
import { setAlert } from "../../alert/state/alertsSlice";
import {
  getProjectGroupPermissionsFromServer,
  getSelectedProjectGroup,
  setCurrentProjectGroupPermissions,
} from "../../projectGroups/state/projectGroupsSlice";
import {
  getCurrentProject,
  getProjectPermissionsFromServer,
  setCurrentProjectPermissions,
  setProjectTeam,
} from "../../projects/state/projectsSlice";
import DynamicTable from "./DynamicTable";

export const CONFIRM_TEAM_COMPONENT = "teamComponent";

const AddTeamModal = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const [isInCollabPage, setIsInCollabPage] = useState(false);
  useEffect(() => {
    setIsInCollabPage(location.pathname.toLowerCase().includes("team"));
  }, [location]);

  const { user, project, projectGroup } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      project: getCurrentProject(state),
      projectGroup: getSelectedProjectGroup(state),
    };
  });

  // This parent component needs to know if its DynamicTable child will be empty (for style reasons)
  const [isEmpty, setIsEmpty] = useState(false);

  /*
  on checkbox click, call setSelected() to update array of selected 

  BEFORE submit: COMPARE 'selected' with 'currentProjectUserPermissions'; disable button (or onsubmit call) if both arrays are EQ
*/

  const onSubmit = async (data: any) => {
    const inviteeIds: any = data.inviteeId;

    dispatch(closeStandardModal("add-team"));
    if (inviteeIds.length > 0) {
      if ((projectGroup || project) && user) {
        const promisesArray = inviteeIds.map((id: number) => {
          return dispatch(
            setProjectTeam({
              inviteeId: id,
              projectGroupId: isInCollabPage ? projectGroup.id : -1,
              projectId: isInCollabPage ? -1 : project.id,
              componentId: CONFIRM_TEAM_COMPONENT,
              inviterId: user.id,
            }),
          );
        });
        await Promise.all(promisesArray).then((res: any) => {
          if (res[0].payload !== undefined) {
            dispatch(
              setAlert({
                msg: isInCollabPage
                  ? t("alerts.collaboratorInvitedSuccessfully")
                  : t("alerts.participantInvitedSuccessfully"),
                type: "success",
              }),
            );
            if (isInCollabPage) {
              dispatch(
                getProjectGroupPermissionsFromServer({ projectGroupId: Number(projectGroup.id) }),
              ).then((res) => {
                dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
                dispatch(
                  setAlert({
                    msg: t("alerts.collaboratorInvitedSuccessfully"),
                    type: "success",
                  }),
                );
              });
            } else {
              dispatch(getProjectPermissionsFromServer({ projectId: Number(project.id) })).then(
                (res) => {
                  dispatch(setCurrentProjectPermissions((res.payload as any).data));
                  dispatch(
                    setAlert({
                      msg: t("alerts.participantInvitedSuccessfully"),
                      type: "success",
                    }),
                  );
                },
              );
            }
            return res.map((dispatchResponse: any) => dispatchResponse.payload.data);
          }
        });
      }
    }
  };

  return (
    <ModalStandard
      className="flex flex-col max-h-[75vh]"
      id="add-team"
      fullScreen={false}
      minimize={isEmpty}
    >
      <div className="flex mb-3">
        <UserAddIcon className="w-6 h-6 stroke-black mr-3" />
        <h1 className="font-bold text-xl">
          {isInCollabPage
            ? t("pages.project.inviteCollaborators.header")
            : t("pages.project.inviteParticipants.header")}
        </h1>
      </div>
      <IsLoading componentId={CONFIRM_TEAM_COMPONENT} showSuccess={false}>
        <DynamicTable
          onSubmit={onSubmit}
          className="flex flex-col flex-grow"
          setIsEmpty={setIsEmpty}
        />
      </IsLoading>
    </ModalStandard>
  );
};

export default AddTeamModal;
