import React from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegister } from "react-hook-form";
import { Dropdown } from "../Input";

interface DropdownOption {
  value: string;
  optionText: string;
}

interface SortableDropdownProps {
  register?: UseFormRegister<any>;
  setValue?: (name: string, value: any) => void;
  defaultValue?: string;
  additionalOptions?: DropdownOption[];
  onChangeCb?: (value: any) => void;
}

const SortableDropdown: React.FC<SortableDropdownProps> = ({
  register,
  setValue,
  defaultValue = "date_desc",
  additionalOptions = [],
  onChangeCb,
}) => {
  const { t } = useTranslation();

  const defaultOptions: DropdownOption[] = [
    { value: "date_desc", optionText: t("general.date_desc") },
    { value: "date_asc", optionText: t("general.date_asc") },
    { value: "alphanum_desc", optionText: t("general.alphanum_desc") },
    { value: "alphanum_asc", optionText: t("general.alphanum_asc") },
  ];

  const options = [...defaultOptions, ...additionalOptions];

  const handleChange = (value: any) => {
    if (setValue) {
      setValue("order", value);
    }
    if (onChangeCb) {
      onChangeCb(value);
    }
  };

  return (
    <Dropdown
      borderOff
      options={options}
      label=""
      registerFct={register ? () => register("order") : undefined}
      onChangeCb={handleChange}
      asDefaultValue={true}
      defaultValue={defaultValue}
    />
  );
};

export default SortableDropdown;
