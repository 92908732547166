import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import ProjectThumbnail from "./ProjectThumbnail";

const EditProjectThumbnailWrapper = () => {
  const { t } = useTranslation();

  const selectedProject = useSelector(getCurrentProject);

  return (
    <>
      <Navigation
        title={`${t("pages.editProjectName.title")}: ${selectedProject.name}`}
        isProjectView={true}
      />
      <ProjectThumbnail mode="edit-thumbnail" />
    </>
  );
};

export default EditProjectThumbnailWrapper;
