import { useTranslation } from "react-i18next";
import { IsLoading, useLoading } from "../../../common/components/AppState/IsLoading";
import ButtonSquareIconsProjectGroupEnduser from "../../../common/components/Button/ButtonSquareIconsProjectGroupEnduser";
import PlaceholderWithIcon from "../../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { IProjectGroup } from "../../../model/model";
import IProjectGroupsCompletionItem from "../../enduserStats/dataTypes/IProjectGroupsCompletionItem";
import { IProjectGroupWithProjects } from "../state/projectGroupsSlice";
import ButtonSquareIconsProjectGroupCreator from "src/common/components/Button/ButtonSquareIconsProjectGroupCreator";
import { useEffect, useState } from "react";

export const PROJECT_GROUP_LIST_COMPONENT_ID = "projectGroupListComponent";
export const PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID = "projectGroupListWithLimitComponent";
export const PROJECT_GROUP_LIST_PROJECTS = "projectGroupListProjects";

export interface IProjectGroupIdAndName {
  id: number;
  name: string;
}

type ProjectGroupListProps = {
  projectGroups?: IProjectGroup[] | IProjectGroupsCompletionItem[];
  projectGroupsWithNestedElements?: IProjectGroupWithProjects[];
  componentId?: string;
  simplified?: boolean;
  handleClick: (projectGroup: any) => void;
  handleThreeDotButtonClick: (e: any) => void;
  isEnduser?: boolean;
  setEditedProjectGroupId?: (id: any, projectGroup: any) => void;
};

const ProjectGroupList = ({
  handleThreeDotButtonClick,
  handleClick,
  simplified,
  projectGroups = [],
  projectGroupsWithNestedElements = [],
  isEnduser = false,
}: ProjectGroupListProps) => {
  const { t } = useTranslation();
  const pgsLoadingState = useLoading(PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID);
  const projectGroupsAreLoading = pgsLoadingState < 2;

  // We want to display only one Project Group when the display is too thin (<1280px)
  const [appWidth, setAppWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setAppWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const numberOfProjectGroupToDisplayInSimplifiedMode = appWidth < 1280 ? 1 : 2;

  // If we're in dashboard, we display only 3 project-groups for endusers, 2 for creators. else we display the whole thingy
  const projectGroupList = simplified
    ? projectGroups
        .slice(0, isEnduser ? 3 : numberOfProjectGroupToDisplayInSimplifiedMode)
        .sort((a: any, b: any) => b.updated_at_ts - a.updated_at_ts)
    : projectGroups;

  const projectGroupsWithNestedElementsList = [...projectGroupsWithNestedElements].slice(
    0,
    simplified
      ? numberOfProjectGroupToDisplayInSimplifiedMode
      : projectGroupsWithNestedElements.length,
  );

  /*
  We need to handle several things: 
    - projectGroupList.length > 0 AFTER LOADING, to display a placeholder, for creators only
    - simplified (call from Dashboard) or not (call from Project groups list)
    - enduser (props.isEnduser) or not
  */

  const ProjectGroups = () => {
    if (!projectGroupsAreLoading) {
      if (
        (isEnduser && projectGroupList.length === 0) ||
        (!isEnduser && projectGroupsWithNestedElementsList.length === 0)
      ) {
        return (
          <div
            className={`${
              isEnduser ? " h-40" : "absolute h-72 left-[40%] flex items-center justify-center"
            } `}
          >
            <PlaceholderWithIcon
              className={`flex flex-col items-center ${isEnduser ? "h-4/5" : "h-2/5"}`}
              title={t("pages.project.noProjectGroupTitle")}
              text={
                isEnduser
                  ? t("pages.project.noProjectGroupBodyEnduser")
                  : t("pages.project.noProjectGroupBody")
              }
              heroIcon={isEnduser ? "InformationCircleIcon" : "FolderAddIcon"}
            />
          </div>
        );
      }
    }
    if (isEnduser && projectGroupList.length <= 3 && projectGroupsAreLoading) {
      return (
        <IsLoading componentId={PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID} showSuccess={false} />
      );
    } else {
      return (
        <div className="w-full flex flex-col justify-start align-center">
          <div
            className={`grid ${
              isEnduser
                ? "xl:grid-cols-6 md:grid-cols-4 grid-cold:2"
                : simplified
                ? "grid-cols-2 xl:grid-cols-2 md:grid-cols-1"
                : ""
            } gap-6 md:gap-10`}
          >
            {isEnduser
              ? projectGroupList.map(
                  (projectGroup: IProjectGroup | IProjectGroupsCompletionItem, i: number) => {
                    const enduserPg = projectGroup as IProjectGroupsCompletionItem;
                    const creatorPg = projectGroup as IProjectGroup;
                    const pgid = isEnduser ? enduserPg.projectGroupId : creatorPg.id;
                    return (
                      <ButtonSquareIconsProjectGroupEnduser
                        key={`zblu=${i * 33}`}
                        handleThreeDotButtonClick={handleThreeDotButtonClick}
                        currentProjectGroup={pgid}
                        projectGroup={isEnduser ? enduserPg : creatorPg}
                        onClick={() => handleClick(creatorPg)}
                        isEnduser={isEnduser}
                      />
                    );
                  },
                )
              : projectGroupsWithNestedElementsList.map(
                  (pgWithNestedElementList: IProjectGroupWithProjects, i: number) => {
                    return (
                      <ButtonSquareIconsProjectGroupCreator
                        key={`zblu=${i * 22}`}
                        projectGroupWithProjects={pgWithNestedElementList}
                        handleThreeDotButtonClick={handleThreeDotButtonClick}
                        openProjectGroup={handleClick}
                        simplified={simplified}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          handleClick(pgWithNestedElementList);
                        }}
                      />
                    );
                  },
                )}
            {
              // only show the spinner below current projectGroupsWithProjects when not on the dashboard
              // the weird second condition handles the cases where the user refresh (F5) while on Dashboard
              (!simplified ||
                (simplified &&
                  projectGroupsAreLoading &&
                  projectGroupsWithNestedElements.length === 0)) && (
                <IsLoading
                  componentId={PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID}
                  showSuccess={false}
                  spinnerPlaceholder
                />
              )
            }
          </div>
        </div>
      );
    }
  };

  return <ProjectGroups />;
};

export default ProjectGroupList;
