import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  getEditedProjectGroup,
  getNewProjectGroup,
  getSelectedProjectGroup,
  setEditedThumbnail,
  setNewProjectGroup,
  updateProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { DynamicMedia } from "../../features/graph/components/DynamicField";
import {
  mediaSelector,
  setMediaFilter,
  setOriginalMediaGallery,
} from "../../features/gallery/state/gallerySlice";
import { useEffect, useState } from "react";
import { IMedia } from "../../model/unityObject";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import ModalGallery from "../../features/graph/components/ModalGallery";
import { FooterButtons } from "../../common/components/FooterButtons";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "src/features/projectGroups/components/ProjectGroupList";

interface ICreateProjectGroupThumbnail {
  incrementStep?: () => void;
  decrementStep?: () => void;
  editMode?: boolean;
}

const CreateProjectGroupThumbnail = ({
  incrementStep,
  decrementStep,
  editMode = false,
}: ICreateProjectGroupThumbnail) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, control } = useForm<any>();

  const mediasList = useSelector(mediaSelector);
  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);

  const [currentThumbnailId, setCurrentThumbnailId] = useState<number>(47); // logoWixar by default

  const { newProjectGroup, selectedProjectGroup, editedProjectGroup } = useSelector(
    (state: RootState) => {
      return {
        newProjectGroup: getNewProjectGroup(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProjectGroup: getEditedProjectGroup(state),
      };
    },
  );

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  // Dance around the media preview issues that arose from repurposing the "EditNodeForm" component
  const getEnhancedBgMusicField = () => {
    const enhancedField = {
      type: "media",
      mediaType: "image",
      fieldName: "source_thumbnail",
      currentValue: currentThumbnailId,
      relevantSourceMedia: mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentThumbnailId),
      ),
    };
    if (!currentThumbnailId) {
      enhancedField.currentValue = -1;
      enhancedField.relevantSourceMedia = mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentThumbnailId),
      );
    }
    return enhancedField;
  };

  const openGallery = () => {
    dispatch(setMediaFilter({ ...currentFilter, mediaType: "image" }));
    dispatch(setOriginalMediaGallery(currentThumbnailId));
    dispatch(openStandardModal("modal-gallery"));
  };

  const onSubmit = () => {
    openGallery();
  };

  // Callback from ModalGallery selection
  const onMediaChange = (selectedMedia: IMedia) => {
    dispatch(closeStandardModal("modal-gallery"));

    setCurrentThumbnailId(Number(selectedMedia.id));
  };

  useEffect(() => {
    if (editMode) {
      const tbnId = Number(editedProjectGroup.source_thumbnail.id);
      setValue("thumbnailId", tbnId);
      setCurrentThumbnailId(tbnId);
    } else {
      const tbnId = newProjectGroup.thumbnailId;
      setValue("thumbnailId", tbnId);
      setCurrentThumbnailId(tbnId);
    }
  }, [editedProjectGroup.source_thumbnail]);

  const enhancedField = getEnhancedBgMusicField();

  const handleSave = () => {
    if (editMode) {
      dispatch(
        updateProjectGroup({
          id: selectedProjectGroup.id,
          payload: {
            source_thumbnail: enhancedField.relevantSourceMedia,
          },
          componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
        }),
      );
      dispatch(setEditedThumbnail(enhancedField.relevantSourceMedia as IMedia));
      navigate(`/courses/${selectedProjectGroup.id}/list`);
    }
  };

  const handleCancel = () => {
    navigate(`/courses/${selectedProjectGroup.id}/list`);
  };

  return (
    <>
      <h1 className="text-2xl my-5 px-6 font-medium">{t("general.selectThumbnail")}</h1>
      <ModalGallery nodeId={"new-project-group-tbn"} onMediaChange={onMediaChange} />

      <form className="flex flex-col grow w-full justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-10 w-full my-5 px-6">
          <div className="flex flex-col items-center justify-center bg-green-200 p-6 w-full max-w-max">
            <Controller
              control={control}
              name={"source_thumbnail"}
              render={({ field }) => (
                <DynamicMedia
                  field={field as any}
                  fieldType={enhancedField.type}
                  register={register}
                  handleButtonClick={() => {
                    openGallery();
                  }}
                  currentMedia={enhancedField.currentValue as number}
                  media={enhancedField.relevantSourceMedia as IMedia}
                />
              )}
            />
          </div>
        </div>
        {editMode ? (
          <FooterButtons
            cancelCb={handleCancel}
            cancelText={t("general.cancel")}
            nextText={t("general.save")}
            nextCb={handleSave}
          />
        ) : (
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() =>
              navigate(`/courses${editMode ? `/${selectedProjectGroup.id}/list` : ""}`)
            }
            backText={t("general.back")}
            backCb={decrementStep}
            nextText={t("general.next")}
            nextCb={() => {
              if (editMode) {
                dispatch(setEditedThumbnail(enhancedField.relevantSourceMedia as IMedia));
              } else {
                dispatch(
                  setNewProjectGroup({ ...newProjectGroup, thumbnailId: currentThumbnailId }),
                );
              }

              if (incrementStep) {
                incrementStep();
              }
            }}
            nextDisabled={!enhancedField.relevantSourceMedia}
          />
        )}
      </form>
    </>
  );
};

export default CreateProjectGroupThumbnail;
