import { useSelector } from "react-redux";
import {
  getSelectedProjectGroupId,
  getSelectedProjectId,
} from "../../features/stats/state/statsSlice";
import StatisticsForProject from "./StatisticsForProject";
import StatisticsForProjectGroup from "./StatisticsForProjectGroup";
import StatisticsOverview from "./StatisticsOverview";

const Statistics = () => {
  const selectedProjectId = useSelector(getSelectedProjectId);
  const selectedProjectGroupId = useSelector(getSelectedProjectGroupId);
  return selectedProjectGroupId === 0 && selectedProjectId === 0 ? (
    <StatisticsOverview />
  ) : selectedProjectGroupId !== 0 && selectedProjectId === 0 ? (
    <StatisticsForProjectGroup />
  ) : (
    <StatisticsForProject />
  );
};

export default Statistics;
