import { Dropdown } from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import media360TagOptionsForGivenMediaType from "../../../common/util/media360TagOptionsForGivenMediaType";
import { IMedia } from "../../../model/unityObject";

type IMedia360TagDropdown = {
  mode: "edit" | "upload";
  mediaType?: string;
  onChangeCb?: any;
  media?: IMedia;
  mobile?: boolean;
  registerFct?: any;
};

export default function Media360TagDropdown({
  mode = "upload",
  mediaType,
  onChangeCb,
  media,
  mobile = false,
  registerFct = () => null,
}: IMedia360TagDropdown) {
  const { t } = useTranslation();

  const defaultValueIndex = media && media.media_360_tag === "media360" ? 0 : 1;

  const renderAppropriateComponent = () => {
    const mediaTypeWhichIsAlwaysDefined_TrustMeImAnEngineer = mediaType
      ? mediaType
      : (media?.type as string);
    const options = media360TagOptionsForGivenMediaType(
      mediaTypeWhichIsAlwaysDefined_TrustMeImAnEngineer,
    );

    if (options.length === 0) {
      return <></>;
    } else {
      switch (mode) {
        case "upload":
          return (
            <Dropdown
              options={options}
              label={t("pages.gallery.uploadPanel.selectMediaType")}
              onChangeCb={onChangeCb}
              asDefaultValue
              defaultValueIndex={defaultValueIndex}
              defaultValue={options[defaultValueIndex].value}
              mobile={mobile}
            />
          );
        case "edit":
          return (
            <Dropdown
              options={options}
              label={t("pages.gallery.uploadPanel.selectMediaType")}
              asDefaultValue
              defaultValueIndex={defaultValueIndex}
              defaultValue={options[defaultValueIndex].value}
              mobile={mobile}
              registerFct={registerFct}
            />
          );
        default:
          return <></>;
      }
    }
  };

  return renderAppropriateComponent();
}
