import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";

export type InfoFrameStyle = "danger" | "warning" | "success";

type InfoFrameProps = {
  children?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
  style: InfoFrameStyle;
  withTimeout?: boolean;
  customTimeoutInMs?: number;
  className?: string;
};

type InfoFrameStyleParams = {
  mainColor: string;
  backgroundClasses: string;
  closeButtonClasses: string;
  timeBeforeHiddingMs: number;
  closeIconClasses: string;
};

const InformationFrame = ({
  children = true,
  showCloseButton = true,
  onClose,
  style,
  withTimeout = false,
  customTimeoutInMs,
  className,
}: InfoFrameProps) => {
  const [currentFrameStyle, setCurrentFrameStyle] = useState<InfoFrameStyleParams>();

  const getFrameStyleParams = (style: string | undefined): InfoFrameStyleParams => {
    switch (style) {
      case "danger":
        return {
          mainColor: "red",
          backgroundClasses: "bg-red-100 dark:bg-red-200 text-red-700 dark:text-red-800",
          closeButtonClasses:
            "focus:ring-red-400 bg-red-100 hover:bg-red-200 dark:bg-red-200 dark:hover:bg-red-300",
          timeBeforeHiddingMs: customTimeoutInMs ? customTimeoutInMs : 10000,
          closeIconClasses: "stroke-red-700",
        };
      case "success":
        return {
          mainColor: "green",
          backgroundClasses: "bg-green-100 dark:bg-green-200 text-green-700 dark:text-green-800",
          closeButtonClasses:
            "focus:ring-green-400 bg-green-100 hover:bg-green-200 dark:bg-green-200 dark:hover:bg-green-300",
          timeBeforeHiddingMs: customTimeoutInMs ? customTimeoutInMs : 5000,
          closeIconClasses: "stroke-green-700",
        };
      case "warning":
        return {
          mainColor: "yellow",
          backgroundClasses:
            "bg-yellow-100 dark:bg-yellow-200 text-yellow-700 dark:text-yellow-800",
          closeButtonClasses:
            "focus:ring-yellow-400 bg-yellow-100 hover:bg-yellow-200 dark:bg-yellow-200 dark:hover:bg-yellow-300",
          timeBeforeHiddingMs: customTimeoutInMs ? customTimeoutInMs : 10000,
          closeIconClasses: "stroke-yellow-700",
        };
      default:
        return {
          mainColor: "unknown",
          backgroundClasses: "",
          closeButtonClasses: "",
          timeBeforeHiddingMs: -1,
          closeIconClasses: "",
        };
    }
  };

  useEffect(() => {
    const styleParams = getFrameStyleParams(style);
    setCurrentFrameStyle(styleParams);
    if (withTimeout && onClose) {
      setTimeout(() => {
        onClose();
      }, styleParams.timeBeforeHiddingMs);
    }
  }, [style, onClose, withTimeout]);

  return (
    <>
      <div className={`${className} pointer-events-none`}>
        <div
          id="alert-4"
          className={`flex p-4 my-4 rounded-lg relative ${currentFrameStyle?.backgroundClasses}`}
        >
          <div className="self-center">
            <InformationCircleIcon className={"w-5 h-5"} />
          </div>

          <div className="w-[95%]">
            <div className="ml-3 text-sm font-medium">{children}</div>
          </div>

          <div className="self-center">
            {showCloseButton && (
              <button
                type="button"
                className={`ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 pointer-events-auto ${currentFrameStyle?.closeButtonClasses}`}
                data-dismiss-target="#alert-4"
                aria-label="Close"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XIcon className={`w-5 h-5 ${currentFrameStyle?.closeIconClasses}`} />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationFrame;
