import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/state/store";

export interface ModalState {
  showStandardModal: { show: boolean; id: string };
  showFormModal: boolean;
  showPopupModal: boolean;
  showSlider: { show: boolean; id: string };
  showConfirmationModal: { show: boolean; id: string };
  showOnboardingModal: { show: boolean; id: string };
  showMediaPreviewModal: { show: boolean; id: string };
  animationClassState: string;
}

const initialState: ModalState = {
  showStandardModal: { show: false, id: "" },
  showFormModal: false,
  showPopupModal: false,
  showSlider: { show: false, id: "" },
  showConfirmationModal: { show: false, id: "" },
  showMediaPreviewModal: { show: false, id: "" },
  showOnboardingModal: { show: false, id: "" },
  animationClassState: "translate-x-full  ease-in duration-[400ms]",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openFormModal: (state: ModalState) => {
      state.showFormModal = true;
    },
    closeFormModal: (state: ModalState) => {
      state.showFormModal = false;
    },
    openPopupModal: (state: ModalState) => {
      state.showPopupModal = true;
    },
    closePopupModal: (state: ModalState) => {
      state.showPopupModal = false;
    },
    openSlider: (state: ModalState, actions) => {
      state.showSlider = {
        show: true,
        id: actions.payload,
      };
    },
    closeSlider: (state: ModalState, actions) => {
      state.showSlider = {
        show: false,
        id: actions.payload,
      };
    },
    openOnboardingModal: (state: ModalState, actions) => {
      state.showOnboardingModal = {
        show: true,
        id: actions.payload,
      };
    },
    closeOnboardingModal: (state: ModalState, actions) => {
      state.showOnboardingModal = {
        show: false,
        id: actions.payload,
      };
    },
    openStandardModal: (state: ModalState, actions) => {
      state.showStandardModal = {
        show: true,
        id: actions.payload,
      };
    },
    closeStandardModal: (state: ModalState, actions) => {
      state.showStandardModal = {
        show: false,
        id: actions.payload,
      };
    },
    openConfirmationModal: (state: ModalState, actions) => {
      state.showConfirmationModal = {
        show: true,
        id: actions.payload,
      };
    },
    closeConfirmationModal: (state: ModalState, actions) => {
      state.showConfirmationModal = {
        show: false,
        id: actions.payload,
      };
    },
    openMediaPreviewModal: (state: ModalState, actions) => {
      state.showMediaPreviewModal = {
        show: true,
        id: actions.payload,
      };
    },
    closeMediaPreviewModal: (state: ModalState, actions) => {
      state.showMediaPreviewModal = {
        show: false,
        id: actions.payload,
      };
    },
    setAnimationClassState: (state: ModalState, actions) => {
      state.animationClassState = actions.payload;
    },
  },
});
export const getCurrentModalState = (state: RootState) => state.modals.showStandardModal.id;
export const getShowStandardModal = (state: RootState) => state.modals.showStandardModal;
export const getShowMediaPreviewModal = (state: RootState) => state.modals.showMediaPreviewModal;
export const getCurrentOnboardingModalState = (state: RootState) =>
  state.modals.showOnboardingModal;
export const getCurrentConfirmModalState = (state: RootState) =>
  state.modals.showConfirmationModal.id;
export const getCurrentModalCurrentState = (state: RootState) => state.modals;
export const getCurrentModalStateSlider = (state: RootState) => state.modals.showSlider.id;
export const getCurrentModalStateSliderShow = (state: RootState) => state.modals.showSlider.show;
export const getCurrentAnimationState = (state: RootState) => state.modals.animationClassState;
export const getConfirmationModalStateShow = (state: RootState) =>
  state.modals.showConfirmationModal.show;

export const isModalGalleryOpen = (state: RootState) => {
  return (
    state.modals.showStandardModal.id === "modal-gallery" && state.modals.showStandardModal.show
  );
};

export const {
  openFormModal,
  closeFormModal,
  openPopupModal,
  closePopupModal,
  setAnimationClassState,
  openSlider,
  closeSlider,
  openStandardModal,
  openOnboardingModal,
  openMediaPreviewModal,
  closeMediaPreviewModal,
  closeOnboardingModal,
  closeStandardModal,
  openConfirmationModal,
  closeConfirmationModal,
} = modalSlice.actions;

export default modalSlice.reducer;
