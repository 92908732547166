import { InformationCircleIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import CardsSelector from "../../../common/components/CardsSelector/CardsSelector";
import { FooterButtons } from "../../../common/components/FooterButtons";
import ManualInterventionIcon from "../../../common/components/Icon/ManualInterventionIcon";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import { useAppDispatch } from "../../../common/state/hooks";
import { getPlatformBuildInfo } from "../../../common/util/getPlatformBuildInfo";
import { IBuild } from "../../../model/model";
import { setAlert, setPublishWarningText } from "../../alert/state/alertsSlice";
import {
  askPublication,
  getLatestBuildsOfProjectGroup,
  getNewPublicationSettings,
  IAskPublicationPayload,
} from "../../builds/state/buildsSlice";
import { getSelectedProjectGroup } from "../../projectGroups/state/projectGroupsSlice";
import PlatformCards, { IPurePlatformCard } from "./PlatformCards";

type Props = {
  decrementStep?(): void;
  isTheOnlyPublishingStep?: boolean;
};

export const SELECT_PLATFORMS_STEP_COMPONENT_ID = "selectPlatformsStepComponentId";

export default function SelectPlatformsStep({
  decrementStep,
  isTheOnlyPublishingStep = false,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const newPublicationSettings = useSelector(getNewPublicationSettings);
  const latestBuildsOfProjectGroup = useSelector(
    getLatestBuildsOfProjectGroup(selectedProjectGroup.id),
  );

  const platformCardsWithContext = PlatformCards.map((pc: IPurePlatformCard) => {
    return {
      ...pc,
      selected: false,
      disabled: isTheOnlyPublishingStep
        ? Boolean(latestBuildsOfProjectGroup.find((b: IBuild) => b.target_platform === pc.id))
        : false,
      component: (
        <>
          {getPlatformBuildInfo(pc.id)?.isManualBuild ? (
            <>
              <div>{pc.component}</div>
              <div className="relative left-[2.5rem] bottom-[3.5rem] w-6">
                <Tooltip
                  message={t("pages.projectGroup.publish.manualInterventionTooltip")}
                  classNameChildren="w-64"
                  forceSingleLine={false}
                >
                  <ManualInterventionIcon />
                </Tooltip>
              </div>
            </>
          ) : (
            pc.component
          )}
        </>
      ),
    };
  });
  const [platformsCards, setPlatformsCards] = useState(platformCardsWithContext);

  const latestBuild = latestBuildsOfProjectGroup[0];

  const navigateToPublishView = () => navigate(`/courses/${selectedProjectGroup.id}/publish`);

  const handleSubmit = () => {
    const payload: IAskPublicationPayload = {
      projectGroupId: selectedProjectGroup.id,
      selectedProjectsIds: [],
      selectedPlatforms: platformsCards.filter((pc) => pc.selected).map((pc) => pc.id),
      shouldBeTested: 0,
      isNewVersion: false,
      callback: navigateToPublishView,
    };

    // When it isTheOnlyPublishingStep, we know a previous build exists, so we can take the data from there
    if (isTheOnlyPublishingStep) {
      payload.selectedProjectsIds =
        latestBuild.project_group_snapshot_sorted_by_order.published_projects.map(
          (p) => p.project.id,
        );
      payload.shouldBeTested = latestBuild.should_be_tested ? 1 : 0;
    } else {
      // Else we get the data from the store
      payload.selectedProjectsIds = newPublicationSettings.selectedProjectsIds;
      payload.shouldBeTested = newPublicationSettings.shouldBeTested;
      payload.isNewVersion = newPublicationSettings.isNewVersion;
    }

    dispatch(askPublication({ ...payload, componentId: SELECT_PLATFORMS_STEP_COMPONENT_ID }))
      .then(() => {
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.requestSent"),
            subMsg: t("alerts.publicationSuccess"),
            customTimeoutInMs: 30000,
          }),
        );
        navigateToPublishView();
      })
      .catch(() => {
        dispatch(setAlert({ type: "danger", msg: t("alerts.publicationFail") }));
      });
  };

  useEffect(() => {
    if (isTheOnlyPublishingStep) {
      dispatch(setPublishWarningText(t("pages.projectGroup.publish.pleaseVerifyBeforeAsking")));
    }
    return () => {
      dispatch(setPublishWarningText("")) as any;
    };
  }, []);

  const timeEstimateForPublication = platformsCards.reduce((acc, val) => {
    const platformInfo = getPlatformBuildInfo(val.id);
    if (val.selected && platformInfo) {
      return acc + platformInfo.buildDurationInMin;
    }
    return acc;
  }, 0);

  return (
    <div className="flex flex-col grow w-full h-full justify-between">
      <IsLoading
        componentId={SELECT_PLATFORMS_STEP_COMPONENT_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <div className="flex flex-col items-center justify-content h-full">
          <CardsSelector
            cards={platformsCards}
            parentClassName="h-full flex flex-row items-center"
            cardClassName="flex flex-col items-center justify-content p-2 m-1 w-32 h-20 border-2 cursor-pointer select-none rounded-md w-1/10"
            disabledTooltipText={t("pages.projectGroup.publish.unselectablePlatformTooltip")}
            cardClickCb={(cardId: string) => {
              setPlatformsCards(
                [...platformsCards].map((pc: any) => {
                  return {
                    ...pc,
                    selected: pc.disabled ? false : pc.id === cardId ? !pc.selected : pc.selected,
                  };
                }),
              );
            }}
          />

          <div
            className={`text-red-700 m-4 mx-32 flex items-center ${
              platformsCards.find(
                (e) =>
                  (e.id === "iPhone" ||
                    e.id === "iPad" ||
                    e.id === "MacOS" ||
                    e.id === "WebGLiframe" ||
                    e.id === "WebGLApp") &&
                  e.selected,
              )
                ? ""
                : "invisible"
            }`}
          >
            <div className="mr-2 w-5 h-5">
              <InformationCircleIcon className={"w-full h-full"} />
            </div>
            <h3>{t("pages.projectGroup.publish.contactNeededWarning")}</h3>
          </div>

          <div className="flex justify-end w-full">
            <h6 className="font-bold m-4">
              {
                // If there is no estimate, display an empty space so the layout doesnt' move each time this condition is updated
                timeEstimateForPublication > 0
                  ? `${t(
                      "pages.projectGroup.publish.publishDuration",
                    )} ${timeEstimateForPublication} ${t("pages.projectGroup.publish.minutes")}
								
								`
                  : "\u00A0"
              }
            </h6>
          </div>
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => navigateToPublishView()}
          backText={t("general.back")}
          backCb={isTheOnlyPublishingStep ? navigateToPublishView : decrementStep}
          nextText={t("general.next")}
          nextCb={() => handleSubmit()}
          nextDisabled={!platformsCards.find((pc: any) => pc.selected)}
        />
      </IsLoading>
    </div>
  );
}
