import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../common/state/hooks";
import InformationComponent from "./InformationComponent";
import { prettifyFileSizeInBytes } from "../../common/util/prettifyFileSizeInBytes";
import { ILanguage } from "../../model/model";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { useTranslation } from "react-i18next";
import {
  getCurrentElements,
  getCurrentProjectGroupWeight,
  getProjectGroupSize,
  getSelectedProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import { languageConfig } from "../../common/util/langConfig";
import { fetchThemes, getThemes } from "src/features/themes/state/themeSlice";
import { dateConfig } from "src/common/util/DateConfig";

export const PROJECT_GROUP_WEIGHT_COMPONENT_ID = "projectGroupWeight";
export const PROJECT_SIZE_WEIGHT_COMPONENT_ID = "projectWeight";

const ProjectGroupInformation = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const dispatch = useAppDispatch();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const getElementsForId = useSelector(getCurrentElements);
  const currentProjectGroupWeight = useSelector(getCurrentProjectGroupWeight);
  const themes = useSelector(getThemes);

  const [isLoading, setIsLoading] = useState(false);

  const arrayForSendIds = getElementsForId.map((project) => {
    return project.project.id;
  });

  useEffect(() => {
    if (arrayForSendIds.length > 0) {
      setIsLoading(true);
      dispatch(
        getProjectGroupSize({
          projectsIds: arrayForSendIds.toString(),
          componentId: PROJECT_GROUP_WEIGHT_COMPONENT_ID,
        }),
      ).finally(() => setIsLoading(false));
    }

    dispatch(fetchThemes({ componentId: PROJECT_GROUP_WEIGHT_COMPONENT_ID }));
  }, []);

  const translateTypeAuth = (type: string) => {
    switch (type) {
      case "classic":
        return t("pages.accessTypeTable.passwordLogin");
      case "simplified":
        return t("pages.accessTypeTable.simplifiedLogin");
      case "none":
        return t("pages.accessTypeTable.noLogin");
      default:
        return t("pages.accessTypeTable.noLogin");
    }
  };

  const createdByTranslate = currentProjectGroup.created_by
    ? currentProjectGroup.created_by
    : t("general.noCreator");

  const projectGroupWeight = currentProjectGroupWeight ? currentProjectGroupWeight : 0;

  const themeName =
    themes?.find((t) => {
      if (typeof currentProjectGroup.theme_info === "number") {
        return Number(t.id) === Number(currentProjectGroup.theme_info);
      } else if (
        typeof currentProjectGroup.theme_info === "object" &&
        currentProjectGroup.theme_info !== null
      ) {
        return Number(t.id) === Number(currentProjectGroup.theme_info.id);
      } else {
        return false;
      }
    })?.name ?? "?";

  const getDateCurrentLocale = (date: string) => {
    return new Date(date).toLocaleDateString(dateConfig(currentLocale));
  };

  return (
    <IsLoading componentId={PROJECT_GROUP_WEIGHT_COMPONENT_ID} showSuccess={false}>
      <div className="relative grow w-full max-w-7xl px-4 xl:px-0 mx-auto">
        <h2 className="text-2xl font-medium mb-2 mt-4">
          {t("pages.projectGroup.information.tabTitle")}
        </h2>
        <h3 className="py-3">{t("general.informationCourse")}</h3>
        <InformationComponent
          title={t("general.coursesName")}
          moduleName={currentProjectGroup.name}
        />
        <InformationComponent
          title={t("general.weight")}
          moduleName={
            isLoading
              ? t("general.loading")
              : String(prettifyFileSizeInBytes(projectGroupWeight, currentLocale))
          }
          infoTooltipText={t("tooltip.mediasWeightDetail")}
        />
        <InformationComponent title={"Creator"} moduleName={String(createdByTranslate)} />
        <InformationComponent
          title={t("general.createdAt")}
          moduleName={getDateCurrentLocale(currentProjectGroup.createdAt)}
        />
        <InformationComponent
          title={t("general.updatedAt")}
          moduleName={getDateCurrentLocale(currentProjectGroup.updatedAt)}
        />

        <InformationComponent
          title={t("general.authenticationType")}
          moduleName={String(translateTypeAuth(String(currentProjectGroup.auth_type)))}
        />
        <InformationComponent
          title={t("pages.projectGroup.information.theme")}
          moduleName={themeName}
        />
      </div>
    </IsLoading>
  );
};

export default ProjectGroupInformation;
