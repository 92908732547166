interface IZendeskArticlesParams {
  language: "en" | "fr";
  reference: string;
}

interface IArticle {
  [key: string]: {
    fr: {
      prettyTitle: string;
      url: string;
    };
    en: {
      prettyTitle: string;
      url: string;
    };
  };
}

const articles: IArticle = {
  howToAddScene: {
    fr: {
      prettyTitle: "Comment ajouter une scène",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406102829458-Ajouter-une-sc%C3%A8ne",
    },
    en: {
      prettyTitle: "How to add scene",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406102839570-Add-new-scene",
    },
  },
  getStarted: {
    fr: {
      prettyTitle: "Je débute en 2 minutes",
      url: "https://wixar.zendesk.com/hc/fr/articles/360017710859-Cr%C3%A9er-un-projet-avec-WiXar",
    },
    en: {
      prettyTitle: "Getting started in two minutes",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406093230994-Create-a-project",
    },
  },
  howToLinkScene: {
    fr: {
      prettyTitle: "Enchaîner des interactions et/ou des scènes entre elles",
      url: " https://wixar.zendesk.com/hc/fr/articles/4406244398738-Enchainer-des-interactions-et-ou-des-sc%C3%A8nes-entre-elles",
    },
    en: {
      prettyTitle: "Link interactions and/or scenes together",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406239858578-Linking-interactions-and-or-scenes-together",
    },
  },
  howToPublish: {
    fr: {
      prettyTitle: "Comment publier mon application",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406098956690-Publier-votre-application",
    },
    en: {
      prettyTitle: "How to publish my application",
      url: "https://wixar.zendesk.com/hc/fr/articles/4406103127570-Publish-your-app",
    },
  },
  howToChooseAuthMode: {
    fr: {
      prettyTitle: "Choisir le bon mode d'authentification",
      url: "https://wixar.zendesk.com/hc/fr/articles/7865490996114-Choisir-le-bon-mode-d-authentification",
    },
    en: {
      prettyTitle: "How to choose the right authentication method",
      url: "https://wixar.zendesk.com/hc/fr/articles/7865607342738-How-to-choose-the-right-authentication-method",
    },
  },
  aboutCourses: {
    fr: {
      prettyTitle: "Plus d'info sur le parcours",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464091932818-Plus-d-info-sur-le-parcours-",
    },
    en: {
      prettyTitle: "About the courses",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464093495570-About-the-courses",
    },
  },
  aboutModules: {
    fr: {
      prettyTitle: "A propos des modules",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464193684754-A-propos-des-modules",
    },
    en: {
      prettyTitle: "About modules",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464194451602-About-modules",
    },
  },
  aboutWebgl: {
    fr: {
      prettyTitle: "L'avantage de l'aperçu sur notre Editor Web",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464297444626-L-avantage-de-l-aper%C3%A7u-sur-notre-Editor-Web-",
    },
    en: {
      prettyTitle: "The advantage of the preview on our Web Editor",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464375608978-The-advantage-of-the-preview-on-our-Web-Editor",
    },
  },
  aboutCollaborators: {
    fr: {
      prettyTitle: "Vos collaborateurs dans Wixar",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464463842962-Vos-collaborateurs-dans-Wixar",
    },
    en: {
      prettyTitle: "Your collaborators in Wixar",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464464757650-Manage-your-collaborators-in-wixar",
    },
  },
  aboutParticipants: {
    fr: {
      prettyTitle: "Vos participants dans Wixar",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464573223698-G%C3%A9rer-vos-participants-",
    },
    en: {
      prettyTitle: "Your participants in Wixar",
      url: "https://wixar.zendesk.com/hc/fr/articles/8464573565330-Manage-your-participants",
    },
  },
  aboutPublication: {
    fr: {
      prettyTitle: "À savoir avant de demander une publication",
      url: "https://wixar.zendesk.com/hc/fr/articles/8575867132306-%C3%A0-savoir-avant-de-demander-une-publication",
    },
    en: {
      prettyTitle: "Good to know before requesting a publication",
      url: "https://wixar.zendesk.com/hc/fr/articles/8575881293970-Good-to-know-before-requesting-a-publication",
    },
  },

  aboutStatistiques: {
    fr: {
      prettyTitle: "La vue statistique",
      url: "https://wixar.zendesk.com/hc/fr/articles/8618236506002-La-vue-statistique-",
    },
    en: {
      prettyTitle: "The statistics overview",
      url: "https://wixar.zendesk.com/hc/fr/articles/8618235809810-The-statistics-overview",
    },
  },

  aboutSoundtrack: {
    fr: {
      prettyTitle: "Ajouter une ambiance sonore à vos modules",
      url: "https://wixar.zendesk.com/hc/fr/articles/8616931321234-Ajouter-une-ambiance-sonore-%C3%A0-vos-modules",
    },
    en: {
      prettyTitle: "Add a soundtrack to your modules",
      url: "https://wixar.zendesk.com/hc/fr/articles/8616992279314-Add-a-soundtrack-to-your-modules-",
    },
  },
  aboutWixarClient: {
    fr: {
      prettyTitle: "Le Wixar Hub",
      url: "https://wixar.zendesk.com/hc/fr/articles/9443508004370-Le-Client-Wixar",
    },
    en: {
      prettyTitle: "The Wixar Hub",
      url: "https://wixar.zendesk.com/hc/fr/articles/9443618355218-The-Wixar-Client",
    },
  },
  modulePublished: {
    //EN not translate
    fr: {
      prettyTitle: "Module publié, que faire ?",
      url: "https://wixar.zendesk.com/hc/fr/articles/10335984107666",
    },
    en: {
      prettyTitle: "Module published, what to do ?",
      url: "https://wixar.zendesk.com/hc/fr/articles/10397653651218",
    },
  },
  manageSubscription: {
    fr: {
      prettyTitle: "Gérer votre abonnement",
      url: "https://wixar.zendesk.com/hc/fr/articles/10780205470610-Fonctionnalit%C3%A9s-incluses-dans-un-abonnement-Wixar",
    },
    en: {
      prettyTitle: "Manage your subscription",
      url: "https://wixar.zendesk.com/hc/fr/articles/10781803449106-Features-included-in-a-Wixar-subscription",
    },
  },
  helpWithMediaExtensions: {
    fr: {
      prettyTitle: "Aide aux extensions de médias",
      url: "https://wixar.zendesk.com/hc/fr/articles/14980505917714-Aide-aux-extensions-de-m%C3%A9dias",
    },
    en: {
      prettyTitle: "Help with media extensions",
      url: "https://wixar.zendesk.com/hc/fr/articles/14980913431698-Help-with-media-extensions",
    },
  },
};

export default ({ language = "en", reference }: IZendeskArticlesParams) => {
  const article = articles[reference][language];
  const id = article.url.split("/articles/")[article.url.split("/articles/").length - 1];
  return {
    ...article,
    id,
  };
};
