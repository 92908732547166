export const prettifyMediaName = ({
  name,
  maxLength = 20,
}: {
  name: string;
  maxLength?: number;
}) => {
  let prettierMediaName = name;

  // Handle the Readspeaker generated audio names
  if (prettierMediaName.length > 4 && prettierMediaName.includes(".oga")) {
    prettierMediaName = prettierMediaName.split(".oga")[0];
  }
  if (prettierMediaName.length > maxLength) {
    prettierMediaName = `${prettierMediaName.substring(0, maxLength)}...`;
  }
  return prettierMediaName;
};
