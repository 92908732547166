export const convertSvgToBase64Png = async (
  svgElement: SVGElement,
): Promise<{ data: string; format: "png" }> => {
  if (!svgElement) {
    throw new Error("SVG element not found");
  }

  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);
  if (!svgString.trim()) {
    throw new Error("Failed to serialize SVG element");
  }

  const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
  const dataUrl = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(svgBlob);
  });

  const img = new Image();
  img.src = dataUrl;

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = reject;
  });

  const canvas = document.createElement("canvas");
  canvas.width = img.width || svgElement.clientWidth || 100;
  canvas.height = img.height || svgElement.clientHeight || 100;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get 2D context from canvas");
  }
  ctx.drawImage(img, 0, 0);

  const pngDataUrl = canvas.toDataURL("image/png");
  const base64Data = pngDataUrl.split(",")[1];

  return { data: base64Data, format: "png" };
};
