import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardsSelector, {
  SelectableCard,
} from "../../../common/components/CardsSelector/CardsSelector";
import { FooterButtons } from "../../../common/components/FooterButtons";
import { useAppDispatch } from "../../../common/state/hooks";
import { IProjectGroupElement } from "../../../model/model";
import { IMedia } from "../../../model/unityObject";
import { setPublishWarningText } from "../../alert/state/alertsSlice";
import { setPublicationParams } from "../../builds/state/buildsSlice";
import { mediaSelector } from "../../gallery/state/gallerySlice";
import {
  getCurrentElements,
  getSelectedProjectGroup,
} from "../../projectGroups/state/projectGroupsSlice";
import ProjectCard from "./ProjectCard";

type Props = { incrementStep(): void };

export default function SelectModulesStep({ incrementStep }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const currentElements = useSelector(getCurrentElements);
  const medias = useSelector(mediaSelector);

  const publishingHistory = [...(selectedProjectGroup.publishing_history ?? [])];
  const sortedVersions =
    publishingHistory?.sort((a, b) => Number(b.version) - Number(a.version)) ?? [];
  const lastVersionProjectIds = sortedVersions?.length > 0 ? sortedVersions[0].projectIds : [];
  const [shouldBeTested] = useState(false);
  const [projectsCards, setProjectsCards] = useState(
    currentElements.map((pge: IProjectGroupElement) => {
      const s3Url = medias.find((media: IMedia) => {
        // we need to handle 2 types of structs: the one from GET /project-group-elements & the one from freshly created PGE (which is 1 level deeper)
        const sourceTbn = pge.project.source_thumbnail as any;
        if (sourceTbn) {
          if (sourceTbn.id) {
            return Number(media.id) === Number(sourceTbn.id);
          } else {
            return Number(media.id) === Number(pge.project.source_thumbnail);
          }
        } else {
          return false;
        }
      })?.s3_url as string;
      const disabled = false; //pge.project.status !== "Edit";
      return {
        id: String(pge.project.id),
        component: <ProjectCard s3Url={s3Url} projectName={pge.project.name} disabled={disabled} />,
        selected: Boolean(
          lastVersionProjectIds.find((elt) => Number(elt) === Number(pge.project.id)),
        ),
        disabled,
      };
    }),
  );

  useEffect(() => {
    if (projectsCards.find((pc: any) => pc.disabled)) {
      dispatch(setPublishWarningText(t("pages.projectGroup.publish.addModuleDisclaimer")));
    } else {
      dispatch(setPublishWarningText(t("pages.projectGroup.publish.pleaseVerifyBeforeAsking")));
    }
    return () => {
      dispatch(setPublishWarningText("")) as any;
    };
  }, []);

  const handleNext = () => {
    const selectedProjectsIds = projectsCards
      .filter((c: SelectableCard) => c.selected)
      .map((p) => p.id);
    dispatch(setPublicationParams({ selectedProjectsIds, shouldBeTested }));
    dispatch(setPublishWarningText(""));

    incrementStep();
  };

  return (
    <div className="flex flex-col grow w-full h-full justify-between">
      <CardsSelector
        cards={projectsCards}
        parentClassName="mt-4 w-[80pc] grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 md:gap-4 mx-auto"
        cardClassName="border-4 cursor-pointer select-none rounded-xl"
        disabledTooltipText={t("pages.projectGroup.publish.unselectableProjectTooltip")}
        cardClickCb={(projectId: number) => {
          setProjectsCards(
            [...projectsCards].map((pc: any) => {
              return {
                ...pc,
                selected: pc.disabled
                  ? false
                  : pc.id === String(projectId)
                  ? !pc.selected
                  : pc.selected,
              };
            }),
          );
        }}
        shouldCheckProjectIntegrity
      />

      <FooterButtons
        cancelText={t("general.cancel")}
        cancelCb={() => navigate(`/courses/${selectedProjectGroup.id}/publish`)}
        backText={t("general.back")}
        backDisabled
        nextText={t("general.next")}
        nextCb={handleNext}
        nextDisabled={!projectsCards.find((pc: any) => pc.selected)}
      />
    </div>
  );
}
