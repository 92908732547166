import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import IEnduserStatsOverview from "../dataTypes/IEnduserStatsOverview";
import IProjectGroupCompletionItem from "../dataTypes/IProjectGroupCompletionItem";
import IProjectGroupsCompletionItem from "../dataTypes/IProjectGroupsCompletionItem";
import ISessionDetailForGivenProject from "../dataTypes/ISessionDetailForGivenProject";

interface IEnduserStatsState {
  projectGroupsCompletion: IProjectGroupsCompletionItem[];
  currentProjectGroupCompletion: IProjectGroupCompletionItem[];
  sessionDetailForGivenProject: ISessionDetailForGivenProject;
  enduserStatsOverview: IEnduserStatsOverview;
}
const initialState: IEnduserStatsState = {
  projectGroupsCompletion: [],
  currentProjectGroupCompletion: [],
  sessionDetailForGivenProject: {
    isProjectScorable: false,
    sessions: [],
  },
  enduserStatsOverview: {
    numberOfFinishedProjectGroups: 0,
    numberOfSucceededProjectGroups: 0,
    numberOfRemainingProjectGroups: 0,
    overallSuccessRate: 0,
    sessionsForTheTwelveLastMonths: [],
    successRatePerProjectGroupSortedByLatestSession: [],
  },
};

export const fetchProjectGroupsCompletion = createAsyncThunk(
  "enduserStats/fetchProjectGroupsCompletion",
  // eslint-disable-next-line no-empty-pattern
  async ({ limit = -1 }: { limit?: number } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.stats.enduserProjectGroupsCompletionSortedByLatestSession}?limit=${limit}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const fetchCurrentProjectGroupCompletion = createAsyncThunk(
  "enduserStats/fetchCurrentProjectGroupCompletion",
  // eslint-disable-next-line no-empty-pattern
  async ({ buildId }: { buildId: number } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.stats.enduserProjectGroupCompletion}?build=${buildId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const fetchEnduserSessionDetailsForGivenProject = createAsyncThunk(
  "enduserStats/fetchEnduserSessionDetailsForGivenProject",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectId }: { projectId: number } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.stats.enduserSessionDetailsForGivenProject}?project=${projectId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const fetchEnduserStatsOverview = createAsyncThunk(
  "enduserStats/fetchEnduserStatsOverview",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    // Cursed bugs calls for cursed solutions.
    // Undefined jwt will only happen here, only once, when F5'ing the dashboard
    if (!axios.defaults.headers.common["Authorization"]) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "wixar.auth.jwt",
      )}`;
    }
    return await axios
      .get(`${apiUrls.stats.enduserStatsOverview}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const enduserStatsSlice = createSlice({
  name: "enduserStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchProjectGroupsCompletion.fulfilled,
      (state: IEnduserStatsState, { payload }) => {
        state.projectGroupsCompletion = payload;
      },
    );
    builder.addCase(
      fetchCurrentProjectGroupCompletion.fulfilled,
      (state: IEnduserStatsState, { payload }) => {
        state.currentProjectGroupCompletion = payload;
      },
    );
    builder.addCase(
      fetchEnduserSessionDetailsForGivenProject.fulfilled,
      (state: IEnduserStatsState, { payload }) => {
        state.sessionDetailForGivenProject = payload;
      },
    );
    builder.addCase(
      fetchEnduserStatsOverview.fulfilled,
      (state: IEnduserStatsState, { payload }) => {
        if (payload) {
          state.enduserStatsOverview = payload;
        }
      },
    );
  },
});

export const getEnduserProjectGroupsCompletion = (state: RootState) =>
  state.enduserStats.projectGroupsCompletion;
export const getEnduserCurrentProjectGroupCompletion = (state: RootState) =>
  state.enduserStats.currentProjectGroupCompletion;
export const getEnduserSessionDetailForGivenProject = (state: RootState) =>
  state.enduserStats.sessionDetailForGivenProject;
export const getEnduserStatsOverview = (state: RootState) =>
  state.enduserStats.enduserStatsOverview;

export const enduserStatsReducer = enduserStatsSlice.reducer;
