import { IUploadItem, addUploadToCurrentList } from "../../../features/gallery/state/gallerySlice";
import { getCurrentUser } from "../../../features/profile/state/profileSlice";
import { ILanguage } from "../../../model/model";
import { languageConfig } from "../../util/langConfig";
import { prettifyFileSizeInBytes } from "../../util/prettifyFileSizeInBytes";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon/Icon";
import { AppDispatch } from "../../state/store";
import ProgressBar from "../ProgressBar/ProgressBar";
import Spinner from "../Loader/Spinner";
import { useTranslation } from "react-i18next";

interface UploadItemProps {
  className?: string;
  item: IUploadItem;
}

const getBarColor = (elt: IUploadItem) => {
  switch (elt.uploadState) {
    default:
    case "in_progress":
      return "bg-purple-500";
    case "transcoding":
      return "bg-violet-800";
    case "completed":
      return "bg-green-500";
    case "error":
      return "bg-red-500";
    case "canceled":
      return "bg-slate-600";
  }
};

const getBarAnimated = (elt: IUploadItem) => {
  switch (elt.uploadState) {
    case "transcoding":
      return true;
    default:
      return false;
  }
};

export const UploadItem = ({ item, className = "" }: UploadItemProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const dispatch: AppDispatch = useDispatch();
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const stopUpload = () => {
    dispatch(
      addUploadToCurrentList({
        uploadId: item.uploadId,
        uploadState: "canceled",
      }),
    );
  };

  return (
    <div
      className={`flex flex-row bg-white rounded-lg h-full p-2 items-center ${className} ${
        item.uploadState === "canceled" ? "line-through" : ""
      }`}
    >
      <div className="w-[10%] mr-2 text-sm flex align-center justify-center">
        {item.uploadState === "transcoding" ? (
          <Spinner
            relative
            style={{
              height: "1.5rem",
              width: "1.5rem",
              marginTop: "0.5rem",
            }}
          />
        ) : (
          `${item.percent ? item.percent.toFixed(0) : 0}%`
        )}
      </div>

      <div className="w-[24%] mr-2 rounded-lg h-1/2 bg-gray-400 flex relative">
        {item.uploadState === "transcoding" ? (
          <span className="w-full text-center absolute text-xs bottom-2 text-[#5b21b6]">
            {t("general.transcoding")}
          </span>
        ) : null}
        <ProgressBar
          value={item.percent ?? 0}
          barClassName={getBarColor(item)}
          animated={getBarAnimated(item)}
        />
      </div>

      <div className="w-[35%] h-full flex items-center">
        <p className="truncate text-sm">{`${item.name}`}</p>
      </div>

      {item.size && (
        <div className="w-[22%] h-full flex items-center justify-center">
          <p className="truncate text-sm">{`${prettifyFileSizeInBytes(
            item.size,
            currentLocale,
            1,
          )}`}</p>
        </div>
      )}

      <div className="w-[7%] h-full flex items-center justify-center">
        {item.uploadState === "in_progress" && (
          <Icon onClick={stopUpload} icon={"XIcon"} className={"hover:cursor-pointer h-full"} />
        )}
      </div>
    </div>
  );
};
export default UploadItem;
