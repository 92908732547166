import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../common/components/Loader/Spinner";
import { getGraphLayout, selectSelectedContentId } from "../state/graphLegacy";
import SVGGraph from "./SVGGraph";

type Props = { loading: boolean };

const SVGGraphContainer: React.FC<Props> = ({ loading }) => {
  const [nodes, layout, links, hiddenSceneNodes] = useSelector(getGraphLayout);

  const selectedContentId = useSelector(selectSelectedContentId);

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  ) : (
    <SVGGraph
      nodes={nodes}
      layout={{ ...layout, height: layout.height + 230 }}
      links={links}
      selectedContentId={selectedContentId as number}
      hiddenSceneNodes={hiddenSceneNodes}
    />
  );
};

export default SVGGraphContainer;
