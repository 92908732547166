import { useTranslation } from "react-i18next";
import AIGeneratedGraph from "src/common/components/AIGeneratedGraph/AIGeneratedGraph";
import AIGeneratedGraphModal from "src/common/components/AIGeneratedGraph/AIGeneratedGraphModal";
import { useAppDispatch } from "src/common/state/hooks";
import { openStandardModal } from "src/common/state/slice/modal/modalSlice";

const GraphBubble = ({ markdown, mdStringId }: { markdown: string; mdStringId: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div
        className="graph-bubble px-4 py-2 mt-4 rounded-3xl bg-gray-300 cursor-zoom-in"
        onClick={() => {
          dispatch(openStandardModal("aiGeneratedGraphModal"));
        }}
      >
        <p className="text-sm text-gray-400">{t("pages.chat.clickToEnlarge")}</p>
        <AIGeneratedGraph markdown={markdown} mdStringId={mdStringId} />
      </div>
      <AIGeneratedGraphModal markdown={markdown} />
    </>
  );
};

export default GraphBubble;
