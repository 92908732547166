import Trophy from "../../../common/components/Trophy/Trophy";

type Props = { score: string; isPerfectScore: boolean };

export default function EnduserScoreWithPotentialTrophy({ score, isPerfectScore }: Props) {
  return (
    <div className="flex">
      <p className={`${isPerfectScore ? "text-green-500 mr-1" : ""}`}>{score}</p>
      {isPerfectScore && <Trophy height={18} width={18} />}
    </div>
  );
}
