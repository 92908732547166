import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlert, setHideAlert } from "../../../features/alert/state/alertsSlice";
import { IAlert } from "../../../model/model";
import { AppDispatch } from "../../state/store";
import InformationFrame, { InfoFrameStyle } from "../InfomationFrame/InformationFrame";

type AlertProps = {
  children?: React.ReactNode;
};

const Alert = ({ children = true }: AlertProps) => {
  const dispatch: AppDispatch = useDispatch();

  const alertProps = useSelector(getAlert);
  const [currentAlert, setCurrentAlert] = useState<IAlert | null>();

  const hide = () => {
    dispatch(setHideAlert(currentAlert));
  };

  if (!alertProps) {
    hide();
  }

  useEffect(() => {
    if (alertProps.alertsQueue.length > 0) {
      const lastAlert = alertProps.alertsQueue[alertProps.alertsQueue.length - 1];

      if (lastAlert.id !== currentAlert?.id) {
        setCurrentAlert(lastAlert);
      }
    } else {
      if (!alertProps.alertsQueue.find((elt) => elt.id === currentAlert?.id)) {
        setCurrentAlert(null);
      }
    }
  }, [alertProps?.alertsQueue]);

  return (
    <>
      {currentAlert && (
        <InformationFrame
          className="w-full px-6 py-6 max-w-6xl mx-auto absolute w-2/3 left-[20%] transition-opacity z-[1000]"
          style={currentAlert.alertStyle as InfoFrameStyle}
          onClose={hide}
          withTimeout={true}
          customTimeoutInMs={currentAlert.customTimeoutInMs ? currentAlert.customTimeoutInMs : 0}
          showCloseButton={currentAlert.alertShowCloseButton}
        >
          {children}
          <div>{currentAlert.alertMsg}</div>
          <div className="text-xs italic">{currentAlert?.alertSubMsg}</div>
        </InformationFrame>
      )}
    </>
  );
};

export default Alert;
