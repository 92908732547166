export default (mediaType: string) => {
  const media360 = {
    value: "media360",
    optionText: "360°",
  };

  const media2d = {
    value: "media2d",
    optionText: "2D",
  };

  const video180stereo = {
    value: "video180stereo",
    optionText: "180 Stereo",
  };

  switch (mediaType) {
    case "video":
      return [media360, media2d, video180stereo];
    case "image":
      return [media360, media2d];
    case "audio":
    case "pdf":
    case "model3d":
    default:
      return [];
  }
};
