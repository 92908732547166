import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThemeInfo } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

interface IThemes {
  all: IThemeInfo[];
}

export const fetchThemes = createAsyncThunk(
  "themes/fetchThemes",
  // eslint-disable-next-line no-empty-pattern
  async ({}: any & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.themeInfos}?populate=organization&select=organization.id`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const updateTheme = createAsyncThunk(
  "themes/updateTheme",
  // eslint-disable-next-line no-empty-pattern
  async ({ theme }: { theme: IThemeInfo } & IAppLoaderAction) => {
    return await axios
      .put(`${apiUrls.themeInfos}/${theme.id}`, { ...theme })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const postNewTheme = createAsyncThunk(
  "themes/postNewTheme",
  // eslint-disable-next-line no-empty-pattern
  async ({ theme }: { theme: IThemeInfo } & IAppLoaderAction) => {
    return await axios
      .post(`${apiUrls.themeInfos}`, { ...theme })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

const initialState: IThemes = {
  all: [],
};

export const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    deleteThemeInStore: (state: IThemes, { payload }) => {
      state.all = state.all.filter((theme) => theme.id !== payload.themeId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemes.fulfilled, (state: IThemes, { payload }) => {
      state.all = payload;
    });
  },
});

export const themesReducer = themesSlice.reducer;

export const getThemes = (state: RootState) => state.themes.all;

export const { deleteThemeInStore } = themesSlice.actions;
