import { SVGProps } from "react";
import { IconType } from "react-icons";

const OrderIcon: IconType = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="19"
      {...props}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 7.5652C7.33333 5.83229 8.42188 1.5 13.0625 1.5C16.5 1.5 18.2188 4.96583 18.2188 7.5652"
        // stroke="#00C889"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="9.96899"
        width="4.47059"
        height="7.53103"
        rx="2.23529"
        // stroke="#00C889"
        strokeWidth="2"
      />
      <rect
        x="8.76465"
        y="9.96899"
        width="4.47059"
        height="7.53103"
        rx="2.23529"
        // stroke="#00C889"
        strokeWidth="2"
      />
      <rect
        x="16.5293"
        y="9.96899"
        width="4.47059"
        height="7.53103"
        rx="2.23529"
        // stroke="#00C889"
        strokeWidth="2"
      />
      <path
        d="M9.37193 6.78565L6.59085 7.89398L5.71143 4.38898"
        // stroke="#00C889"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrderIcon;
