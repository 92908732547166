import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UserIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../common/components/Input";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import {
  currentOrgSelector,
  currentUserSelector,
} from "../../../common/state/selectors/authSelector";
import { AppDispatch, RootState } from "../../../common/state/store";
import { isStringNullOrEmpty } from "../../../common/util/isStringNullOrEmpty";
import { setAlert } from "../../alert/state/alertsSlice";
import { getIsAdminOfOrganization } from "../../profile/state/profileSlice";
import {
  getProjectGroupPermissionsFromServer,
  getSelectedProjectGroup,
  setCurrentProjectGroupPermissions,
} from "../../projectGroups/state/projectGroupsSlice";
import { setProjectTeam } from "../../projects/state/projectsSlice";
import { inviteExternalCreatorToOrg } from "../state/teamSlice";
import PlaceholderWithIcon from "../../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { CONFIRM_TEAM_COMPONENT } from "./AddTeamModal";
import { InviteExternalFormData } from "../state/teamInterfaces";

export const INVITE_EXTERNAL_COMPONENT = "inviteExternalComponent";

interface IInviteExternalCollaborator {
  onSubmit: (data: any) => void;
  isFormSimplified: boolean;
  enableEmail: boolean;
  maxCollaboratorsExceeded: boolean;
}

const InviteExternalCollaboratorFormContainer = ({
  isFormSimplified = false,
  enableEmail = false,
  maxCollaboratorsExceeded,
}: {
  isFormSimplified?: boolean;
  enableEmail?: boolean;
  maxCollaboratorsExceeded: boolean;
}) => {
  const { user, org } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      org: currentOrgSelector(state),
    };
  });
  const dispatch: AppDispatch = useDispatch();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  const onSubmit = async (data: InviteExternalFormData) => {
    if (org && org.id && user && user.id) {
      const res = await dispatch(
        inviteExternalCreatorToOrg({
          componentId: CONFIRM_TEAM_COMPONENT,
          inviterId: user.id,
          organizationId: org?.id,
          ...data,
        }),
      );
      const newUser = (res.payload as any).data;
      await dispatch(
        setProjectTeam({
          inviteeId: newUser.id,
          projectGroupId: currentProjectGroup.id,
          componentId: CONFIRM_TEAM_COMPONENT,
          inviterId: user.id,
        }),
      ).then(() => {
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.collaboratorInvitedSuccessfully"),
          }),
        );
      });

      dispatch(
        getProjectGroupPermissionsFromServer({ projectGroupId: currentProjectGroup.id }),
      ).then((res) => {
        dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
      });
    }
  };

  return (
    <InviteExternalCollaborator
      isFormSimplified={isFormSimplified}
      enableEmail={enableEmail}
      onSubmit={onSubmit}
      maxCollaboratorsExceeded={maxCollaboratorsExceeded}
    />
  );
};

const InviteExternalCollaborator = ({
  onSubmit,
  isFormSimplified,
  enableEmail,
  maxCollaboratorsExceeded,
}: IInviteExternalCollaborator) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InviteExternalFormData>();
  let ref: any;
  const clearForm = () => {
    if (ref) {
      ref.reset();
    }
  };
  const watchValues = watch();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const isAdminOfOrg = useSelector(getIsAdminOfOrganization);
  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  useEffect(() => {
    if (isAdminOfOrg) {
      if (currentProjectGroup.auth_type === "simplified" && isFormSimplified) {
        if (
          !isStringNullOrEmpty(watchValues.newUserFirstName) &&
          !isStringNullOrEmpty(watchValues.newUserLastName)
        ) {
          setIsAddButtonDisabled(false);
        } else {
          setIsAddButtonDisabled(true);
        }
      } else {
        if (
          !isStringNullOrEmpty(watchValues.newUserEmail) &&
          !isStringNullOrEmpty(watchValues.newUserFirstName) &&
          !isStringNullOrEmpty(watchValues.newUserLastName)
        ) {
          setIsAddButtonDisabled(false);
        } else {
          setIsAddButtonDisabled(true);
        }
      }
    }
  }, [watchValues]);

  return (
    <div className="flex invite-external ml-12 mt-3 mb-3 min-w-[80%]">
      <div className="flex border-2 border-gray-300 rounded-lg py-3 px-4 flex-1 mx-auto items-center justify-center relative">
        <div className="flex absolute top-2 left-2">
          <UserIcon className=" w-5 h-5 mb-3 text-gray-500 mr-1" />
          <span className="text-gray-500 font-medium">
            {t("pages.project.projectGroupCollaborators.newCollaborator") as any}
          </span>
        </div>
        {maxCollaboratorsExceeded ? (
          <div className="flex items-center justify-center">
            <PlaceholderWithIcon
              title={t("pages.project.projectGroupCollaborators.nbAccountExceededTitle")}
              text={t("pages.project.projectGroupCollaborators.nbAccountExceededSubtitle")}
              heroIcon="ExclamationIcon"
              iconClassName="h-16 w-16 stroke-yellow-400"
            />
          </div>
        ) : (
          <form
            className="w-full mt-6"
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
              clearForm();
            })}
            ref={(f) => (ref = f)}
          >
            <div className="flex w-full items-center">
              <div className="w-full mr-5">
                <Input
                  type="newUserFirstName"
                  label={t("general.firstname")}
                  placeholder={t("general.firstname")}
                  registerFct={() => register("newUserFirstName", { required: true })}
                  className="rounded border-2 w-72 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                />
                <span className={`text-red-500 ${errors.newUserFirstName ? "" : "invisible"}`}>
                  {t("pages.project.invalidFirstName") as any}
                </span>
              </div>
              <div className="w-full mr-5">
                <Input
                  type="newUserLastName"
                  label={t("general.lastname")}
                  placeholder={t("general.lastname")}
                  registerFct={() => register("newUserLastName", { required: true })}
                  className="rounded border-2  w-72 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                />
                <span className={`text-red-500 ${errors.newUserLastName ? "" : "invisible"}`}>
                  {t("pages.project.invalidLastName") as any}
                </span>
              </div>
              <>
                {isFormSimplified && enableEmail && (
                  <div className="w-full mr-5">
                    <Input
                      type="newUserEmail"
                      label={t("general.email")}
                      placeholder={t("general.email")}
                      registerFct={() =>
                        register("newUserEmail", { required: true, pattern: /^\S+@\S+\.\S+$/i })
                      }
                      className="rounded border-2 w-72 border-solid border-gray-300 bg-slate-100 p-2 placeholder:italic"
                    />
                    <span className={`text-red-500 ${errors.newUserEmail ? "" : "invisible"}`}>
                      {t("pages.project.invalidEmail") as any}
                    </span>
                  </div>
                )}
              </>
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <Tooltip
                  message={isAdminOfOrg ? "" : `${t("general.onlyAdminCanDoThis")}`}
                  classNameChildren="right-1"
                >
                  <button className="btn-primary-fill" type="submit" disabled={isAddButtonDisabled}>
                    {String(t("general.invite"))}
                  </button>
                </Tooltip>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default InviteExternalCollaboratorFormContainer;
