import { useDispatch } from "react-redux";
import ModalStandard from "../../../common/components/Modal/ModalStandard";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { AppDispatch } from "../../../common/state/store";
import { ThemeEditor } from "./ThemeEditor";
import { IThemeInfo } from "src/model/model";
import Alert from "../../../common/components/Alert/Alert";

// Todo: have 'nodeId' for: ["profile", "organization", "background_audio"]

const ModalThemeEditor = ({
  title,
  themeId = 0,
  createthemeWithThisBase = false,
  onCancel,
  onConfirm,
}: {
  title: string;
  themeId?: number;
  createthemeWithThisBase?: boolean;
  onCancel?: () => void | null;
  onConfirm?: (theme: IThemeInfo) => void | null;
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onCancelDefault = () => {
    dispatch(closeStandardModal("modalThemeEditor"));
  };

  return (
    <ModalStandard
      id="modalThemeEditor"
      fullScreen={true}
      isStatic
      modalType="standard"
      clickOnBackgroundClose={false}
    >
      <div className="flex w-full">
        <div className="text-center">
          <Alert />
        </div>
        <div className="grow overflow-y-visible ">
          <ThemeEditor
            title={title}
            themeId={themeId}
            createthemeWithThisBase={createthemeWithThisBase}
            onCancel={onCancel ? onCancel : onCancelDefault}
            onConfirm={onConfirm ?? null}
          />
        </div>
      </div>
    </ModalStandard>
  );
};

export default ModalThemeEditor;
