interface NodeObject {
  [key: string]: any;
}

interface NodeToEdit {
  object: NodeObject;
}

interface RelevantPld {
  id: number;
  [key: string]: any;
}

interface MapContentKeysWithPldKeys {
  [contentType: string]: [string, string][];
}

interface FunctionParams {
  node: {
    modelName: string;
    object: NodeObject;
  };
  changes: NodeToEdit;
  relevantPld: RelevantPld;
  mapContentKeysWithPldKeys: MapContentKeysWithPldKeys;
}

const buildUpdatedProjectLanguageData = ({
  node,
  changes,
  relevantPld,
  mapContentKeysWithPldKeys,
}: FunctionParams) => {
  // Flatten the mapping for quick access
  const keyMap = new Map();
  Object.values(mapContentKeysWithPldKeys).forEach((array) => {
    array.forEach(([pldKey, nodeKey]) => {
      keyMap.set(nodeKey, pldKey);
    });
    if (node.modelName === "content-video-2-d") {
      keyMap.set("source_media", "media_video_id");
    }
  });

  // Initialize the updated data object with the 'id' from relevantPld
  const updatedData = {
    id: relevantPld.id,
  };

  // Transform and map data from 'changes' using the keyMap
  Object.entries(changes).forEach(([key, value]) => {
    if (keyMap.has(key)) {
      updatedData[keyMap.get(key)] = value;
    }
  });

  return updatedData;
};

export default buildUpdatedProjectLanguageData;
