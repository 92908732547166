import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../common/state/hooks";
import {
  IProjectGroupWithProjects,
  deleteProjectGroup,
  fetchAllCurrentElements,
  fetchProjectGroupsWithProjects,
  getAllProjectGroupsWithNestedElements,
  getCurrentModeListProjectGroup,
  getProjectGroupPermissionsFromServer,
  setCurrentModeListProjectGroup,
  setCurrentProjectGroupPermissions,
  setEdited as setEditedProjectGroup,
  setSelectedProjectGroup,
  setSelectedProjectGroupFromId,
} from "../../features/projectGroups/state/projectGroupsSlice";
import ProjectGroupList, {
  PROJECT_GROUP_LIST_COMPONENT_ID,
} from "../../features/projectGroups/components/ProjectGroupList";
import { useTranslation } from "react-i18next";

import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import {
  fetchCurrentProjectGroupCompletion,
  fetchProjectGroupsCompletion,
  getEnduserProjectGroupsCompletion,
} from "../../features/enduserStats/state/enduserStatsSlice";
import IProjectGroupsCompletionItem from "../../features/enduserStats/dataTypes/IProjectGroupsCompletionItem";
import { ENDUSER_PROJECT_LIST_COMPONENT_ID } from "../../enduserPages/EnduserProjectsList/EnduserProjectsList";
import { ViewGridIcon, ViewListIcon } from "@heroicons/react/outline";
import ModeListProjectGroup from "./ModeListProjectAndProjectsGroup";

const ProjectGroupsList = ({ isEnduser = false }: { isEnduser?: boolean }) => {
  const [isCheck, setIsCheck] = useState<Array<any>>([]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const projectGroups = isEnduser ? useSelector(getEnduserProjectGroupsCompletion) : [];
  const projectGroupsWithNestedElements = useSelector(getAllProjectGroupsWithNestedElements);

  const [clickedProjectGroupId, setClickedProjectGroupId] = useState(0);
  const modeListCurrentState = useSelector(getCurrentModeListProjectGroup);

  useEffect(() => {
    if (isEnduser) {
      if (!projectGroups || projectGroups.length === 0) {
        dispatch(fetchProjectGroupsCompletion({ componentId: PROJECT_GROUP_LIST_COMPONENT_ID }));
      }
    } else {
      //  if (!projectGroupsWithNestedElements || projectGroupsWithNestedElements.length === 0) {
      // 11/06/24 : I commented the condition above so it can refresh after content-type update (that updated project_group.updated_at_ts)
      dispatch(
        fetchProjectGroupsWithProjects({
          componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
        }),
      );
      // }
    }
  }, [location.key]);

  const handleClick = (projectGroup: IProjectGroupWithProjects | IProjectGroupsCompletionItem) => {
    if (isEnduser) {
      const pg = projectGroup as IProjectGroupsCompletionItem;
      dispatch(
        fetchCurrentProjectGroupCompletion({
          componentId: ENDUSER_PROJECT_LIST_COMPONENT_ID,
          buildId: pg.latestVersion.buildId,
        }),
      );

      navigate(`../courses/${pg.projectGroupId}/list`);
    } else {
      const pg = projectGroup as IProjectGroupWithProjects;
      dispatch(
        fetchAllCurrentElements({
          projectGroupId: Number(pg.id),
          componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
        }),
      );
      dispatch(setSelectedProjectGroup(pg));
      dispatch(getProjectGroupPermissionsFromServer({ projectGroupId: Number(pg.id) })).then(
        (res) => {
          dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
        },
      );
      navigate(`../courses/${pg.id}/list`);
    }
  };

  const onChangeGridMedia = () => {
    dispatch(setCurrentModeListProjectGroup(!modeListCurrentState));
  };

  const setEditedProjectGroupId = (id: number, projectGroup: IProjectGroupWithProjects) => {
    const pg = projectGroup as IProjectGroupWithProjects;
    setClickedProjectGroupId(id);
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: pg.id,
      }),
    );
    dispatch(setSelectedProjectGroup(pg));
    dispatch(setEditedProjectGroup());
  };

  return (
    <>
      <Navigation title={t("general.projectGroups")} showSearchBar={!isEnduser} />
      {!isEnduser && <InfoButton relevantArticle="aboutCourses" />}
      <div className="fixed top-20 z-40 right-2 mr-5">
        <button onClick={onChangeGridMedia}>
          {!isEnduser &&
            (modeListCurrentState ? (
              <ViewGridIcon className="w-7 h-7 stroke-green-500 mr-3" />
            ) : (
              <ViewListIcon className="w-7 h-7 stroke-green-500 mr-3" />
            ))}
        </button>
      </div>
      {modeListCurrentState ? (
        <div
          className={`overflow-y-auto personalize-scroll-visible  ${
            isCheck.length > 0 ? "mt-10" : ""
          } `}
        >
          <div className="block w-full overflow-y-auto personalize-scroll-visible ">
            <ModeListProjectGroup
              setEditedProjectGroupId={setEditedProjectGroupId}
              setIsCheck={setIsCheck}
              isCheck={isCheck}
              arrayItemsForList={isEnduser ? [] : projectGroupsWithNestedElements}
              setClickedProjectGroupId={setClickedProjectGroupId}
              handleClick={handleClick}
              clickedProjectGroupId={clickedProjectGroupId}
              isProjectGroup={true}
            />
          </div>
        </div>
      ) : (
        <div className="block px-9 w-full py-6 overflow-y-auto personalize-scroll-visible">
          {!isEnduser && (
            <div className="mr-6 w-72 mb-7">
              <button
                className="btn-primary-fill px-5 py-3 text-sm rounded-2xl"
                onClick={() => navigate("/courses/new")}
              >
                {t("general.newProjectGroup")}
              </button>
            </div>
          )}
          <div className="inline-flex justify-between w-full">
            <div
              className={`${
                projectGroups.length === 0 ? "left-[40%] " : ""
              } inline-flex justify-between w-full flex-col items-center`}
            >
              <ProjectGroupList
                setEditedProjectGroupId={setEditedProjectGroupId}
                handleThreeDotButtonClick={(e: any) => {
                  setClickedProjectGroupId(e.itemId);
                  dispatch(setSelectedProjectGroupFromId(e.itemId));
                  dispatch(
                    fetchAllCurrentElements({
                      projectGroupId: e.itemId,
                      componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
                    }),
                  );
                  handleThreeDotsButtonClickForProjectsAndProjectGroups({
                    payload: e,
                    deleteCallback: () => {
                      dispatch(openConfirmationModal("deleteProjectGroupConfirmation"));
                    },
                    t,
                    navigate,
                    dispatch: dispatch,
                  });
                }}
                handleClick={handleClick}
                projectGroups={projectGroups}
                projectGroupsWithNestedElements={projectGroupsWithNestedElements}
                isEnduser={isEnduser}
              />
            </div>
          </div>
        </div>
      )}

      <ModalConfirmation
        id="deleteProjectGroupConfirmation"
        onClickSubmit={() => {
          dispatch(closeConfirmationModal("deleteProjectGroupConfirmation"));

          dispatch(
            deleteProjectGroup({
              id: clickedProjectGroupId,
              componentId: `project-group-${clickedProjectGroupId}`,
            }),
          );
        }}
        title={t("pages.projectGroup.deletionModal.title")}
        text={t("pages.projectGroup.deletionModal.body")}
        componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
      />
    </>
  );
};

export default ProjectGroupsList;
