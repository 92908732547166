export const prettifyDuration = (duration: any): string => {
  const durationInt = Number(duration);
  const isValidDuration = !isNaN(durationInt);
  let output = "N/A";
  if (!isValidDuration) {
    return output;
  }

  if (duration < 60) {
    output = `${Math.round(duration)}s`;
  }
  if (duration >= 60) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);
    output = `${minutes}min${seconds}s`;
  }
  return output;
};
