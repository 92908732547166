import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../common/components/Input";
import { AppDispatch } from "../../../common/state/store";
import { forgottenPassword, IForgottenPasswordFormData } from "../state/authSlice";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { Error } from "../../../common/components/AppState/Error";
import Footer from "src/common/components/Footer/Footer";
import Icon from "src/common/components/Icon/Icon";

export const FORGOTTEN_PASSWORD_COMPONENT_ID = "forgottenPasswordComponent";

interface IForgottenPasswordForm {
  onSubmit: (data: any) => void;
}

const ForgottenPasswordContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState(false);

  const onSubmit = async (data: IForgottenPasswordFormData) => {
    await dispatch(
      forgottenPassword({ componentId: FORGOTTEN_PASSWORD_COMPONENT_ID, ...data }),
    ).finally(() => {
      setHasEmailBeenSent(true);
    });
  };

  if (hasEmailBeenSent) {
    return <ForgottenPasswordFeedback />;
  } else {
    return <ForgottenPassword onSubmit={onSubmit} />;
  }
};

const ForgottenPassword = ({ onSubmit }: IForgottenPasswordForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<IForgottenPasswordFormData>();
  const inputLabel = t("pages.login.forgotPassword.fillEmail");

  return (
    <div className="bg-slate-100 flex flex-col h-screen">
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="border flex flex-col bg-white border-gray-200 rounded-lg shadow-md py-3 px-4">
            <div className="my-2">
              <h2 className="text-xl font-medium text-black">
                {t("pages.login.forgotPassword.title")}
              </h2>
            </div>
            <Error componentId={FORGOTTEN_PASSWORD_COMPONENT_ID} />
            <IsLoading componentId={FORGOTTEN_PASSWORD_COMPONENT_ID} showSuccess={false} />
            <Input
              autoFocus
              type="email"
              label={inputLabel}
              placeholder={t("pages.login.emailPlaceholder")}
              registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
            />
            <div className="flex flex-col sm:flex-row sm:pt-8 sm:justify-between">
              <button
                onClick={() => navigate("/")}
                className="btn-alternative-outline mb-2 sm:mb-0"
                type="button"
              >
                {t("general.back")}
              </button>
              <button className="btn-primary-fill" type="submit">
                {t("pages.login.forgotPassword.sendMeALink")}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer className="m-1 self-end flex relative" />
    </div>
  );
};

const ForgottenPasswordFeedback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      navigate("/");
    }
  };

  return (
    <div
      className="bg-slate-100 flex flex-col h-screen"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={containerRef}
    >
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center w-full">
        <div className="border flex flex-col bg-white border-gray-200 rounded-lg shadow-md py-3 px-4 w-90 items-center">
          <Icon icon="CheckIcon" className="text-green-500 w-20 h-20" />
          <h2 className="text-xl font-medium text-black">
            {t("pages.login.forgotPassword.mailSent")}
          </h2>
          <button className="btn-primary-fill mb-2 mt-6 sm:mb-0" onClick={() => navigate("/")}>
            {t("general.ok")}
          </button>
        </div>
      </div>
      <Footer className="m-1 self-end flex relative" />
    </div>
  );
};

export default ForgottenPasswordContainer;
