import React from "react";
import ReactDOM from "react-dom/client";
import App from "./common/template/App";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider } from "@mui/material/styles";
import { store } from "./common/state/store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import "./index.css";

/* 
In the case of any 404 or 403 error, S3 will rewrite the url with a #!/ prefix (see 'S3 Static website hosting' config)
Here, we replace those urls back to human-friendly format (without #!/)

reference: https://viastudio.com/hosting-a-reactjs-app-with-routing-on-aws-s3/
relevant sof: https://stackoverflow.com/questions/51218979/react-router-doesnt-work-in-aws-s3-bucket
*/
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
const history = createBrowserHistory();
if (path) {
  history.replace(path);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <App />
      </Provider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
