import { ITemplateProject } from "../../../model/model";

const TemplatePreviewMedia = ({ item }: { item: ITemplateProject | undefined }) => {
  let TemplateMediaComponent = null;

  if (item?.source_video) {
    TemplateMediaComponent = (
      <iframe
        className="w-full lg:max-w-3xl lg:mx-auto px-6 pt-6 xl:absolute xl:top-1/3 xl:right-12 xl:w-1/2 aspect-video"
        src={item?.source_video.s3_url}
      />
    );
  } else if (item?.source_screenshot?.s3_url) {
    TemplateMediaComponent = (
      <img
        className="w-full lg:max-w-3xl lg:mx-auto px-6 pt-6 xl:absolute xl:top-1/3 xl:right-12 xl:w-1/2"
        src={item?.source_screenshot?.s3_url}
        title={item?.source_screenshot?.name}
        alt={item?.source_screenshot?.name}
      />
    );
  }
  return TemplateMediaComponent;
};

export default TemplatePreviewMedia;
