import { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../common/components/Loader/Spinner";
import { IMedia } from "../../../model/unityObject";
import { mediaSelector } from "../../gallery/state/gallerySlice";

type RoundImagePreviewProps = {
  media: any;
  componentId?: any;
  alt?: string;
  bigger?: boolean;
};

export default function RoundImagePreview({
  media,
  alt = "",
  bigger = false,
}: RoundImagePreviewProps) {
  const medias = useSelector(mediaSelector);

  const [loadingPhoto, setLoadingPhoto] = useState(true);
  // handle the wacky typing
  const mediaId = media?.id ? Number(media.id) : Number(media);

  const foundMedia = medias.find((m: IMedia) => Number(m.id) === Number(mediaId));

  return (
    <>
      {loadingPhoto && foundMedia?.s3_url && (
        <div className="relative mb-10 mr-10 sm:ml-4">
          <Spinner />
        </div>
      )}
      {!foundMedia?.s3_url ? (
        <div className="relative mb-10 mr-10 sm:ml-4">
          <Spinner />
        </div>
      ) : (
        <img
          className={`inline object-cover mr-10 sm:ml-4 ${
            bigger ? "w-16 h-16" : "w-12 h-12"
          } mr-2 rounded-full border border-gray-300`}
          style={{ display: loadingPhoto ? "none" : "block" }}
          onLoad={() => setLoadingPhoto(false)}
          src={
            foundMedia?.s3_url
              ? foundMedia.s3_url
              : "https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/link_broken.png"
          }
          alt={alt}
        />
      )}
    </>
  );
}
