import {
  IDeleteMediaResponse,
  IUpdatedWithPlaceholder,
} from "../../features/gallery/state/gallerySlice";
import { updateProjectGroupThumbnail } from "../../features/projectGroups/state/projectGroupsSlice";
import { updateProjectThumbnail } from "../../features/projects/state/projectsSlice";
import { IMedia } from "../../model/unityObject";

export const handleMediaPlaceholderRefreshAfterDeletion = ({
  payload,
  dispatch,
  allMedias,
}: {
  payload: IDeleteMediaResponse;
  dispatch: any;
  allMedias: IMedia[];
}) => {
  /* update the relevant stores so its seemless for the users. we need to handle:
      - project_group.source_thumbnail v
      - project.source_thumbnail  v
      - all node edition (in graph)
      - organization.source_logo
      - user.source_avatar
  */

  payload.updatedWithPlaceholders.forEach((updated: IUpdatedWithPlaceholder) => {
    const placeholderMediaObject = allMedias.find(
      (m: IMedia) => Number(m.id) === Number(updated.newMediaId),
    ) as IMedia;

    if (updated.modelName === "project-group") {
      dispatch(updateProjectGroupThumbnail(placeholderMediaObject));
    }
    if (updated.modelName === "project") {
      dispatch(updateProjectThumbnail(placeholderMediaObject));
    }
    /* since this one is pretty complex to handle, we instead disabled the "suppress media" button in the context of the graph gallery  
		if (updated.modelName.includes("content-")) {
		}
		*/
  });
  return null;
};
