import { Tooltip } from "@mui/material";

const WarningIconWithTooltip = ({
  message,
  iconColor = "yellow",
}: {
  message: string;
  iconColor?: string;
}) => {
  return (
    <Tooltip title={message} placement="top">
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          strokeWidth="0.5"
          className={`h-6 w-6 ${iconColor === "red" ? "text-red-500" : "text-yellow-900"}`}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {/* Warning Circle */}
          <circle cx="12" cy="12" r="10" fill={iconColor === "red" ? "#F44336" : "#FFC107"} />
          <path
            d="M12 4.5L18 17H6L12 4.5z"
            fill={iconColor === "red" ? "#F44336" : "#FFC107"}
            stroke="#422042"
            strokeWidth="1.5"
          />
          <path
            d="M12 9v4m0 3h.01"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke={iconColor === "red" ? "#B71C1C" : "#6B470B"}
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export default WarningIconWithTooltip;
