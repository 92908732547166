export const isBrowserSupportingWebGL = () => {
  // good enough: https://stackoverflow.com/a/22953053
  try {
    const canvas = document.createElement("canvas");
    const res =
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"));
    return res;
  } catch (e) {
    return false;
  }
};
