import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const Footer = (props: React.ButtonHTMLAttributes<HTMLDivElement>): ReactElement => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <span style={{ color: "white" }}>{t("footer.copyright")}</span>
      &nbsp;
      <span style={{ color: "white" }}> | </span>
      &nbsp;
      <span style={{ color: "white" }}>
        <a
          className="underline"
          target="_blank"
          href={t("footer.termsOfSales_url")}
          rel="noopener noreferrer"
        >
          {t("footer.termsOfSales")}
        </a>
        &nbsp;|&nbsp;
        <a
          className="underline"
          href={t("footer.privacyPolicy_url")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("footer.privacyPolicy")}
        </a>
        &nbsp;
        {/* |&nbsp; */}
        {/* <a
                    href="https://wixar.atlassian.net/servicedesk/customer/portal/2/group/3/create/9"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="underline"
                >
                    {t("footer.reportBug")}
                </a> */}
      </span>
    </div>
  );
};

export default Footer;
