import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getShowStandardModal } from "src/common/state/slice/modal/modalSlice";
import ButtonSquareWithDropdownMenu from "../Button/ButtonSquareWithDropdownMenu";
import { GoBackButton } from "../GoBackButton/GoBackButton";
import Breadcrumb from "./Breadcrumb";
import NavigationTabs from "./NavigationTabs";
import { ProjectGroupFilter } from "./ProjectGroupFilter";
import { SearchProjectGroups } from "./ProjectGroupSearch";
import { getSelectedProjectGroup } from "src/features/projectGroups/state/projectGroupsSlice";

interface NavigationProps {
  tabs?: any;
  buttons?: React.ReactNode;
  title: string | React.JSX.Element;
  isProjectView?: boolean;
  isProjectGroupView?: boolean;
  titles?: string;
  tagsBar?: React.ReactNode;
  showGoBackButton?: boolean;
  showSearchBar?: boolean;
  backDepth?: number; // for example a depth of 2 will navigate to "../.."
  backCustomRedirectTo?: string;
}

const Navigation = ({
  tabs,
  buttons,
  tagsBar,
  title,
  isProjectView = false,
  isProjectGroupView = false,
  showGoBackButton = false,
  showSearchBar = true,
  backDepth = 2,
  backCustomRedirectTo = "",
}: NavigationProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pathArray = pathname.split("/").filter(Boolean);

  const forDisplayProjectGroup = pathArray[0] === "courses" && pathArray.length === 1;
  const forDisplayProject = pathArray[2] === "list" && pathArray.length === 3;
  const forStatistics = pathArray[0] === "statistics";

  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  // Fix WIXAR-3864
  const isGraphNodePath = /^\/?courses\/\d+\/list\/\d+\/graph\/node\/[^/]+\/\d+\/?$/.test(pathname);
  const showStandardModal = useSelector(getShowStandardModal);
  const isInsideModalGallery =
    showStandardModal.show === true && showStandardModal.id === "modal-gallery";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  }; // to remove the tooltip text when the dropdown is open

  return (
    <div
      id="navbar"
      className={`sticky top-0 w-full block bg-slate-100 z-40 ${
        tabs ? "" : "border-b border-gray-200"
      }`}
    >
      <div className="flex mx-6 pt-4 justify-between mb-2 h-2/3">
        <div className="block">
          <Breadcrumb titles={title} />
          <div className="flex items-center">
            {showGoBackButton && (
              <div className="onboardingHowToAddScene-step-5">
                <GoBackButton
                  className="min-w-0 mr-2"
                  depth={backDepth}
                  backCustomRedirectTo={backCustomRedirectTo}
                />
              </div>
            )}
            <h3
              className={`${tabs ? "mt-2 mb-2" : "py-4"} text-3xl font-medium cursor-default ${
                isProjectGroupView && currentProjectGroup ? "text-green-500" : ""
              }`}
              style={{ caretColor: "transparent" }}
            >
              {isGraphNodePath && isInsideModalGallery ? "" : title}
            </h3>
            <div>{tagsBar}</div>

            {isProjectGroupView && (
              <Tooltip title={t("tooltip.modifyCourse")} disableHoverListener={isDropdownOpen}>
                <div className="ml-1">
                  <ButtonSquareWithDropdownMenu
                    options="projectGroup"
                    onOpenChange={handleDropdownToggle}
                  />
                </div>
              </Tooltip>
            )}

            {isProjectView && (
              <Tooltip title={t("tooltip.modifyModule")} disableHoverListener={isDropdownOpen}>
                <div className="ml-1">
                  <ButtonSquareWithDropdownMenu
                    options="project"
                    onOpenChange={handleDropdownToggle}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>

        {showSearchBar && (
          <div
            className={`absolute bottom-8 right-12 ${
              forStatistics ? "top-[-1.5vh]" : "lg:top-4"
            } flex flex-col space-y-2 min-w-[150px]`}
          >
            <div className="flex mr-16">
              {forDisplayProjectGroup && <SearchProjectGroups isProjectGroup />}
              {forDisplayProjectGroup && <ProjectGroupFilter />}
            </div>
            <div className="mr-16">
              {forDisplayProject && <SearchProjectGroups isProjectGroup={false} />}
            </div>
            {buttons}
          </div>
        )}
      </div>
      {tabs && <NavigationTabs tabs={tabs} />}
    </div>
  );
};

export default Navigation;
