import { ReactElement } from "react";

interface Props {
  style: string;
  width: number;
  height: number;
  fill: string;
  stroke: string;
}

export default function MacOS({ width, height, fill, style, stroke }: Props): ReactElement {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.56 40"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            id="apple"
            className="cls-1"
            d="M27.21,21.25a9,9,0,0,1,4.31-7.61,9.35,9.35,0,0,0-7.31-4c-3.12-.31-6.08,1.84-7.66,1.84s-4-1.79-6.6-1.74a9.82,9.82,0,0,0-8.28,5C-1.85,20.9.77,30,4.21,34.93c1.68,2.43,3.68,5.16,6.31,5.07s3.49-1.64,6.55-1.64S21,40,23.67,39.94s4.46-2.47,6.12-4.91a21.59,21.59,0,0,0,2.77-5.69,8.82,8.82,0,0,1-5.36-8.09h0Zm-5-14.86A8.8,8.8,0,0,0,24.25,0a9,9,0,0,0-5.89,3,8.38,8.38,0,0,0-2.12,6.19,7.38,7.38,0,0,0,5.93-2.83Z"
          />
        </g>
      </g>
    </svg>
  );
}
