import { setSelectedProjectGroupFromProjectGroupId } from "src/features/projectGroups/state/projectGroupsSlice";

export interface IThreeDotClickPayload {
  action: "Edit" | "Delete" | "Fait template" | "Modifier" | "Change name";
  item: "project" | "projectGroup";
  itemId: string | number;
}

interface IProps {
  payload: IThreeDotClickPayload;
  navigate(string: string): any;
  deleteCallback: any;
  t: any;
  dispatch?: (payload: any) => any;
}

const handleThreeDotsButtonClickForProjectsAndProjectGroups = ({
  payload,
  navigate,
  deleteCallback,
  t,
  dispatch,
}: IProps) => {
  const { action, item, itemId } = { ...payload };
  if (action === "Edit") {
    if (item === "projectGroup") {
      navigate(`/courses/${itemId}/list`);
    }
    if (item === "project") {
      navigate(`${itemId}/graph`);
    }
  }
  if (action === t("general.modify") && item === "projectGroup") {
    if (dispatch) {
      dispatch(setSelectedProjectGroupFromProjectGroupId(Number(itemId)));
    }
    navigate(`/courses/${itemId}/list`);
  }
  if (action == t("general.delete")) {
    deleteCallback();
  }
  if (action === "Fait template") {
    navigate(`../template/${itemId}`);
  }
  if (action === t("general.modify")) {
    if (item === "project") {
      navigate(`../list/${itemId}/edit-name`);
    }
  }
};

export default handleThreeDotsButtonClickForProjectsAndProjectGroups;
