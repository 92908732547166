const parseSimplifiedUserIdentifier = (email: string) => {
  if (email) {
    if (email.endsWith("@sneakysneaky.ua")) {
      const splitted = email.split("_");
      const ultimateSplit = String(splitted[splitted.length - 1]).split("@sneakysneaky.ua")[0];
      return ultimateSplit;
    } else return email;
  } else return email;
};

export default parseSimplifiedUserIdentifier;
