/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable indent */
// eslint-disable @typescript-eslint/no-empty-function

import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { queryIds } from "../../../common/util/queries";
import { DonutData, IEnduser, IMetaDatas } from "../../../model/store";
import {
  IBuild,
  IProject,
  IProjectEditable,
  IProjectsPermissions,
  ISessionsInfo,
  ITemplateProject,
  IUpdatedProj,
  IUser,
} from "../../../model/model";
import apiUrls from "../../../api";
import { RootState } from "../../../common/state/store";
import { IMedia } from "../../../model/unityObject";
export const keyStore = "wwvr-front-v1";

export interface IProjectsState {
  currentProjectSizeInMbs?: number;
  contentScoringGeneralCount: number;
  csgcLoading: boolean;
  tableEnduserLoading: false;
  list: IProject[];
  draftProjects: IProject[];
  pendingProjects: IProject[];
  activeProjects: IProject[];
  archivedProjects: IProject[];
  dataDonuts: DonutData[];
  dataGraph: { month: string; sessions: number }[];
  month: number;
  projectTemplates: IProject[];
  templates: ITemplateProject[];
  current: IProject;
  currentEditable: IProjectEditable | null;
  currentEditableActiveItem: null | "start-scene" | "menu";
  loading: boolean;
  ptLoading: boolean;
  metaLoading: boolean;
  currentProjectMetaDatas: IMetaDatas;
  currentProjectData: {
    sessions: number;
    completedSessions: number;
    identifiedUsers: IUser[];
    guests: number;
    graphData: GraphData[];
    scoreByScene: any[];
  };
  inviteData: {
    firstname: string;
    lastname: string;
    email: string;
    orgName: string;
    usersToInvite: IUser[];
  };
  projectTeam: IUser[];
  projectPermissions: IProjectsPermissions[];
  tabProjectName: string;
  endusers: IEnduser[];
  sessionsInfo: ISessionsInfo[];
  currentProjectBuilds: IBuild[];
}

const initialState: IProjectsState = {
  ptLoading: false,
  csgcLoading: false,
  tableEnduserLoading: false,
  contentScoringGeneralCount: 0,
  list: [],
  activeProjects: [],
  draftProjects: [],
  pendingProjects: [],
  archivedProjects: [],
  projectTemplates: [],
  templates: [],
  dataDonuts: [],
  dataGraph: [],
  month: -1,
  currentProjectSizeInMbs: 0,
  current: {
    id: 597,
    name: "",
    language: {
      id: 1,
      name: "French",
    },
    need_login: false,
    is_allowing_collab: false,
    status: "Edit",
    organization: {
      id: 0,
      name: "",
      createdAt: "",
      updatedAt: "",
      legal_name: "",
      address_locality: "",
      address_region: "",
      address_country: "",
      phone: "",
      postal_code: "",
      email: "",
      siret: "",
      street_address: "",
      source_logo: {
        id: 0,
        s3_url: "",
        createdAt: "",
        updatedAt: "",
        organization: 0,
        type: "image",
        name: "",
        filename: "",
      },
      admin: {
        avatar3d_url: "",
        blocked: false,
        confirmationToken: null,
        confirmed: true,
        createdAt: "",
        email: "",
        firstname: "",
        has_accepted_cgv: true,
        id: 0,
        lastname: "",
        password: "",
        provider: null,
        resetPasswordToken: null,
        updatedAt: "",
        username: "",
      },
    },
    is_template: false,
    createdAt: "",
    updatedAt: "",
    uuid: "",
    builds: [],
    enabled_languages: [],
    source_thumbnail: {
      label: "",
      id: 0,
      s3_url: "",
      createdAt: "",
      updatedAt: "",
      filename: "",
      organization: {
        id: 0,
        name: "",
        created_at: "",
        updated_at: "",
        legal_name: "",
        address_locality: "",
        address_region: "",
        address_country: "",
        phone: "",
        postal_code: "",
        email: "",
        siret: "",
        street_address: "",
        source_logo: {
          id: 0,
          s3_url: "",
          created_at: "",
          updated_at: "",
          organization: 0,
          type: "image",
          name: "",
          filename: "",
        },
        admin: {
          avatar3d_url: "",
          blocked: false,
          confirmationToken: null,
          confirmed: true,
          createdAt: "",
          email: "",
          firstname: "",
          has_accepted_cgv: true,
          id: 0,
          lastname: "",
          password: "",
          provider: null,
          resetPasswordToken: null,
          updatedAt: "",
          username: "",
        },
      },
      type: "image",
      name: "",
      upload_status: "completed",
    },
    source_logo: {
      label: "",
      id: 0,
      s3_url: "",
      createdAt: "",
      updatedAt: "",
      filename: "",
      organization: {
        id: 0,
        name: "",
        created_at: "",
        updated_at: "",
        legal_name: "",
        address_locality: "",
        address_region: "",
        address_country: "",
        phone: "",
        postal_code: "",
        email: "",
        siret: "",
        street_address: "",
        source_logo: {
          id: 0,
          s3_url: "",
          created_at: "",
          updated_at: "",
          organization: 0,
          type: "image",
          name: "",
          filename: "",
        },
        admin: {
          avatar3d_url: "",
          blocked: false,
          confirmationToken: null,
          confirmed: true,
          createdAt: "",
          email: "",
          firstname: "",
          has_accepted_cgv: true,
          id: 0,
          lastname: "",
          password: "",
          provider: null,
          resetPasswordToken: null,
          updatedAt: "",
          username: "",
        },
      },
      type: "image",
      name: "",
      upload_status: "completed",
    },
    permissions: [],
    cloned_from: null,
    id_build_vr: null,
    id_build_mobile: null,
    id_build_webgl: null,
    id_background_music: null,
    background_music_volume: 1,
    is_deleted: false,
    is_user_template: false,
  },
  currentEditable: null,
  loading: false,
  metaLoading: false,
  currentEditableActiveItem: null,
  currentProjectMetaDatas: {
    projectId: 0,
    sizeInKbs: 0,
    sizeInMbs: 0,
    durationInSecs: 0,
    interactions: [],
  },
  currentProjectData: {
    sessions: 0,
    completedSessions: 0,
    identifiedUsers: [],
    guests: 0,
    graphData: [],
    scoreByScene: [],
  },
  inviteData: {
    firstname: "",
    lastname: "",
    email: "",
    orgName: "",
    usersToInvite: [],
  },
  projectTeam: [],
  projectPermissions: [],
  tabProjectName: "",
  endusers: [],
  sessionsInfo: [],
  currentProjectBuilds: [],
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setLoading: (state: IProjectsState, action: any) => {
      state.loading = action.payload;
    },
    setMetaLoading: (state: IProjectsState, action: any) => {
      state.metaLoading = action.payload;
    },
    setSessionsInfo: (state: IProjectsState, action: any) => {
      state.sessionsInfo = [...action.payload];
    },
    setProjects: (state: IProjectsState, action: any) => {
      state.list = [...action.payload];
    },
    removeProjectFromList: (state: IProjectsState, action: any) => {
      state.list = state.list.filter(
        (elt: any) => Number(elt.id) !== Number(action.payload.projectId),
      );
    },
    setProjectTemplates: (state: IProjectsState, action: any) => {
      state.projectTemplates = [...action.payload];
    },
    setActiveProjects: (state: IProjectsState, action: any) => {
      state.activeProjects = [...action.payload];
    },
    setDraftProjects: (state: IProjectsState, action: any) => {
      state.draftProjects = [...action.payload];
    },
    setPendingProjects: (state: IProjectsState, action: any) => {
      state.pendingProjects = [...action.payload];
    },
    setArchivedProjects: (state: IProjectsState, action: any) => {
      state.archivedProjects = [...action.payload];
    },
    setDonutData: (state: IProjectsState, action: any) => {
      state.dataDonuts = [...action.payload];
    },
    setGraphData: (state: IProjectsState, action: any) => {
      state.dataGraph = [...action.payload];
    },
    setCurrentProjectMetaDatas: (state: IProjectsState, action: any) => {
      state.currentProjectMetaDatas = { ...action.payload };
    },
    setCurrentProjectSizeInMbs: (state: IProjectsState, action: any) => {
      state.currentProjectSizeInMbs = action.payload;
    },
    setEditableProject: (state: IProjectsState, action: any) => {
      const { project } = action.payload;
      delete action.payload.project;
      state.currentEditable = { ...project, ...action.payload };
    },
    setCurrentEditableActiveItem: (state: IProjectsState, action: any) => {
      state.currentEditableActiveItem = action.payload;
    },
    disableCurrentEditableActiveItem: (state: IProjectsState, action: any) => {
      state.currentEditableActiveItem = action.payload;
    },
    setTemplates: (state: IProjectsState, action: any) => {
      state.templates = [...action.payload];
    },
    addProject: (state: IProjectsState, action: any) => {
      state.list.push(action.payload);
    },
    addProjectTemplate: (state: IProjectsState, action: any) => {
      state.projectTemplates.push(action.payload);
      if (action.payload.templateOverview) {
        state.templates.push(action.payload.templateOverview);
      }
    },
    clearProjects: (state: IProjectsState) => {
      state.list = [];
    },
    setCurrent: (state: IProjectsState, action: any) => {
      state.current = action.payload;
    },
    setCurrentProjectData: (state: IProjectsState, action: any) => {
      state.currentProjectData = { ...action.payload };
    },
    setCurrentSessions: (state: IProjectsState, action: any) => {
      state.currentProjectData.sessions = action.payload;
    },
    setCurrentCompletedSessions: (state: IProjectsState, action: any) => {
      state.currentProjectData.completedSessions = action.payload;
    },
    setCurrentIdentifiedUsers: (state: IProjectsState, action: any) => {
      state.currentProjectData.identifiedUsers = [...action.payload];
    },
    setCurrentGuests: (state: IProjectsState, action: any) => {
      state.currentProjectData.guests = action.payload;
    },
    setCurrentGraphData: (state: IProjectsState, action: any) => {
      state.currentProjectData.graphData = [...action.payload];
    },
    setScoreByScene: (state: IProjectsState, action: any) => {
      state.currentProjectData.scoreByScene = [...action.payload];
    },
    selectMonth: (state: IProjectsState, action: any) => {
      state.month = action.payload;
    },
    setThumbnail: (state: IProjectsState, action: any) => {
      state.current.source_thumbnail = { ...action.payload };
    },
    setInviteData: (state: IProjectsState, action: any) => {
      state.inviteData = { ...action.payload };
    },
    updateInviteFirstname: (state: IProjectsState, action: any) => {
      state.inviteData.firstname = action.payload;
    },
    updateInviteLastname: (state: IProjectsState, action: any) => {
      state.inviteData.lastname = action.payload;
    },
    updateInviteEmail: (state: IProjectsState, action: any) => {
      state.inviteData.email = action.payload;
    },
    updateInviteOrgName: (state: IProjectsState, action: any) => {
      state.inviteData.orgName = action.payload;
    },
    addCoworkerInvite: (state: IProjectsState, action: any) => {
      state.inviteData.usersToInvite.push(action.payload);
    },
    cancelCoworkerInvite: (state: IProjectsState, action: any) => {
      state.inviteData.usersToInvite = state.inviteData.usersToInvite.filter(
        (user: IUser) => user.id !== action.payload.id,
      );
    },
    clearInvite: (state: IProjectsState) => {
      state.inviteData = {
        firstname: "",
        lastname: "",
        email: "",
        orgName: "",
        usersToInvite: [],
      };
    },
    setProjectTeam: (state: IProjectsState, action: any) => {
      function isIterable(obj: any) {
        // checks for null and undefined
        if (obj === null) {
          return false;
        }
        return typeof obj[Symbol.iterator] === "function";
      }
      state.projectTeam = isIterable(action.payload) ? [...action.payload] : Array(action.payload);
    },
    addToProjectTeam: (state: IProjectsState, action: any) => {
      state.projectTeam = [...state.projectTeam, { ...action.payload }];
    },
    setProjectPermissions: (state: IProjectsState, action: any) => {
      state.projectPermissions = [...action.payload];
    },
    addProjectPermission: (state: IProjectsState, action: any) => {
      state.projectPermissions = [...state.projectPermissions, { ...action.payload }];
    },
    removeProjectPermission: (state: IProjectsState, action: any) => {
      state.projectPermissions = [...state.projectPermissions].filter(
        (perm) => perm.id !== action.payload,
      );
    },
    removeProjectTeam: (state: IProjectsState, action: any) => {
      state.projectTeam = [...state.projectTeam].filter((perm) => perm.id !== action.payload);
    },
    setTabProjectName: (state: IProjectsState, action: any) => {
      state.tabProjectName = action.payload;
    },
    setEndUsers: (state: IProjectsState, action: any) => {
      state.endusers = [...action.payload];
    },
    addEndusers: (state: IProjectsState, action: any) => {
      state.endusers.push({
        email: action.payload.email,
        enduser: action.payload.id,
        first_session: "",
        last_session: "",
        last_session_score: "waiting_enduser_validation",
        project: action.payload.project,
        user_name: action.payload.firstname + " " + action.payload.lastname,
      } as IEnduser);
    },
    setProjectName: (state: IProjectsState, action: any) => {
      state.current.name = action.payload.name;
    },
    setIsUserTemplate: (state: IProjectsState, action: any) => {
      state.current.is_user_template = action.payload.is_user_template;
    },
    setTheCurrentProjectStatus: (state: IProjectsState, action: any) => {
      state.current.status = action.payload;
    },
    setCurrentProjectAudioBackground: (state: IProjectsState, action: any) => {
      state.current.id_background_music = action.payload;
    },
    setCurrentProjectAudioBackgroundVolume: (state: IProjectsState, action: any) => {
      state.current.background_music_volume = action.payload;
    },
    setContentScoringGeneralCount: (state: IProjectsState, action: any) => {
      state.contentScoringGeneralCount = action.payload;
    },
    setIsAllowingCollab: (state: IProjectsState, action: any) => {
      state.list = state.list.map((project: any) => {
        if (Number(project.id) === Number(action.payload.id)) {
          const updatedProject = { ...project, is_allowing_collab: action.payload.bool };
          return updatedProject;
        } else return project;
      });
    },
    setPtLoading: (state: IProjectsState, action: any) => {
      state.ptLoading = action.payload;
    },
    setCsgcLoading: (state: IProjectsState, action: any) => {
      state.csgcLoading = action.payload;
    },
    setTableEnduserLoading: (state: IProjectsState, action: any) => {
      state.tableEnduserLoading = action.payload;
    },
    setCurrentBuilds: (state: IProjectsState, action: any) => {
      state.currentProjectBuilds = action.payload;
    },
  },
});

export const {
  setSessionsInfo,
  setIsUserTemplate,
  setIsAllowingCollab,
  setProjects,
  setPtLoading,
  removeProjectFromList,
  setActiveProjects,
  setDraftProjects,
  setPendingProjects,
  setArchivedProjects,
  setProjectTemplates,
  setDonutData,
  setGraphData,
  clearProjects,
  setLoading,
  setMetaLoading,
  addProject,
  addProjectTemplate,
  setCurrent,
  setCurrentProjectMetaDatas,
  setCurrentProjectSizeInMbs,
  setTemplates,
  setEditableProject,
  setCurrentEditableActiveItem,
  disableCurrentEditableActiveItem,
  setCurrentSessions,
  setCurrentCompletedSessions,
  setCurrentIdentifiedUsers,
  setCurrentGuests,
  setCurrentProjectData,
  setCurrentGraphData,
  setScoreByScene,
  setContentScoringGeneralCount,
  selectMonth,
  setThumbnail,
  setInviteData,
  updateInviteFirstname,
  updateInviteLastname,
  updateInviteEmail,
  updateInviteOrgName,
  setProjectTeam,
  addToProjectTeam,
  setProjectPermissions,
  addProjectPermission,
  removeProjectPermission,
  removeProjectTeam,
  addCoworkerInvite,
  clearInvite,
  cancelCoworkerInvite,
  setTabProjectName,
  setEndUsers,
  addEndusers,
  setProjectName,
  setTheCurrentProjectStatus,
  setCurrentProjectAudioBackground,
  setCurrentProjectAudioBackgroundVolume,
  setCsgcLoading,
  setTableEnduserLoading,
  setCurrentBuilds,
} = projectSlice.actions;

// ETA 03/11/21: this function is NEVER called, NOWHERE, NEVAH EVAH
// nani the fuck ? let's recycle this trash into something somewhat useful
export const fetch = (userId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.allWithUserTemplates}`)
    .then((response) => {
      const organizationProjects = response.data.filter((project: IProject) => {
        return project.permissions.map((p: any) => Number(p.user)).includes(userId);
      });
      dispatch(setProjects(organizationProjects));
    })
    .catch((error) => console.error(error))
    .finally(() => dispatch(setLoading(false)));
};

export const fetchProjectTemplates =
  (query: any = {}) =>
  (dispatch: Dispatch) => {
    dispatch(setPtLoading(true));
    axios
      .get(`${apiUrls.projects.all}?is_template=true`, { params: { ...query } })
      .then((response) => dispatch(setProjectTemplates(response.data)))
      .catch((error) => console.error(error))
      .finally(() => dispatch(setPtLoading(false)));
  };

export const fetchFullProject = (projectId: any) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.clone}?project=${projectId}`)
    .then((response) => dispatch(setEditableProject(response.data)))
    .catch((error) => console.error(error))
    .finally(() => dispatch(setLoading(false)));
};

export const clone =
  (
    projectId: number,
    newName: string,
    needLogin: boolean,
    canCollabChecked: boolean,
    isSimplifiedLogin: boolean,
    callback: any = undefined,
  ): any =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const organizationId = getState().profile.currentUser?.organization.id;
    dispatch(setLoading(true));
    axios
      .post(
        `${apiUrls.projects.clone}?project=${projectId}&newOrg=${organizationId}&newName=${newName}&needLogin=${needLogin}&isAllowingCollab=${canCollabChecked}&isSimplifiedLogin=${isSimplifiedLogin}`,
      )
      .then((response) => {
        dispatch(addProject(response.data.newProject));
        // dispatch(setAlert({ isOpen: true, msg: "cloneSuccess", type: "success" }));
        return response.data.newProject;
      })
      .catch(
        () => {},
        // dispatch(setAlert({ isOpen: true, msg: "cloneFail", type: "danger", subMsg: e })),
      )
      .finally(() => {
        dispatch(setLoading(false));
        if (callback) callback();
      });
  };

export const cloneTemplate =
  (
    projectId: number,
    newName: string,
    needLogin: boolean,
    canCollabChecked: boolean,
    top: string,
    bottom: string,
    more: string,
    callback: any = undefined,
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const organizationId = getState().profile.currentUser?.organization.id;

    dispatch(setLoading(true));
    axios
      .post(
        `${apiUrls.projects.cloneTemplate}?project=${projectId}&newOrg=${organizationId}&newName=${newName}&needLogin=${needLogin}&isAllowingCollab=${canCollabChecked}`,
        {
          top: top,
          bottom: bottom,
          more: more,
        },
      )
      .then((response) => {
        dispatch(addProject(response.data));
        dispatch(addProjectTemplate(response.data));
        //dispatch(setAlert({ isOpen: true, msg: "templateSuccess", type: "success" }));
        return response.data;
      })

      .catch(() => {
        if (callback) callback();

        /* dispatch(
                      setAlert({
                        isOpen: true,
                        msg: "templateFailed",
                        type: "danger",
                        subMsg: e?.response?.data?.message,
                      }),
                    );*/
      })
      .finally(() => {
        dispatch(setLoading(false));
        if (callback) callback();
      });
  };

type monthGraphData = { month: string; sessions: number }[];
type GraphData = { month: string; dateValue: string; sessions: number }[];

export const fetchProjectSessions = (projects: IProject[]) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const promises = projects
    .filter((proj) => proj.status === "Published")
    .map((proj) => {
      return axios
        .get(`${apiUrls.projects.graph.sessions}?project=${proj.id}`)
        .then((response) => {
          return {
            project_name: proj.name,
            project_id: proj.id,
            need_login: proj.need_login,
            sessions: response.data.map((session: any) => {
              return {
                ...session,
                created_at:
                  Number(session.created_at_ts * 1000) === 0
                    ? session.created_at
                    : Number(session.created_at_ts * 1000),
                is_completed: session.is_completed,
                enduser: session.enduser ? String(session.enduser.role) : null,
              };
            }),
          };
        })
        .catch(console.error);
    });
  Promise.all(promises)
    .then((res) => {
      dispatch(setSessionsInfo(res));
    })
    .finally(() => dispatch(setLoading(false)));
};

export const fetchCurrentCompletedSessions = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.graph.sessions}?project=${projectId}&is_completed=true`)
    .then((response) => {
      const sessions_clear = response.data.filter(function (session: {
        created_at: string;
        project: IProject;
        enduser: IUser | null;
      }) {
        if (
          (session.project.need_login === true && Number(session.enduser?.role) === 4) ||
          (session.project.need_login === false && session.enduser === null)
        ) {
          return true;
        } else {
          return false;
        }
      });
      dispatch(setCurrentCompletedSessions(sessions_clear.length));
    })
    .catch(console.error)
    .finally(() => dispatch(setLoading(false)));
};

const fetchAllUsers = (Ids: number[]) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  if (Ids.length > 0) {
    const url = apiUrls.register.users;
    const query = queryIds(Ids);
    axios
      .get(url + `?${query}`)
      .then((response) => {
        const endusersArr = Array(response.data).filter((user: IUser) => {
          const roleId =
            typeof user.role === "object" && user.role !== null
              ? Number(user.role.id)
              : Number(user.role);
          return roleId === 4;
        });
        dispatch(setCurrentIdentifiedUsers(endusersArr));
      })
      .catch(null);
  } else {
    dispatch(setCurrentIdentifiedUsers([]));
  }
};

export const fetchCurrentIdentifiedUsers = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.graph.sessions}?project=${projectId}&enduser_null=false`)
    .then((response) => {
      const userIds = response.data.map(
        (session: { enduser: { id: number } }) => session.enduser.id,
      );
      const uniqueUserIds = userIds.filter(
        (value: number, index: number, self: any) => self.indexOf(value) === index,
      );
      fetchAllUsers(uniqueUserIds)(dispatch);
    })
    .catch(console.error)
    .finally(() => dispatch(setLoading(false)));
};

export const fetchCurrentGuests = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.graph.sessions}?project=${projectId}&enduser_null=true`)
    .then((response) => dispatch(setCurrentGuests([...response.data].length)))
    .catch(console.error)
    .finally(() => dispatch(setLoading(false)));
};

export const fetchCurrentProjectBuilds = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.builds}?project=${projectId}`)
    .then((response) => {
      const res = (response.data as []).map((elt: any) => ({
        id: elt.id,
        name: elt.name,
        s3_url: elt.s3_url,
        target_platform: elt.target_platform,
        version: elt.version,
        created_at: elt.created_at,
        updated_at: elt.updated_at,
      }));
      dispatch(setCurrentBuilds(res));
    })
    .catch(console.error)
    .finally(() => dispatch(setLoading(false)));
};

export const fetchCurrentGraphData =
  (
    projectId: number,
    filter: number,
    dataMonth: GraphData,
    dataWeek: monthGraphData,
    firstdate: Date,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    let url = `${apiUrls.projects.graph.sessions}?project=${projectId}`;
    if (firstdate) {
      url += `&created_at_gt=${firstdate.toISOString()}`;
    }
    url += "";
    axios
      .get(url)
      .then((response) => {
        [...response.data].forEach(
          (session: { created_at: string; project: IProject; enduser: IUser | null }) => {
            //get only enduser sessions for need_login true, and guest session
            const created_at = new Date(session.created_at);
            let month: number = created_at.getMonth();
            if (filter === -1) {
              //get index (of the month of the session) in datamonth array
              //to get index, we translate both in two digit string
              month = dataMonth
                .map((e) => {
                  return e.dateValue.slice(0, 2);
                })
                .indexOf(("0" + (created_at.getMonth() + 1)).slice(-2).toString());
              dataMonth[month] = { ...dataMonth[month], sessions: dataMonth[month].sessions + 1 };
            } else if (month === filter) {
              let day = Math.floor((created_at.getDate() - 1) / 7);
              if (day > 3) {
                day = 3;
              }
              dataWeek[day] = { ...dataWeek[day], sessions: dataWeek[day].sessions + 1 };
            }
          },
        );

        dispatch(setCurrentGraphData(filter === -1 ? dataMonth : dataWeek));
      })
      .catch(console.error)
      .finally(() => dispatch(setLoading(false)));
  };
export const updateThumbnail = (ThumbnailId: number, projectId: number) => () => {
  axios
    .put(`${apiUrls.projects.all}/${projectId}`, { source_thumbnail: ThumbnailId })
    .then(
      () => {},
      //dispatch(setAlert({ isOpen: true, msg: "changeThumbnailSuccess", type: "success" })),
    )
    .catch(
      () => {},
      // dispatch(setAlert({ isOpen: true, msg: "changeThumbnailFail", type: "danger", subMsg: e })),
    );
};
export const changeProjectName = (projectId: number, name: string) => (dispatch: Dispatch) => {
  axios
    .put(`${apiUrls.projects.all}/${projectId}`, { name: name })
    .then((response) => {
      dispatch(setProjectName(response.data));
    })
    .then(
      () => {} /*dispatch(setAlert({ isOpen: true, msg: "renameProjectSuccess", type: "success" }))*/,
    )
    .catch(
      () => {},
      //dispatch(setAlert({ isOpen: true, msg: "renameProjectFail", type: "danger", subMsg: e })),
    );
};

export const fetchProjectTeam = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${apiUrls.projects.projectUserPermissions}?project=${projectId}`)
    .then((response) => {
      dispatch(setProjectPermissions(response.data));
      const userIds = [...response.data].map((permission) => permission.user.id);
      return userIds;
    })
    .then((userIds) => {
      if (userIds.length === 0) {
        dispatch(setProjectTeam([]));
      } else {
        const url = apiUrls.register.users;
        const query = decodeURI(queryIds(userIds));
        axios
          .get(url + `?${query}`)
          .then((response) => dispatch(setProjectTeam(response.data)))
          .catch(console.error);
      }
    })
    .catch(console.error)
    .finally(() => dispatch(setLoading(false)));
};

export const fetchContentScoringGeneralCount = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setCsgcLoading(true));
  axios
    .get(`${apiUrls.projects.getContentScoringGeneralCount}?projectId=${projectId}`)
    .then((response) => {
      dispatch(setContentScoringGeneralCount(response.data));
    })
    .catch(console.error)
    .finally(() => dispatch(setCsgcLoading(false)));
};

export const inviteCreator =
  (coworkers: IUser[], projectId: number, currentId: number) => (dispatch: Dispatch) => {
    const requests = coworkers.map((coworker) =>
      axios
        .post(`${apiUrls.projects.inviteExistingUser}`, {
          inviterId: currentId,
          inviteeId: coworker.id,
          projectId: projectId,
          isSendEmail: true,
        })
        .then(
          () => {} /* dispatch(setAlert({ isOpen: true, msg: "inviteSuccess", type: "success" }))*/,
        )
        .catch(
          () => {},
          //dispatch(setAlert({ isOpen: true, msg: "inviteFail", type: "danger", subMsg: e })),
        ),
    );
    Promise.all(requests).then(() => fetchProjectTeam(projectId)(dispatch));
  };

export const inviteExistingEnduser =
  (endusers: IUser[], projectId: number, currentId: number) => (dispatch: Dispatch) => {
    const requests = endusers.map((enduser) =>
      axios
        .post(`${apiUrls.projects.inviteExistingUser}`, {
          userId: currentId,
          guestId: enduser.id,
          projectId: projectId,
          isSendEmail: true,
        })
        .then((response) => {
          dispatch(addProjectPermission(response.data.perm));
          dispatch(addToProjectTeam(response.data.invitee));

          //dispatch(setAlert({ isOpen: true, msg: "inviteSuccess", type: "success" }));
        })
        .catch(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          () => {},
          // dispatch(setAlert({ isOpen: true, msg: "inviteFail", type: "danger", subMsg: e })),
        ),
    );
    Promise.all(requests).then(() => fetchEndUsersPerProject(projectId)(dispatch));
  };

export const deleteUserPerm = (permission: IProjectsPermissions) => (dispatch: Dispatch) => {
  axios
    .delete(`${apiUrls.projects.projectUserPermissions}/${permission.id}`)
    .then(() => {
      dispatch(removeProjectPermission(permission.id));
      dispatch(removeProjectTeam(permission.user.id));
      //dispatch(setAlert({ isOpen: true, msg: "removeUserPermSuccess", type: "success" }));
    })
    .catch(
      () => {},
      //dispatch(setAlert({ isOpen: true, msg: "removeUserPermFail", type: "danger", subMsg: e })),
    );
};

export const fetchEndUsersPerProject =
  (projectId: number, cb: any = () => null, isAnonymous = false) =>
  (dispatch: Dispatch) => {
    dispatch(setTableEnduserLoading(true));
    axios
      .get(
        `${apiUrls.projects.getEnduser}?projectId=${projectId}${
          isAnonymous ? "&isAnonymous=true" : ""
        }`,
      )
      .then((response) => {
        dispatch(setEndUsers([...response.data]));
        cb(response.data.length);
      })
      .catch(console.error)
      .finally(() => dispatch(setTableEnduserLoading(false)));
  };

export const uploadCSV = (file: File, userId: number, projectId: number) => () => {
  const formdata = new FormData();
  formdata.append("files", file);
  formdata.append("projectId", `${projectId}`);
  formdata.append("userId", `${userId}`);
  axios
    .post(`${apiUrls.csv.add}`, formdata)
    .then(
      () => {},
      // dispatch(setAlert({ isOpen: true, msg: "uploadCsvFileSuccess", type: "success" })),
    )
    .catch(
      () => {},
      // dispatch(setAlert({ isOpen: true, msg: "uploadCsvFileFail", type: "danger", subMsg: e })),
    );
};

export const inviteExternalUser =
  (
    projectId: number,
    organizationId: number,
    newUserFirstName: string,
    newUserLastName: string,
    newUserEmail: string,
    newUserUserName: string,
    inviterFirstName: string,
    inviterLastName: string,
  ) =>
  () => {
    axios
      .post(`${apiUrls.projects.inviteExternalUser}`, {
        projectId,
        organizationId,
        newUserFirstName,
        newUserLastName,
        newUserEmail,
        newUserUserName,
        inviterFirstName,
        inviterLastName,
      })
      .then(
        () => {} /*dispatch(setAlert({ isOpen: true, msg: "inviteSuccess", type: "success" }))*/,
      )
      .catch(
        () => {},
        // dispatch(setAlert({ isOpen: true, msg: "inviteFail", type: "danger", subMsg: e })),
      );
  };

export const inviteEnduser =
  (projectId: number, firstname: string, lastname: string, email: string, userId: number) =>
  (dispatch: Dispatch) => {
    axios
      .post(`${apiUrls.projects.inviteEnduser}`, {
        userId,
        projectId,
        firstname,
        lastname,
        email,
      })
      .then((res) => {
        dispatch(addEndusers(res.data.user));

        dispatch(addToProjectTeam(res.data.user));
        dispatch(addProjectPermission(res.data.createdPermission));

        // dispatch(setAlert({ isOpen: true, msg: "inviteSuccess", type: "success" }));
      })
      .catch(() => {
        /*dispatch(
                      setAlert({
                        isOpen: true,
                        msg: "inviteFail",
                        type: "danger",
                        subMsg: e.response.data.message.error,
                      }),
                    );*/
      });
  };

export const sendResults =
  (projectId: number, trainerId: number, traineeId: any, to: string) => () => {
    axios
      .post(
        `${apiUrls.projects.nuSendResults}?projectId=${projectId}&trainerId=${trainerId}&traineeId=${traineeId}&to=${to}`,
      )
      .then(() => {
        //dispatch(setAlert({ isOpen: true, msg: "sendResultSuccess", type: "success" }));
      })
      .catch(
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "sendResultFail", type: "danger", subMsg: e })),
      );
  };

export const sendGlobalResults = (projectId: number, trainerId: number) => () => {
  axios
    .post(`${apiUrls.projects.nuSendGlobalResults}?projectId=${projectId}&trainerId=${trainerId}`)
    .then(() => {
      //dispatch(setAlert({ isOpen: true, msg: "sendResultSuccess", type: "success" }));
    })
    .catch(
      () => {},
      // dispatch(setAlert({ isOpen: true, msg: "sendResultFail", type: "danger", subMsg: e })),
    );
};

export const askAdminPublish =
  (projectName: string, adminId: number, userName: string, userMail: string, stateWanted: string) =>
  () => {
    axios
      .post(`${apiUrls.creatorWantPublish}`, {
        projectName: projectName,
        adminId: adminId,
        userName: userName,
        userEmail: userMail,
        stateWanted: stateWanted,
      })
      .then(
        () => {} /*dispatch(setAlert({ isOpen: true, msg: "askAsminSuccess", type: "success" }))*/,
      )
      .catch(
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "askAdminFail", type: "danger", subMsg: e })),
      );
  };

export const setCurrentProjectStatus =
  (status: "PublishWaiting" | "Edit" | "Published" | "Archived") => (dispatch: Dispatch) => {
    dispatch(setTheCurrentProjectStatus(status));
  };

export const createScene =
  (projectId: number, mediaId: number, cb?: any, sceneName?: string) => () => {
    axios
      .post(
        `${apiUrls.projects.createScene}?projectId=${projectId}&mediaId=${mediaId}${
          sceneName ? "&sceneName=" + sceneName : ""
        }`,
      )
      .then(
        () => {} /*dispatch(setAlert({ isOpen: true, msg: "createSceneSuccess", type: "success" }))*/,
      )
      .catch(
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "createSceneFail", type: "danger", subMsg: e })),
      )
      .finally(() => (cb ? cb() : null));
  };

export const setBackgroundAudio =
  (projectId: number, audioId: IMedia | null) => (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    axios
      .put(`${apiUrls.projects.all}/${projectId}`, { id_background_music: audioId })
      .then(() => dispatch(setCurrentProjectAudioBackground(audioId)))
      .catch(
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "sendResultFail", type: "danger", subMsg: e })),
      )
      .finally(() => dispatch(setLoading(false)));
  };

export const updateIsAllowingCollab = (updatedProjects: IUpdatedProj[]) => (dispatch: Dispatch) => {
  //  dispatch(setLoading(true));

  Promise.all(
    updatedProjects.map((proj) => {
      axios
        .put(`${apiUrls.projects.all}/${proj.id}`, { is_allowing_collab: proj.bool })
        .then(() => dispatch(setIsAllowingCollab({ bool: proj.bool, id: proj.id })))
        .catch(
          () => {},
          // dispatch(setAlert({ isOpen: true, msg: "sendResultFail", type: "danger", subMsg: e })),
        )
        .finally(() => dispatch(setLoading(false)));
    }),
  );
};

export const setBackgroundAudioVolume =
  (projectId: number, volume: number) => (dispatch: Dispatch) => {
    axios
      .put(`${apiUrls.projects.all}/${projectId}`, { background_music_volume: volume })
      .then(() => {
        dispatch(setCurrentProjectAudioBackgroundVolume(volume));
        // dispatch(setAlert({ isOpen: true, msg: "updateUserSuccess", type: "success" }));
      })
      .catch(
        () => {},
        //dispatch(setAlert({ isOpen: true, msg: "updateUserFail", type: "danger", subMsg: e })),
      )
      .finally(() => dispatch(setLoading(false)));
  };

export const setProjectDeleted = (projectId: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .put(`${apiUrls.projects.all}/${projectId}`, { is_deleted: true })
    .then(
      () => {} /* dispatch(setAlert({ isOpen: true, msg: "deleteProjectSuccess", type: "success" }))*/,
    )
    .catch(
      () => {},
      //dispatch(setAlert({ isOpen: true, msg: "deleteProjectFail", type: "danger", subMsg: e })),
    )
    .finally(() => dispatch(setLoading(false)));
};
export const setAllProjectDeleted = (projectsId: number[]) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  axios
    .put(`${apiUrls.projects.all}/delete-all`, projectsId)
    .then(
      () => {} /*dispatch(setAlert({ isOpen: true, msg: "deleteProjectSuccess", type: "success" }))*/,
    )
    .catch(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      () => {},
      //dispatch(setAlert({ isOpen: true, msg: "deleteProjectFail", type: "danger", subMsg: e })),
    )
    .finally(() => dispatch(setLoading(false)));
};
export const getProjects = (state: RootState) => state.projectLegacy.list;
export const getActiveProjects = (state: RootState) => state.projectLegacy.activeProjects;
export const getDraftProjects = (state: RootState) => state.projectLegacy.draftProjects;
export const getPendingProjects = (state: RootState) => state.projectLegacy.pendingProjects;
export const getArchivedProjects = (state: RootState) => state.projectLegacy.archivedProjects;
export const getDonutData = (state: RootState) => state.projectLegacy.dataDonuts;
export const getGraphData = (state: RootState) => state.projectLegacy.dataGraph;
export const getSelectedMonth = (state: RootState) => state.projectLegacy.month;
export const getFullProject = (state: RootState) => state.projectLegacy.currentEditable;
export const getTemplates = (state: RootState) => state.projectLegacy.templates;
export const getIsLoading = (state: RootState) => state.projectLegacy.loading;
export const getIsPtLoading = (state: RootState) => state.projectLegacy.ptLoading;
export const getCurrent = (state: RootState) => state.projectLegacy.current;

export const getCurrentEditableActiveItem = (state: RootState) =>
  state.projectLegacy.currentEditableActiveItem;
export const getMetaLoading = (state: RootState) => state.projectLegacy.metaLoading;
export const getMetaDatas = (state: RootState) => state.projectLegacy.currentProjectMetaDatas;
export const getCurrentProjectSizeInMbs = (state: RootState) =>
  state.projectLegacy.currentProjectSizeInMbs;
export const getCurrentProjectData = (state: RootState) => state.projectLegacy.currentProjectData;
export const getInviteData = (state: RootState) => state.projectLegacy.inviteData;
export const getProjectTeam = (state: RootState) => state.projectLegacy.projectTeam;
export const getProjectTemplates = (state: RootState) => state.projectLegacy.projectTemplates;
export const getTabProjectName = (state: RootState) => state.projectLegacy.tabProjectName;
export const getEndusers = (state: RootState) => state.projectLegacy.endusers;
export const getProjectPermissions = (state: RootState) =>
  state.projectLegacy.projectPermissions || [];
export const getCurrentProjectStatus = (state: RootState) => state.projectLegacy.current.status;
export const getContentScoringGeneralCount = (state: RootState) =>
  state.projectLegacy.contentScoringGeneralCount;
export const getCsgcLoading = (state: RootState) => state.projectLegacy.csgcLoading;
export const getTableEnduserLoading = (state: RootState) => state.projectLegacy.tableEnduserLoading;
export const getSessionsInfo = (state: RootState) => state.projectLegacy.sessionsInfo;
export const getCurrentProjectBuilds = (state: RootState) =>
  state.projectLegacy.currentProjectBuilds;

export default projectSlice.reducer;
