import { UsersIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProjectPermissons,
  getCurrentProjectPermissions,
  setCurrentProjectPermissions,
} from "../../features/projects/state/projectsSlice";
import { useEffect, useState } from "react";
import { ILanguage, IProjectsPermissions } from "../../model/model";
import { AppDispatch } from "../../common/state/store";
import {
  closeConfirmationModal,
  openConfirmationModal,
  openStandardModal,
} from "../../common/state/slice/modal/modalSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { CONFIRM_TEAM_COMPONENT } from "../../features/team/components/AddTeamModal";

import { TiDelete } from "react-icons/ti";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import { setAlert } from "../../features/alert/state/alertsSlice";
import PlaceholderWithIcon from "../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { currentUserSelector } from "../../common/state/selectors/authSelector";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import InviteExternalParticipantFormContainer from "../../features/team/components/InviteExternalParticipantForm";
import { dateConfig } from "src/common/util/DateConfig";
import { languageConfig } from "src/common/util/langConfig";
import TableComp from "src/common/components/Table/Table";

const ProjectParticipants = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const currentProjectPermissions = useSelector(getCurrentProjectPermissions);
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const currentUser = useSelector(currentUserSelector);
  const preferredLanguage = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const currentProjectParticipants = currentProjectPermissions.filter(
    (perm) => Number(perm.user?.role.id) === 4,
  );
  const [ProjectParticipants, setProjectParticipants] = useState<IProjectsPermissions[]>([]);
  useEffect(() => {
    setProjectParticipants(currentProjectParticipants);
  }, [currentProjectPermissions]);

  const [isDarkMode, setIsDarkMode] = useState(false);
  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedPerm, setSelectedPerm] = useState<IProjectsPermissions | null>(null);

  const openDeletePermModal = (perm: IProjectsPermissions) => {
    setSelectedPerm(perm);
    dispatch(openConfirmationModal("deletePermParticipantConfirmation"));
  };

  const onSelectMode = (mode: "dark" | "light") => {
    if (mode === "dark") {
      if (!isDarkMode) setIsDarkMode(true);
    } else {
      if (isDarkMode) setIsDarkMode(false);
    }
  };

  useEffect(() => {
    // Check to see if Media-Queries are supported
    if (window.matchMedia) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => onSelectMode(e.matches ? "dark" : "light"));

      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (!isDarkMode) setIsDarkMode(true);
      }
    }
  }, []);

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (1 / 2));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const onAddUserClick = () => {
    dispatch(openStandardModal("add-team"));
  };

  const deletePerm = (perm: IProjectsPermissions) => {
    dispatch(
      deleteProjectPermissons({
        permission: perm,
        componentId: CONFIRM_TEAM_COMPONENT,
      }),
    )
      .then(() => {
        const newPerms = currentProjectPermissions.filter((p) => Number(p.id) !== Number(perm.id));
        dispatch(setCurrentProjectPermissions(newPerms));
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.permissionRemovedSuccessfully"),
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            type: "danger",
            msg: t("alerts.permissionRemovedFail"),
          }),
        );
      });
    dispatch(closeConfirmationModal("deletePermParticipantConfirmation"));
  };

  const identifierShow =
    (Number(currentUser?.organization.admin.id) === Number(currentUser?.id) ||
      Number(currentUser?.role.id) === 5) &&
    currentProjectGroup.auth_type === "simplified"
      ? true
      : false;

  const identifierAndEmailHide =
    Number(currentUser?.organization.admin.id) !== Number(currentUser?.id) &&
    Number(currentUser?.role.id) !== 5 &&
    currentProjectGroup.auth_type === "simplified"
      ? true
      : false;

  const colHeaders = () => {
    if (identifierShow) {
      return [
        t("general.firstname"),
        t("general.lastname"),
        t("general.identifier"),
        t("general.dateAdded"),
        "",
      ];
    } else if (identifierAndEmailHide) {
      return [t("general.firstname"), t("general.lastname"), t("general.dateAdded"), ""];
    } else {
      return [
        t("general.firstname"),
        t("general.lastname"),
        t("general.email"),
        t("general.dateAdded"),
        "",
      ];
    }
  };
  const getColumnValues = identifierShow
    ? ProjectParticipants.map((perm: any, index: number) => ({
        index: index + 1,
        firstname: perm.user.firstname,
        lastname: perm.user.lastname,
        identifier: perm.user.username,
        date: new Date(perm.createdAt).toLocaleString(dateConfig(preferredLanguage)),
        delete: (
          <button onClick={() => openDeletePermModal(perm)}>
            <TiDelete size={30} />
          </button>
        ),
      }))
    : identifierAndEmailHide
    ? ProjectParticipants.map((perm: any, index: number) => ({
        index: index + 1,
        firstname: perm.user.firstname,
        lastname: perm.user.lastname,
        date: new Date(perm.createdAt).toLocaleString(dateConfig(preferredLanguage)),
        delete: (
          <button onClick={() => openDeletePermModal(perm)}>
            <TiDelete size={30} />
          </button>
        ),
      }))
    : ProjectParticipants.map((perm: any, index: number) => ({
        index: index + 1,
        firstname: perm.user.firstname,
        lastname: perm.user.lastname,
        email: perm.user.email,
        date: new Date(perm.createdAt).toLocaleString(dateConfig(preferredLanguage)),
        delete: (
          <button onClick={() => openDeletePermModal(perm)}>
            <TiDelete size={30} />
          </button>
        ),
      }));

  return (
    <>
      <div className="container w-full h-full max-w-7xl mx-auto px-4">
        <InfoButton relevantArticle="aboutParticipants" />
        {
          // If project-group is anonymous, display an explicative placeholder instead of 'invite endusers' interface
          currentProjectGroup.auth_type === "none" ? (
            <div className="container w-full h-1/2 max-w-7xl mx-auto px-4 flex justify-center">
              <PlaceholderWithIcon
                title={t("pages.project.projectGroupParticipants.anonymousDisclaimerTitle")}
                text={t("pages.project.projectGroupParticipants.anonymousDisclaimerText")}
                heroIcon="InformationCircleIcon"
                className="flex flex-col items-center h-[6pc]"
              />
            </div>
          ) : (
            <IsLoading componentId={CONFIRM_TEAM_COMPONENT} showSuccess={false}>
              <div className="add-collab">
                <div className="inline-block mt-8">
                  <h2 className="text-2xl font-medium mb-2">
                    {t("pages.project.projectGroupParticipants.addParticipant")}
                  </h2>
                </div>
                <div className="flex">
                  {currentProjectGroup.auth_type !== "simplified" && (
                    <div className="flex">
                      <div className="mt-14">
                        <button
                          className="inline-flex text-sm text-green-500 font-medium "
                          onClick={onAddUserClick}
                        >
                          <UsersIcon className="stroke-green-500 w-9 h-9 mr-1" />
                          <span className="w-36 pr-1">
                            {t("pages.project.projectGroupParticipants.existingParticipant")}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <InviteExternalParticipantFormContainer />
                </div>
              </div>

              <div className="personalize-scroll-visible h-[50%]">
                <h2 className="text-2xl font-medium mb-2">
                  {t("pages.project.projectGroupParticipants.participants")}
                </h2>
                {ProjectParticipants.length > 0 ? (
                  <div
                    className="relative my-5 mx-auto overflow-x-auto border border-gray-300 shadow-md sm:rounded-lg personalize-scroll-visible"
                    key={`${tableHeight}`}
                    style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
                  >
                    <TableComp
                      isCollaboratorAndParticipant={true}
                      rows={getColumnValues}
                      colHeaders={colHeaders()}
                      listTofilter={ProjectParticipants}
                      setListOfItemsFilter={setProjectParticipants}
                      className="h-full w-full overflow-y-auto personalize-scroll-visible the-table-comp"
                    />
                  </div>
                ) : (
                  <p className="text-center mt-8">
                    {t("pages.project.projectGroupParticipants.noParticipants")}
                  </p>
                )}
              </div>

              <ModalConfirmation
                id="deletePermParticipantConfirmation"
                onClickSubmit={() => deletePerm(selectedPerm as IProjectsPermissions)}
                title={t("pages.project.projectGroupParticipants.deletePermConfirmModal.title")}
                text={t("pages.project.projectGroupParticipants.deletePermConfirmModal.body")}
                componentId={CONFIRM_TEAM_COMPONENT}
              />
            </IsLoading>
          )
        }
      </div>
    </>
  );
};

export default ProjectParticipants;
