import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import {
  ISubscriptionState,
  IFetchSubscriptionResponse,
  IPaLink,
  SubscriptionState,
  IAnonymousSessionsCount,
} from "../../../model/store";
import featureMapper from "./featureMapper";

const initialState: ISubscriptionState = {
  subscriptionFeatures: [],
  subscriptionStatus: "Active",
  paLinks: [],
  anonymousSessionsCount: {
    numberOfCurrentlyAvailableAnonymousSessions: 0,
    numberOfSessionsBehindPaywall: 0,
  },
};

export const fetchSubscription = createAsyncThunk(
  "subscription/fetchSubscriptionState",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<IFetchSubscriptionResponse>(apiUrls.getSubscriptionState);
    return { data: (await response).data };
  },
);

export const fetchPaEmbedLinks = createAsyncThunk(
  "subscription/fetchPaEmbedLinks",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<IPaLink[]>(apiUrls.paEmbedLinks);
    return { data: (await response).data };
  },
);

export const fetchAnonymousSessionsCount = createAsyncThunk(
  "subscription/fetchAnonymousSessionsCount",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<IAnonymousSessionsCount>(apiUrls.anonymousSessionsCount);
    return { data: (await response).data as IAnonymousSessionsCount };
  },
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, { payload }) => {
      state.subscriptionFeatures = featureMapper(payload.data.Features);
      state.subscriptionStatus = payload.data.Status as SubscriptionState;
    });
    builder.addCase(fetchPaEmbedLinks.fulfilled, (state, { payload }) => {
      state.paLinks = payload.data;
    });
    builder.addCase(fetchAnonymousSessionsCount.fulfilled, (state, { payload }) => {
      state.anonymousSessionsCount = payload.data;
    });
  },
  reducers: {
    setSubscriptionState: (state: ISubscriptionState, action: PayloadAction<SubscriptionState>) => {
      state.subscriptionStatus = action.payload;
    },
  },
});

export const getSubscriptionFeatures = (state: RootState) =>
  state.subscription.subscriptionFeatures;
export const getSubscriptionStatus = (state: RootState) => state.subscription.subscriptionStatus;

export const getPaSubscribedIframeUrl = (state: RootState) => {
  const link = state.subscription.paLinks.find((elt) => elt.rel === "hosted-home");
  const url = link?.href;
  return url;
};

export const getNumberOfPaywalledAnonymousSessions = (state: RootState) =>
  state.subscription.anonymousSessionsCount.numberOfSessionsBehindPaywall;

export const isLimitReachedForFeature = (state: RootState, featureIdentifier: string) => {
  let isLimitReached = false;
  const relevantFeature = state.subscription.subscriptionFeatures.find((f) => {
    return f.featureIdentifier === featureIdentifier;
  });
  if (relevantFeature && relevantFeature.TypeFeature === "Limitation") {
    if (relevantFeature.QuantityCurrent === 0) {
      isLimitReached = true;
    }
  }
  return isLimitReached;
};

export const getSubscriptionFeature = (state: RootState, featureIdentifier: string) => {
  return state.subscription.subscriptionFeatures.find((f) => {
    return f.featureIdentifier === featureIdentifier;
  });
};

export const subscriptionReducer = subscriptionSlice.reducer;

export const { setSubscriptionState } = subscriptionSlice.actions;
