import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../../locales/en.json";
import fr from "../../locales/fr.json";

export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language;
  return browserLanguage.startsWith("fr") ? "fr" : "en";
};

i18n.use(initReactI18next).init({
  resources: {
    en,
    fr,
  },
  lng: getBrowserLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
