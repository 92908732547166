import { t } from "i18next";
import { ButtonText } from "../Button";
import { openStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { setMediaFilter } from "../../../features/gallery/state/gallerySlice";
import { allTeamSelector } from "../../../features/team/state/teamSlice";
import { IUser } from "../../../model/model";
import { getCurrentOrganization } from "../../../features/profile/state/profileSlice";

type ListItemProps = {
  title: string;
  currUserId?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  setDisabled: (arg: boolean) => void;
  setValueFct?: any;
  hidden?: boolean;
  customButton?: React.ReactNode;
};

const OrganizationInputStyle = ({
  title,
  currUserId,
  disabled,
  hidden = false,
  setDisabled,
  children,
  customButton,
}: ListItemProps) => {
  const dispatch = useDispatch();
  const team = useSelector(allTeamSelector);
  const org = useSelector(getCurrentOrganization);

  const admin = team.find((u: IUser) => Number(u.id) === Number(org?.admin?.id));
  const adminId = Number(admin?.id);

  const openPhotoGallery = () => {
    dispatch(openStandardModal("modal-gallery"));
    dispatch(
      setMediaFilter({
        mediaType: "image",
        labels: [],
        order: "date_desc",
        media360tag: "",
      }),
    );
  };

  const DefaultButton = () => {
    // Org admin is allowed to edit
    if (Number(currUserId) === Number(adminId) && !hidden) {
      return (
        <ButtonText
          type="button"
          text={t("general.edit")}
          onClick={() => {
            isLogoField ? openPhotoGallery() : setDisabled(!disabled);
          }}
        />
      );
    } else if (
      Number(currUserId) !== Number(adminId) &&
      title === t("pages.profile.organization.adminEmail")
    ) {
      return (
        <a href={`mailto:${admin?.email}`}>
          <ButtonText type="button" text={t("pages.profile.organization.contactAdmin")} />
        </a>
      );
    } else {
      return <></>;
    }
  };

  const isLogoField = title === t("pages.profile.organization.companyLogo");
  return (
    <div className="w-full flex flex-col sm:flex-row items-center py-2 px-2 xl:py-3 xl:px-4 border-t border-gray-300">
      <p className="w-full sm:w-1/3 text-sm text-center sm:text-left">{title}</p>
      {children}
      <div className="flex grow justify-end">{customButton || <DefaultButton />}</div>
    </div>
  );
};

export default OrganizationInputStyle;
