import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentProjectGroupForSearch,
  setFilterProjectGroup,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { useForm } from "react-hook-form";

export interface IProjectGroupsFilter {
  order: "date_asc" | "date_desc" | "size_asc" | "size_desc";
}
import { useEffect } from "react";
import SortableDropdown from "../Input/SortableDropdown";

export const ProjectGroupFilter = () => {
  const dispatch = useDispatch();

  const projectGroupNoUpdated = useSelector(getCurrentProjectGroupForSearch);

  const { register, watch, setValue } = useForm<any>({
    defaultValues: {
      order: "date_desc",
    },
  });
  const watchFields = watch();

  useEffect(() => {
    if (watchFields.order !== "") {
      const forGroupFilterSearch = {
        type: watchFields.order,
        actualGroupList: projectGroupNoUpdated,
      };
      dispatch(setFilterProjectGroup(forGroupFilterSearch));
    }
  }, [watchFields.order, projectGroupNoUpdated]);

  return (
    <div className="mb-5 ml-2 pt-13">
      <div className="space-y-4">
        <SortableDropdown
          register={register}
          setValue={setValue}
          defaultValue="date_desc"
          onChangeCb={(value) => setValue("order", value)}
        />
      </div>
    </div>
  );
};
