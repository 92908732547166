import { useEffect, useMemo, useRef, useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { SearchAutocomplete } from "src/common/components/SearchAutocomplete/SearchAutocomplete";
import { store } from "src/common/state/store";
import ModalConfirmation from "../../../common/components/Modal/ModalConfirmation";
import { useAppDispatch } from "../../../common/state/hooks";
import { closeConfirmationModal } from "../../../common/state/slice/modal/modalSlice";
import { IProject, IProjectTag } from "../../../model/model";
import {
  deleteProject,
  getCurrentTemplateId,
  INewProjectFormValues,
  setCurrentTemplateId,
  TemplatesForTagSelector,
} from "../state/projectsSlice";
import Spinner from "src/common/components/Loader/Spinner";
import TemplateCards from "./TemplateCards";
import SortableDropdown from "src/common/components/Input/SortableDropdown";

const TemplateCategories = ({
  tagList,
  control,
  setValue,
  insideOfProject = true,
}: {
  setValue?: UseFormSetValue<INewProjectFormValues>;
  control?: Control<INewProjectFormValues, object>;
  tagList: IProjectTag[];
  insideOfProject?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentTemplateId = useSelector(getCurrentTemplateId);
  const [sortedTaglist, setSortedTaglist] = useState<IProjectTag[]>([]);

  useEffect(() => {
    setSortedTaglist(
      tagList
        .filter((tag: IProjectTag) => {
          // Filter "custom" & "favorites" when there is none
          return tag.projects.filter((p) => !p.is_deleted).length >= 1;
        })
        // Sort so Custom template comes first, then Structure, then Gaming mechanics
        .sort((a: any, b: any) => b.id - a.id),
    );
  }, [tagList]);

  const [collapsedCategories, setCollapsedCategories] = useState<string[]>([]);
  const [templatesByTag, setTemplatesByTag] = useState<Record<string, IProject[]>>({});
  const templatesContainerRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const [filterByText, setFilterByText] = useState("");
  const [filterByOrder, setFilterByOrder] = useState("date_desc"); // ["date_desc", "date_asc", "alphanum_desc", "alphanum_asc"]
  const [filteredTemplatesByTag, setFilteredTemplatesByTag] = useState<Record<string, IProject[]>>(
    {},
  );

  const [isComputingTemplates, setIsComputingTemplates] = useState(true);

  const handleToggleCollapse = (category: string) => {
    setCollapsedCategories((prevCollapsedCategories) =>
      prevCollapsedCategories.includes(category)
        ? prevCollapsedCategories.filter((t) => t !== category)
        : [...prevCollapsedCategories, category],
    );
  };

  const itemHeight = 146;
  const maxVisibleHeight = 2 * itemHeight;

  const checkContainerHeight = (tag: string) => {
    const containerRef = templatesContainerRefs.current[tag];
    if (containerRef) {
      const containerHeight = containerRef.getBoundingClientRect().height;
      return containerHeight > maxVisibleHeight;
    }
    return false;
  };

  useEffect(() => {
    const initialCollapsedCategories = tagList.map((tag) => tag.slug);
    setCollapsedCategories(initialCollapsedCategories);
  }, []);

  const filterTemplates = (templatesToFilter: Record<string, IProject[]>) => {
    const newFilteredTemplatesByTag = { ...templatesToFilter };

    Object.keys(newFilteredTemplatesByTag).forEach((tag) => {
      let filteredTemplates = [...newFilteredTemplatesByTag[tag]];

      if (filterByText !== "") {
        filteredTemplates = filteredTemplates.filter((template) =>
          template.name.toLowerCase().includes(filterByText.toLowerCase()),
        );
      }

      if (filterByOrder === "date_desc") {
        filteredTemplates = filteredTemplates.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        );
      }
      if (filterByOrder === "date_asc") {
        filteredTemplates = filteredTemplates.sort(
          (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        );
      }
      if (filterByOrder === "alphanum_desc") {
        filteredTemplates = filteredTemplates.sort((a, b) => a.name.localeCompare(b.name));
      }
      if (filterByOrder === "alphanum_asc") {
        filteredTemplates = filteredTemplates.sort((a, b) => b.name.localeCompare(a.name));
      }

      newFilteredTemplatesByTag[tag] = filteredTemplates;
    });

    setFilteredTemplatesByTag(newFilteredTemplatesByTag);

    if (isComputingTemplates) {
      const firstTemplate = Object.values(newFilteredTemplatesByTag)
        .flat()
        .find((template) => !template.is_deleted);
      if (firstTemplate) {
        dispatch(setCurrentTemplateId(firstTemplate.id));
      }
    }
    setIsComputingTemplates(false);
  };

  useEffect(() => {
    const newTemplatesByTag: Record<string, IProject[]> = {};
    sortedTaglist.forEach((tag) => {
      const templates = TemplatesForTagSelector({ tag: tag.slug, state: store.getState() });
      newTemplatesByTag[tag.slug] = templates.filteredProjects;
    });

    setTemplatesByTag(newTemplatesByTag);

    filterTemplates(newTemplatesByTag);
  }, [sortedTaglist]);

  useEffect(() => {
    filterTemplates(templatesByTag);
  }, [filterByText, filterByOrder]);

  const memoizedItems = useMemo(() => {
    return Object.values(templatesByTag)
      .flat()
      .map((template, index) => ({
        id: template.id || index,
        name: template.name,
      }));
  }, [templatesByTag]);

  return (
    <div className="w-full h-full">
      <IsLoading componentId={"projectListComponent"} showSuccess={false} spinnerPlaceholder>
        {isComputingTemplates ? (
          <div className="flex justify-center items-center h-full">
            <Spinner relative={true} />
          </div>
        ) : (
          <div className="flex flex-col grow w-full justify-between h-full">
            <div className="whitespace-no-wrap w-full space-y-6 py-6 px-6">
              <div className="flex">
                <div className="mt-1">
                  <SearchAutocomplete
                    items={memoizedItems}
                    handleOnSearch={setFilterByText}
                    handleOnSelect={(item) => setFilterByText(item.name)}
                  />
                </div>
                <div className="pl-6">
                  <SortableDropdown onChangeCb={(e) => setFilterByOrder(e.target.value)} />
                </div>
              </div>
              {sortedTaglist.map((tag: IProjectTag, index: number) => {
                const filteredTemplates = filteredTemplatesByTag[tag.slug] || [];
                const templates = templatesByTag[tag.slug] || [];

                const isCollapsed = collapsedCategories.includes(tag.slug);
                const showCollapseButton = checkContainerHeight(tag.slug);

                return (
                  <div key={index}>
                    <div className="flex mb-5 mt-6">
                      <h3 className="font-medium text-lg">{t(`chooseTemplateType.${tag.slug}`)}</h3>
                      {showCollapseButton && (
                        <div className="flex ml-4">
                          <button
                            className="text-green-500 text-sm"
                            onClick={(e) => {
                              e.preventDefault();
                              handleToggleCollapse(tag.slug);
                            }}
                          >
                            {isCollapsed ? t("general.showMore") : t("general.showLess")}
                          </button>
                        </div>
                      )}
                    </div>
                    {templates.length < 1 ? (
                      tag.slug === "custom-templates" ? (
                        <p className="w-96">{t("chooseTemplateType.noCustomTemplate")}</p>
                      ) : (
                        <p className="w-96">{t("pages.templates.notFound")}</p>
                      )
                    ) : filteredTemplates.length < 1 ? (
                      <p className="w-96">{t("pages.templates.notFound")}</p>
                    ) : (
                      <div className="flex justify-start">
                        <div className="flex flex-col grow w-full justify-between h-full">
                          <div className="whitespace-no-wrap w-full space-y-6">
                            <div className="w-full overflow-x-auto">
                              <div
                                ref={(el) => (templatesContainerRefs.current[tag.slug] = el)}
                                className="grid gap-6 sm:gap-5 w-auto personalize-scroll-visible py-2 px-3"
                                style={{
                                  gridTemplateColumns:
                                    "repeat(auto-fit, minmax(224px, max-content))",
                                  maxHeight: isCollapsed
                                    ? `calc(2 * ${itemHeight}px + 0.1px)`
                                    : "none",
                                  overflowY: "hidden",
                                }}
                              >
                                <TemplateCards
                                  templates={filteredTemplates}
                                  tagSlug={tag.slug}
                                  control={control}
                                  setValue={setValue}
                                  insideOfProject={insideOfProject}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              <ModalConfirmation
                id="deleteTemplateConfirmation"
                onClickSubmit={() => {
                  dispatch(closeConfirmationModal("deleteTemplateConfirmation"));
                  dispatch(
                    deleteProject({
                      id: currentTemplateId,
                      componentId: `template-${currentTemplateId}`,
                    }),
                  );

                  setIsComputingTemplates(true);
                }}
                title={t("pages.faitTemplate.deletionModal.title")}
                text={t("pages.faitTemplate.deletionModal.body")}
                submitButtonText={t("general.delete")}
              />
            </div>
          </div>
        )}
      </IsLoading>
    </div>
  );
};

export default TemplateCategories;
