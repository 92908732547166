import React, { useLayoutEffect, useState } from "react";

const useElementRect = (containerRef: React.RefObject<HTMLElement>): DOMRect | undefined => {
  const [rect, setRect] = useState<DOMRect>();
  useLayoutEffect(() => {
    const domElement = containerRef.current;

    // method called to update the rect value on changes
    const updateRectOnDOMElementResize = () => {
      setRect(domElement.getBoundingClientRect());
    };

    // first load
    updateRectOnDOMElementResize();

    if (typeof window.ResizeObserver === "function") {
      // on-resize, element-wise
      const resizeObserver = new ResizeObserver(updateRectOnDOMElementResize);
      resizeObserver.observe(domElement);
      return () => resizeObserver.unobserve(domElement);
    } else {
      // on-resize on non supporting browsers: we listen to the whole window
      window.addEventListener("resize", updateRectOnDOMElementResize);
      return () => window.removeEventListener("resize", updateRectOnDOMElementResize);
    }
  }, [containerRef, setRect]);
  return rect;
};

export default useElementRect;
