import * as React from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import type { History, Blocker, Transition } from "history";

// https://dev.to/bangash1996/detecting-user-leaving-page-with-react-router-dom-v602-33ni
export function useBlocker(blocker: Blocker, when = true): void {
  const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History;

  React.useEffect(() => {
    if (when) {
      const unblock = navigator.block((tx: Transition) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });

      return unblock;
    } else {
      return undefined;
    }
  }, [navigator, blocker, when]);
}
