import { t } from "i18next";
import { PureComponent } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { roundScorePercentage } from "../../../../common/util/round";
import { ScorePerScene } from "../../state/statsSlice";

interface Props {
  data: ScorePerScene[];
}

export default class SuccessRatePerScene extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  prettyData = this.props.data.map((sps: ScorePerScene) => {
    return {
      name: sps.name,
      percent: roundScorePercentage({ ...sps }),
    };
  });

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={600}
          height={300}
          data={this.prettyData}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 68, bottom: 40 }}
        >
          <XAxis type="number" tickFormatter={(tick) => `${tick}%`} domain={[0, 100]} />
          {this.prettyData.length > 0 && (
            <YAxis
              type="category"
              dataKey="name"
              tickFormatter={(tick) => (tick.length > 14 ? tick.substring(0, 14) + "..." : tick)}
            />
          )}
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <Tooltip />
          <Bar dataKey="percent" fill="#00C889" name={t("pages.statistics.successRate")} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
