import ThreeDotsDropdownMenu, { menuItem } from "src/common/components/Icon/ThreeDotsDropdownMenu";
import ThemePreview from "src/common/components/ThemePreview/ThemePreview";
import { IThemeInfo } from "src/model/model";

export interface IThemeRadioItemParams {
  theme: IThemeInfo;
  onClick: (arg0: IThemeInfo) => void;
  threeDotsMenuItems?: menuItem[];
  threeDotsMenuHandleClick?: (item: any, index: number) => void;
  formName: string;
  inputElementParams?: any;
}

const ThemeRadioItem = ({
  theme,
  onClick,
  threeDotsMenuItems,
  threeDotsMenuHandleClick,
  formName,
  inputElementParams,
}: IThemeRadioItemParams) => {
  return (
    <div className="relative" key={`chooseThemeListPrivate_${theme.id}`}>
      <label className="relative cursor-pointer">
        <ThemePreview themeInfo={theme} className="w-52 h-52" />
        <div className="flex justify-center items-center">
          <input
            {...inputElementParams}
            type="radio"
            className="mr-2"
            name={formName}
            checked={inputElementParams?.checked}
            onClick={(e) => {
              e.stopPropagation(); // Prevent label click

              onClick(theme); // This directly handles setting the selected theme
            }}
          />
          <span>{theme.name}</span>
        </div>
        {threeDotsMenuItems && threeDotsMenuHandleClick && threeDotsMenuItems.length > 0 && (
          <div className="relative bottom-52 right-0 flex justify-end pr-[1.5rem]">
            <div className="absolute right-0 flex justify-end pr-[1.5rem]">
              <ThreeDotsDropdownMenu
                handleClick={(item, index) => {
                  threeDotsMenuHandleClick(item, index);
                }}
                menuItems={threeDotsMenuItems}
              />
            </div>
          </div>
        )}
      </label>
    </div>
  );
};

export default ThemeRadioItem;
