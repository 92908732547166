import { ReactElement } from "react";

interface Props {
  style: string;
  width: number;
  height: number;
  fill: string;
  stroke: string;
}

export default function Windows10({ width, height, fill, style, stroke }: Props): ReactElement {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.88 40"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            id="Windows"
            className="cls-1"
            d="M0,5.66,16.3,3.44V19.16L0,19.26ZM16.29,21V36.71L0,34.47V20.87Zm2-17.82L39.87,0V19l-21.61.18Zm21.62,18V40L18.26,37l0-15.86Z"
          />
        </g>
      </g>
    </svg>
  );
}
