import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FooterButtons } from "src/common/components/FooterButtons";
import { getThemes, postNewTheme, updateTheme } from "../state/themeSlice";
import ThemePreview from "src/common/components/ThemePreview/ThemePreview";
import { IThemeInfo } from "src/model/model";
import { parseHexColor, ColorType, getHexColor } from "src/common/util/parseHexColor";
import Switch from "@mui/material/Switch";
import Navigation from "src/common/components/Navigation/Navigation";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { useAppDispatch } from "src/common/state/hooks";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { getCurrentOrganization } from "src/features/profile/state/profileSlice";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { GrSettingsOption } from "react-icons/gr";

/**
 * @param {number} themeId: id of the theme to id, < 1 for new theme
 */
interface IThemeEditorParams {
  title: string;
  themeId: number;
  createthemeWithThisBase: boolean;
  onCancel: null | (() => void);
  onConfirm: null | ((themeInfo: IThemeInfo) => void);
}

type ThemeColorType =
  | "color_primary"
  | "color_secondary"
  | "color_surface"
  | "text_color_primary"
  | "text_color_secondary";
type ThemeFontType = "font_title_name" | "font_main_name";

const THEME_EDITOR_COMPONENT = "themeEditorComponent";

const ThemeEditor = ({
  title,
  themeId,
  createthemeWithThisBase,
  onCancel = null,
  onConfirm = null,
}: IThemeEditorParams) => {
  const { t } = useTranslation();
  const themesList = useSelector(getThemes);
  const dispatch = useAppDispatch();
  const currentOrg = useSelector(getCurrentOrganization);

  const fontList = [
    "LiberationSans SDF",
    "Prilly Monly SDF",
    "Roboto-Bold SDF",
    "SalmaPro-MediumNarrow SDF",
    "Montserrat-VariableFont_wght SDF",
  ];

  const [themeInfo, setThemeInfo] = useState<IThemeInfo | undefined>({
    id: 0,
    name: "",
    color_primary: "#DDE2E5",
    color_secondary: "#00C889",
    color_background: "#0C1E59",
    color_surface: "#00965C",
    font_title_name: "Roboto-Bold SDF",
    font_main_name: "Roboto-Bold SDF",
    corner_radius: 90,
    enable_outline: false,
    outline_width: 3,
    is_using_theme_text_color: false,
    text_color_primary: "#000000",
    text_color_secondary: "#FFFFFF",
  });
  const [themeNameForEdit, setThemeNameForEdit] = useState<string>("");

  const titleFontMenuRef = useRef(null);
  const mainFontMenuRef = useRef(null);
  const fontOptionMenuRef = useRef(null);
  const textColorPrimRef = useRef(null);
  const textColorSecRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [subMenuSelectedItemRef, setSubMenuSelectedItemRef] = useState<any | null>(null);

  const onColorChange = (value: string, colorKey: ThemeColorType) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    const parsedColor = parseHexColor(value);

    parsedColor.a = parseHexColor(newTheme[colorKey] as string).a;
    newTheme[colorKey] = getHexColor(parsedColor);
    setThemeInfo(newTheme);
  };

  const onAlphaChange = (value: number, colorKey: ThemeColorType) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    const parsedColor: ColorType = parseHexColor(newTheme[colorKey] as string);
    parsedColor.a = value;

    newTheme[colorKey] = getHexColor(parsedColor);

    setThemeInfo(newTheme);
  };

  const onCornerRadiusChange = (value: number) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    newTheme.corner_radius = value;

    setThemeInfo(newTheme);
  };

  const onFontChange = (value: string, fontKey: ThemeFontType) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    newTheme[fontKey] = value;
    setThemeInfo(newTheme);
  };

  const onBorderChange = (enableBorder: boolean, borderWidth: number | null = null) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    newTheme.enable_outline = enableBorder;
    if (borderWidth != null) {
      newTheme.outline_width = borderWidth;
    }

    setThemeInfo(newTheme);
  };

  const onValueChange = (fieldName: keyof IThemeInfo, value: any) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    (newTheme as any)[fieldName] = value;

    setThemeInfo(newTheme);
  };

  const onNameChange = (name: string) => {
    const newTheme = { ...(themeInfo as IThemeInfo) };
    newTheme.name = name;

    setThemeInfo(newTheme);
  };

  const menuItem = (children: any, refToUse: any = null, isSubMenuItem = false, enabled = true) => {
    const ref = refToUse ?? useRef(null);
    return (
      <>
        <div
          className={`bg-white w-full h-14 rounded-2xl ${
            enabled ? "focus:ring-green-500 hover:ring-green-500 hover:ring-4 focus:ring-4" : ""
          } ${
            (isSubMenuItem ? subMenuSelectedItemRef : selectedItem) === ref && enabled
              ? "ring-4 ring-green-500"
              : ""
          } ${enabled ? "" : "opacity-25"}`}
          onClick={(e) => {
            e.stopPropagation();
            if (isSubMenuItem) {
              setSubMenuSelectedItemRef(ref);
            } else {
              setSelectedItem(ref);
            }
          }}
          ref={ref}
        >
          {children}
        </div>
      </>
    );
  };

  const colorItem = (
    colorName: string,
    colorHex: string | undefined,
    colorType: ThemeColorType,
    refToUse: any = null,
    isSubMenuItem = false,
    enabled = true,
  ) => {
    const item = menuItem(
      <>
        <label className={`${enabled ? "cursor-pointer" : "cursor-not-allowed"} w-full`}>
          <div className="p-2 h-full flex">
            <div className="w-[25%] h-full flex items-center p-2">
              <svg viewBox={"0 0 10 10"} className="w-full">
                <circle
                  cx="5"
                  cy="5"
                  r="4.5"
                  fill={(themeInfo as IThemeInfo)[colorType]?.substring(0, 7)}
                  x="5"
                  y="5"
                  strokeWidth="0.5"
                />
                <circle
                  cx="5"
                  cy="5"
                  r="4.4"
                  fill="none"
                  x="50"
                  y="50"
                  stroke="black"
                  width="20"
                  height="20"
                  strokeWidth="0.25"
                ></circle>
              </svg>
            </div>

            <div className="w-full h-full flex flex-col ml-4 justify-center">
              <h6>{colorName}</h6>
              <h6>{colorHex?.substring(0, 7)}</h6>
            </div>

            <input
              type="color"
              value={(themeInfo as IThemeInfo)[colorType]?.substring(0, 7)}
              id="color-picker"
              onChange={(e) => onColorChange(e.target.value, colorType)}
              className="invisible"
              disabled={!enabled}
            />
          </div>
        </label>
      </>,
      refToUse,
      isSubMenuItem,
      enabled,
    );

    return item;
  };

  const fontItem = (title: string, refToUse: any = null) => {
    const item = menuItem(
      <>
        <label className="cursor-pointer w-full">
          <div className="p-2 h-full flex items-center">
            <div className="w-[20%] h-full flex items-center p-2 justify-center">
              <span
                className="text-3xl"
                style={{ fontFamily: (themeInfo as IThemeInfo)["font_title_name"] }}
              >
                A
              </span>
              <span className="text-1xl">&nbsp;/&nbsp;</span>
              <span
                className="text-3xl"
                style={{ fontFamily: (themeInfo as IThemeInfo)["font_main_name"] }}
              >
                A
              </span>
            </div>

            <div className="w-[80%] h-full flex flex-col ml-4 justify-center">
              <h6>{title}</h6>
              <h6>
                {truncStringWithDots((themeInfo as IThemeInfo)["font_title_name"] as string, 7)} /{" "}
                {truncStringWithDots((themeInfo as IThemeInfo)["font_main_name"] as string, 7)}
              </h6>
            </div>
          </div>
        </label>
      </>,
      refToUse,
    );

    return item;
  };

  const fontOptionsItem = (refToUse: any = null) => {
    const item = menuItem(
      <>
        <label className="cursor-pointer w-full">
          <div className="p-2 h-full flex items-center justify-center">
            <GrSettingsOption size={25} />
          </div>
        </label>
      </>,
      refToUse,
    );

    return item;
  };

  const alphaSlider = (value: number, colorType: ThemeColorType) => {
    return (
      <div className="flex w-full">
        <span>{t("pages.themeEditor.opacity")}</span>
        <input
          id="rangeSelector"
          type="range"
          min="0"
          max="255"
          value={value}
          onChange={(event) => {
            onAlphaChange(Number(event.target.value), colorType);
          }}
          step="1"
          className="w-full bg-gray-200 rounded-lg range-selector cursor-pointer dark:bg-gray-400 ml-4"
          style={{
            accentColor: "rgb(0 180 123)",
          }}
        />
      </div>
    );
  };

  const colorAndAlphaItem = (
    colorName: string,
    colorHex: string | undefined,
    colorType: ThemeColorType,
  ) => {
    return (
      <div>
        {colorItem(colorName, colorHex, colorType)}
        <div className="mt-1">{alphaSlider(parseHexColor(colorHex as string).a, colorType)}</div>
      </div>
    );
  };

  const truncStringWithDots = (value: string, maxLength: number) => {
    if (value.length <= maxLength) return value;
    return value.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    if (themeId > 0) {
      setThemeInfo(themesList.find((t) => t.id === themeId));
      setThemeNameForEdit(themesList.find((t) => t.id === themeId)?.name as string);
    }
  }, []);

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "rgb(0 200 137)",
      },
    },
  });

  const onSaveClick = () => {
    if (!createthemeWithThisBase) {
      if (themeId > 0) {
        dispatch(
          updateTheme({ theme: themeInfo as IThemeInfo, componentId: THEME_EDITOR_COMPONENT }),
        ).then((res: any) => {
          if (res.payload) {
            if (onConfirm) onConfirm(themeInfo as IThemeInfo);
          } else {
            dispatch(setAlert({ msg: t("alerts.themeNameAlreadyExists"), type: "danger" }));
          }

          if (onConfirm) onConfirm(themeInfo as IThemeInfo);
        });
      } else {
        const themeToSave = { ...themeInfo, organization: currentOrg };
        dispatch(
          postNewTheme({ theme: themeToSave as IThemeInfo, componentId: THEME_EDITOR_COMPONENT }),
        ).then((res: any) => {
          if (res.payload) {
            if (onConfirm) onConfirm(themeInfo as IThemeInfo);
          } else {
            dispatch(setAlert({ msg: t("alerts.themeNameAlreadyExists"), type: "danger" }));
          }
        });
      }
    } else {
      const themeToSave = { ...themeInfo, organization: currentOrg };
      dispatch(
        postNewTheme({ theme: themeToSave as IThemeInfo, componentId: THEME_EDITOR_COMPONENT }),
      ).then((res: any) => {
        if (res.payload) {
          if (onConfirm) onConfirm(themeInfo as IThemeInfo);
        } else {
          dispatch(setAlert({ msg: t("alerts.themeNameAlreadyExists"), type: "danger" }));
        }
      });
    }
  };

  const themesNames = themesList.map((t) => t.name);
  const themesFilteredForEditMode = themesNames.filter((t) => t !== themeNameForEdit);
  const themeNamesInEditMode = themeId > 0 ? themesFilteredForEditMode : themesNames;
  const errorThemeNameAlreadyExists = themeNamesInEditMode.includes(themeInfo?.name as string);
  const themeIsNotEmpty = themeInfo?.name ? themeInfo?.name.length < 0 : true;

  const textColorPrimaryItem = colorItem(
    t("pages.themeEditor.textColorOnPrimary"),
    themeInfo?.text_color_primary?.toUpperCase(),
    "text_color_primary",
    textColorPrimRef,
    true,
    themeInfo?.is_using_theme_text_color,
  );

  const textColorSecondaryItem = colorItem(
    t("pages.themeEditor.textColorOnSecondary"),
    themeInfo?.text_color_secondary?.toUpperCase(),
    "text_color_secondary",
    textColorSecRef,
    true,
    themeInfo?.is_using_theme_text_color,
  );

  return (
    <div
      className="flex flex-col w-full h-screen justify-between"
      onClick={() => setSelectedItem(null)}
    >
      <Navigation title={title} />
      <IsLoading componentId={THEME_EDITOR_COMPONENT} showSuccess={false}>
        <div className="flex h-full items-center space-x-8">
          <div className="w-[45%] p-4 flex flex-col justify-center h-full">
            <div className="ml-20 mr-4 font-medium text-xl">
              <h3 className="absolute translate-y-[-150%]">
                {t("pages.themeEditor.customizeTheme")}
              </h3>
            </div>
            <div className="w-half bg-white p-4 w-3/4 self-center rounded-2xl">
              <ThemePreview themeInfo={themeInfo as IThemeInfo} />
            </div>
          </div>

          <div className="w-[22%] space-y-5 h-full flex flex-col justify-center">
            <div>
              <div className="flex items-center space-x-4">
                <span>{t("pages.themeEditor.themeName")}</span>
                <input
                  type="text"
                  required
                  value={themeInfo?.name}
                  onChange={(e) => {
                    onNameChange(e.target.value);
                  }}
                />
              </div>
              {errorThemeNameAlreadyExists && (
                <div className="text-red-600 text-sm ml-12 mt-3 -mb-1">
                  {t("alerts.themeNameAlreadyExists")}
                </div>
              )}
            </div>

            {colorAndAlphaItem(
              t("pages.themeEditor.colorPrimary"),
              (themeInfo as IThemeInfo).color_primary?.toUpperCase(),
              "color_primary",
            )}

            {colorAndAlphaItem(
              t("pages.themeEditor.colorSecondary"),
              (themeInfo as IThemeInfo).color_secondary?.toUpperCase(),
              "color_secondary",
            )}

            {colorAndAlphaItem(
              t("pages.themeEditor.colorSurface"),
              (themeInfo as IThemeInfo).color_surface?.toUpperCase(),
              "color_surface",
            )}

            <div className="flex space-x-4">
              <div className="w-[85%]">
                {fontItem(t("pages.themeEditor.fontSelection"), titleFontMenuRef)}
              </div>
              <div className="w-[15%]">{fontOptionsItem(fontOptionMenuRef)}</div>
            </div>

            <div className="flex items-center space-x-4 w-full justify-between p-2">
              <span className="font-bold text-5xl w-1/3">{themeInfo?.corner_radius}</span>
              <div className="w-2/3">
                <span>{t("pages.themeEditor.cornerRadius")}</span>
                <input
                  id="rangeSelector"
                  type="range"
                  min="0"
                  max="200"
                  value={themeInfo?.corner_radius}
                  onChange={(event) => {
                    onCornerRadiusChange(Number(event.target.value));
                  }}
                  step="1"
                  className="w-full bg-gray-200 rounded-lg range-selector cursor-pointer dark:bg-gray-400 "
                  style={{
                    accentColor: "rgb(0 180 123)",
                  }}
                />
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <ThemeProvider theme={muiTheme}>
                <Switch
                  value={themeInfo?.enable_outline as boolean}
                  checked={themeInfo?.enable_outline as boolean}
                  onClick={() =>
                    onBorderChange(!themeInfo?.enable_outline, themeInfo?.outline_width)
                  }
                  color="primary"
                />
              </ThemeProvider>
              <span>{t("pages.themeEditor.border")}</span>
              <input
                type="number"
                min="0"
                max="20"
                value={themeInfo?.outline_width}
                onChange={(e) => {
                  if (e.target.checkValidity()) {
                    onBorderChange(themeInfo?.enable_outline as boolean, Number(e.target.value));
                  }
                }}
                disabled={!themeInfo?.enable_outline}
              />
            </div>
          </div>

          {(titleFontMenuRef === selectedItem || mainFontMenuRef == selectedItem) && (
            <div className="w-[22%] space-y-4 h-[70vh] overflow-auto">
              <div
                className="w-full space-y-4 bg-white p-4 rounded-2xl"
                onClick={(e) => e.stopPropagation()}
              >
                <h6>{t("pages.themeEditor.titleFont")}</h6>
                {fontList.map((elt) => {
                  return (
                    <div
                      className={`flex justify-center items-center w-full h-12 bg-gray-300 rounded-xl text-2xl focus:ring-green-500 hover:ring-green-500
                    hover:ring-4 focus:ring-4 cursor-pointer ${
                      (themeInfo as IThemeInfo).font_title_name === elt
                        ? "ring-4 ring-green-500"
                        : ""
                    }`}
                      style={{ fontFamily: elt }}
                      key={`font-select-list-${elt}`}
                      onClick={() => {
                        onFontChange(elt, "font_title_name");
                      }}
                    >
                      <p className="text-ellipsis whitespace-nowrap overflow-x-hidden">{elt}</p>
                    </div>
                  );
                })}
              </div>
              <div
                className="w-full space-y-4 bg-white p-4 rounded-2xl"
                onClick={(e) => e.stopPropagation()}
              >
                <h6>{t("pages.themeEditor.mainFont")}</h6>
                {fontList.map((elt) => {
                  return (
                    <div
                      className={`flex justify-center items-center w-full h-12 bg-gray-300 rounded-xl text-2xl focus:ring-green-500 hover:ring-green-500
                    hover:ring-4 focus:ring-4 cursor-pointer ${
                      (themeInfo as IThemeInfo).font_main_name === elt
                        ? "ring-4 ring-green-500"
                        : ""
                    }`}
                      style={{ fontFamily: elt }}
                      key={`font-select-list-${elt}`}
                      onClick={() => {
                        onFontChange(elt, "font_main_name");
                      }}
                    >
                      <p className="text-ellipsis whitespace-nowrap overflow-x-hidden">{elt}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {fontOptionMenuRef === selectedItem && (
            <div className="w-[22%] space-y-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex items-center space-x-4" onClick={(e) => e.stopPropagation()}>
                <ThemeProvider theme={muiTheme}>
                  <Switch
                    value={themeInfo?.is_using_theme_text_color as boolean}
                    checked={themeInfo?.is_using_theme_text_color as boolean}
                    onClick={() =>
                      onValueChange(
                        "is_using_theme_text_color",
                        !themeInfo?.is_using_theme_text_color,
                      )
                    }
                    color="primary"
                  />
                </ThemeProvider>
                <span>{t("pages.themeEditor.useThemeFontColors")}</span>
              </div>
              <div onClick={(e) => e.stopPropagation()}>{textColorPrimaryItem}</div>
              <div onClick={(e) => e.stopPropagation()}>{textColorSecondaryItem}</div>
            </div>
          )}
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => {
            if (onCancel) onCancel();
          }}
          nextText={themeId > 0 ? t("general.save") : t("general.confirm")}
          nextCb={() => {
            onSaveClick();
          }}
          nextDisabled={!themeInfo || errorThemeNameAlreadyExists || themeIsNotEmpty}
          nextDisabledTooltipText={t("pages.themeEditor.nameMustBeValid")}
        />
      </IsLoading>
    </div>
  );
};

export { ThemeEditor };
