import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import ProjectNewName from "./ProjectNewName";

const EditProjectNameWrapper = () => {
  const { t } = useTranslation();

  const selectedProject = useSelector(getCurrentProject);

  return (
    <>
      <Navigation
        title={`${t("pages.editProjectName.title")}: ${selectedProject.name}`}
        isProjectView={true}
      />
      <ProjectNewName mode="edit-name" />
    </>
  );
};

export default EditProjectNameWrapper;
