interface IPGThumbnail {
  src: string;
  title: string;
  alt?: string;
  className?: string;
}

export const PGThumbnail: React.FC<IPGThumbnail> = ({
  src,
  title,
  alt,
  className,
}: IPGThumbnail) => {
  return (
    <div
      className={`w-20 h-16 bg-black rounded-lg border-2 border-green-500 overflow-hidden flex-shrink-0 ${className}`}
      style={{ position: "relative" }}
    >
      <img
        src={src}
        title={title}
        alt={alt ? alt : title}
        className="absolute inset-0 w-full h-full object-cover m-auto"
      />
    </div>
  );
};
