import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../features/profile/state/profileSlice";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar/Sidebar";
import StackTrace from "stacktrace-js";

export default function ErrorView(): ReactElement {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const error = window.sessionStorage.getItem("error");
  const [mappedError, setMappedError] = useState(window.sessionStorage.getItem("error"));

  const isUserDeveloper = currentUser?.role.name === "Developer";
  const devMode = process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG;
  const shouldDisplayEnhancedOutput = isUserDeveloper || devMode;

  const PrettyPrintJson = ({ data }: any) => {
    return (
      <div className="w-full text-sm xxl:text-base xl:text-base lg:text-xs sm:text-xs">
        <pre>{data}</pre>
      </div>
    );
  };

  useEffect(() => {
    if (error) {
      StackTrace.fromError(new Error(error)).then((stackframes) => {
        const stackTrace = stackframes.map((sf) => sf.toString()).join("\n");
        setMappedError(stackTrace);
      });
    }
  }, [error]);

  return (
    <>
      <div className="fixed flex w-full min-h-screen bg-slate-100">
        {currentUser?.role.name === "Creator" && <Sidebar />}
        <main className="relative flex flex-col w-full h-screen overflow-y-auto">
          <div className="flex flex-col justify-center items-center h-full">
            <div className="mb-[15vh] flex flex-col justify-center items-center">
              <p className="m-5 text-xl font-medium text-black">
                {t("error.ohnoes")}
                {shouldDisplayEnhancedOutput && " Monkey developers... 🐵"}
              </p>
              {shouldDisplayEnhancedOutput ? (
                <>
                  <PrettyPrintJson data={mappedError} />

                  <img
                    className="h-60 w-auto"
                    src="https://i.imgur.com/MA9k7ZZ.gif"
                    alt="oopsie"
                    title="oopsie"
                  />
                </>
              ) : (
                <a
                  href="https://wixar.atlassian.net/servicedesk/customer/portal/2/group/3/create/9"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "rgb(0 200 137)",
                  }}
                >
                  {t("error.reportBug")}
                </a>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
