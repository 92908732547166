import { ReactElement } from "react";

interface Props {
  style: string;
  width: number;
  height: number;
  fill: string;
  stroke: string;
}

export default function IPad({ width, height, fill, style, stroke }: Props): ReactElement {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.85 40"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            id="Ipad"
            className="cls-1"
            d="M27.32,37.2H1.53V2.88H27.32ZM28.85,1.13A1.13,1.13,0,0,0,27.73,0H1.13A1.12,1.12,0,0,0,0,1.11V38.87A1.12,1.12,0,0,0,1.11,40H27.72a1.12,1.12,0,0,0,1.13-1.11V1.13ZM14.43,1.07a.45.45,0,0,1,.4.4.4.4,0,0,1-.4.4.39.39,0,0,1-.4-.4.37.37,0,0,1,.36-.4h0m0,37.27a.4.4,0,1,0,0,.8.4.4,0,0,0,.4-.4c.07-.27-.14-.4-.4-.4m0,1.14a.74.74,0,1,1,.73-.74.74.74,0,0,1-.73.74"
          />
        </g>
      </g>
    </svg>
  );
}
