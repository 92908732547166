import { useSelector } from "react-redux";
import { errorSelector } from "../../state/slice/appStateSlice";
import { RootState } from "../../state/store";

export const useError = (componentId: string) => {
  const { error } = useSelector((state: RootState) => {
    return {
      error: errorSelector(state, componentId),
    };
  });
  return error;
};

export const Error = ({
  componentId,
  errorString,
}: {
  componentId: string;
  errorString?: string;
}) => {
  const error = useError(componentId);
  return (
    <>
      {error && error !== "" && (
        <span className="text-red-500">{errorString ? errorString : error}</span>
      )}
    </>
  );
};
