import { useTranslation } from "react-i18next";
import InformationComponent from "../ProjectGroups/InformationComponent";
import { useNameFormatter } from "src/features/graph/util/NameFormatter";

interface Interactions {
  [key: string]: number;
}

interface InteractionsListProps {
  interactions: Interactions | [];
}

const InteractionsList: React.FC<InteractionsListProps> = ({
  interactions,
}: InteractionsListProps) => {
  const { t } = useTranslation();
  const { getSameFormattedModelNameThanUnity } = useNameFormatter();

  if (!interactions || Object.keys(interactions).length === 0) {
    return <div className="text-center text-gray-500">{t("general.noElementsFound")}</div>;
  }

  const objectValuesFromInteractions = Object.values(interactions);

  return (
    <>
      {Object.keys(interactions).map((interaction, key) => {
        const title = getSameFormattedModelNameThanUnity(interaction);
        return (
          <InformationComponent
            key={interaction}
            title={title}
            moduleName={String(objectValuesFromInteractions[key])}
          />
        );
      })}
    </>
  );
};

export default InteractionsList;
