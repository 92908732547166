import { useEffect, useState, useRef } from "react";
import CheckAll from "../UsersTable/CheckAll";
import { useTranslation } from "react-i18next";
import { IsLoading } from "../AppState/IsLoading";
import TableComp from "../Table/Table";

interface ICreateOrUpdateUserAsAdminPermissionsListProps {
  rows: any[];
  userRole: "creator" | "enduser" | "simplifiedEnduser";
  handleSelectAll: any;
  isCheckAllChecked: boolean;
  setPermissions: any;
  permissions: any;
}

export const CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT =
  "createOrUpdateUserAsAdminPermissionsListComponent";

export const CreateOrUpdateUserAsAdminPermissionsList = ({
  rows = [],
  userRole,
  isCheckAllChecked,
  handleSelectAll,
  permissions = [],
  setPermissions = () => {
    [];
  },
}: ICreateOrUpdateUserAsAdminPermissionsListProps) => {
  const { t } = useTranslation();

  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (1 / 2));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const colHeaders = [t(`general.${userRole === "creator" ? "projectGroup" : "project"}`), ""];
  const tableContainerRef = useRef(null);
  return (
    <IsLoading
      componentId={CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT}
      spinnerPlaceholder
      showSuccess={false}
    >
      <TableComp
        createOrUpdateUserAsAdminPermissionsList={true}
        key={tableHeight}
        maxTableHeightPx={tableHeight}
        selectFromListInTable={true}
        rows={rows}
        colHeaders={colHeaders}
        className="h-full overflow-x-hidden the-table-comp w-[85%]"
        ref={tableContainerRef}
        listTofilter={permissions}
        setListOfItemsFilter={setPermissions}
        checkAll={
          userRole === "simplifiedEnduser" ? (
            <div></div>
          ) : (
            <CheckAll
              className={`${rows.length === 0 ? "hidden" : ""} check-all`}
              checked={isCheckAllChecked}
              onChange={handleSelectAll}
            />
          )
        }
        emptyPlaceholder={
          <div className="flex m-8 items-center justify-center">{t("general.noElementsFound")}</div>
        }
        showSearchBar={true}
      />
    </IsLoading>
  );
};
