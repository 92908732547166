import { FolderIcon, LogoutIcon, PhotographIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ZendeskAPI } from "react-zendesk";
import { getIsAdminOfOrganization } from "src/features/profile/state/profileSlice";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import { setSelectedProjectId } from "../../../features/stats/state/statsSlice";
import { currentUserSelector } from "../../state/selectors/authSelector";
import { RootState } from "../../state/store";
import AIChatButton from "../AIChat/AIChatButton";
import CollaboratorIcon2 from "../Icon/CollaboratorIcon2";
import { IconName } from "../Icon/Icon";
import ParticipantsIcons from "../Icon/ParticipantsIcon";
import ThemeIcon from "../Icon/ThemeIcon";
import { SidebarItem, SidebarItemSmall } from "./SidebarItem";
import { SidebarSection } from "./SidebarSection";

type SideBarLinkProps = {
  title: string;
  iconName?: IconName;
  routePath: string;
  customIcon?: any;
  classNameOnboarding?: string;
};

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split("/");

  const { user, isAdmin, proabonoWixarAiAccess } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      isAdmin: getIsAdminOfOrganization(state),
      proabonoWixarAiAccess: getSubscriptionFeature(state, "wixar-ai-access"),
    };
  });

  const isAllowedToUseWixarAi = proabonoWixarAiAccess?.IsEnabled;

  const [isChatOpen, setIsChatOpen] = useState(false);

  const sidebarLinks: SideBarLinkProps[] = [
    {
      title: t("sidebar.projectGroups"),
      customIcon: <FolderIcon className="w-8 stroke-white" />,
      routePath: "/courses",
    },
  ];

  if (user?.role.name === "Creator") {
    sidebarLinks.push({
      title: t("sidebar.media"),
      customIcon: <PhotographIcon className="w-8 stroke-white" />,
      routePath: "/medias",
      classNameOnboarding: "introOnboarding-step-3",
    });
  }

  const isBetaVersion = Boolean(Number(process.env.REACT_APP_IS_BETA_VERSION ?? 0));

  const chatClickHandler = () => {
    if (isChatOpen) {
      ZendeskAPI("webWidget", "hide");
      setIsChatOpen(false);
    } else {
      ZendeskAPI("webWidget", "show");
      ZendeskAPI("webWidget", "open");
      setIsChatOpen(true);
    }
  };

  if (isChatOpen) {
    ZendeskAPI("webWidget:on", "userEvent", function (userEvent: any) {
      if (userEvent.action === "Web Widget Minimised") {
        // when mimizing, hide the widget instead
        chatClickHandler();
      }
    });
  }

  const logout = () => {
    setIsChatOpen(false);
    dispatch(logoutAuth());
  };

  const SidebarItems = () => {
    const items = sidebarLinks.map((link, i) => {
      return (
        <SidebarItem
          classNameOnboarding={link.classNameOnboarding}
          routeTo={link.routePath}
          key={i}
          title={link.title}
          icon={link?.iconName}
          customIcon={link?.customIcon}
          isSelected={path[1] === link.routePath.replace("/", "")}
          onClick={() => {
            // dirty reset of statistic view.
            // todo: refactor so this variable lives in one useState of wrapper component ~
            dispatch(setSelectedProjectId(0));
          }}
        />
      );
    });
    items.splice(2, 0);
    return <>{items}</>;
  };

  return (
    <div
      id="sidebar"
      className="flex flex-col justify-between w-38 pt-3 pb-6 bg-blue-500 z-50 px-2 1536px:w-40"
    >
      <div>
        <Link to={"/"}>
          <img
            className="object-scale-down hover:scale-110 cursor-pointer w-28 h-28 m-auto"
            src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/Wixar_Editor_Logo_Capital_500x500.png"
            alt="wixar-logo"
          />
        </Link>

        {isAllowedToUseWixarAi && <AIChatButton />}
        {isBetaVersion && (
          <span className="text-white absolute text-m left-[60px] top-[80px]">Beta</span>
        )}
        <div className={`mb-3 ${!isAllowedToUseWixarAi ? "mt-8" : ""}`}>
          <SidebarItems />
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <SidebarSection title={t("sidebar.customization")}>
          <SidebarItemSmall
            routeTo={"/templates"}
            key={"templates"}
            title={<span className="text-sm">{t("sidebar.templates")}</span>}
            isSelected={path[1] === "templates"}
            icon="CollectionIcon"
          />
          <SidebarItemSmall
            routeTo={"/themes"}
            key={"themes"}
            title={<span className="text-sm">{t("sidebar.themes")}</span>}
            isSelected={path[1] === "themes"}
            customIcon={<ThemeIcon />}
          />
        </SidebarSection>

        <SidebarSection title={t("sidebar.formation")}>
          <SidebarItemSmall
            routeTo={"/learners"}
            key={"learners"}
            title={<span className="text-sm">{t("general.participants")}</span>}
            customIcon={<ParticipantsIcons fill="white" height={18} width={24} className="w-4" />}
            isSelected={path[1] === "learners"}
            onClick={() => {
              dispatch(setSelectedProjectId(0));
            }}
          />
          <SidebarItemSmall
            routeTo={"/statistics"}
            key={"statistics"}
            title={<span className="text-sm">{t("sidebar.statistics")}</span>}
            isSelected={path[1] === "statistics"}
            icon="ChartBarIcon"
          />
        </SidebarSection>

        <SidebarSection title={t("sidebar.parameters")}>
          <SidebarItemSmall
            icon="UserIcon"
            routeTo={"/profile/me"}
            title={<span className="text-sm">{t("sidebar.profile")}</span>}
            isSelected={path[1] === "profile"}
          />
          {isAdmin && (
            <SidebarItemSmall
              routeTo={"/team"}
              key={"team"}
              title={<span className="text-sm">{t("general.collaborators")}</span>}
              customIcon={<CollaboratorIcon2 fill={"white"} height={16} width={16} />}
              isSelected={path[1] === "team"}
              onClick={() => {
                dispatch(setSelectedProjectId(0));
              }}
            />
          )}
          <SidebarItemSmall
            icon="DownloadIcon"
            title={<span className="text-sm">{t("sidebar.wixar-client")}</span>}
            routeTo={"/download-client"}
          />
        </SidebarSection>

        <div className="divide-y">
          <span className="text-gray-400 text-sm uppercase">{t("sidebar.support")}</span>
          <div className="py-2 xxl:mt-1">
            {user?.role.name === "Creator" && (
              <>
                <Tooltip
                  title={`${t("pages.help.exitDomainWarningTooltip")}`}
                  placement="right-end"
                >
                  <div className="introOnboarding-step-5">
                    <SidebarItemSmall
                      icon="InformationCircleIcon"
                      title={<span className="text-sm">{t("sidebar.help")}</span>}
                      routeTo={""}
                      onClick={() => open(t("pages.help.url"))}
                    />
                  </div>
                </Tooltip>
                <div className="introOnboarding-step-7">
                  <SidebarItemSmall
                    icon="ChatIcon"
                    title={<span className="text-sm">{t("sidebar.chat")}</span>}
                    fillIcon={isChatOpen ? "#fffc9b" : undefined}
                    routeTo={""}
                    onClick={() => {
                      chatClickHandler();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="text-red-500 ml-1 1536px:mb-4">
        <SidebarItemSmall
          customIcon={<LogoutIcon className="w-6 h-6 stroke-red-600 m-auto" />}
          title={<span className="text-red-500 text-sm">{t("sidebar.logout")}</span>}
          onClick={logout}
          routeTo={"/login"}
        />
      </div>
    </div>
  );
};

export default Sidebar;
