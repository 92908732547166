export const prettifyPlatformName = (string: string) => {
  switch (string) {
    case "OculusQuest":
      return "Oculus Quest";
    case "PicoNeo":
      return "Pico";
    case "Windows10":
      return "Windows 64bit";
    case "WebGLApp":
      return "WebGL App";
    case "WebGLiframe":
      return "WebGL iframe";
    default:
      return string;
  }
};
