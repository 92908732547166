import { useSelector } from "react-redux";
import { useAppDispatch } from "src/common/state/hooks";
import { useEffect, useState } from "react";
import TemplateCategories from "src/features/projects/components/TemplateCategories";
import { GET_TEMPLATE_COMPONENT } from "../Projects/Templates";
import { getProjectTagList } from "src/features/projects/state/projectsSlice";
import { RootState } from "src/common/state/store";
import { getProjectTagListSelector } from "../../features/projects/state/projectsSlice";
import { getCurrentUploadsStateList } from "src/features/gallery/state/gallerySlice";

const TemplatesList = () => {
  useEffect(() => {
    dispatch(getProjectTagList({ componentId: GET_TEMPLATE_COMPONENT }));
  }, []);

  const dispatch = useAppDispatch();

  const { tagList, uploads } = useSelector((state: RootState) => {
    return {
      tagList: getProjectTagListSelector(state),
      uploads: getCurrentUploadsStateList(state),
    };
  });

  const [formHeight, setFormHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const navbarElement = document.getElementById("navbar");
      const uploadBarElement = document.getElementById("upload-bar-overlay");
      const navbarHeight = uploadBarElement?.clientHeight || 0;
      const uploadBarHeight = navbarElement?.clientHeight || 0;
      const windowHeight = document.documentElement.clientHeight;

      setFormHeight(windowHeight - navbarHeight - uploadBarHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [uploads]);

  return (
    <div className="w-full xl:px-0 ">
      <div
        key={formHeight}
        style={{ height: `${formHeight}px` }}
        className="flex flex-col justify-between w-full"
      >
        <div className="w-full px-6 pb-6 h-full overflow-auto personalize-scroll-visible">
          <TemplateCategories tagList={tagList} insideOfProject={false} />
        </div>
      </div>
    </div>
  );
};

export default TemplatesList;
