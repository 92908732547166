import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUploadsStateList,
  setUploadCurrentList,
  UploadState,
} from "../../../features/gallery/state/gallerySlice";
import { AppDispatch } from "../../state/store";
import ButtonText from "../Button/ButtonText";
import UploadItem from "./UploadItem";

interface UploadBarProps {
  className?: string;
}

export const UploadBar = ({ className = "" }: UploadBarProps) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUploadsStateList = useSelector(getCurrentUploadsStateList);
  const { t } = useTranslation();

  const canBeClearedStates: Array<UploadState> = ["completed", "canceled", "error"];

  const clearUploadList = () => {
    dispatch(
      setUploadCurrentList(
        currentUploadsStateList.filter(
          (e) => !canBeClearedStates.includes(e.uploadState ?? "in_progress"),
        ),
      ),
    );
  };

  return (
    <div
      className={`bg-gray-200 flex flex-row ${className} items-center h-full w-full justify-between`}
    >
      <div className="h-full overflow-x-auto">
        <div className="w-fit h-full flex h-[3rem]">
          {currentUploadsStateList.map((v) => (
            <div key={`upload_bar_${v.uploadId}-${v.percent}`} className="m-2 w-96 h-[75%]">
              <UploadItem item={v} />
            </div>
          ))}
        </div>
      </div>
      <div className="w-20 flex items-center justify-center border border-slate-400 h-full rounded-tl-md">
        <ButtonText
          type="button"
          className="h-full w-full"
          text={t("pages.gallery.uploadBar.clearButton")}
          disabled={
            currentUploadsStateList.filter((e) => {
              return e.uploadState && canBeClearedStates.includes(e.uploadState);
            }).length === 0
          }
          onClick={() => {
            clearUploadList();
          }}
        />
      </div>
    </div>
  );
};

export default UploadBar;
