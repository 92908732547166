import { useTranslation } from "react-i18next";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { ButtonSquareIconsProject } from "../../../common/components/Button";
import PlaceholderWithIcon from "../../../common/components/PlaceholderWithIcon/PlaceholderWithIcon";
import { IProject } from "../../../model/model";
export const PROJECT_LIST_COMPONENT_ID = "projectListComponent";

type ProjectListProps = {
  projects: IProject[];
  componentId?: string;
  handleClick: (project: IProject) => void;
  handleThreeDotButtonClick: (e: any) => void;
  setEditedProjectId?: (id: any) => void;
};

const ProjectList = ({
  handleThreeDotButtonClick,
  handleClick,
  projects,
  setEditedProjectId,
}: ProjectListProps) => {
  const { t } = useTranslation();

  return (
    <IsLoading componentId={PROJECT_LIST_COMPONENT_ID} showSuccess={false}>
      <>
        {projects.length > 0 ? (
          <div
            className={`w-full max-w-9xl grid grid-cols-2
        md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-10 mx-auto`}
          >
            {projects
              .filter((project: IProject) => {
                if (project) {
                  return !project?.is_template;
                } else {
                  return false;
                }
              })
              .map((project: IProject, i: number) => {
                return (
                  <IsLoading
                    key={i + project.id}
                    componentId={`project-${project.id}`}
                    spinnerPlaceholder
                  >
                    <ButtonSquareIconsProject
                      setEditedProjectId={setEditedProjectId}
                      handleThreeDotButtonClick={(e: any) => {
                        handleThreeDotButtonClick(e);
                      }}
                      currentProject={project.id}
                      project={project}
                      onClick={() => handleClick(project)}
                    />
                  </IsLoading>
                );
              })}
          </div>
        ) : (
          <div className="absolute h-72 left-[40%] pt-3">
            <PlaceholderWithIcon
              title={t("pages.project.noProjectTitle")}
              text={t("pages.project.noProjectBody")}
              heroIcon="FolderAddIcon"
            />
          </div>
        )}
      </>
    </IsLoading>
  );
};

export default ProjectList;
