import { ExclamationIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import { ISessionsByProjectWithColor } from "src/features/stats/state/statsSlice";

interface TitleWithDeletedIconProps {
  selectedProjectWithSessions: ISessionsByProjectWithColor | undefined;
  selectedCourse: any;
  isDeletedProject: boolean;
  isSelectedCourseDeleted: boolean;
}

const TitleWithDeletedIcon: React.FC<TitleWithDeletedIconProps> = ({
  selectedProjectWithSessions,
  selectedCourse,
  isDeletedProject,
  isSelectedCourseDeleted,
}: TitleWithDeletedIconProps) => {
  const title = selectedProjectWithSessions
    ? selectedProjectWithSessions.project_name
    : selectedCourse?.project_group_name || t("pages.statistics.overview");

  const isDeleted = selectedProjectWithSessions ? isDeletedProject : isSelectedCourseDeleted;

  return (
    <>
      {title}
      {isDeleted && (
        <Tooltip
          title={
            selectedProjectWithSessions
              ? String(t("pages.project.deletedProject"))
              : String(t("pages.project.deletedCourse"))
          }
        >
          <ExclamationIcon className="ml-2 w-5 h-5 text-red-500 inline-block cursor-pointer" />
        </Tooltip>
      )}
    </>
  );
};
export default TitleWithDeletedIcon;
