import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import ModalConfirmUserDeletion from "src/common/components/Modal/ModalConfirmUserDeletion";
import Navigation from "src/common/components/Navigation/Navigation";

const ThemesWrapper = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Navigation
        title={t("pages.themeEditor.title")}
        showGoBackButton={location.pathname !== "/themes/" && location.pathname !== "/themes"}
        backDepth={1}
      />

      <Outlet />
      <ModalConfirmUserDeletion />
    </>
  );
};

export default ThemesWrapper;
