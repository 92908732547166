import React, { useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/solid";

const Accordian = () => {
  const [disclosures, setDisclosures] = useState([
    {
      id: "disclosure-panel-1",
      isOpen: false,
      buttonText: "What is your refund policy?",
      panelText:
        "If you're unhappy with your purchase for any reason, email us within 90 days and we'll refund you in full, no questions asked.",
    },
    {
      id: "disclosure-panel-2",
      isOpen: false,
      buttonText: "Do you offer technical support?",
      panelText: "No.",
    },
  ]);

  const handleClick = (id: string) => {
    setDisclosures(
      disclosures.map((d) => (d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false })),
    );
  };

  return (
    <div className="w-full px-4 pt-16">
      <div className="mx-auto w-full max-w-5xl rounded-2xl bg-slate-100 p-2 space-y-2">
        {disclosures.map(({ id, isOpen, buttonText, panelText }) => (
          <React.Fragment key={id}>
            <button
              className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-3 text-left font-medium text-black hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75"
              onClick={() => handleClick(id)}
              aria-expanded={isOpen}
              {...(isOpen && { "aria-controls": id })}
            >
              {buttonText}
              <ChevronUpIcon
                className={`${isOpen ? "rotate-180 transform" : ""} h-5 w-5 text-green-500`}
              />
            </button>
            {isOpen && <div className="px-4 pt-4 pb-2 text-gray-500">{panelText}</div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Accordian;
