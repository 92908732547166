import { IOrganization, IUser } from "../../model/model";

const AUTH_USER_KEY = "wixar.profile.user";
const AUTH_ORGANIZATION_KEY = "wixar.profile.organization";
const AUTH_JWT_KEY = "wixar.auth.jwt";
const AUTH_JWT_ISREMEMBERED = "wixar.auth.isRemebered";

export const storeInLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) ? localStorage.getItem(key) : "";
};

export const storeAuthInLocalStorage = (
  user: IUser,
  organization: IOrganization,
  jwt: string,
  isRemebered: boolean,
) =>
  new Promise((resolve, reject) => {
    try {
      storeInLocalStorage(AUTH_USER_KEY, JSON.stringify(user));
      storeInLocalStorage(AUTH_ORGANIZATION_KEY, JSON.stringify(organization));
      storeInLocalStorage(AUTH_JWT_KEY, jwt);
      storeInLocalStorage(AUTH_JWT_ISREMEMBERED, isRemebered);
      resolve(0);
    } catch (error) {
      reject(error);
    }
  });

export const getAuthFromLocalStorage = (): {
  user: IUser | null;
  organization: IOrganization | null;
  jwt: string | null;
  isRemebered: boolean | null;
} => {
  const userStr = getFromLocalStorage(AUTH_USER_KEY);
  const user = userStr && userStr !== "" ? (JSON.parse(userStr) as IUser) : null;
  const orgStr = getFromLocalStorage(AUTH_ORGANIZATION_KEY);
  const organization = orgStr && orgStr !== "" ? (JSON.parse(orgStr) as IOrganization) : null;
  const jwt = getFromLocalStorage(AUTH_JWT_KEY);
  const isRemeberedStr = getFromLocalStorage(AUTH_JWT_ISREMEMBERED);
  const isRemebered =
    isRemeberedStr && isRemeberedStr !== "" ? (JSON.parse(isRemeberedStr) as boolean) : null;
  return { user, organization, jwt, isRemebered };
};
