import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllProjectGroupsWithNestedElements,
  setSearchProjectGroups,
  getCurrentProjectGroupForSearch,
  getCurrentElements,
  setSearchProject,
  getCurrentProjectForSearch,
  setUpdateProject,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
interface IProjectGroupSearchProps {
  isProjectGroup: boolean;
}

export const SearchProjectGroups = ({ isProjectGroup = false }: IProjectGroupSearchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectListElements = useSelector(getCurrentElements);
  const projectGroupNoUpdated = useSelector(getCurrentProjectGroupForSearch);
  const projectListNoUpdated = useSelector(getCurrentProjectForSearch);
  const currentProjectGroups = useSelector(getAllProjectGroupsWithNestedElements);

  useEffect(() => {
    dispatch(setUpdateProject(projectListNoUpdated));
  }, []);

  const [inputBorder, setInputBorder] = useState(false);

  const groupItems = currentProjectGroups.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });
  const filterProjects = projectListElements.map((item: any) => item.project);
  const projectItems = filterProjects.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const handleOnSearch = () => {
    if (isProjectGroup) {
      const forGroupFilterSearch = {
        name: "",
        actualGroupList: projectGroupNoUpdated,
      };
      dispatch(setSearchProjectGroups(forGroupFilterSearch));
    } else {
      const forProjectFilterSearch = {
        name: "",
        actualProjectList: projectListNoUpdated,
      };
      dispatch(setSearchProject(forProjectFilterSearch));
    }
  };
  const handleOnSelect = (item: any) => {
    if (isProjectGroup) {
      const forGroupFilterSearch = {
        name: item.name,
        actualGroupList: currentProjectGroups,
      };
      dispatch(setSearchProjectGroups(forGroupFilterSearch));
    } else {
      const forProjectFilterSearch = {
        name: item.name,
        actualProjectList: projectListElements,
      };
      dispatch(setSearchProject(forProjectFilterSearch));
    }
  };
  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  return (
    <div className="search-filter mb-2 pt-14">
      <header className="">
        <div onBlur={() => setInputBorder(false)} style={{ width: 250 }}>
          <ReactSearchAutocomplete
            items={isProjectGroup ? groupItems : projectItems}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            autoFocus
            onFocus={() => setInputBorder(true)}
            formatResult={formatResult}
            placeholder={t("general.search")}
            showIcon={false}
            styling={{
              borderRadius: "10px",
              iconColor: "#00c889",
              clearIconMargin: "10px 30px 10px 10px",
              placeholderColor: "#ccd7e0",
              border: inputBorder ? "1px solid #d4d5d8" : "0px",
              height: "46px",
              boxShadow: "0px",
            }}
            showNoResultsText={t("general.noResults")}
          />
        </div>
        <BiSearchAlt2
          color={"#9ca3af"}
          className={`${isProjectGroup ? "" : "mt-2"} absolute top-17 left-500 w-6 h-6`}
        />
      </header>
    </div>
  );
};
