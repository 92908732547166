import { useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../state/store";
import {
  closeSlider,
  getCurrentAnimationState,
  openOnboardingModal,
  setAnimationClassState,
} from "../../state/slice/modal/modalSlice";
type SlideOverProps = {
  large: boolean;
  children: any;
  id: string;
  shouldPopHelpIntoUserFace?: boolean;
  relevantArticle?: string;
  setShouldOpenTour?: (value: boolean) => void;
};

export const sliderAnimationDurationInMs = 400; // in milliseconds; 0.4s = 400ms is the duration of the animation
export const classSlideOpenAnimation = "translate-x-0 ease-in duration-400";
export const classSlideCloseAnimation = "translate-x-full ease-in duration-400";

const SlideOver = ({
  id,
  large,
  children,
  shouldPopHelpIntoUserFace,
  relevantArticle,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShouldOpenTour = () => {},
}: SlideOverProps) => {
  const sliderState = useSelector((state: RootState) => state.modals.showSlider);
  const dispatch = useDispatch();
  const slideFadeState = useSelector(getCurrentAnimationState);

  const handleCloseSlider = (event: any) => {
    const fuctionCloseSlide = () => {
      dispatch(closeSlider({ id }));
      if (shouldPopHelpIntoUserFace) {
        setShouldOpenTour(false);
        dispatch(openOnboardingModal(relevantArticle));
      }
    };
    switch (
      event.detail //this is the event.detail is for the click or doubleclick event
    ) {
      default: {
        break;
      }
      case 1: {
        setTimeout(() => {
          fuctionCloseSlide();
        }, sliderAnimationDurationInMs);
        break;
      }
      case 2: {
        fuctionCloseSlide();
        break;
      }
    }
  };

  useEffect(() => {
    if (sliderState.show) {
      setTimeout(() => {
        dispatch(setAnimationClassState(classSlideOpenAnimation));
      }, 1);
    } else {
      dispatch(setAnimationClassState(classSlideCloseAnimation));
    }
  }, [sliderState.show, dispatch]);

  return (
    <>
      {id === sliderState.id && sliderState.show && (
        <Transition.Root show={sliderState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            static
            open={sliderState.show}
            onClose={handleCloseSlider}
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleCloseSlider}
            />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div
                  className={`pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ${slideFadeState}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dialog.Panel
                    className={`pointer-events-auto w-screen ${large ? "max-w-3xl" : "max-w-md"}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloseSlider(e);
                        }}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                      {children}
                    </div>
                  </Dialog.Panel>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default SlideOver;
