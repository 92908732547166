import ImageCard from "./ImageCard";
import { useEffect, useState } from "react";
import { IMedia } from "../../../model/unityObject";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { MEDIA_LIST_COMPONENT_ID } from "../Gallery";
import { currentFilterSelector, setCurrentMediaGallery } from "../state/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../common/state/store";
import { menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";

import { languageConfig } from "../../../common/util/langConfig";
import { getCurrentUser } from "../../profile/state/profileSlice";
import { ILanguage } from "../../../model/model";

import { openConfirmationModal } from "../../../common/state/slice/modal/modalSlice";

import ModeListProjectGroup from "../../../pages/ProjectGroups/ModeListProjectAndProjectsGroup";
import { NoMediaPlaceholder } from "./NoMediaPlaceholder";
import ModalConfirmationToDeleteMedia from "src/common/components/Modal/ModalConfirmationToDeleteMedia";

export const MEDIA_CHECK_BEFORE_DELETE_ID = "rCheckBeforeDelete";
export const MEDIA_MULTIPLE_MEDIA_DELETE_COMPONENT_ID = "deleteMultipleMedias";

interface IMediaList {
  medias: Array<IMedia>;
  openMediaDetail: (mediaId: number, item: any, index: number) => void;
  currentMedia?: number;
  isMediaPickerMode: boolean;
  customMenuItems?: menuItems;
  className?: string;
  isModalEdit?: boolean;
  listMode?: boolean;
  setListMode?(bool: boolean): () => void;
}

export const MEDIA_COMPONENT_ID = "mediaComponentId";

export const MediaList = ({
  medias,
  openMediaDetail,
  currentMedia,
  isMediaPickerMode,
  customMenuItems,
  isModalEdit = true,
  listMode = false,
}: IMediaList) => {
  const dispatch: AppDispatch = useDispatch();
  const [idMedia, setIdMedia] = useState(-1);
  const [mediasToDelete, setMediasToDelete] = useState([]);
  const [mediasIdToDeleteThatIsChecked, setMediasIdToDeleteThatIsChecked] = useState([]);
  const currentFilter = useSelector(currentFilterSelector);
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);

  const [isCheck, setIsCheck] = useState<Array<any>>([]);
  const [isCheckMedias, setIsCheckMedias] = useState<Array<any>>([]);
  const handleClick = (e: any) => {
    const mediaValue = JSON.parse(e.target.value);

    const { id, checked } = e.target;

    setIsCheck([...isCheck, String(id)]);
    setIsCheckMedias([...isCheckMedias, mediaValue]);

    if (!checked) {
      setIsCheckMedias(isCheckMedias.filter((item) => Number(item.id) !== Number(mediaValue.id)));
      setIsCheck(isCheck.filter((item) => String(item) !== String(id)));
    }
  };

  useEffect(() => {
    setIsCheck([]);
  }, [currentFilter]);

  useEffect(() => {
    setMediasToDelete(isCheckMedias);
  }, [isCheckMedias]);

  useEffect(() => {
    setMediasIdToDeleteThatIsChecked(isCheck);
  }, [isCheck]);

  const openModalDeleteMultipleMedias = () => {
    setMediasToDelete(isCheckMedias);
    setMediasIdToDeleteThatIsChecked(isCheck);
    dispatch(openConfirmationModal("deleteMultipleMedia"));
  };

  const onDeleteMedia = (media: any) => {
    if (media?.id) {
      setIdMedia(media.id);
      setMediasToDelete([media]);
      setMediasIdToDeleteThatIsChecked([media.id]);
    }
    dispatch(openConfirmationModal("deleteMultipleMedia"));
  };

  return (
    <div
      className={`mb-1 ${
        listMode ? "overflow-y-hidden" : "overflow-y-auto"
      } personalize-scroll-visible h-full`}
    >
      <div className="overflow-y-auto personalize-scroll-visible h-full">
        {listMode ? (
          <IsLoading componentId={MEDIA_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={MEDIA_CHECK_BEFORE_DELETE_ID} showSuccess={false}>
              <div className="h-full p-4">
                <ModeListProjectGroup
                  onDeleteMedia={onDeleteMedia}
                  isModalEdit={isModalEdit}
                  openMediaDetail={openMediaDetail}
                  isMediaList
                  openModalDeleteMultipleMedias={openModalDeleteMultipleMedias}
                  isCheckMedias={isCheckMedias}
                  currentLocale={currentLocale}
                  setIsCheckMedias={setIsCheckMedias}
                  setIsCheck={setIsCheck}
                  isCheck={isCheck}
                  arrayItemsForList={medias}
                  setClickedProjectGroupId={setIdMedia}
                  handleClick={handleClick}
                  clickedProjectGroupId={idMedia}
                />
              </div>
            </IsLoading>
          </IsLoading>
        ) : (
          <>
            <IsLoading componentId={MEDIA_LIST_COMPONENT_ID} showSuccess={false}>
              <div
                className={`p-6 grid grid-cols-1  ${
                  medias.length > 0
                    ? "lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 md:gap-10"
                    : "grid-cols-none"
                } `}
              >
                {medias.length === 0 && <NoMediaPlaceholder />}
                {medias.map((media, key) => (
                  <ImageCard
                    handleCardClick={() => dispatch(setCurrentMediaGallery(media.id))}
                    key={key}
                    media={media}
                    handleButtonClick={openMediaDetail}
                    currentMedia={currentMedia}
                    isMediaPickerMode={isMediaPickerMode}
                    customMenuItems={customMenuItems}
                    showThreeDotsMenu={false}
                    showEditButton={Number(media?.organization.id) !== 3}
                    id={
                      Number(media.id) === Number(currentMedia)
                        ? "gallery-selected-media-target"
                        : ""
                    }
                  />
                ))}
              </div>
            </IsLoading>
          </>
        )}
      </div>
      <ModalConfirmationToDeleteMedia
        isMediaList
        id={"deleteMultipleMedia"}
        mediasIdToDeleteThatIsChecked={mediasIdToDeleteThatIsChecked}
        mediasToDelete={mediasToDelete}
        idMedia={idMedia}
      />
    </div>
  );
};
