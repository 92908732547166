import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateConfig } from "src/common/util/DateConfig";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { useAppDispatch } from "../../common/state/hooks";
import { languageConfig } from "../../common/util/langConfig";
import { prettifyFileSizeInBytes } from "../../common/util/prettifyFileSizeInBytes";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import {
  getCurrentInteractionElements,
  getCurrentInteractions,
  getCurrentProject,
  getProjectSize,
} from "../../features/projects/state/projectsSlice";
import { ILanguage } from "../../model/model";
import InformationComponent from "../ProjectGroups/InformationComponent";
import InteractionsList from "./InteractionsList";

export const PROJECT_ELEMENTS_COMPONENT_ID = "projectElements";
export const PROJECT_SIZE_COMPONENT_ID = "projectSize";

const ProjectInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const currentProject = useSelector(getCurrentProject);
  const getElementsInteractions = useSelector(getCurrentInteractionElements);

  useEffect(() => {
    if (currentProject?.id) {
      dispatch(
        getCurrentInteractions({
          projectId: currentProject.id,
          componentId: PROJECT_ELEMENTS_COMPONENT_ID,
        }),
      );
      dispatch(
        getProjectSize({
          projectId: currentProject.id,
          componentId: PROJECT_SIZE_COMPONENT_ID,
        }),
      );
    }
  }, [dispatch, currentProject?.id]);

  const currentProjectCreator = currentProject;
  const createdByTranslate = currentProjectCreator?.created_by
    ? currentProjectCreator.created_by
    : t("general.noCreator");

  const getDateCurrentLocale = (date) => {
    return new Date(date).toLocaleDateString(dateConfig(currentLocale));
  };

  return (
    <IsLoading componentId={PROJECT_SIZE_COMPONENT_ID} showSuccess={false}>
      <div className="relative grow w-full px-4 xl:px-0 mx-auto overflow-x-auto personalize-scroll-visible">
        <div className="ml-4">
          <h2 className="text-2xl font-medium mb-2 mt-4">Description</h2>
          <h3 className="py-3">{t("general.informationModule")}</h3>
          <InformationComponent title={t("general.moduleName")} moduleName={currentProject?.name} />
          <InformationComponent
            title={t("general.weight")}
            moduleName={String(
              prettifyFileSizeInBytes(getElementsInteractions?.sizeInMbs || 0, currentLocale),
            )}
            infoTooltipText={t("tooltip.mediasWeightDetail")}
          />
          {currentProjectCreator?.creator && (
            <InformationComponent title={"Creator"} moduleName={String(createdByTranslate)} />
          )}
          <InformationComponent
            title={t("general.createdAt")}
            moduleName={getDateCurrentLocale(currentProject?.createdAt)}
          />
          <InformationComponent
            title={t("general.updatedAt")}
            moduleName={getDateCurrentLocale(currentProject?.updatedAt)}
          />
          {currentProject?.cloned_from?.name && (
            <InformationComponent
              title={t("general.duplicatedFrom")}
              moduleName={String(currentProject.cloned_from.name)}
            />
          )}
          <div className="relative grow w-full px-4 xl:px-0 mx-auto">
            <h2 className="text-2xl font-medium mb-2 mt-4 xl:mt-8">Elements</h2>
            <h3 className="py-3">{t("general.interactionsElements")}</h3>
            <InteractionsList interactions={getElementsInteractions?.interactions} />
          </div>
        </div>
      </div>
    </IsLoading>
  );
};

export default ProjectInfo;
