import React from "react";
import "./TypingIndicator.scss";

const TypingIndicator: React.FC = () => {
  return (
    <div className="flex items-center space-x-2 mt-4">
      <div className="dot bg-gray-400 w-2 h-2 rounded-full animate-bounce" />
      <div className="dot bg-gray-400 w-2 h-2 rounded-full animate-bounce delay-200" />
      <div className="dot bg-gray-400 w-2 h-2 rounded-full animate-bounce delay-400" />
    </div>
  );
};

export default TypingIndicator;
