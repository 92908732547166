import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import { useAppDispatch } from "../../state/hooks";
import { MobileNavbarItem } from "./MobileFootertem";

export default function MobileNavbar({ className = "" }: { className?: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = location.pathname.split("/");

  return (
    <div className={className}>
      <MobileNavbarItem
        icon="HomeIcon"
        title={t("sidebar.dashboard")}
        routeTo={"/"}
        isSelected={path[1] === ""}
      />
      <MobileNavbarItem
        icon="PhotographIcon"
        title={t("sidebar.media")}
        routeTo={""}
        isSelected={false}
        fillIcon={"#6b7280"}
        textColor="text-gray-500"
        disabled
      />
      <MobileNavbarItem
        icon={"UploadIcon"}
        title={t("sidebar.uploads")}
        routeTo={"/uploads"}
        isSelected={path[1] === "uploads"}
      />

      <MobileNavbarItem
        icon="LogoutIcon"
        title={t("sidebar.logout")}
        routeTo={""}
        isSelected={false}
        fillIcon={"#0C1E59"}
        stroke={"#DC3545"}
        textColor="text-red-500"
        onClick={() => {
          dispatch(logoutAuth());
        }}
      />
    </div>
  );
}
