import { InputProps } from "./Input";

const Checkbox = ({ label, registerFct, ...props }: InputProps) => {
  return (
    <div className="flex items-center py-2">
      <input
        {...props}
        type="checkbox"
        className="cursor-pointer"
        {...(registerFct && registerFct())}
      ></input>
      <label className="mx-2 block text-sm text-gray-700">{label}</label>
    </div>
  );
};

export default Checkbox;
