/* eslint-disable indent */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Field } from "../util/graphFieldsExtractor";
import { Controller, useForm } from "react-hook-form";
import { IMedia } from "../../../model/unityObject";

import { mediaSelector, setMediaFilter } from "../../../features/gallery/state/gallerySlice";

import { IsLoading } from "../../../common/components/AppState/IsLoading";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import { DynamicField, DynamicMedia } from "./DynamicField";
import ModalGallery from "./ModalGallery";
import RangeSelector from "../../../common/components/RangeSelector/RangeSelector";
import { getCurrentProject, setBackgroundMusic } from "../../projects/state/projectsSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useEventListener from "@use-it/event-listener";
import InfoButton from "../../../common/components/InfoButton/InfoButton";
import { menuItem, menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import canSaveProject from "../util/canSaveProject";
import { FooterButtons } from "../../../common/components/FooterButtons";
import ModalConfirmBeforeNavigatingOut from "../../../common/components/Modal/ModalConfirmBeforeNavigatingOut";
import { useCallbackPrompt } from "../../../common/util/useCallbackPrompt";
import CustomXIcon from "src/common/components/CustomXIcon/CustomXIcon";

const BACKGROUND_AUDIO_COMPONENT_ID = "backgroundAudioComponentId";

export const BackgroundMusic = ({ mode }: { mode: "edit" | "view" }) => {
  const { register, setValue, handleSubmit, control } = useForm<any>();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const mediasList = useSelector(mediaSelector);

  const currentProject = useSelector(getCurrentProject);
  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);

  const [currentBackgroundMusic, setCurrentBackgroundMusic] = useState<number | null>(
    Number(
      currentProject.id_background_music
        ? Object.prototype.hasOwnProperty.call(currentProject.id_background_music, "id")
          ? currentProject.id_background_music.id
          : currentProject.id_background_music
        : null,
    ),
  );

  useEffect(() => {
    setCurrentBackgroundMusic(
      Number(
        currentProject.id_background_music
          ? Object.prototype.hasOwnProperty.call(currentProject.id_background_music, "id")
            ? currentProject.id_background_music.id
            : currentProject.id_background_music
          : null,
      ),
    );
  }, [currentProject]);

  const [volume, setVolume] = useState<number>(
    Number(currentProject.background_music_volume ? currentProject.background_music_volume : 1),
  );
  const [fieldToDisplay, setFieldToDisplay] = useState({
    type: "media",
    mediaType: "audio",
    fieldName: "source_audio",
    currentValue: currentBackgroundMusic,
    currentMedia: mediasList.find((m: IMedia) => Number(m.id) === Number(currentBackgroundMusic)),
  });

  const [currentMediaField, setCurrentMediaField] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [enabled, setEnabled] = useState(Boolean(currentBackgroundMusic));
  const [stopPlayingMedia, setStopPlayingMedia] = useState(false);

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));

    setLoading(false);
  }, []);

  const enable = () => {
    if (enabled) {
      setEnabled(false);
      setCurrentBackgroundMusic(null);
      setVolume(1);
    } else {
      setEnabled(true);
      setCurrentBackgroundMusic(16);
      setVolume(1);
      handleOpenGallery();
    }
  };

  const onSubmit = () => {
    // Useless since we don't use react-hook-forms
  };

  const rangeAudioElement = document.getElementById("rangeSelector") as HTMLInputElement;

  const audioGetElement = document.getElementById("myAudio") as HTMLAudioElement;
  const onChangeMyAudio = () => {
    if (canSave.canSave) {
      setVolume(Number(audioGetElement.volume));
    }
  };
  const onChangeRangeSelector = () => {
    if (audioGetElement?.volume) {
      audioGetElement.volume = Number(volume);
    }
  };
  useEventListener("change", onChangeRangeSelector, rangeAudioElement);

  useEventListener("volumechange", onChangeMyAudio, audioGetElement);

  const getEnhancedBgMusicField = () => {
    const enhancedField = {
      type: "media",
      mediaType: "audio",
      fieldName: "source_audio",
      currentValue: currentBackgroundMusic,
      relevantSourceMedia: mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentBackgroundMusic),
      ),
    };
    if (!currentBackgroundMusic) {
      enhancedField.currentValue = -1;
      enhancedField.relevantSourceMedia = mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentBackgroundMusic),
      );
    }
    return enhancedField;
  };

  const onUpdateBackgroundMusic = () => {
    setIsSaved(true);

    const payload = {
      enabled,
      id_background_music: currentBackgroundMusic,
      background_music_volume: volume,
      componentId: BACKGROUND_AUDIO_COMPONENT_ID,
      projectId: currentProject.id,
    } as any;

    if (!enabled) {
      payload.id_background_music = null;
      payload.background_music_volume = null;
    }
    setLoading(true);
    dispatch(setBackgroundMusic(payload)).then(() => navigate("../graph"));
  };

  const onMediaChange = (selectedMedia: IMedia) => {
    setValue(currentMediaField, selectedMedia.id);
    const updatedFields: any = { ...fieldToDisplay, currentValue: selectedMedia.id };
    setFieldToDisplay(updatedFields);

    dispatch(closeStandardModal("modal-gallery"));
    setCurrentBackgroundMusic(Number(selectedMedia.id));
  };

  const canSave = canSaveProject(currentProject);

  const enhancedField = getEnhancedBgMusicField();

  const handleOpenGallery = (menuItem: menuItem | null = null) => {
    const mediaType: any = enhancedField.type === "media" && enhancedField.mediaType;
    dispatch(setMediaFilter({ ...currentFilter, mediaType }));
    setCurrentMediaField(enhancedField.fieldName);
    setEnabled(true);
    if (menuItem?.itemId === "enableBackgroundAudio") {
      enable();
    } else {
      dispatch(openStandardModal("modal-gallery"));
    }
  };
  const displayButtonForMedia = enhancedField.currentValue !== -1;

  const model3dMenuItems: menuItems = [
    {
      menuItemText: t("general.delete"),
      icon: "DocumentRemoveIcon",
      itemId: "enableBackgroundAudio",
    },
  ];

  const hasSomethingChanged = () => {
    if (isSaved) return false;

    if (
      currentBackgroundMusic !==
      Number(currentProject.id_background_music?.id ? currentProject.id_background_music?.id : null)
    ) {
      return true;
    }

    if (
      volume !==
      Number(currentProject.background_music_volume ? currentProject.background_music_volume : 1)
    ) {
      return true;
    }

    return false;
  };

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    hasSomethingChanged(),
  );

  return (
    <div className="h-full flex flex-col justify-between">
      {mode === "edit" && (
        <ModalGallery nodeId={`bgmusic-${currentProject.id}`} onMediaChange={onMediaChange} />
      )}
      <InfoButton relevantArticle="aboutSoundtrack" />
      <div className="w-full h-full mt-16 mx-auto max-w-5xl px-6 xl:px-0">
        <div className="text-center">
          <span className="mb-3 text-2xl">{t("pages.project.backgroundMusic")}</span>
        </div>

        {(mode === "edit" || (mode === "view" && displayButtonForMedia)) && (
          <div className="w-4/5 mx-24 mt-8">
            <h3 className="text-center">{t("pages.project.backgroundMusicExplaination")}</h3>
          </div>
        )}

        {loading && <IsLoading componentId={BACKGROUND_AUDIO_COMPONENT_ID} showSuccess />}

        <form className="relative max-h-max rounded-lg w-full" onSubmit={handleSubmit(onSubmit)}>
          {displayButtonForMedia ? (
            <div className="items-center justify-center ml-background-music mt-4 pt-6 w-full w-80">
              <Controller
                control={control}
                name={enhancedField.fieldName}
                render={({ field }) => (
                  <DynamicMedia
                    field={field}
                    fieldType={enhancedField.type}
                    register={register}
                    stopPlayingMedia={stopPlayingMedia}
                    handleButtonClick={(_mediaId, item) => {
                      if (mode === "edit") {
                        handleOpenGallery(item);
                      } else {
                        setStopPlayingMedia(!stopPlayingMedia);
                      }
                    }}
                    currentMedia={enhancedField.currentValue as number}
                    media={enhancedField.relevantSourceMedia as IMedia}
                    customMenuItems={mode === "edit" ? model3dMenuItems : []}
                    disabled={mode !== "edit" || !canSave.canSave}
                    showThreeDotsMenu={mode === "edit"}
                    CustomRemoveButton={
                      mode === "edit"
                        ? {
                            component: CustomXIcon,
                            customProps: {
                              className:
                                "stroke-black w-6 h-6 absolute right-3 top-4 hover:stroke-gray-600",
                              onClick: (e: Event) => {
                                e.stopPropagation();
                                enable();
                              },
                              tooltipText: t("pages.project.removeBackgroundMusic"),
                            },
                          }
                        : undefined
                    }
                  />
                )}
              />
            </div>
          ) : (
            mode === "edit" && (
              <button
                className="btn-alternative-outline mt-8 ml-background-music button-enviroment3d text-green-500"
                onClick={() => handleOpenGallery()}
                disabled={mode !== "edit" || !canSave.canSave}
              >
                {<PlusIcon className="h-6 w-6 ml-36 mb-5" />}
                {t("general.addBackgroundAudio")}
              </button>
            )
          )}

          {!displayButtonForMedia && mode === "view" && (
            <p className="flex w-full justify-center items-center pt-6">
              {currentProject.is_template ? (
                <span>{t("pages.templates.noBackgroundMusic")}</span>
              ) : (
                <span>{t("pages.project.noBackgroundMusic")}</span>
              )}
            </p>
          )}

          <div className="w-full p-6">
            <div className="mb-10 max-w-md">
              <DynamicField
                disabled={mode !== "edit" || !canSave.canSave}
                field={fieldToDisplay as Field}
                register={register}
              />
            </div>
            {enabled && enhancedField.relevantSourceMedia !== undefined && (
              <div className="h-24">
                <RangeSelector
                  label="Volume"
                  value={volume}
                  setValue={setVolume}
                  disabled={mode !== "edit" || !canSave.canSave}
                />
              </div>
            )}
          </div>
        </form>
      </div>
      {mode === "edit" && (
        <>
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate("../graph")}
            nextText={t("general.save")}
            nextCb={onUpdateBackgroundMusic}
            nextDisabledTooltipText={t(canSave.reason)}
            nextDisabled={
              !canSave.canSave ||
              !(
                currentProject.background_music_volume !== volume ||
                Number(currentProject.id_background_music?.id) !== Number(currentBackgroundMusic) ||
                (!currentProject.id_background_music && enabled) ||
                (currentProject.id_background_music && !enabled)
              )
            }
          />
          <ModalConfirmBeforeNavigatingOut
            showDialog={showPrompt}
            cancelNavigation={cancelNavigation}
            confirmNavigation={confirmNavigation}
          />
        </>
      )}
    </div>
  );
};
