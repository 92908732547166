import { UseFormRegisterReturn } from "react-hook-form";

interface CommonInputProps {
  registerFct?: () => UseFormRegisterReturn;
  highlightEnabled?: string;
  className?: string;
}

const CommonInput = ({ className, highlightEnabled, registerFct, ...props }: CommonInputProps) => {
  return (
    <input
      className={`${className} ${highlightEnabled} `}
      {...props}
      {...(registerFct && registerFct())}
    />
  );
};

export default CommonInput;
