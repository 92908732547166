/* eslint-disable indent */
import React from "react";
import SVGNodeScene from "./SVGNodeScene";
import SVGNodeAsset from "./SVGNodeAsset";
import { Node } from "../../util/nodeTypes";
import { validateNode } from "../../util/graphValidator";
const LINEWIDTH = 4;

type Props = {
  from: Node;
  to: Node;
  linksToScene?: boolean;
  index?: number;
  isNotThatSimple?: boolean;
};

const Line: React.FC<Props> = ({ from, to, linksToScene, isNotThatSimple, index }) => {
  const { x: x1, y: y1 } = getCenterPosition(from);
  const { x: x2, y: y2 } = getCenterPosition(to);
  const valid = validateNode(from) && validateNode(to);

  //const stroke = valid ? "#02FCA5" : "#1976D2";
  const computeColor = () => {
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG) {
      let whichCase = 0;
      if (valid) whichCase = 1;
      if (valid && linksToScene) whichCase = 2;
      if (valid && linksToScene && isNotThatSimple) whichCase = 3;
      switch (whichCase) {
        case 1:
          return "#02FCA5";
        case 2:
          return "#21FC02";
        case 3:
          return "#FC0259";
        default:
          return "#1976D2";
      }
    } else return valid ? "#02FCA5" : "#1976D2";
  };

  const color = computeColor();
  return linksToScene ? (
    <g viewBox="0 0 100 100" style={{ width: "100%", height: "100%" }}>
      <line
        x1={x1}
        x2={x2}
        y1={y1 + LINEWIDTH / 2}
        y2={y2 + LINEWIDTH / 2}
        stroke={color}
        strokeWidth={LINEWIDTH}
      />
      {process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG ? (
        <text x={(x1 + x2) / 2} y={(y1 + LINEWIDTH / 2 + (y2 + LINEWIDTH / 2)) / 2} fill="red">
          {index}
        </text>
      ) : null}
    </g>
  ) : (
    <g>
      <line
        x1={x1}
        x2={x2}
        y1={y1 + LINEWIDTH / 2}
        y2={y2 + LINEWIDTH / 2}
        stroke={color}
        strokeWidth={LINEWIDTH}
      />
    </g>
  );
};

const getCenterPosition = (node: Node) => {
  switch (node.type) {
    case "scene":
      return {
        x: node.x + SVGNodeScene.HEIGHT / 2,
        y: node.y + SVGNodeScene.WIDTH / 2,
      };
    case "asset":
      return {
        x: node.x + SVGNodeAsset.HEIGHT / 2,
        y: node.y + SVGNodeAsset.WIDTH / 2,
      };
  }
};

export default Line;
