import {
  CheckCircleIcon,
  DesktopComputerIcon,
  ExternalLinkIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import Accordian from "../../common/components/Accordian/Accordian";
import Navigation from "../../common/components/Navigation/Navigation";

const Help = () => {
  return (
    <>
      <Navigation title="Aide" />
      <div className="w-full max-w-7xl mx-auto px-6 xl:px-0">
        <h2 className="text-2xl font-medium my-6 mt-4 xl:mt-8">Bonjour</h2>
        <div className="flex columns-3 gap-10 xl:gap-28">
          <button className="inline-flex justify-start w-full">
            <div className="mr-3">
              <CheckCircleIcon className="w-8 h-8 stroke-green-500" />
            </div>

            <div className="block text-left">
              <div className="flex justify-between">
                <h2 className="mb-3 font-medium">Se lancer</h2>
                <ExternalLinkIcon className="w-5 h-5 stroke-gray-500" />
              </div>

              <p className="text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, neque totam
                perspiciatis at
              </p>
            </div>
          </button>
          <button className="inline-flex justify-start w-full">
            <div className="mr-3">
              <LightningBoltIcon className="w-8 h-8 stroke-green-500" />
            </div>

            <div className="block text-left">
              <div className="flex justify-between">
                <h2 className="mb-3 font-medium">Utiliser Casque</h2>
                <ExternalLinkIcon className="w-5 h-5 stroke-gray-500" />
              </div>
              <p className="text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, neque totam
                perspiciatis at{" "}
              </p>
            </div>
          </button>
          <button className="inline-flex justify-start w-full">
            <div className="mr-3">
              <DesktopComputerIcon className="w-8 h-8 stroke-green-500" />
            </div>

            <div className="block text-left">
              <div className="flex justify-between">
                <h2 className="mb-3 font-medium">Logiciel Wixar</h2>
                <ExternalLinkIcon className="w-5 h-5 stroke-gray-500" />
              </div>
              <p className="text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, neque totam
                perspiciatis at{" "}
              </p>
            </div>
          </button>
        </div>
        <Accordian />
      </div>
    </>
  );
};

export default Help;
