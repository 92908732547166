import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IApiDownloadClientResponse {
  version: string;
  windowsUri: string;
  imacUri: string;
}

export const getWixarClientUris = createAsyncThunk(
  "downloadClient/getWixarLauncherUris",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    return await axios
      .get(apiUrls.getWixarLauncherUris)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
);
const initialState: IApiDownloadClientResponse = {
  version: "",
  windowsUri: "",
  imacUri: "",
};
export const downloadClientSlice = createSlice({
  name: "downloadClient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getWixarClientUris.fulfilled,
      (state: IApiDownloadClientResponse, { payload }) => {
        state.version = payload.version;
        state.windowsUri = payload.windowsUri;
        state.imacUri = payload.imacUri;
      },
    );
  },
});

export const downloadClientReducer = downloadClientSlice.reducer;

export const getLatestWixarClientWindowsUri = (state: RootState) =>
  state.wixarClientLatestUris.windowsUri;
export const getLatestWixarClientImacUri = (state: RootState) =>
  state.wixarClientLatestUris.imacUri;
export const getLatestWixarClientVersion = (state: RootState) =>
  state.wixarClientLatestUris.version;
