import { ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import parseSimplifiedUserIdentifier from "../../common/util/parseSimplifiedUserIdentifier";
import {
  getCurrentProjectGroupElement,
  getSelectedProjectId,
  getSelectedProjectWithSessions,
} from "../../features/stats/state/statsSlice";
import { ILanguage, IProject, ISession, IUser } from "../../model/model";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { useNavigate } from "react-router-dom";
import {
  fetchAllCurrentElements,
  getProjectGroupPermissionsFromServer,
  setCurrentProjectGroupPermissions,
  setSelectedProjectGroup,
  setSelectedProjectGroupFromId,
} from "src/features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import {
  getProjectPermissionsFromServer,
  setCurrentProject,
  setCurrentProjectPermissions,
} from "src/features/projects/state/projectsSlice";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "src/features/projectGroups/components/ProjectGroupList";
import Spinner from "src/common/components/Loader/Spinner";
import ParticipantIcon2 from "src/common/components/Icon/ParticipantIcon2";
import { dateConfig } from "src/common/util/DateConfig";
import { languageConfig } from "src/common/util/langConfig";
import TableComp from "src/common/components/Table/Table";

const StatisticsParticipants = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedProjectId = useSelector(getSelectedProjectId);
  const projectWithSessions = useSelector(getSelectedProjectWithSessions(selectedProjectId));
  const sessions = projectWithSessions.sessions;
  const currentProjectElement = useSelector(getCurrentProjectGroupElement);
  const [uniqueParticipants, setUniqueParticipants] = useState<any[]>([{ initial: "D", id: -1 }]);
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const projectGroupId = currentProjectElement.project_group.id
    ? currentProjectElement.project_group.id
    : 0;

  const isCreator = (currentUser as IUser).role.type !== "creator";

  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);

  /* Reminder: here we want the 'participants', not the "users that have a project_user_permission".
  To do that, we have to parse the sessions & isolate individual users:
    - sort sessions by ASCENDING
    - for each session, allocate [user.id]:{...session} onto object, so latest session always overrides the previous one for a given user
    - map this object into table rows
  */
  useEffect(() => {
    const sorted = [...sessions].sort(
      (a: ISession, b: ISession) => a.created_at_ts - b.created_at_ts,
    );
    const Map = {} as any;
    sorted.forEach((s: ISession) => {
      Map[s.enduser?.id] = s;
    });

    setUniqueParticipants(Object.values(Map));
  }, [selectedProjectId]);

  const manageParticipants = ({
    project,
    projectGroup,
  }: {
    project: IProject;
    projectGroup: any;
  }) => {
    dispatch(setSelectedProjectGroupFromId(Number(projectGroup.id)));
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: Number(projectGroup.id),
        componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
      }),
    );
    dispatch(setCurrentProject(project));
    dispatch(setSelectedProjectGroup(projectGroup));
    dispatch(getProjectPermissionsFromServer({ projectId: Number(project.id) })).then((res) => {
      dispatch(setCurrentProjectPermissions((res.payload as any).data));
    });
    dispatch(
      getProjectGroupPermissionsFromServer({ projectGroupId: Number(projectGroup.id) }),
    ).then((res) => {
      dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
    });
    navigate(`/courses/${projectGroupId}/list/${selectedProjectId}/learners`);
  };

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (2 / 3));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const colHeaders = [
    t("general.firstname"),
    t("general.lastname"),
    projectWithSessions.is_simplified_login ? t("pages.statistics.identifier") : t("general.email"),
    t("pages.statistics.dateOfLatestSession"),
    "",
  ];

  const rows = uniqueParticipants
    .filter((up): ISession => up.enduser)
    .map((up: ISession) => {
      const participant = up.enduser;

      return {
        noCsv: "",
        firstName: participant.firstname,
        lastName: participant.lastname,
        email: projectWithSessions.is_simplified_login
          ? parseSimplifiedUserIdentifier(participant.email)
          : participant.email,
        // new Date expect UNIX epoch to be in milliseconds, so x1000
        dateOfLatestSession: new Date(up.created_at_ts * 1000).toLocaleString(
          dateConfig(currentLocale),
        ),
        view: (
          <button>
            <Link to={`${participant.id}`}>
              <ChevronRightIcon className="w-6 h-6  stroke-black" />
            </Link>
          </button>
        ),
      };
    });

  return (
    <div className="container h-full w-full max-w-7xl mx-auto px-4 personalize-scroll-visible overflow-auto">
      <div className="inline-block mt-8">
        <h2 className="text-2xl font-medium mb-2">{t("general.participants")}</h2>
        {currentProjectElement.project_group.id !== 0 ? (
          <button
            className="flex text-sm rounded-2xl"
            onClick={() =>
              manageParticipants({
                project: currentProjectElement.project as IProject,
                projectGroup: currentProjectElement.project_group,
              })
            }
            disabled={isCreator}
          >
            <ParticipantIcon2
              fill={""}
              className="w-9 h-9 mb-1 cursor-pointer scale-x-[-1] fill-green-500"
            />
            <span className="pt-2 text-green-500 ">{t("tooltip.manageParticipants")}</span>
          </button>
        ) : (
          <Spinner />
        )}
      </div>
      <div
        className="relative my-5 mx-auto overflow-x-auto border border-gray-300 shadow-md sm:rounded-lg personalize-scroll-visible"
        key={`${tableHeight}`}
        style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
      >
        <TableComp
          isUserList={true}
          selectFromListInTable={true}
          isStats={true}
          rows={rows}
          colHeaders={colHeaders}
          className="h-full w-full overflow-x-hidden the-table-comp"
          listTofilter={uniqueParticipants}
          setListOfItemsFilter={setUniqueParticipants}
        />
      </div>
    </div>
  );
};

export default StatisticsParticipants;
