import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../../common/components/Input/Input";
import { useForm } from "react-hook-form";
import { INewProjectFormValues } from "../../features/projects/state/projectsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  getEditedProjectGroup,
  getNewProjectGroup,
  setEditedName,
  setNewProjectGroup,
  setCurrentProjectGroupName,
  getSelectedProjectGroup,
  updateProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import isStringLegalProjectName from "../../common/util/isStringLegalProjectName";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "src/features/projectGroups/components/ProjectGroupList";

interface ICreateProjectGroupName {
  incrementStep?: () => void;
  editMode?: boolean;
}

const CreateProjectGroupName = ({ incrementStep, editMode = false }: ICreateProjectGroupName) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [inputValueNewProjectGroup, setinputValueNewProjectGroup] = useState<string>("");
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<INewProjectFormValues>();

  const { newProjectGroup, editedProjectGroup, selectedProjectGroup } = useSelector(
    (state: RootState) => {
      return {
        newProjectGroup: getNewProjectGroup(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProjectGroup: getEditedProjectGroup(state),
      };
    },
  );

  const onSubmit = (data: INewProjectFormValues) => {
    if (isStringLegalProjectName(inputValueNewProjectGroup)) {
      if (editMode) {
        dispatch(setEditedName(data.newName));
        // fix Gros bug du 11 septembre 2024 (communément appelé "Le 11 Septembre")
        dispatch(setCurrentProjectGroupName(data.newName));
      } else {
        dispatch(setNewProjectGroup({ ...newProjectGroup, newName: data.newName }));
      }
      if (incrementStep) incrementStep();
    }
  };

  const handleSave = () => {
    if (editMode) {
      dispatch(
        updateProjectGroup({
          id: selectedProjectGroup.id,
          payload: {
            name: inputValueNewProjectGroup,
          },
          componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
        }),
      ).then(() => {
        dispatch(setEditedName(inputValueNewProjectGroup));
        navigate(`/courses/${selectedProjectGroup.id}/list`);
      });
    }
  };

  useEffect(() => {
    if (editMode && editedProjectGroup.name) {
      setValue("newName", editedProjectGroup.name);
      setinputValueNewProjectGroup(editedProjectGroup.name);
      trigger("newName");
    } else if (newProjectGroup.newName) {
      setValue("newName", newProjectGroup.newName);
      setinputValueNewProjectGroup(newProjectGroup.newName);
    }
  }, [editMode, editedProjectGroup.name, newProjectGroup.newName, setValue, trigger]);

  useEffect(() => {
    const nameToSet = editMode ? editedProjectGroup.name : newProjectGroup.newName;

    if (nameToSet) {
      setValue("newName", nameToSet);
      setinputValueNewProjectGroup(nameToSet);
    }
  }, [editMode, editedProjectGroup.name, newProjectGroup.newName, setValue]);

  const handleCancel = () => {
    navigate(`/courses/${selectedProjectGroup.id}/list`);
  };

  return (
    <>
      <h1 className="text-2xl my-5 mx-6 font-medium">
        {editMode
          ? t("pages.editProjectGroupName.pleaseRename")
          : t("pages.createProjectGroup.projectGroupName")}
      </h1>
      <form className="flex flex-col grow w-full justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-6 max-w-md">
          <Input
            autoFocus
            type="text"
            placeholder={t("pages.createProjectGroup.namePlaceholder")}
            registerFct={() =>
              register("newName", {
                required: true,
                maxLength: 24,
                onChange(event) {
                  setinputValueNewProjectGroup(event.target.value);
                  trigger("newName");
                },
                validate: {
                  notEmptyOrSpaces: (value) =>
                    value.trim() !== "" || t("notPossibleToNameWithEmptyCharProjectGroup"),
                },
              })
            }
          />

          {errors.newName?.type === "maxLength" && (
            <span className="text-red-600 text-sm mt-2">{t("alerts.maxCharacters")}</span>
          )}
          {isStringLegalProjectName(inputValueNewProjectGroup) === false && (
            <span className="text-red-600 text-sm mt-2">
              {t("alerts.wrongProjectGroupNameFormat")}
            </span>
          )}
          {errors.newName?.type === "notEmptyOrSpaces" && (
            <span className="text-red-600 text-sm mt-2">
              {t("pages.project.notPossibleToNameWithEmptyCharProjectGroup")}
            </span>
          )}
          {errors.newName?.type === "required" && (
            <span className="text-red-600 text-sm mt-2">
              {t("pages.createProjectGroup.nameProjectGroupValidation")}
            </span>
          )}
        </div>

        {editMode ? (
          <FooterButtons
            cancelCb={handleCancel}
            cancelText={t("general.cancel")}
            nextText={t("general.save")}
            nextCb={handleSave}
            nextDisabled={
              errors.newName?.type === "notEmptyOrSpaces" || errors.newName?.type === "required"
            }
          />
        ) : (
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate(-1)}
            backText={t("general.back")}
            backDisabled
            nextText={t("general.next")}
            nextDisabled={errors.newName?.type === "notEmptyOrSpaces"}
          />
        )}
      </form>
    </>
  );
};

export default CreateProjectGroupName;
