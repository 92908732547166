import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InformationFrame from "../../../common/components/InfomationFrame/InformationFrame";
import { getPublishWarningText } from "../../alert/state/alertsSlice";
import { getCurrentProjectGroupBuilds } from "../../builds/state/buildsSlice";
import PublishNewVersion from "./PublishNewVersion";
import PublishWhileThereIsAnExistingBuild from "./PublishWhileThereIsAnExistingBuild";
import { Outlet, useLocation } from "react-router-dom";

export default function AskPublicationWrapper() {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname.split("/");
  const lastUrlElement = path[path.length - 1];

  const currentProjectGroupBuilds = useSelector(getCurrentProjectGroupBuilds);
  const publishWarningText = useSelector(getPublishWarningText);

  return (
    <div className="flex flex-col align-center items-center h-full">
      {publishWarningText ? (
        <InformationFrame
          className="w-full px-6 max-w-6xl mx-auto z-50 transition-opacity z-[100]"
          style="warning"
          withTimeout={false}
          showCloseButton={false}
        >
          <span>{publishWarningText}</span>
        </InformationFrame>
      ) : null}
      <h2 className="mx-4 font-medium text-2xl">
        {t("pages.projectGroup.publish.newPublication")}
      </h2>
      <Outlet />
      {lastUrlElement === "new" &&
        (currentProjectGroupBuilds.length === 0 ? (
          <PublishNewVersion />
        ) : (
          <PublishWhileThereIsAnExistingBuild />
        ))}
    </div>
  );
}
