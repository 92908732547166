import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ModalStandard from "../Modal/ModalStandard";
import { changePassword, checkPassword } from "../../../features/profile/state/profileSlice";
import {
  closeStandardModal,
  getCurrentModalCurrentState,
} from "../../state/slice/modal/modalSlice";
import { AppDispatch } from "../../state/store";
import { Error } from "../AppState/Error";
import { IsLoading } from "../AppState/IsLoading";
import { InputIcon } from "../Input";
import { setAlert } from "../../../features/alert/state/alertsSlice";

export const OLDPASSWORD_COMPONENT_ID = "oldPasswordComponent";
export const NEWPASSWORD_COMPONENT_ID = "newPasswordComponent";

type NewPasswordValues = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const ChangePasswordPopup = (prop: any) => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(true);

  const [currPasswordVisible, setCurrPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [newPasswordConfirmVisible, setNewPasswordConfirmVisible] = useState(false);

  const {
    register: registerNewPasswordData,
    reset: resetNewPasswordData,
    handleSubmit: handleSubmitNewPasswordData,
    watch: watch,
  } = useForm<NewPasswordValues>();

  const newPasswordInput = watch("password");
  const newPasswordConfirmationInput = watch("passwordConfirmation");
  const modalState = useSelector(getCurrentModalCurrentState);

  useEffect(() => {
    setErrorOldPassword(false);
    setChangePasswordOpen(true);
  }, [modalState]);

  const isValidPassWord =
    newPasswordInput === newPasswordConfirmationInput &&
    newPasswordInput?.length > 7 &&
    newPasswordConfirmationInput?.length > 7;

  const checkNewWordForMessage = newPasswordInput?.length > 0 && newPasswordInput?.length < 8;

  const passwordDontMatch =
    newPasswordInput === newPasswordConfirmationInput &&
    newPasswordConfirmationInput?.length === newPasswordInput?.length;

  const onSubmitNewPassword = (data: NewPasswordValues) => {
    const newPassword = {
      email: prop.email,
      password: data.password,
      passwordConfirmation: data.passwordConfirmation,
    };

    dispatch(changePassword({ componentId: NEWPASSWORD_COMPONENT_ID, ...newPassword }))
      .then((res: any) => {
        if (res.payload.status === 200) {
          dispatch(
            setAlert({
              type: "success",
              msg: t("alerts.passwordChangedSuccessfully"),
            }),
          );
        } else {
          dispatch(
            setAlert({
              type: "danger",
              msg: t("alerts.passwordChangedFail"),
            }),
          );
        }
        if (!res.payload.error) {
          dispatch(closeStandardModal("modalChangePassword"));
          resetNewPasswordData({
            password: "",
            passwordConfirmation: "",
          });
        }
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const handlePasswordValidation = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      checkPassword({
        componentId: OLDPASSWORD_COMPONENT_ID,
        password: oldPassword,
      }),
    ).then((res: any) => {
      if (Number(res.payload) === 0) {
        setChangePasswordOpen(false);
        setErrorOldPassword(false);
        setOldPassword("");
      } else {
        setErrorOldPassword(true);
      }
    });
  };

  return (
    <>
      <ModalStandard overFlowOff id="modalConfirmPassword" fullScreen={false}>
        {changePasswordOpen ? (
          <div className="flex w-full">
            <div className="completely-centered z-50">
              <IsLoading componentId={OLDPASSWORD_COMPONENT_ID} showSuccess={false} />
            </div>
            <div className="relative grow w-full max-w-7xl px-4 xl:px-0 mx-auto">
              <form onSubmit={handlePasswordValidation}>
                <h2 className="pt-3 pb-1 font-medium text-lg">
                  {t("pages.profile.main.changePassword.title1")}
                </h2>
                <h3 className="pb-3">{t("pages.profile.main.changePassword.smTitle1")}</h3>
                <InputIcon
                  onChange={(event: any) => {
                    setOldPassword(event.target.value);
                  }}
                  type={currPasswordVisible ? "text" : "password"}
                  position="right"
                  placeholder={t("pages.profile.main.changePassword.oldPasswordPlaceholder")}
                  handleClick={() => setCurrPasswordVisible(!currPasswordVisible)}
                  icon={currPasswordVisible === true ? "EyeIcon" : "EyeOffIcon"}
                />
                {errorOldPassword && (
                  <div className="font-small text-red-700">
                    {t("pages.profile.main.changePassword.error")}
                  </div>
                )}
                <div className="flex justify-end mt-3">
                  <button className="btn-primary-fill" type="submit">
                    {t("general.confirm")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex w-full">
            <div className="completely-centered z-50">
              <Error componentId={NEWPASSWORD_COMPONENT_ID} />
              <IsLoading componentId={NEWPASSWORD_COMPONENT_ID} showSuccess={false} />
            </div>
            <div className="relative grow w-full max-w-7xl px-4 xl:px-0 mx-auto">
              <h3 className="py-3">{t("pages.profile.main.changePassword.title2")}</h3>
              <form onSubmit={handleSubmitNewPasswordData(onSubmitNewPassword)}>
                <div className="mb-2">
                  <InputIcon
                    autoFocus
                    registerFct={() => registerNewPasswordData("password", { required: true })}
                    type={newPasswordVisible ? "text" : "password"}
                    position="right"
                    placeholder={t("pages.profile.main.changePassword.newPasswordPlaceholder")}
                    handleClick={() => setNewPasswordVisible(!newPasswordVisible)}
                    icon={newPasswordVisible === true ? "EyeIcon" : "EyeOffIcon"}
                  />
                </div>
                <InputIcon
                  registerFct={() =>
                    registerNewPasswordData("passwordConfirmation", { required: true })
                  }
                  type={newPasswordConfirmVisible ? "text" : "password"}
                  position="right"
                  placeholder={t("pages.profile.main.changePassword.newPasswordConfirmPlaceholder")}
                  handleClick={() => setNewPasswordConfirmVisible(!newPasswordConfirmVisible)}
                  icon={newPasswordConfirmVisible === true ? "EyeIcon" : "EyeOffIcon"}
                />

                <div>
                  {!passwordDontMatch && (
                    <p className="font-small text-red-700">
                      {t("pages.profile.main.changePassword.passwordDontMatch")}
                    </p>
                  )}

                  {checkNewWordForMessage && (
                    <p className="font-small text-red-700">
                      {t("pages.profile.main.changePassword.newPasswordTooShort")}
                    </p>
                  )}
                </div>
                <div className="flex justify-end mt-3">
                  <button
                    onClick={() => dispatch(closeStandardModal("modalConfirmPassword"))}
                    disabled={!isValidPassWord}
                    className="btn-primary-fill"
                    type="submit"
                  >
                    {t("general.save")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </ModalStandard>
    </>
  );
};

export default ChangePasswordPopup;
