import { FaInfoCircle } from "react-icons/fa";

export const MediaGenerationInfoParagraph = ({ text }: { text: string }) => {
  return (
    <div className="mt-4 flex flex-row items-start" style={{ fontSize: "1rem" }}>
      <FaInfoCircle
        style={{
          color: "rgb(37 99 235)",
          height: "1rem",
          width: "1rem",
          flexShrink: 0,
          marginTop: "0.1rem",
        }}
      />
      <p className="text-blue-600 ml-2 leading-relaxed">{text}</p>
    </div>
  );
};
