import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganization, IUser } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import { storeAuthInLocalStorage } from "../../../common/util/localStorage";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { ZendeskAPI } from "react-zendesk";
import { clearProfile } from "../../profile/state/profileSlice";
import {
  clearProjectGroups,
  IProjectGroupWithProjects,
  setProjectGroupsWithProjects,
} from "../../projectGroups/state/projectGroupsSlice";
import { clearProjects } from "../../graph/state/projectsSliceLegacy";
import { clearGallery } from "../../gallery/state/gallerySlice";
import { clearTeam } from "../../team/state/teamSlice";
import { clearAlerts } from "../../alert/state/alertsSlice";

export interface IAuthFormData {
  email: string;
  password: string;
  from_environment?: string;
  rememberme: boolean;
}

export interface IForgottenPasswordFormData {
  email: string;
}

export interface IResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export interface IAuth {
  jwt: string;
  userId?: number;
  currentCommitHash: string;
}

export const signIn = createAsyncThunk(
  "auth/login",
  async (
    { email, password, rememberme, from_environment = "WebApp" }: IAuthFormData & IAppLoaderAction,
    { dispatch },
  ) => {
    const response = await axios.post<{
      jwt: string;
      user: IUser;
      firstTwoProjectGroupsWithProjects?: IProjectGroupWithProjects[];
    }>(
      `${apiUrls.auth.signIn}?populate=*&populate[organization][populate][admin]=*&populate[organization][populate][source_logo]=*`,
      {
        identifier: email,
        password,
        from_environment,
        remember_me: rememberme,
      },
    );

    const { jwt, user, firstTwoProjectGroupsWithProjects } = response.data;
    const organization = user.organization;

    if (
      Array.isArray(firstTwoProjectGroupsWithProjects) &&
      firstTwoProjectGroupsWithProjects.length > 0
    ) {
      dispatch(setProjectGroupsWithProjects(firstTwoProjectGroupsWithProjects));
    }
    // Storing jwt and user in local storage
    await storeAuthInLocalStorage(user, organization, jwt, true);

    return { jwt };
  },
);

export const getEditorWebLastCommit = createAsyncThunk("auth/getEditorWebLastCommit", async () => {
  const response = await axios.get(apiUrls.getEditorWebLastCommit);

  return { commitHash: response.data.commit_hash };
});

export const forgottenPassword = createAsyncThunk(
  "auth/forgottenPassword",
  async ({ email }: IForgottenPasswordFormData & IAppLoaderAction, { rejectWithValue }) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(apiUrls.auth.forgetPassword, {
        email,
      });
      // window.location.replace("/");
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      password,
      passwordConfirmation,
      code,
    }: IResetPasswordFormData & { code: string } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(apiUrls.auth.resetPassword, {
        code: code,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

const initialState: IAuth = { jwt: "", currentCommitHash: "" };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (
      state: IAuth,
      action: PayloadAction<{ jwt: string; user: IUser; organization: IOrganization }>,
    ) => {
      state.jwt = action.payload.jwt;
    },
    resetJwt: (state: IAuth) => {
      state.jwt = "";
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.clear();
    },
    setCurrentCommitHash: (state: IAuth, action: PayloadAction<string>) => {
      state.currentCommitHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.jwt = payload.jwt;
    });
  },
});

export const authReducer = authSlice.reducer;

export const getCurrentJwt = (state: IAuth) => state.jwt;

export const getCurrentCommitHash = (state: IAuth) => state.currentCommitHash;

export const logoutAuth = (): any => (dispatch: any) => {
  ZendeskAPI("webWidget", "hide");
  dispatch(clearProfile());
  dispatch(clearProjectGroups());
  dispatch(clearProjects());
  dispatch(clearGallery());
  dispatch(clearTeam());
  dispatch(clearAlerts());
  // We don't want to discard localStorage.CURRENT_COMMIT_HASH
  const CURRENT_COMMIT_HASH = localStorage.getItem("CURRENT_COMMIT_HASH");
  dispatch(authSlice.actions.resetJwt());
  if (CURRENT_COMMIT_HASH) {
    localStorage.setItem("CURRENT_COMMIT_HASH", CURRENT_COMMIT_HASH);
  }
};

export const { setAuth, setCurrentCommitHash } = authSlice.actions;
