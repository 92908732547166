import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { RootState } from "../../../common/state/store";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { ISession } from "../../../model/model";
import graphColors from "../../../assets/graphColors";

export interface ISessionsByProject {
  project_name: string;
  project_id: number;
  need_login: boolean;
  is_simplified_login: boolean;
  sessions: ISession[];
  is_deleted: boolean;
}

export interface ISessionsByProjectGroup {
  project_group_name: string;
  project_group_id: number;
  projects: ISessionsByProjectWithColor[];
  need_login: boolean;
  is_simplified_login: boolean;
  sessions: ISession[];
  total_projectGroups: number;
  is_deleted: boolean;
}

export interface ISessionsByProjectWithColor extends ISessionsByProject {
  color: string;
}

export interface ISessionsByProjectGroupWithColor extends ISessionsByProjectGroup {
  color: string;
}

export interface ScorePerScene {
  id: string | number;
  name: string;
  score: number;
  score_max: number;
}

export interface ScorePerProjectGroup {
  project_group_id: number;
  projects: ScorePerScene[];
}

export interface IStatsState {
  allSessionsByProject: ISessionsByProjectWithColor[];
  allSessionsByProjectGroup: ISessionsByProjectGroup[];
  selectedProjectId: number;
  selectedProjectGroupId: number;
  selectedProjectScorePerScene: ScorePerScene[];
  selectedProjectGroupScorePerProject: ScorePerProjectGroup;
  selectedUserProjectScore: IEnduserStatsPerProject;
  currentProjectGroupElement: {
    id: string | number;
    order: number;
    project: {
      id: string | number;
    };
    project_group: {
      id: string | number;
      auth_type: string;
    };
  };
}

export interface IEnduserStatsPerProject {
  enduser: number;
  first_session: string;
  last_session: string;
  project: string;
  user_name: string;
  first_session_score: number;
  last_session_score: number;
  project_max_score: number;
  firstSessionScoreAndMaxScoreString: string;
  lastSessionScoreAndMaxScoreString: string;
  sourceAvatarId: number;
  email: string;
  lastSessionCompleted: boolean;
  rank: number;
  competitors: number;
  successRate: ScorePerScene[];
}

const initialState: IStatsState = {
  allSessionsByProject: [],
  allSessionsByProjectGroup: [],
  selectedProjectId: 0,
  selectedProjectGroupId: 0,
  selectedProjectScorePerScene: [],
  selectedProjectGroupScorePerProject: {
    project_group_id: 0,
    projects: [],
  },
  selectedUserProjectScore: {
    enduser: 0,
    first_session: "",
    last_session: "",
    project: "0",
    user_name: "",
    first_session_score: 0,
    last_session_score: 0,
    project_max_score: 0,
    firstSessionScoreAndMaxScoreString: "",
    lastSessionScoreAndMaxScoreString: "",
    sourceAvatarId: 0,
    email: "",
    lastSessionCompleted: true,
    rank: 0,
    competitors: 0,
    successRate: [],
  },
  currentProjectGroupElement: {
    id: 0,
    order: 0,
    project: { id: 0 },
    project_group: { id: 0, auth_type: "" },
  },
};

export const getAllSessionsByProject = createAsyncThunk(
  "stats/allSessionsByProject",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<Array<any>>(`${apiUrls.stats.getAllSessionsByProject}`);
    return { data: (await response).data };
  },
);

export const getAllSessionsByProjectGroup = createAsyncThunk(
  "stats/allSessionsByProjectGroup",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<Array<any>>(`${apiUrls.stats.getAllSessionsByProjectGroup}`);
    return { data: (await response).data };
  },
);

export const fetchCurrentProjectGroupElement = createAsyncThunk(
  "stats/fetchCurrentProjectGroupElement",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectId }: { projectId: number } & IAppLoaderAction) => {
    const response = (await axios.get)<Array<any>>(
      `${apiUrls.projects.projectGroupElements}?filters[project][id][$eq]=${projectId}&populate=project,project_group`,
    );
    const cpge = (await response).data[0];
    return cpge;
  },
);

export const getScorePerSceneForProject = createAsyncThunk(
  "stats/scorePerSceneForProject",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectId }: any & IAppLoaderAction) => {
    const response = (await axios.get)<any>(
      `${apiUrls.stats.successRatePerScene}?project=${projectId}`,
    );
    return (await (
      await response
    ).data) as ScorePerScene[];
  },
);

export const getAvarageScorePerProjectForProjectGroup = createAsyncThunk(
  "stats/getAvarageScorePerProjectForProjectGroup",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectGroupId }: any & IAppLoaderAction) => {
    const response = (await axios.get)<any>(
      `${apiUrls.stats.successRatePerProjectGroup}?projectGroupId=${projectGroupId}`,
    );
    return (await (
      await response
    ).data) as ScorePerProjectGroup;
  },
);

export const getEnduserStatsPerProject = createAsyncThunk(
  "stats/enduserStatsPerProject",
  // eslint-disable-next-line no-empty-pattern
  async ({ userId, projectId }: any & IAppLoaderAction) => {
    const response = (await axios.get)<any>(
      `${apiUrls.stats.enduserStatsPerProject}?user=${userId}&project=${projectId}`,
    );
    return (await (
      await response
    ).data) as IEnduserStatsPerProject;
  },
);

export const sendProjectResultsPerUser = createAsyncThunk(
  "stats/sendProjectResultsPerUser",
  async ({
    projectId,
    trainerId,
    traineeId,
    to,
  }: { projectId: number; trainerId: number; traineeId: any; to: string } & IAppLoaderAction) => {
    const response = (await axios.post)<any>(
      `${apiUrls.stats.sendProjectResults}?projectId=${projectId}&trainerId=${trainerId}&traineeId=${traineeId}&to=${to}`,
    );
    return (await response).data;
  },
);

export const sendProjectResultsGlobal = createAsyncThunk(
  "stats/sendProjectResultsGlobal",
  async ({ projectId, trainerId }: { projectId: number; trainerId: number } & IAppLoaderAction) => {
    const response = (await axios.post)<any>(
      `${apiUrls.stats.sendGlobalResults}?projectId=${projectId}&trainerId=${trainerId}`,
    );
    return (await response).data;
  },
);

export const fetchGlobalResultsDownloadLink = createAsyncThunk(
  "stats/fetchGlobalResultsDownloadLink",
  async ({ projectId }: { projectId: number } & IAppLoaderAction) => {
    const response = (await axios.get)<any>(
      `${apiUrls.stats.getGlobalResultsDownloadLink}?projectId=${projectId}`,
    );
    return (await response).data;
  },
);

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllSessionsByProject.fulfilled, (state: IStatsState, { payload }) => {
      const projects = payload.data as any[];

      state.allSessionsByProject = projects.map((project: ISessionsByProject, index: number) => {
        return {
          ...project,
          color: graphColors[index % graphColors.length],
        };
      });
    });
    builder.addCase(getAllSessionsByProjectGroup.fulfilled, (state: IStatsState, { payload }) => {
      const groups = payload.data as any[];
      state.allSessionsByProjectGroup = groups.map(
        (group: ISessionsByProjectGroup, index: number) => {
          return {
            ...group,
            projects: group.projects.map((project: ISessionsByProjectWithColor, index: number) => {
              return {
                ...project,
                color: graphColors[index % graphColors.length],
              };
            }),
            color: graphColors[index % graphColors.length],
          };
        },
      );
    });
    builder.addCase(getScorePerSceneForProject.fulfilled, (state: IStatsState, { payload }) => {
      state.selectedProjectScorePerScene = payload;
    });
    builder.addCase(
      getAvarageScorePerProjectForProjectGroup.fulfilled,
      (state: IStatsState, { payload }) => {
        state.selectedProjectGroupScorePerProject = payload;
      },
    );
    builder.addCase(getEnduserStatsPerProject.fulfilled, (state: IStatsState, { payload }) => {
      state.selectedUserProjectScore = payload;
    });
    builder.addCase(
      fetchCurrentProjectGroupElement.fulfilled,
      (state: IStatsState, { payload }) => {
        state.currentProjectGroupElement = payload;
      },
    );
  },
  reducers: {
    setCurrentSessions: (state: IStatsState, action: PayloadAction<any[]>) => {
      state.allSessionsByProject = action.payload;
    },
    setSelectedProjectId: (state: IStatsState, action: PayloadAction<number>) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedProjectGroupId: (state: IStatsState, action: PayloadAction<number>) => {
      state.selectedProjectGroupId = action.payload;
    },
    setSelectedProjectScorePerScene: (
      state: IStatsState,
      action: PayloadAction<ScorePerScene[]>,
    ) => {
      state.selectedProjectScorePerScene = action.payload;
    },
    setSelectedProjectGroupScorePerProject: (
      state: IStatsState,
      action: PayloadAction<ScorePerProjectGroup>,
    ) => {
      state.selectedProjectGroupScorePerProject = action.payload;
    },
  },
});

export const sessionsSelector = (state: RootState) => state.stats.allSessionsByProject;
export const sessionsByProjectGroupSelector = (state: RootState) =>
  state.stats.allSessionsByProjectGroup;

// https://stackoverflow.com/a/62546164
export const getSelectedProjectWithSessions = (projectId: number) => (state: RootState) => {
  for (const group of state.stats.allSessionsByProjectGroup) {
    const project = group.projects.find((p) => Number(p.project_id) === Number(projectId));

    // Return as soon as a project is found
    if (project) {
      return project;
    }
  }

  // If no project is found after all groups have been checked, return a default empty project
  return {} as ISessionsByProjectWithColor;
};

export const getSelectedProjectGroupWithSessions =
  (projectGroupId: number) => (state: RootState) => {
    return (
      state.stats.allSessionsByProjectGroup.find(
        (sbp) => sbp.project_group_id === projectGroupId,
      ) || ({} as ISessionsByProjectGroup)
    );
  };

export const getSelectedProjectId = (state: RootState) => state.stats.selectedProjectId;
export const getSelectedProjectGroupId = (state: RootState) => state.stats.selectedProjectGroupId;

export const getSelectedProjectScorePerScene = (state: RootState) =>
  state.stats.selectedProjectScorePerScene;

export const getSelectedProjectGroupScorePerProject = (state: RootState) =>
  state.stats.selectedProjectGroupScorePerProject;

export const getSelectedUserProjectScore = (state: RootState) =>
  state.stats.selectedUserProjectScore;

export const getCurrentProjectGroupElement = (state: RootState) =>
  state.stats.currentProjectGroupElement;

export const statsReducer = statsSlice.reducer;

export const { setCurrentSessions, setSelectedProjectGroupId, setSelectedProjectId } =
  statsSlice.actions;
