import { useTranslation } from "react-i18next";
import {
  setEditedProjectEnabledLanguagesIds,
  setNewProjectEnabledLanguagesIds,
} from "src/features/projects/state/projectsSlice";

interface LanguageSelectorProps {
  options: { value: string; optionText: string }[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  mode: string;
  dispatch: any;
}

const LanguageSelector = ({
  options,
  selectedValues,
  setSelectedValues,
  mode,
  dispatch,
}: LanguageSelectorProps) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    const updatedValues = isChecked
      ? [...selectedValues, value]
      : selectedValues.filter((id) => id !== value);

    setSelectedValues(updatedValues);

    if (mode === "edit") {
      dispatch(setEditedProjectEnabledLanguagesIds(updatedValues));
    }
    if (mode === "newProject" || mode === "newProjectFromCustomTemplate") {
      dispatch(setNewProjectEnabledLanguagesIds(updatedValues));
    }
  };

  return (
    <div className="mt-1">
      <label className="block font-medium text-gray-700 text-lg">
        {t("pages.editProjectLanguage.selectLanguages")}
      </label>
      <div className="mt-2">
        {options.map((option) => {
          const isMainLanguage = selectedValues[0] === option.value;
          return (
            <div key={option.value} className="flex items-center">
              <input
                type="checkbox"
                id={`language-${option.value}`}
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <label
                htmlFor={`language-${option.value}`}
                className={`ml-2 ${isMainLanguage ? "font-bold" : ""}`}
              >
                {option.optionText}
              </label>
              {isMainLanguage && (
                <span className="ml-2 text-sm text-gray-600">
                  -&nbsp;{t("pages.editProjectLanguage.mainLanguage")}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSelector;
