import { ReactElement } from "react";

interface Props {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
}

export default function ManualInterventionIcon({
  width = "100%",
  height = "100%",
  fill = "none",
  className = "",
}: Props): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3162_25505)">
        <path
          d="M4.6375 0.262535C4.48438 0.41566 4.375 0.80941 4.375 1.13753C4.375 1.96878 3.82813 2.14378 3.17188 1.53128C2.7125 1.09378 2.55938 1.05003 2.07813 1.26878C1.77188 1.42191 1.42188 1.77191 1.26875 2.07816C1.05 2.55941 1.09375 2.71253 1.53125 3.17191C2.14375 3.82816 1.96875 4.37503 1.1375 4.37503C0.35 4.37503 0 4.72503 0 5.57816C0 6.40941 0.35 6.78128 1.11563 6.78128C1.94688 6.78128 2.07813 7.10941 1.55313 7.78753C0.940625 8.57503 0.984375 8.83753 1.77188 9.49378C2.45 10.0625 2.625 10.0625 3.325 9.45003C3.87188 8.99066 4.375 9.27503 4.375 10.0188C4.375 10.7188 4.85625 11.2875 5.25 11.0469C5.425 10.9375 5.40313 10.7844 5.1625 10.5438C4.9875 10.325 4.8125 9.88753 4.76875 9.51566C4.65938 8.55316 3.71875 8.26878 2.95313 8.96878C2.51563 9.38441 2.3625 9.42816 2.07813 9.18753C1.64063 8.81566 1.6625 8.55316 2.23125 7.94066C2.69063 7.45941 2.69063 7.37191 2.38438 6.82503C2.16563 6.40941 1.85938 6.21253 1.35625 6.16878C0.721875 6.10316 0.634375 6.03753 0.7 5.51253C0.74375 5.03128 0.896875 4.90003 1.4875 4.85628C2.5375 4.74691 2.8875 3.91566 2.1875 3.17191C1.29063 2.20941 2.20938 1.29066 3.17188 2.18753C3.91563 2.88753 4.74688 2.53753 4.85625 1.48753C4.9 0.89691 5.03125 0.743785 5.5125 0.700035C6.0375 0.63441 6.10313 0.72191 6.16875 1.35628C6.2125 1.85941 6.40938 2.16566 6.825 2.38441C7.37188 2.69066 7.45938 2.69066 7.94063 2.23128C8.55313 1.66253 8.81563 1.64066 9.1875 2.07816C9.42813 2.36253 9.38438 2.51566 8.99063 2.90941C8.50938 3.45628 8.4 4.04691 8.75 4.26566C8.88125 4.33128 9.05625 4.17816 9.1875 3.89378C9.29688 3.60941 9.55938 3.17191 9.73438 2.93128C10.0625 2.49378 10.0406 2.40628 9.51563 1.77191C8.8375 0.984409 8.575 0.94066 7.7875 1.55316C7.10938 2.07816 6.78125 1.94691 6.78125 1.11566C6.78125 0.350035 6.40938 3.43323e-05 5.57813 3.43323e-05C5.20625 3.43323e-05 4.79063 0.109409 4.6375 0.262535Z"
          fill="#212429"
        />
        <path
          d="M4.41866 4.44062C3.43428 5.49063 4.17803 7.21875 5.62178 7.21875C6.29991 7.21875 7.21866 6.3 7.21866 5.62188C7.21866 4.15625 5.40303 3.39062 4.41866 4.44062ZM6.51866 5.44687C6.69366 6.54063 5.31553 6.97813 4.70303 6.0375C4.30928 5.44687 4.46241 4.85625 5.11866 4.59375C5.70928 4.375 6.43116 4.8125 6.51866 5.44687Z"
          fill="#212429"
        />
        <path
          d="M8.6625 5.16253C8.37812 5.42503 8.3125 6.05941 8.3125 8.35628V11.2219L7.80937 10.7625C6.9125 9.93128 5.90625 10.2594 5.90625 11.375C5.90625 11.7688 6.23437 12.2719 6.95625 12.9719C7.525 13.5407 8.07187 14 8.15937 14C8.55312 14 8.225 13.3657 7.30625 12.4469C6.7375 11.8782 6.36562 11.3094 6.43125 11.1782C6.69375 10.7625 7.35 10.9157 7.98437 11.5938C8.3125 11.9438 8.68437 12.1844 8.79375 12.1188C8.88125 12.0532 8.96875 10.5438 8.96875 8.72816C8.96875 5.64378 8.99062 5.46878 9.40625 5.46878C9.77812 5.46878 9.84375 5.64378 9.8875 7.48128L9.95312 9.49378L11.375 9.77816C13.3 10.1719 13.4969 10.4125 13.1687 11.8563C13.0375 12.4907 12.8625 13.2563 12.775 13.5407C12.4687 14.5688 13.1906 13.8907 13.5844 12.775C14.3719 10.5438 13.9562 9.71253 11.9 9.29691L10.6094 9.03441L10.5437 7.32816C10.5 6.10316 10.3687 5.46878 10.1281 5.20628C9.69062 4.72503 9.1 4.70316 8.6625 5.16253Z"
          fill="#212429"
        />
      </g>
      <defs>
        <clipPath id="clip0_3162_25505">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
