import { ReactElement } from "react";

interface Props {
  width: number;
  height: number;
  fill: string;
  stroke: string;
  style: string;
}

export default function Pico({
  width,
  height,
  fill = "black",
  stroke,
  style,
}: Props): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 45"
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.315 21.23H40.39V8.626h7.924c3.423 0 6.114 2.778 6.114 6.31 0 3.532-2.691 6.294-6.114 6.294Zm0-15.23H39.09c-.718 0-1.3.589-1.3 1.313v25.359c0 .724.582 1.313 1.3 1.313.717 0 1.3-.589 1.3-1.313v-8.815h7.924c2.332 0 4.53-.921 6.159-2.581 1.644-1.676 2.556-3.925 2.556-6.325 0-2.415-.912-4.664-2.541-6.34A8.55 8.55 0 0 0 48.315 6ZM17.743 16.445c4.141 3.955 6.698 4.724 8.282 4.875.045-.437.09-.875.09-1.328 0-6.264-5.053-11.366-11.257-11.366-4.066 0-7.64 2.204-9.612 5.48.224-.197.628-.499 1.24-.846a8.446 8.446 0 0 1 2.437-.694c2.99-.363 5.935.935 8.82 3.879ZM3.601 19.992c0 6.264 5.053 11.366 11.257 11.366 4.844 0 8.97-3.11 10.554-7.471-1.988-.257-4.993-1.299-9.492-5.57-2.303-2.355-4.545-3.427-6.668-3.155-2.87.347-4.933 2.943-5.606 3.91-.03.301-.045.618-.045.92Zm-2.601 0C1 12.264 7.204 6.015 14.858 6.015c7.64 0 13.843 6.249 13.858 13.977 0 7.728-6.204 13.993-13.858 13.993C7.204 33.985 1 27.72 1 19.992Zm6.174.423c0-.966.777-1.75 1.734-1.75s1.734.784 1.734 1.75-.777 1.751-1.734 1.751a1.744 1.744 0 0 1-1.734-1.75Zm55.597-2.957c0-.725.583-1.314 1.3-1.314.718 0 1.301.59 1.301 1.314v15.215c0 .724-.583 1.313-1.3 1.313-.718 0-1.301-.589-1.301-1.313V17.458Zm38.525 13.9c-3.453 0-6.249-2.838-6.249-6.31 0-3.486 2.81-6.309 6.249-6.309 3.453 0 6.249 2.838 6.249 6.31 0 3.471-2.796 6.31-6.249 6.31Zm0-15.245c-4.874 0-8.85 4.015-8.85 8.936 0 4.936 3.977 8.936 8.85 8.936 4.874 0 8.85-4.015 8.85-8.936 0-4.936-3.976-8.936-8.85-8.936ZM84.971 20.58a6.13 6.13 0 0 0-4.425-1.857c-1.66 0-3.244.664-4.425 1.857a6.25 6.25 0 0 0-1.839 4.468c0 1.69.658 3.275 1.839 4.468a6.19 6.19 0 0 0 4.425 1.856 6.19 6.19 0 0 0 4.425-1.856 1.29 1.29 0 0 1 1.839 0 1.32 1.32 0 0 1 0 1.856A8.768 8.768 0 0 1 80.546 34a8.768 8.768 0 0 1-6.264-2.627 8.99 8.99 0 0 1-2.586-6.324c0-2.385.912-4.634 2.586-6.325a8.816 8.816 0 0 1 6.264-2.611c2.362 0 4.59.92 6.264 2.611a1.32 1.32 0 0 1 0 1.857 1.29 1.29 0 0 1-1.839 0Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
