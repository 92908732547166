import React, { useEffect, useMemo, useState } from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { generateHashFromString } from "../utils/generateHashFromString";
import { convertSvgToBase64Png } from "../utils/convertSvgToBase64Png";
import { ReactPdfTextComponents } from "./ReactPdfTextComponents";
import { useTranslation } from "react-i18next";

interface GeneratePdfProps {
  markdown: string;
  key: string;
}

const GeneratePdfFromScenarioHelperResponse: React.FC<GeneratePdfProps> = ({ markdown, key }) => {
  const { t } = useTranslation();
  const [imageSource, setImageSource] = useState<{ data: string; format: "png" } | null>(null);
  const [isBufferReady, setIsBufferReady] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchBuffer = async () => {
      try {
        const markdownHash = generateHashFromString(markdown);
        const svgElement = document.getElementById(`scenario-helper-svg-${markdownHash}`);

        if (!svgElement) {
          console.warn("SVG element not found");
          return;
        }

        const bufferData = await convertSvgToBase64Png(svgElement as any);

        if (signal.aborted) return;

        if (
          bufferData &&
          typeof bufferData === "object" &&
          "data" in bufferData &&
          bufferData.data.length > 0
        ) {
          setImageSource(bufferData);
          setIsBufferReady(true);
        } else {
          console.error("Buffer data is invalid or empty");
          setImageSource(null);
          setIsBufferReady(false);
        }
      } catch (error) {
        if (signal.aborted) return;
        console.error("Error in fetchBuffer:", error);
        setImageSource(null);
        setIsBufferReady(false);
      }
    };

    fetchBuffer();

    return () => {
      controller.abort();
      setImageSource(null);
      setIsBufferReady(false);
    };
  }, [markdown]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      padding: 30,
    },
    section: {
      marginBottom: 20,
    },
    svgImage: {
      marginTop: 20,
      width: "100%",
      textAlign: "center",
    },
  });

  const isValidImageSource = useMemo(() => {
    return (
      imageSource &&
      typeof imageSource === "object" &&
      "data" in imageSource &&
      "format" in imageSource
    );
  }, [imageSource]);

  const PDFContent = useMemo(() => {
    try {
      return (
        <Document>
          <Page style={styles.page}>
            <View style={styles.section}>
              <ReactPdfTextComponents markdown={markdown} />
            </View>
          </Page>
          <Page style={styles.page} orientation="landscape">
            <View style={styles.section}>
              {isValidImageSource ? (
                <Image src={`data:image/png;base64,${imageSource?.data}`} />
              ) : (
                <Text>{"L'image n'a pas pu être chargée ou est indisponible."}</Text>
              )}
            </View>
          </Page>
        </Document>
      );
    } catch (e) {
      console.error("Error generating PDF content:", e);
      return (
        <Document>
          <Page style={styles.page}>
            <Text>{"An error occurred while generating the PDF."}</Text>
          </Page>
        </Document>
      );
    }
  }, [isValidImageSource, imageSource, markdown]);

  return (
    <button
      className="btn-primary-outline mt-1 w-3/5"
      style={{
        display: isBufferReady ? "block" : "none",
      }}
      key={key}
    >
      {isBufferReady && (
        <PDFDownloadLink
          document={PDFContent}
          fileName="scenario.pdf"
          style={{
            width: "100%",
            height: "100%",
            display: "block",
            cursor: "pointer",
          }}
        >
          {
            // @ts-expect-error: U can twust me Typescwipt-san Im leet engineew (uwu)
            ({
              blob,
              url,
              loading,
              error,
            }: {
              blob: any;
              url: any;
              loading: boolean;
              error: any;
            }) => {
              try {
                if (error) {
                  console.error("Error in PDFDownloadLink:", error);
                  return t("general.error");
                }
                return loading ? "..." : `${t("general.download")} PDF`;
              } catch (e) {
                console.error("Unexpected error in PDFDownloadLink rendering:", e);
                return t("general.error");
              }
            }
          }
        </PDFDownloadLink>
      )}
    </button>
  );
};

export default GeneratePdfFromScenarioHelperResponse;
