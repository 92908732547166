import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ButtonIcon } from "../../../common/components/Button";
import Navigation from "../../../common/components/Navigation/Navigation";
import { openSlider } from "../../../common/state/slice/modal/modalSlice";
import { RootState } from "../../../common/state/store";
import { setAlert } from "../../alert/state/alertsSlice";
import { getNodeById } from "../../graph/state/graphLegacy";
import {
  currentFilterSelector,
  getCurrentModeListGallery,
  isUploading,
  setCurrentModeListGallery,
  setUploadedFile,
} from "../state/gallerySlice";
import {
  computeUploadPanelParams,
  deduceMediaType,
  fromMediaTypeInferInputAcceptParam,
} from "../state/uploadUtil";
import { GENERATE_MEDIA_SLIDEOVER_COMPONENT } from "./GenerateMediaPanel";
import { MediaSearchPanelContainer } from "./MediaSearchPanel";
import { useLocation } from "react-router-dom";
import Alert from "../../../common/components/Alert/Alert";
import { ViewGridIcon, ViewListIcon } from "@heroicons/react/outline";
import { isStringNullOrEmpty } from "src/common/util/isStringNullOrEmpty";
import { useIsModalGallery } from "src/common/util/isModalGallery";
export const regexIsNodeContentScene = (pathname: string) => {
  return /courses\/\d+\/list\/\d+\/graph\/create\/scene/.test(pathname);
};

export const Nav = ({
  nodeId,
  mediaForSearchTitle,
  title = "",
}: {
  nodeId: string;
  mediaForSearchTitle: any;
  title?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isModalGallery = useIsModalGallery();

  const currentMediaFilter = useSelector(currentFilterSelector) as any;
  const uploading = useSelector(isUploading);
  const currentModeListGallery = useSelector(getCurrentModeListGallery);

  const hiddenFileInput = useRef(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const currentNode =
    Number(nodeId) !== 0 ? useSelector((state: RootState) => getNodeById(state, nodeId)) : null;

  const isNodeContentScene = currentNode?.modelName === "content-scene";

  let fileType = "all";
  if (currentNode) {
    if (currentNode.modelName === "content-scene" && currentMediaFilter?.mediaType !== "model3d") {
      fileType = "content-scene";
    } else {
      fileType = currentMediaFilter?.mediaType;
    }
  }
  if (regexIsNodeContentScene(pathname)) {
    fileType = "content-scene";
  }
  const alertExtension = (e: any, isGif = false) => {
    e.target.value = null;
    dispatch(
      setAlert({
        msg: isGif ? t("general.extensionNotAcceptedGif") : t("general.extensionNotAccepted"),
        type: "danger",
        isOpen: true,
      }),
    );
  };

  const isNodeContentSceneOrRegex = isNodeContentScene || regexIsNodeContentScene(pathname);

  return (
    <>
      {isNodeContentSceneOrRegex && (
        <div className="z-50 text-center">
          <Alert />
        </div>
      )}
      <Navigation
        title={title}
        buttons={
          <>
            <div>
              <ButtonIcon
                styled="btn-primary-fill w-full"
                text={t("pages.project.importMedia")}
                icon="CloudUploadIcon"
                onClick={() => {
                  const hfi = hiddenFileInput?.current as any;
                  hfi.click();
                }}
                disabled={uploading}
              />
              <input
                id="upload-input"
                key={inputKey}
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                accept={fromMediaTypeInferInputAcceptParam(fileType) ?? "image"}
                onChange={(e: any) => {
                  const extension = e.target.files[0]?.name.slice(
                    e.target.files[0]?.name.lastIndexOf("."),
                  );
                  const testIfExtensioIsGif = () => {
                    if (isNodeContentSceneOrRegex) {
                      if (extension === ".gif") {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  };

                  let currentFileType = e.target.files[0].type;
                  if (isStringNullOrEmpty(currentFileType)) {
                    const splitFileName = e.target.files[0].name.split(".");
                    if (splitFileName.length > 0) {
                      try {
                        const type = deduceMediaType(splitFileName[splitFileName.length - 1]);
                        currentFileType = type + "/" + splitFileName[splitFileName.length - 1];
                      } catch {
                        currentFileType = "";
                      }
                    }
                  }

                  const splitInArray = fromMediaTypeInferInputAcceptParam(fileType).split(",");
                  const testExtensions = !splitInArray.includes(currentFileType);

                  if (testExtensions || extension === ".m4v" || testIfExtensioIsGif()) {
                    alertExtension(e, testIfExtensioIsGif());
                  } else {
                    const uploadedFile = e.target.files[0] as File;
                    const cupp = computeUploadPanelParams({ file: uploadedFile, from: "graph" });
                    dispatch(setUploadedFile({ file: uploadedFile, metadata: cupp }));
                    setInputKey(Date.now());
                  }
                }}
              ></input>
            </div>
            <ButtonIcon
              styled="btn-alternative-outline w-full"
              text={t("pages.gallery.generateMediaPanel.title")}
              icon="PuzzleIcon"
              onClick={() => {
                dispatch(openSlider(GENERATE_MEDIA_SLIDEOVER_COMPONENT));
              }}
              disabled={uploading}
            />

            {!isModalGallery && (
              <button
                className="w-7 flex justify-end mb-1 ml-44"
                onClick={() => dispatch(setCurrentModeListGallery(!currentModeListGallery))}
              >
                {currentModeListGallery ? (
                  <ViewGridIcon className="w-7 h-7 stroke-green-500 " />
                ) : (
                  <ViewListIcon className="w-7 h-7 stroke-green-500 " />
                )}
              </button>
            )}
          </>
        }
        tagsBar={
          <MediaSearchPanelContainer nodeId={nodeId} mediaForSearchTitle={mediaForSearchTitle} />
        }
      />
    </>
  );
};
