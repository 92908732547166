import { setAlert } from "src/features/alert/state/alertsSlice";

/**
 * Dispatch a setAlert with relevant message (color is deduced from httpStatus)
 */
export const displayProperAlert = ({
  httpStatus,
  successMsg,
  errorMsg,
  t,
  dispatch,
}: {
  httpStatus: any;
  successMsg: string;
  errorMsg: string;
  t(string: string): any;
  dispatch(payload: any): any;
}) => {
  const isSuccess = Number(httpStatus) === 200;
  return dispatch(
    setAlert({
      type: isSuccess ? "success" : "danger",
      msg: t(isSuccess ? successMsg : errorMsg),
    }),
  );
};
