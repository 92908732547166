import React from "react";
import Chatbot from "src/features/chatbot/components/Chatbot";

const WixarpediaChat: React.FC = () => {
  return (
    <div className="h-full">
      <Chatbot mode="wixarpedia" />
    </div>
  );
};

export default WixarpediaChat;
