import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FooterButtons } from "../../../common/components/FooterButtons";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../../common/state/store";
import { IMedia } from "../../../model/unityObject";
import { getCurrentMediaGallery } from "../state/gallerySlice";

export const Actions = ({
  onConfirmSelectMedia,
  onCancel,
  modalId,
}: {
  onConfirmSelectMedia?: (media: IMedia) => void;
  onCancel?: () => void;
  modalId?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const modalState = useSelector((state: RootState) => state.modals.showStandardModal);

  const currentMediaGallery = useSelector(getCurrentMediaGallery);

  return (
    <FooterButtons
      cancelText={t("general.cancel")}
      cancelCb={onCancel}
      nextText={t("general.confirm")}
      nextCb={() => {
        onConfirmSelectMedia && currentMediaGallery && onConfirmSelectMedia(currentMediaGallery);
        {
          modalState ? dispatch(closeStandardModal(modalId)) : navigate(-1);
        }
      }}
    />
  );
};
