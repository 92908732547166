type Props = {
  label?: string;
  value: number;
  setValue?: any;
  printValue?: boolean;
  disabled?: boolean;
};

export default function RangeSelector({
  label,
  value,
  setValue,
  printValue = true,
  disabled = false,
}: Props) {
  const prettyValue = Math.round(value * 100);

  return (
    <div>
      {label && (
        <label
          htmlFor="steps-range"
          className=" text-center block mb-2 text-sm font-medium text-black dark:text-black"
        >
          {label} {printValue && `: ${prettyValue}%`}
        </label>
      )}
      <input
        id="rangeSelector"
        type="range"
        min="0.01"
        max="1"
        value={value}
        onChange={(event) => {
          setValue(Number(event.target.value));
        }}
        step="0.01"
        className="w-96 h-5 bg-gray-200 rounded-lg range-selector cursor-pointer dark:bg-gray-400 ml-72"
        style={{
          accentColor: "rgb(0 180 123)",
        }}
        disabled={disabled}
      ></input>
    </div>
  );
}
