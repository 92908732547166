export interface UserFormResults {
  metier?: string;
  followUp?: string;
}

export interface ChatState {
  userFormResults: UserFormResults;
  userPrompt: string;
}

export const initialState: ChatState = {
  userFormResults: {},
  userPrompt: "",
};

export const updateChatState = (state: ChatState, key: keyof UserFormResults, value: string) => {
  return { ...state, userFormResults: { ...state.userFormResults, [key]: value } };
};
